import React from 'react'

const deepmerge = (a, b) => a;

const commonSurfaceStyles = theme => {

        if(!theme.palette){
           return {}
        }
        return {
            /* ------ displayVariants ----- */
            displayVariantNone: {
                //Do not remove - it's empty, but required
            },
            displayVariantOutlined: {
                border: `1px solid ${theme.palette.divider}`,
            },
            displayVariantOutlinedHover: {
                border: `1px solid ${theme.palette.divider}`,
                transition: theme.transitions.create(['box-shadow', 'border'], {
                    duration: theme.transitions.duration.short,
                }),
                '&:hover': {
                    boxShadow: theme.shadows[4],
                    border: `1px solid ${theme.palette.divider}`,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        boxShadow: theme.shadows[2],
                    },
                },
            },
            displayVariantElevationHover: {
                transition: theme.transitions.create(['box-shadow'], {
                    duration: theme.transitions.duration.short,
                }),
                '&:hover': {
                    boxShadow: theme.shadows[4],
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        boxShadow: theme.shadows[2],
                    },
                },
            },
            displayVariantBackgroundHover: {
                transition: theme.transitions.create(['background'], {
                    duration: theme.transitions.duration.short,
                }),
                '&:hover': {
                    //backgroundColor: systemSubZero,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: 'inherit',
                    },
                },
            },
            /* ------ variants ----- */
            withNoBoxShadow: {
                boxShadow: 'none',
            },
            variantFitted: {
                padding: 0,
            },
            variantDense: {
                padding: theme.spacing(2),
            },
            variantMinimal: {
                padding: theme.spacing(0.3),
            },
            variantSmall: {
                padding: theme.spacing(1),
                [theme.breakpoints.up('lg')]: {
                    padding: theme.spacing(1),
                },
            },
            variantMedium: {
                padding: theme.spacing(3),
                [theme.breakpoints.up('lg')]: {
                    padding: theme.spacing(3),
                },
            },
            variantLarge: {
                [theme.breakpoints.up('sm')]: {
                    padding: theme.spacing(7),
                },
                [theme.breakpoints.up('lg')]: {
                    padding: theme.spacing(7),
                },
            },
            variantXL: {
                [theme.breakpoints.up('sm')]: {
                    padding: theme.spacing(7),
                },
                [theme.breakpoints.up('lg')]: {
                    padding: theme.spacing(7),
                },
            },
            variantMobileOnly: {
                padding: theme.spacing(0, 0),
                [theme.breakpoints.down('sm')]: {
                    padding: theme.spacing(3),
                },
            },
            /* -------------------------------- */
            withOutline: {
                border: `1px solid ${theme.palette.divider}`,
            },
            /* ------ padding and margins ----- */
            withNoTopPadding: {
                paddingTop: 0,
            },
            withLargeTopPadding: {
                paddingTop: theme.spacing(7),
            },
            withLargeBottomPadding: {
                paddingBottom: theme.spacing(7),
            },
            withMediumTopMargin: {
                marginTop: theme.spacing(3),
            },
            withMediumBottomMargin: {
                marginBottom: theme.spacing(3),
            },
            withSmallBottomMargin: {
                marginBottom: theme.spacing(1),
            },
            withMinimalMargin: {
                margin: theme.spacing(0.3),
            },
            withMinimalTopMargin: {
                marginTop: theme.spacing(0.3),
            },
            withMinimalLeftMargin: {
                marginLeft: theme.spacing(0.3),
            },
            withMinimalBottomMargin: {
                marginBottom: theme.spacing(0.3),
            },
            withMinimalRightMargin: {
                marginRight: theme.spacing(0.3),
            },
            withLargeMargins: {
                margin: theme.spacing(7),
            },
            withLargeTopMargin: {
                marginTop: theme.spacing(7),
            },
            withLargeLeftMargin: {
                marginLeft: theme.spacing(7),
            },
            withLargeBottomMargin: {
                marginBottom: theme.spacing(7),
            },
            withLargeRightMargin: {
                marginRight: theme.spacing(7),
            },
            withMinimalPadding: {
                padding: theme.spacing(0.3),
            },
            withNoMargins: {
                margin: 0,
            },
            withSmallMargins: {
                margin: theme.spacing(1),
            },
            withNoPadding: {
                padding: 0
            },
            removeTopPadding: {
                paddingTop: 0,
            },
            removeBottomPadding: {
                paddingBottom: 0,
            },
            removeLeftPadding:{
                paddingLeft: 0,
            },
            removeRightPadding:{
                paddingRight: 0,
            },
            removeTopMargin: {
                marginTop: 0,
            },
            removeBottomMargin: {
                marginBottom: 0,
            },
            removeLeftMargin:{
                marginLeft: 0,
            },
            removeRightMargin:{
                marginRight: 0,
            },
            withMinimalTopPadding: {
                paddingTop: theme.spacing(0.3),
            },
            withMinimalLeftPadding: {
                paddingLeft: theme.spacing(0.3),
            },
            withMinimalBottomPadding: {
                paddingBottom: theme.spacing(0.3),
            },
            withMinimalRightPadding: {
                paddingRight: theme.spacing(0.3),
            },
            withMediumTopPadding: {
                paddingTop: theme.spacing(3),
            },
            withMediumLeftPadding: {
                paddingLeft: theme.spacing(3),
            },
            withMediumBottomPadding: {
                paddingBottom: theme.spacing(3),
            },
            withMediumRightPadding: {
                paddingRight: theme.spacing(3),
            },
            /* ------ backgrounds ----- */
            defaultBackground: {
                backgroundColor: theme.palette.background.default,
            },
            systemBackground: {
                //backgroundColor: systemSubZero,
            },
            backgroundPaper: {
                backgroundColor: theme.palette.background.paper,
            },
            backgroundColorNone: {
                backgroundColor: 'transparent'
            },
            backgroundColorPaper: {
                backgroundColor: theme.palette.background.paper
            },
            backgroundColorDefault: {
                backgroundColor: theme.palette.background.default
            },
            backgroundColorSecondary: {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText
            },
            backgroundColorPrimary: {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText
            },
            /* ------ misc ----- */
            noElevation: {
                boxShadow: 'none'
            }
        }
    })

export const makeSurfaceStyleSheet = (theme, specialisedStyles) => {
    const styleSheet = deepmerge(commonSurfaceStyles(theme), specialisedStyles);
    return {};
}

export const contentBlockStyleSheet = theme => {
	const elevations = {};
	theme.shadows.forEach((shadow, index) => {
		elevations[`elevation${index}`] = {
	  		boxShadow: shadow,
		};
	});

    let styles = {
       root: {
           // backgroundColor: 'transparent',
       },
        innerWrap: {
            padding: theme.spacing(2),
             [theme.breakpoints.up('sm')]: {
				paddingBottom: theme.spacing(3),
				paddingTop: theme.spacing(3),
			},
        },
        noElevation: {
            // backgroundColor: 'transparent',
            width: '100%',
        },
        roundedCorners: {
            borderRadius: theme.shape.borderRadius
        },
		...elevations,
    };

    const styleSheet = makeSurfaceStyleSheet(theme, styles)

    const finalStyleSheet = theme.breakpoints.keys.reduce((styles, breakpoint) => {
        return styles;
    }, styleSheet);

    return finalStyleSheet;
};