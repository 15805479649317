import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {darken, lighten,} from '@mui/material/styles';
import { withStyles } from '@mui/styles';;
import ContentBlock from '../surface/ContentBlock';

import WarningOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SuccessOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import {capitalize} from '@mui/material/utils';

export const styles = (theme) => {
if(!theme.palette){
    return {}
}
    const getColor = theme.palette.type === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.type === 'light' ? lighten : darken;
    const defaultColor = theme.palette.grey['200'];
    return {
        /* Styles applied to the root element. */
        root: {
            ...theme.typography.body2,
            // borderRadius: theme.shape.borderRadius,
            backgroundColor: 'transparent',
            display: 'flex',
            padding: theme.spacing(0.75, 1.25),
            alignItems: 'center'
        },
        /* Styles applied to the root element if `variant="standard"` and `color="success"`. */
        standardSuccess: {
            color: getColor(theme.palette.success.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.success.main, 0.9),
            '& $icon': {
                color: theme.palette.success.main,
            },
        },
        /* Styles applied to the root element if `variant="standard"` and `color="info"`. */
        standardInfo: {
            color: getColor(theme.palette.info.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.info.main, 0.9),
            '& $icon': {
                color: theme.palette.info.main,
            },
        },
        /* Styles applied to the root element if `variant="standard"` and `color="warning"`. */
        standardWarning: {
            color: getColor(theme.palette.warning.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.warning.main, 0.9),
            '& $icon': {
                color: theme.palette.warning.main,
            },
        },
        /* Styles applied to the root element if `variant="standard"` and `color="error"`. */
        standardError: {
            color: getColor(theme.palette.error.main, 0.6),
            backgroundColor: getBackgroundColor(theme.palette.error.main, 0.9),
            '& $icon': {
                color: theme.palette.error.main,
            },
        },
        standardDefault: {
            color: getColor(theme.palette.grey[800], 0.6),
            backgroundColor: getBackgroundColor(defaultColor, 0.9),
            '& $icon': {
                color: theme.palette.grey[800],
            },
        },
        /* Styles applied to the root element if `variant="outlined"` and `color="success"`. */
        outlinedSuccess: {
            color: getColor(theme.palette.success.main, 0.6),
            border: `${theme.spacing(0.125)} solid ${theme.palette.success.main}`,
            '& $icon': {
                color: theme.palette.success.main,
            },
        },
        /* Styles applied to the root element if `variant="outlined"` and `color="info"`. */
        outlinedInfo: {
            color: getColor(theme.palette.info.main, 0.6),
            border: `${theme.spacing(0.125)} solid ${theme.palette.info.main}`,
            '& $icon': {
                color: theme.palette.info.main,
            },
        },
        /* Styles applied to the root element if `variant="outlined"` and `color="warning"`. */
        outlinedWarning: {
            color: getColor(theme.palette.warning.main, 0.6),
            border: `${theme.spacing(0.125)} solid ${theme.palette.warning.main}`,
            '& $icon': {
                color: theme.palette.warning.main,
            },
        },
        /* Styles applied to the root element if `variant="outlined"` and `color="error"`. */
        outlinedError: {
            color: getColor(theme.palette.error.main, 0.6),
            border: `${theme.spacing(0.125)} solid ${theme.palette.error.main}`,
            '& $icon': {
                color: theme.palette.error.main,
            },
        },
        /* Styles applied to the root element if `variant="outlined"` and `color="default"`. */
        outlinedDefault: {
            color: getColor(theme.palette.grey[800], 0.6),
            border: `${theme.spacing(0.125)} solid ${defaultColor}`,
            '& $icon': {
                color: theme.palette.grey[800],
            },
        },

        /* Styles applied to the root element if `variant="filled"` and `color="default"`. */
        filledDefault: {
            color: theme.palette.grey[800],
            backgroundColor: defaultColor,
            fontWeight: theme.typography.fontWeightMedium,
        },
        /* Styles applied to the root element if `variant="filled"` and `color="success"`. */
        filledSuccess: {
            color: '#fff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.success.main,
        },
        /* Styles applied to the root element if `variant="filled"` and `color="info"`. */
        filledInfo: {
            color: '#fff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.info.main,
        },
        /* Styles applied to the root element if `variant="filled"` and `color="warning"`. */
        filledWarning: {
            color: '#fff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.warning.main,
        },
        /* Styles applied to the root element if `variant="filled"` and `color="error"`. */
        filledError: {
            color: '#fff',
            fontWeight: theme.typography.fontWeightMedium,
            backgroundColor: theme.palette.error.main,
        },

        /* Styles applied to the root element if `variant="filled"` and `color="default"` and `typeVariant="info"`. */
        filledDefaultInfo: {
            color: theme.palette.grey[800],
            backgroundColor: defaultColor,
            '& $icon': {
                color: theme.palette.info.main,
            },
        },
        /* Styles applied to the icon wrapper element. */
        icon: {
            marginRight: 12,
            padding: theme.spacing(0.875, 0),
            display: 'flex',
            fontSize: 22,
            opacity: 0.9,
        },
        /* Styles applied to the message wrapper element. */
        message: {
            padding: theme.spacing(1, 0),
        },
    }
}

const defaultIconMapping = {
    success: <SuccessOutlinedIcon fontSize="inherit" />,
    warning: <WarningOutlinedIcon fontSize="inherit" />,
    error: <ErrorOutlineOutlinedIcon fontSize="inherit" />,
    info: <InfoOutlinedIcon fontSize="inherit" />,
}

const CallOut = React.forwardRef(function Callout(props, ref) {
    const {
        children,
        classes,
        className,
        color,
        icon,
        iconMapping = defaultIconMapping,
        role,
        square,
        typeVariant = 'info',
        variant = 'standard',
        ...other
    } = props;

    return (
        <ContentBlock
            role="alert"
            square={square ? square : undefined}
            elevation={0}
            overrideClasses={classnames(
                classes.root,
                classes[`${variant}${capitalize(color || typeVariant)}`],
                className,
            )}
            ref={ref}
            {...other}
        >
            {icon !== false && (
                <div className={classes.icon}>
                    {icon || iconMapping[typeVariant] || defaultIconMapping[typeVariant]}
                </div>
            )}
            <div className={classes.message}>{children}</div>
        </ContentBlock>
    );
});

CallOut.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * Override the default label for the *close popup* icon button.
     *
     * For localization purposes, you can use the provided [translations](/guides/localization/).
     */
    closeText: PropTypes.string,
    /**
     * The main color for the alert. Unless provided, the value is taken from the `typeVariant` prop.
     */
    color: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'default']),
    /**
     * Override the icon displayed before the children.
     * Unless provided, the icon is mapped to the value of the `typeVariant` prop.
     */
    icon: PropTypes.node,
    /**
     * The component maps the `typeVariant` prop to a range of different icons,
     * for instance success to `<SuccessOutlined>`.
     * If you wish to change this mapping, you can provide your own.
     * Alternatively, you can use the `icon` prop to override the icon displayed.
     */
    iconMapping: PropTypes.shape({
        error: PropTypes.node,
        info: PropTypes.node,
        success: PropTypes.node,
        warning: PropTypes.node,
    }),
    /**
     * The ARIA role attribute of the element.
     */
    role: PropTypes.string,
    /**
     * The infoType of the alert. This defines the color and icon used.
     */
    typeVariant: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
    /**
     * The variant to use.
     */
    variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
}

export default withStyles(styles)(CallOut);