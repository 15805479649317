import React from 'react'
import PropTypes from 'prop-types';

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

export default function LegendItem(props) {
  	const { label, color } = props;
	console.log('LegendItem label:', label);

	return (
		<Grid container alignItems="center">
			<Box display="flex" component="div" bgcolor={color} m={[0, 2, 0, 0]} p={1} />
			<Box display="flex" flexGrow="1" p={2} fontSize="body2.fontSize">
				{label}
			</Box>
		</Grid>
	);
}

LegendItem.propTypes = {
   label: PropTypes.string.isRequired,
   color: PropTypes.node.isRequired,
};

LegendItem.defaultProps = {
	label: 'null',
	color:  'black',
};
