let counter = 0;

export const makeActionId = (type) => {
    return type.toLowerCase().concat('-').concat(""+Date.now()).concat("" + ++counter);
}

export const LOAD_CURRENT_READINGS = '$$LOAD_CURRENT_READINGS';
export const LOAD_CURRENT_READINGS_SUCCESS = '$$LOAD_CURRENT_READINGS_SUCCESS';
export const LOAD_CURRENT_READINGS_FAIL = '$$LOAD_CURRENT_READINGS_FAIL';

export const loadCurrentReadings = (userContext) => {
    if(userContext){
        return { type: LOAD_CURRENT_READINGS, actionId: makeActionId(LOAD_CURRENT_READINGS), userContext }
    }
    return clearCurrentReadings()
}

export const CLEAR_CURRENT_READINGS = '$$CLEAR_CURRENT_READINGS';
export const clearCurrentReadings = () => {
    return { type: CLEAR_CURRENT_READINGS}
}