import React from 'react';
import PropTypes from 'prop-types';

import MessageDisplay from './MessageDisplay';
import {dcxMessages} from './../i18n/BaseAppMessages';

const Success = ({children, message, withLargeTopMargin = false}) => {
    return (
        <MessageDisplay
            message={message}
            typeVariant={'success'}
            withLargeTopMargin={withLargeTopMargin}
        >
            {children && children}
        </MessageDisplay>
    )
}

Success.defaultProps = {
    message: dcxMessages.defaultSuccessMessage,
}
Success.propTypes = {
    message: PropTypes.object,
}
export default (Success);