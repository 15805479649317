import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';

import {getToken} from './../token-utils';
import {getCurrentUser} from './../actions/authentication-actions';

import {RequestStatus} from '@dc/dcx/app/util/RequestConstants';

class AuthContainer extends React.Component {
    render() {
        const {
            children,
            isAuthorised,
            isActive,
            userRequired,
            userData,
            loadAction
        } = this.props;

        if(isActive){
            return (<>Loading</>);
        }

        if ((userRequired && !isAuthorised )) {
            return this.renderFail(userRequired);
        }


        if (isAuthorised && loadAction) {
           debugger;
            loadAction(userData)
        }
        if (children) {
            return children;
        }
        debugger;
        return (null);
    }

    renderFail(userRequired) {
        const {isActive} = this.props;

        if (userRequired) {
           return (
               <Navigate
                   to={'/signin'}
               />
           )
        }
        debugger;
        return ("<>navi default</>")
    }
}

const mapStateToProps = (state) => {
    const {auth: {authTokenStatus, user: userData}} = state;
    let isActive =  RequestStatus.ACTIVE == authTokenStatus.currentStatus;
    let isAuthorised = RequestStatus.SUCCESS == authTokenStatus.currentStatus;
    return {
        isActive,
        isAuthorised,
        userData,
    }
}


AuthContainer.propTypes = {
    children: PropTypes.node.isRequired,
    isActive: PropTypes.bool.isRequired,
    isAuthorised: PropTypes.bool.isRequired,
    userData: PropTypes.object,
    user: PropTypes.bool,
    guest: PropTypes.bool,
    redirect: PropTypes.any,
    loadAction: PropTypes.func,
};

AuthContainer.defaultProps = {
    user: null,
}

export default connect(mapStateToProps)(AuthContainer)
