const dotProp = require('dot-prop-immutable');
import {RequestStatus} from '@dc/dcx/app/util/RequestConstants';

import {
    AUTH_TOKEN,
    AUTH_TOKEN_FAIL,
    AUTH_TOKEN_SUCCESS,
    AUTHENTICATION,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_SUCCESS,
    SIGNOUT,
    SIGNOUT_SUCCESS
} from '../actions/authentication-actions'

const newAuthenticationStatus = () => {
    return { currentStatus: RequestStatus.NONE, nonce: null, authToken: null };
}

const newTokenAuthenticationStatus = () => {
    return { currentStatus: RequestStatus.NONE, nonce: null, authToken: null };
}

const newAuthTokenStatus = () => {
    return { currentStatus: RequestStatus.NONE, nonce: null };
}

const initialState = {
  authenticationStatus: newAuthenticationStatus(),
  authTokenStatus: newAuthTokenStatus()
};

const handleAuthenticationFailure = (state, actionData) => {
    let requestStatus = RequestStatus.FAIL;
    if(403 == actionData.errorCode){
        requestStatus = RequestStatus.FORBIDDEN;
    } else if(401 == actionData.errorCode){
        requestStatus = RequestStatus.UNAUTHORIZED;
    } else if(412 == actionData.errorCode){
        requestStatus = RequestStatus.REJECTED;
    }
    return mergeAuthenticationStatus(state, requestStatus, actionData.data)
}

const mergeAuthTokenStatus  = (state, currentStatus) => {
  let authTokenStatus = { ...state.authTokenStatus };
  let updatedAuthTokenStatus = dotProp.set(authTokenStatus, ['currentStatus'], currentStatus);
  return updatedAuthTokenStatus;
}

const mergeAuthenticationStatus = (state, currentStatus, data) => {
  let authenticationStatus = { ...state.authenticationStatus };
  let updatedAuthenticationStatus = dotProp.set(authenticationStatus, ['currentStatus'], currentStatus);
  if(data && RequestStatus.REJECTED == currentStatus){
      updatedAuthenticationStatus = dotProp.set(updatedAuthenticationStatus, ['data'], data);
  }
  return updatedAuthenticationStatus;
}

const mergeAuthenticationSuccess  = (state, currentStatus, actionData) => {
   return { currentStatus, authToken: actionData.data.token }
}

const reducers = (state = initialState, actionData = null) => {
  switch (actionData.type) {
    case AUTHENTICATION:
      return {
        ...state,
        authenticationStatus: mergeAuthenticationStatus(state, RequestStatus.ACTIVE, actionData),
      };

    case AUTHENTICATION_SUCCESS:
      return {
        ...state,
        authenticationStatus: mergeAuthenticationSuccess(state, RequestStatus.SUCCESS, actionData),
      };

    case AUTHENTICATION_FAIL:
      return {
        ...state,
        authenticationStatus: handleAuthenticationFailure(state, actionData),
      };
    case SIGNOUT:
      return {
        ...state,
        user: null,
      };

    case AUTH_TOKEN:
      return {
        ...state,
        authTokenStatus: mergeAuthTokenStatus(state, RequestStatus.ACTIVE),
        user: null,
      };

    case AUTH_TOKEN_SUCCESS:
      return {
        ...state,
        authTokenStatus: mergeAuthTokenStatus(state, RequestStatus.SUCCESS),
        user: actionData.data,
      };

    case AUTH_TOKEN_FAIL:
      return {
        ...state,
        authTokenStatus: mergeAuthTokenStatus(state, RequestStatus.FAIL),
        authenticationStatus: mergeAuthenticationStatus(state, RequestStatus.NONE),
        user: null,
      };
    case SIGNOUT_SUCCESS:
      return {
        ...state,
        authTokenStatus: mergeAuthTokenStatus(state, RequestStatus.NONE),
        authenticationStatus: mergeAuthenticationStatus(state, RequestStatus.NONE),
        user: null,
      };
    default:
      return state;
  }
};

export default reducers;
