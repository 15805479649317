import React from 'react';
import Button from '@mui/material/Button';
import SecondaryButton from '@dc/dc/components/buttons/SecondaryButton';
import CTAButton from '@dc/dc/components/buttons/CTAButton';
import SubmitButton from '@dc/dc/components/buttons/SubmitButton';
import ChangeStatusButton from "../buttons/ChangeStatusButton";

import {toTheme} from '@dc/dc/themes/themes-base';

import {navigationLinkStyleSheet} from '@dc/dc/style/BaseStyles';

export const userActionNavigationButton = (classes, buttonLabel, buttonAction) => (
    <Button
        variant="contained"
        color="primary"
        size="small"
        aria-label="add"
        className={classes.button}
        onClick={buttonAction}
    >
        {buttonLabel}
    </Button>
)

export const userActionSubmissionButton = (buttonLabel, handleSubmit) => (
    <SubmitButton
        buttonText={buttonLabel}
        onClick={handleSubmit}
    />
)

export const userActionDialogButtonCreator = (buttonLabel) => (clickFunction) => {
    return (
        <CTAButton
            ActionBtnLight
            buttonText={buttonLabel}
            onClick={clickFunction}
        />
    )
}

export const userActionDialogButtonCreator_DIST = (buttonLabel) => (clickFunction) => (
    <SecondaryButton
        ActionBtnLight
        buttonText={buttonLabel}
        onClick={clickFunction}
    />
)

export const launchActionDialogButtonCreator = (classes, buttonLabel) => (clickFunction) => (
    <ChangeStatusButton
        buttonText={buttonLabel}
        onClick={(e) => {
            clickFunction(e)
            e.stopPropagation();
            e.preventDefault();
        }}
    />
)

export const dashboardStyleSheet = theme => ({
    menuBadge: {
        marginRight: theme.spacing(3.5),
        fontSize: '1.7em',
    },
    listItemIconCustom: {
        minWidth: 0,
    },
    newActionsBadge: {
        backgroundColor: theme.palette.background.newAction,
        width: theme.spacing(2.5),
        left: `-${theme.spacing(1)}px`
    },
    pendingActionsBadge: {
        backgroundColor: theme.palette.background.pending,
        width: theme.spacing(2.5),
        left: `-${theme.spacing(1)}px`
    },
    activeActionsBadge: {
        backgroundColor: theme.palette.background.activeAction,
        width: theme.spacing(2.5),
        left: `-${theme.spacing(1)}px`
    },
    defaultNavigationLinkText: {
        color: theme.palette.text.secondary
    }
})

export const innerTheme = toTheme({
    palette: {
        type: 'dark', // Switching the dark mode on is a single field value change.
        primary: {
            main: '#ffffff',
//            contrastText: brandPrimaryText
        },
        background: {
            default: '#ffffff',
//            paper: newStateColor
        }
    },
    typography: {
//		useNextVariants: true,
        fontSize: 14,
        htmlFontSize: 16,
        //    fontFamily: "'Roboto', sans-serif",
        h1: {
            fontSize: "2.125rem",
            lineHeight: "2.375rem",
            fontWeight: 400,
            '@media (min-width:600px)': {
                fontSize: "3.375rem",
                lineHeight: "3.625rem",
            },
        },
        h2: {
            fontSize: "2.125rem",
            lineHeight: "2.375rem",
            fontWeight: 400,
        },
        h3: {
            fontSize: "1.25rem",
            lineHeight: "1.625rem",
            fontWeight: 400,
        },
        h4: {
            fontSize: "1.125rem",
            lineHeight: "1.5rem",
            fontWeight: 500,
        },
        h5: {
            fontSize: "1.375rem",
            fontWeight: 400,
        },
        h6: {
            fontSize: "1.225rem",
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            fontWeight: 400,
        },
        body2: {
            fontSize: "0.875rem",
            fontWeight: 400,
        },
        caption: {
            fontSize: "0.75rem",
            fontWeight: 400,
        },
    },
})

const portalLifecyclePalette = {
    palette: {
        status: {
            intermediateStateRole: '#FF9800',
            initialStateRole: '#f5C224',
            successStateRole: '#1FC754',
            captureStateRole: '#32A0B8',
            failStateRole: '#E10050',
            standard: '#55E1FE',
            intermediate: '#5385FE',
            advanced: '#8E5AFD',
            enterprise: '#4743C0',
            warn: 'rgb(228, 161, 90)',
            bug: '#e11d21',
            incomplete: '#ccc',
            important: 'purple',
            docs: 'yellow',
        }
    }
}

export const tagStyleSheet = theme => {
    return {
        root: {
            marginRight: theme.spacing(0.5),
            borderRadius: 0,
            display: 'inline-flex',
            padding: '0.08em ',
            justifyContent: 'center',
            flexWrap: 'wrap',
            position: 'relative',
            height: theme.spacing(3),
            fontSize: '0.65rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.75rem',
            }
        },
        label: {
            fontWeight: '500',
        },
        clickable: {
            backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            '&:hover': {
                backgroundColor: 'inherit'
            },
        },
        intermediate: {
            backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            '&:hover': {
                backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            },
            '&:active': {
                backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            },
            '&:focus': {
                backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            }
        },
        standard: {
            backgroundColor: portalLifecyclePalette.palette.status.standard,
            '&:hover': {
                backgroundColor: portalLifecyclePalette.palette.status.standard,
            },
            '&:active': {
                backgroundColor: portalLifecyclePalette.palette.status.standard,
            },
            '&:focus': {
                backgroundColor: portalLifecyclePalette.palette.status.standard,
            }
        },
        advanced: {
            backgroundColor: portalLifecyclePalette.palette.status.advanced,
            '&:hover': {
                backgroundColor: portalLifecyclePalette.palette.status.advanced,
            },
            '&:active': {
                backgroundColor: portalLifecyclePalette.palette.status.advanced,
            },
            '&:focus': {
                backgroundColor: portalLifecyclePalette.palette.status.advanced,
            }
        },
        enterprise: {
            backgroundColor: portalLifecyclePalette.palette.status.enterprise,
            '&:hover': {
                backgroundColor: portalLifecyclePalette.palette.status.enterprise,
            },
            '&:active': {
                backgroundColor: portalLifecyclePalette.palette.status.enterprise,
            },
            '&:focus': {
                backgroundColor: portalLifecyclePalette.palette.status.enterprise,
            }
        },
        docs: {
            backgroundColor: portalLifecyclePalette.palette.status.docs,
        },
        bug: {
            backgroundColor: portalLifecyclePalette.palette.status.bug,
            color: theme.palette.common.white,
        },
        incomplete: {
            backgroundColor: portalLifecyclePalette.palette.status.incomplete,
        },
        important: {
            backgroundColor: portalLifecyclePalette.palette.status.important,
            color: theme.palette.common.white,
        },
    }
};

export const chipTagStyleSheet = theme => {
    return {
        root: {
            justifyContent: 'center',
            flexWrap: 'wrap',
            position: 'relative',
            display: 'inline-flex',
            margin: '0 0.5em',
            padding: '0.1em',
            height: theme.spacing(3),
            fontSize: '0.65rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.75rem',
            },
            borderRadius: 0,
            borderWidth: '1px',
        },
        rootRounded: {
            display: 'inline-flex',
            padding: '0.1em ',
            justifyContent: 'center',
            flexWrap: 'wrap',
            position: 'relative',
            height: theme.spacing(3),
            fontSize: '0.65rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.75rem',
            }
        },
        rootSquare: {
            borderRadius: 0,
            display: 'inline-flex',
            padding: '0.1em ',
            justifyContent: 'center',
            flexWrap: 'wrap',
            position: 'relative',
            height: theme.spacing(3),
            fontSize: '0.65rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.75rem',
            }
        },
        label: {
            fontWeight: theme.typography.fontWeightRegular,
            lineHeight: '1em'
        },
        intermediate: {
            backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            '&:hover': {
                backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            },
            '&:active': {
                backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            },
            '&:focus': {
                backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            }
        },
        standard: {
            backgroundColor: portalLifecyclePalette.palette.status.standard,
            '&:hover': {
                backgroundColor: portalLifecyclePalette.palette.status.standard,
            },
            '&:active': {
                backgroundColor: portalLifecyclePalette.palette.status.standard,
            },
            '&:focus': {
                backgroundColor: portalLifecyclePalette.palette.status.standard,
            }
        },
        advanced: {
            backgroundColor: portalLifecyclePalette.palette.status.advanced,
            '&:hover': {
                backgroundColor: portalLifecyclePalette.palette.status.advanced,
            },
            '&:active': {
                backgroundColor: portalLifecyclePalette.palette.status.advanced,
            },
            '&:focus': {
                backgroundColor: portalLifecyclePalette.palette.status.advanced,
            }
        },
        enterprise: {
            backgroundColor: portalLifecyclePalette.palette.status.enterprise,
            '&:hover': {
                backgroundColor: portalLifecyclePalette.palette.status.enterprise,
            },
            '&:active': {
                backgroundColor: portalLifecyclePalette.palette.status.enterprise,
            },
            '&:focus': {
                backgroundColor: portalLifecyclePalette.palette.status.enterprise,
            }
        },
        initialStateRole: {
            borderColor: portalLifecyclePalette.palette.status.initialStateRole,
            border: `1px solid ${portalLifecyclePalette.palette.status.initialStateRole}`,
            // backgroundColor: portalLifecyclePalette.palette.status.initialStateRole,
            // '&:hover': {
            //     backgroundColor: portalLifecyclePalette.palette.status.initialStateRole,
            // },
            // '&:active': {
            //     backgroundColor: portalLifecyclePalette.palette.status.initialStateRole,
            // },
            // '&:focus': {
            //     backgroundColor: portalLifecyclePalette.palette.status.initialStateRole,
            // }
        },
        intermediateStateRole: {
            borderColor: portalLifecyclePalette.palette.status.intermediateStateRole,
            border: `3px solid ${portalLifecyclePalette.palette.status.intermediateStateRole}`,
            // backgroundColor: portalLifecyclePalette.palette.status.intermediateStateRole,
            // '&:hover': {
            //     backgroundColor: portalLifecyclePalette.palette.status.intermediateStateRole,
            // },
            // '&:active': {
            //     backgroundColor: portalLifecyclePalette.palette.status.intermediateStateRole,
            // },
            // '&:focus': {
            //     backgroundColor: portalLifecyclePalette.palette.status.intermediateStateRole,
            // }
        },
        successStateRole: {
            borderColor: portalLifecyclePalette.palette.status.successStateRole,
            border: `3px solid ${portalLifecyclePalette.palette.status.successStateRole}`,
            // backgroundColor: portalLifecyclePalette.palette.status.successStateRole,
            // '&:hover': {
            //     backgroundColor: portalLifecyclePalette.palette.status.successStateRole,
            // },
            // '&:active': {
            //     backgroundColor: portalLifecyclePalette.palette.status.successStateRole,
            // },
            // '&:focus': {
            //     backgroundColor: portalLifecyclePalette.palette.status.successStateRole,
            // }
        },
        failStateRole: {
            borderColor: portalLifecyclePalette.palette.status.failStateRole,
            border: `3px solid ${portalLifecyclePalette.palette.status.failStateRole}`,
            // backgroundColor: portalLifecyclePalette.palette.status.failStateRole,
            // '&:hover': {
            //     backgroundColor: portalLifecyclePalette.palette.status.failStateRole,
            // },
            // '&:active': {
            //     backgroundColor: portalLifecyclePalette.palette.status.failStateRole,
            // },
            // '&:focus': {
            //     backgroundColor: portalLifecyclePalette.palette.status.failStateRole,
            // }
        },
        captureStateRole: {
            borderColor: portalLifecyclePalette.palette.status.captureStateRole,
            border: `3px solid ${portalLifecyclePalette.palette.status.captureStateRole}`,
            // backgroundColor: portalLifecyclePalette.palette.status.captureStateRole,
            // '&:hover': {
            // 	backgroundColor: portalLifecyclePalette.palette.status.captureStateRole,
            // },
            // '&:active': {
            // 	backgroundColor: portalLifecyclePalette.palette.status.captureStateRole,
            // },
            // '&:focus': {
            // 	backgroundColor: portalLifecyclePalette.palette.status.captureStateRole,
            // }
        },
        clickable: {
            // backgroundColor: portalLifecyclePalette.palette.status.intermediate,
            '&:hover': {
                backgroundColor: 'inherit'
            },
        },
    }
};

export const resourceHeaderStylesheet = theme => (Object.assign({
    orderFixXs: {
        minWidth: '100%',
        flexBasis: '100%',
        order: '-1',
        [theme.breakpoints.up('sm')]: {
//          order: '0',
            flexGrow: '2',
        },
        [theme.breakpoints.down('md')]: {
            minWidth: '100%',
            flexBasis: '100%',
//            order: '-1',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
            flexBasis: '100%',
//            order: '-1',
        }
    },
    tagsOrderFix: {
        flexGrow: 2,
        [theme.breakpoints.up('sm')]: {
            order: '0',
        },
        [theme.breakpoints.down('xs')]: {
            order: '1',
        }
    },
    resourceLabel: {
        [theme.breakpoints.down('xs')]: {
            flexGrow: 2,
        }
    },
    avatar: {
        display: 'inline-flex',
        fontSize: 'inherit',
    },
    textAttention: {
        color: theme.palette.grey['600'],
    },
    textAttentionBackgroundWrapper: {
        color: theme.palette.grey['200'],
    },
    circleRadius: {
        borderRadius: 90,
    },
    verticalLeading: {
        padding: theme.spacing(1),
    },
    removeSideLeading: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    gutterPadding: {
        padding: `0 ${theme.spacing(1)}px`,
    },
    spacer: {
        marginRight: `${theme.spacing(1)}px`,
    },
    badge: {
        lineHeight: '100%',
        fontSize: '0.875rem',
        top: 'auto',
        width: '0.875rem',
        right: '-0.875rem',
        height: '0.875rem',
    },
    fontWeightMedium: {
        fontWeight: theme.typography.fontWeightMedium
    }
}, navigationLinkStyleSheet))

export const metadataHeaderStyleSheet = theme => ({
    denseText: {
        [theme.breakpoints.down('sm')]: {
            lineHeight: '100%',
        },
    },
    headerContainer: {
        borderTop: `1px solid ${theme.palette.grey[400]}`,
        //  borderRight: `1px solid ${theme.palette.grey[400]}`,
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
//        marginBottom: theme.spacing(8)
    },
    innerGridWrapper: {
        alignItems: 'stretch',
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center',
        },
    },
    fixedHeightItem: {
        height: theme.spacing(12),
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    lastColumn: {
        borderRight: 'none'
    },
    headerItem: {
        alignSelf: 'stretch',
        flexGrow: 1,
        flexShrink: 1,
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1.5),
        },
        borderRight: `1px solid ${theme.palette.grey[400]}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1.5),
        },
    },
    headerImageItem: {
        alignSelf: 'stretch',
        padding: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(1.5),
        },
        maxWidth: theme.spacing(12),
        borderRight: `1px solid ${theme.palette.grey[400]}`,
        borderBottom: `1px solid ${theme.palette.grey[400]}`,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    headerImage: {
        display: 'inline-flex',
        maxWidth: '100%',
        height: 'auto',
    }
})

export const editorTableStyles = theme => {
    return {
        tableRowHead: {
            fontWeight: theme.typography.fontWeightMedium,
            color: theme.palette.text.primary,
            fontSize: '1.125rem',

        },
        tableRowCellBody: {
            fontSize: '1rem'
        },
        removeLeftPadding: {
            paddingLeft: 0,
        },
        removeRightPadding: {
            paddingRight: 0,
        },
        fixedHeaderCol: {
            width: theme.spacing(24)
        }
    }
};

export const decoratedTableRowStyles = theme => {
    return {
        active: {
            padding: 0,
        }
    }
};

export const genericListStyleSheet = theme => ({
    headerGroup: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(4),
        }
    },
    listContainer: {
        marginBottom: theme.spacing(4),
        width: '100%',
        minWidth: '100%',
        border: `1px solid ${theme.palette.grey[300]}`,
    },
    listContainerNoBorder: {
        marginBottom: theme.spacing(4),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
        minWidth: '100%',
    },
    listGroup: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    listSubheader: {
        marginBottom: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    }
})

export const organizationSectionStyles = theme => ({
    tabsSpacer: props =>
        props.noIndicator ?
            {
                minHeight: theme.spacing(4),
                [theme.breakpoints.up('xs')]: {
                    paddingRight: theme.spacing(7),
                },
            }
            : {
                [theme.breakpoints.up('xs')]: {
                    paddingRight: theme.spacing(4),
                },
            },
    link: props =>
        props.noIndicator ?
            {
                paddingTop: 0,
                paddingBottom: 0,
                height: '100%',
                minHeight: 1,
                minWidth: 'auto',
                marginRight: theme.spacing(1),
                marginLeft: 0,
                paddingRight: 0,
                paddingLeft: 0,
                '&:hover': {
                    color: theme.palette.text.primary
                },
                '&$tabSelected': {
                    color: theme.palette.text.primary,
                    fontWeight: theme.typography.fontWeightMedium,
                },
                '&:focus': {
                    color: theme.palette.text.primary
                },
            }
            : {
                paddingTop: theme.spacing(1.25),
                paddingBottom: theme.spacing(1.25),
                marginRight: theme.spacing(2),
                marginLeft: theme.spacing(2),
                paddingRight: 0,
                paddingLeft: 0,
                textDecoration: 'none',
                fontSize: theme.typography.fontSize,
                color: theme.palette.text.secondary,
                minWidth: theme.spacing(2),
                '&:hover': {
                    color: theme.palette.text.primary
                },
                '&$tabSelected': {
                    color: theme.palette.text.primary,
                    fontWeight: theme.typography.fontWeightMedium,
                },
                '&:focus': {
                    color: theme.palette.text.primary
                },
            },
    tabSelected: {},
    indicator: props =>
        props.noIndicator
            ? {
                height: 0,
                background: 'transparent none',
                transition: 'none',
                display: 'none'
            } :
            {
                height: `${theme.spacing(0.25) + 1}px`,
                background: theme.palette.primary.main,
                transition: 'opacity 0.3s',
            },
    pageWrap: {
        maxWidth: '600px',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1000px',
        },
        margin: '0 auto'
    },
    headingSemiBold: {
        fontWeight: theme.typography.fontWeightMedium,
        paddingBottom: theme.spacing(1.5),
    },
    backgroundWhite: {
        backgroundColor: theme.palette.common.white
    },
    flexLabel: {
        flex: '0 0 160px',
    },
    fieldWrapper: {
        paddingBottom: theme.spacing(2)
    },
    sectionBreakLarge: {
        marginBottom: theme.spacing(7),
    },
    sectionBreak: {
        marginBottom: theme.spacing(3),
    },
    contentBreak: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    pageTabsRoot: {
        alignItems: 'center',
        minHeight: `${theme.spacing(7)}px`
    },
    fullWidth: {
        width: '100%',
        display: 'flex',
    }
})

export const sectionCardStyleSheet = theme => ({
    card: {
        position: 'relative',
        maxWidth: '600px',
        backgroundColor: theme.palette.background.paper,
        // boxShadow: 'none',
    }
})

export const sectionWrapperStyleSheet = theme => ({
    sectionWrapper: {
        display: 'flex',
        width: '100%',
        // padding: theme.spacing(3),
        // [theme.breakpoints.up('lg')]: {
        //     padding: theme.spacing(7, 0),
        // },
    },
    // oversizeSectionWrapper: {
    // 	[theme.breakpoints.up('sm')]: {
    // 		padding: theme.spacing(7, 3, 9),
    // 	},
    // 	[theme.breakpoints.up('lg')]: {
    // 	  padding: theme.spacing(7, 0, 9),
    // 	},
    // },
    // superSizeSectionWrapper: {
    // 	[theme.breakpoints.up('sm')]: {
    // 		padding: theme.spacing(18, 3, 9, 3),
    // 	},
    // },
    // mobileOnlyPadding: {
    //     padding: theme.spacing(0, 0),
    //     [theme.breakpoints.down('sm')]: {
    //         padding: theme.spacing(3),
    //     },
    // }
    variantDense: {
        padding: theme.spacing(2),
    },
    variantMedium: {
        padding: theme.spacing(3),
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(7, 0),
        },
    },
    variantLarge: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(7, 3, 9),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(7, 0, 9),
        },
    },
    variantXL: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(7, 3, 9),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(7, 0, 9),
        },
    },
    variantMobileOnly: {
        padding: theme.spacing(0, 0),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
    },
    removeVerticalPadding: {
        paddingTop: 0,
    },
    removeBottomPadding: {
        paddingBottom: 0,
    },

})

