import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import NoResults from '@dc/dcx/app/common/NoResults';
import ContainerX from '@dc/dc/components/layout/ContainerX';

import DynamicGrid from 'gc-ui-components/ui/grid/DynamicGrid';
import {setCurrentGridConfiguration} from 'gc-ui-components/ui/mw/grid-service/actions/grid-service-actions';

import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import {
    currentReadingsGridCellComponentProvider,
    currentReadingsGridExpandedRowComponentProvider,
    makeProgramsGridConfiguration
} from './GrowcloudGridWrapperHelper';

import {
    getProgramsGridData,
} from './mw/actions/growcloud-grid-services-actions';

import {handleInitialCurrentContext} from './StylesUtils'

const GRID_CHANNEL = 'programs';

const styleSheet = theme => ({ });

import { gridStyles } from './StylesUtils'

const extractRanges = (currentRange, ranges) => {
    let nextRanges = ['SITE', 'ENCLOSURE', 'WATERING', 'HEATING', 'AERATION', 'LIGHTING', 'FEEDING', 'FORMULATION', 'WASTE PROCESSING']
    nextRanges.push('OPTIONS')
    return nextRanges;
}

class ProgramsGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: { page: [] },
            ranges: extractRanges(),
            currentRange: extractRanges()
        }
    }

    leftAction(){
    }

    rightAction(){
    }

    componentDidMount(){
        const{dispatch,contextConfigurations,userContext} = this.props;
        const{currentRange,ranges} = this.state;
        handleInitialCurrentContext(dispatch,
                    {contextConfigurations},
                    GRID_CHANNEL,
                    [GRID_CHANNEL]);
        let gridConfiguration = makeProgramsGridConfiguration(extractRanges(currentRange, ranges))
        dispatch(setCurrentGridConfiguration(GRID_CHANNEL, GRID_CHANNEL, gridConfiguration));
        dispatch(getProgramsGridData(userContext, GRID_CHANNEL, GRID_CHANNEL, gridConfiguration, []))

    }

    static getDerivedStateFromProps(props, state) {
        const {contextConfigurations} = props;
        const {currentPage} = state;
        if (contextConfigurations) {
            if (currentPage && contextConfigurations[GRID_CHANNEL]) {
                if(contextConfigurations[GRID_CHANNEL].currentPage){
                    const nextCurrentPage = contextConfigurations[GRID_CHANNEL].currentPage[GRID_CHANNEL];
                    if (nextCurrentPage) {
                        return {currentPage: nextCurrentPage}
                    }
                }
            }
        }
        return null;
    }

    renderGridLayout() {
        const {currentPage} = this.state;
        const {
            gridConfigurations,
            dispatch,
            theme,
            withStylesDynamicGrid: StyledDynamicGrid
        } = this.props;

        const currentDynamicGrid = gridConfigurations[GRID_CHANNEL];

        if(!currentDynamicGrid){
            return (<>Loading</>)
        }

        let rightDisabled = false;
        let leftDisabled = false;

        return (
                <React.Fragment>
                    <Grid item xs={12}>
                        <ContainerX AsContainer withLargeBottomMargin >
                                <DynamicGrid
                                    pageContext={currentPage}
                                    currentDynamicGrid={currentDynamicGrid}
                                    cellComponentProvider={currentReadingsGridCellComponentProvider}
                                    expandedRowComponentProvider={currentReadingsGridExpandedRowComponentProvider}
                                    gridContext={{dispatch}}
                                    elideTableRowDetail={true}
                                    gridStyles={gridStyles}
                                />
                         </ContainerX>
                 </Grid>
                </React.Fragment>
        )
    }

    render() {
        const {readings} = this.props;
        return this.renderGridLayout()
    }
}

export default connect(state => {
    return {
        gridConfigurations: state.dynamicGrid.gridConfigurations,
        contextConfigurations: state.application.contextConfigurations
    }
})(
    withStyles(styleSheet, {withTheme: true})(ProgramsGrid)
)