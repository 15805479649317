import React from 'react';

import { withStyles } from '@mui/styles';

import ButtonListItem from '@dc/dc/components/lists/ButtonListItem';
import GenericList from "../components/GenericList";
import {dashboardStyleSheet} from "../style/PortalStyles";


class NavigationList extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    renderList() {
        const {classes, items, clickHandler} = this.props;
        let itemsArray = [];
        let counter = 0;
        items.forEach(item => {
            itemsArray.push((
                <ButtonListItem
                    clickHandler={clickHandler}
                    key={`button-${counter++}`}
                    item={item}
                    linkClassName={classes.defaultNavigationLinkText}
                />
            ))
        })
        return itemsArray;
    }

    render() {
        return (
            <GenericList NoBorder>
                {this.renderList()}
            </GenericList>
        )
    }
}


export default withStyles(dashboardStyleSheet, {withTheme: true})(NavigationList)