import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

import { withStyles } from '@mui/styles';
import { capitalize } from '../../shim/capitalize';
import {makeClassNames} from "../../style/StylesUtils";
import {makeSurfaceStyleSheet} from "../../style/SurfaceStyles";
import Text from "../../content/Text";

import Box from '@mui/material/Box';

export const cardXStyles = (theme) => {
    const styleSheet = makeSurfaceStyleSheet(theme, {
        root: {
            maxWidth: 345,
        },
        widthSmall: {
            maxWidth: 280,
        },
        widthLarge: {
            maxWidth: 425,
        },
        displayVariantNone: {
            //Do not remove - it's empty, but required
            boxShadow: 'none',
            border: 'none'
        },
        flexContainer: {
            display: 'flex'
        },
        mediaFixSize: {
            width: 'auto',
            maxWidth: '36%',
            height: '100%'
        },
        maxWidthAuto: {
            maxWidth: '100%'
        }
    });
    return styleSheet;
};

export const renderClassic = (title, titleIcon, secondaryNode) => {
    return (
        <React.Fragment>
            {title && (
                <Text
                    variant="h4">
                    {titleIcon && titleIcon} {title}
                </Text>
            )}
            {secondaryNode && typeof secondaryNode === 'string' && (
                <Text color="textPrimary" variant="h5" gutterBottom>
                    {secondaryNode}
                </Text>
            )}
            {secondaryNode && typeof secondaryNode !== 'string' && (
                <>{secondaryNode}</>
            )}
        </React.Fragment>
    )
}

export const displayBlock = (title, subTitle, titleIcon, titleHref=null) => {
    return (
        <Box alignItems={'center'}>
            {subTitle && (
                <Text
                    color="textSecondary"
                    variant="caption"
                    component="p"
                    gutterBottom
                >
                    {subTitle}
                </Text>
            )}

            <Box display="flex">
                {titleIcon && (
                    <Box>
                        {titleIcon}
                    </Box>
                )}
                <Box style={{marginLeft: (titleIcon) ? '.6em' : 0 }}>
                    <Text
                        variant="h4"
                        href={titleHref}
                    >
                      {title}
                    </Text>
                </Box>
            </Box>
        </Box>
    )
}


export const displayNouveau = (title, subTitle, titleIcon, otherNode, titleHref) => {
    return (
        <Box display="flex">
            <Box>
                {displayBlock(title, subTitle, titleIcon, titleHref)}
            </Box>
            {otherNode && (
                <>
                    <Box flexGrow={1}></Box>
                    <Box>{otherNode}</Box>
                </>
            )}
        </Box>
    )
}

const renderNouveau = (title, subTitle, titleIcon, secondaryNode, otherNode) => {
    return (
        <React.Fragment>
            <Grid item xs={12}>
                {displayNouveau(title, subTitle, titleIcon, otherNode)}
            </Grid>
            {secondaryNode && typeof secondaryNode === 'string' && (
                <Text color="textPrimary" variant="h5" gutterBottom>
                    {secondaryNode}
                </Text>
            )}
            {secondaryNode && typeof secondaryNode !== 'string' && (
                <>{secondaryNode}</>
            )}
        </React.Fragment>
    )
}

const CardX = React.forwardRef(function CardX(props, ref) {
    const {
        children,
        classes,
        raised = false,
        width = 'medium',
        variant = 'medium',
        displayVariant = 'none',
        direction = 'column',
        title,
        titleIcon,
        subTitle,
        secondaryNode,
        otherNode,
        supportText,
        mediaComponent: MediaComponent = 'img',
        cardComponent: CardComponent = 'div',
        mediaAltTag = '',
        mediaTitle = null,
        mediaHeight = 140,
        imgSrc = null,
        cardButton,
        cardButton2,
        Nouveau,
        cardActions,
        //TODO we pull this out so it doesn't get spread, through we should actually be doing something with it
        cardIconButton = null,
        ...rest
    } = props;

    const classNameObj = makeClassNames(classes, {variant, displayVariant}, rest, {
        [classes[`width${capitalize(width)}`]]: width !== 'medium',
        [classes.flexContainer]: direction === 'row',
        [classes.maxWidthAuto]: direction === 'row'
    }, {});

    const cardContainerClasses = classnames({
        [classes.flexContainer]: direction === 'row'
    });

    const mediaClassNames = classnames({
        [classes.mediaFixSize]: direction === 'row'
    });

    return (
        <CardComponent
            className={classNameObj.classNames}
            elevation={raised ? 8 : 1}
            ref={ref}
            {...classNameObj.props}
        >
            {imgSrc && (
                <CardMedia
                    component={MediaComponent}
                    alt={mediaAltTag}
                    height={mediaHeight}
                    image={imgSrc}
                    title={mediaTitle}
                    className={mediaClassNames}
                />
            )}
            <React.Fragment>
                <CardContent>
                    {Nouveau
                        ? renderNouveau(title, subTitle, titleIcon, secondaryNode, otherNode)
                        : renderClassic(title, titleIcon, secondaryNode)
                    }
                    {supportText && (
                        <Grid item xs={12}>
                            <Text color="textSecondary" component="p" gutterBottom>
                                {supportText}
                            </Text>
                        </Grid>
                    )}
                </CardContent>
                {cardButton || cardButton2 && (
                    <CardActions>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                            {cardButton && (
                                <Grid item xs>
                                    {cardButton}
                                </Grid>
                            )}
                            {cardButton2 && (
                                <Grid container item xs justifyContent={cardButton && 'flex-end'}>
                                    <Grid item>
                                        {cardButton2}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </CardActions>
                )}
                {cardActions && (
                    <CardActions>
                        {cardActions}
                    </CardActions>
                )}
            </React.Fragment>
        </CardComponent>
    );
});

CardX.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    Nouveau: PropTypes.bool,
    /**
     * If `true`, the card will use raised styling.
     * */
    raised: PropTypes.bool,
    /**
     * If `true`, rounded corners are disabled.
     * */
    square: PropTypes.bool,
    backgroundColor: PropTypes.oneOf(['none', 'default', 'paper', 'alt', 'primary']),
    width: PropTypes.oneOf(['small', 'medium', 'large']),
    displayVariant: PropTypes.oneOf(['elevation', 'outlined', 'outlinedHover', 'elevationHover', 'backgroundHover', 'none']),
    title: PropTypes.string,
    titleIcon: PropTypes.node,
    secondaryNode: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    supportText: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    mediaComponent: PropTypes /* @typescript-to-proptypes-ignore */.elementType,
    /**
     * Image to be displayed as a background image.
     * Either `image` or `src` prop must be specified.
     * Note that caller must specify height otherwise the image will not be visible.
     */
    imgSrc: PropTypes.string,
    mediaHeight: PropTypes.number,
    mediaAltTag: PropTypes.string,
    mediaTitle: PropTypes.string,
//    padding: PropTypes.oneOf(['regular', 'dense', 'none', 'appbar', 'sideBar', 'appbarSideBar']),

};

export default withStyles(cardXStyles, {name: 'MuiCard'})(CardX);