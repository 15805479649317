// source: growcloud/v1/grid/GridServiceMessages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var growcloud_v1_grid_GridDefinitions_pb = require('../../../growcloud/v1/grid/GridDefinitions_pb.js');
goog.object.extend(proto, growcloud_v1_grid_GridDefinitions_pb);
var growcloud_v1_grid_GridCommons_pb = require('../../../growcloud/v1/grid/GridCommons_pb.js');
goog.object.extend(proto, growcloud_v1_grid_GridCommons_pb);
goog.exportSymbol('proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.StatusCase', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetColumnDataRequest', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetColumnDataResponse', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetColumnDataResponse.StatusCase', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetColumnDataSuccess', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetConfigurationRequest', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetConfigurationResponse', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetConfigurationResponse.StatusCase', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetConfigurationSuccess', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetConfigurationsResponse', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetConfigurationsResponse.StatusCase', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GetConfigurationsSuccess', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetConfigurationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.grid.GetConfigurationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetConfigurationRequest.displayName = 'proto.growcloud.v1.grid.GetConfigurationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetColumnDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.grid.GetColumnDataRequest.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.grid.GetColumnDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetColumnDataRequest.displayName = 'proto.growcloud.v1.grid.GetColumnDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.displayName = 'proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.displayName = 'proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.oneofGroups_);
};
goog.inherits(proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.displayName = 'proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetColumnDataSuccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.grid.GetColumnDataSuccess.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.grid.GetColumnDataSuccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetColumnDataSuccess.displayName = 'proto.growcloud.v1.grid.GetColumnDataSuccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetColumnDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.growcloud.v1.grid.GetColumnDataResponse.oneofGroups_);
};
goog.inherits(proto.growcloud.v1.grid.GetColumnDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetColumnDataResponse.displayName = 'proto.growcloud.v1.grid.GetColumnDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.grid.GetConfigurationsSuccess.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.grid.GetConfigurationsSuccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetConfigurationsSuccess.displayName = 'proto.growcloud.v1.grid.GetConfigurationsSuccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetConfigurationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.growcloud.v1.grid.GetConfigurationsResponse.oneofGroups_);
};
goog.inherits(proto.growcloud.v1.grid.GetConfigurationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetConfigurationsResponse.displayName = 'proto.growcloud.v1.grid.GetConfigurationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetConfigurationSuccess = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.grid.GetConfigurationSuccess, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetConfigurationSuccess.displayName = 'proto.growcloud.v1.grid.GetConfigurationSuccess';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GetConfigurationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.growcloud.v1.grid.GetConfigurationResponse.oneofGroups_);
};
goog.inherits(proto.growcloud.v1.grid.GetConfigurationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GetConfigurationResponse.displayName = 'proto.growcloud.v1.grid.GetConfigurationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetConfigurationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetConfigurationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetConfigurationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetConfigurationRequest}
 */
proto.growcloud.v1.grid.GetConfigurationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetConfigurationRequest;
  return proto.growcloud.v1.grid.GetConfigurationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetConfigurationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetConfigurationRequest}
 */
proto.growcloud.v1.grid.GetConfigurationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetConfigurationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetConfigurationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetConfigurationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string channel = 2;
 * @return {string}
 */
proto.growcloud.v1.grid.GetConfigurationRequest.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.grid.GetConfigurationRequest} returns this
 */
proto.growcloud.v1.grid.GetConfigurationRequest.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.grid.GetColumnDataRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetColumnDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetColumnDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetColumnDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetColumnDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    parameterisedcolumnList: jspb.Message.toObjectList(msg.getParameterisedcolumnList(),
    growcloud_v1_grid_GridDefinitions_pb.ParameterisedColumn.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetColumnDataRequest}
 */
proto.growcloud.v1.grid.GetColumnDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetColumnDataRequest;
  return proto.growcloud.v1.grid.GetColumnDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetColumnDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetColumnDataRequest}
 */
proto.growcloud.v1.grid.GetColumnDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_grid_GridDefinitions_pb.ParameterisedColumn;
      reader.readMessage(value,growcloud_v1_grid_GridDefinitions_pb.ParameterisedColumn.deserializeBinaryFromReader);
      msg.addParameterisedcolumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetColumnDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetColumnDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetColumnDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetColumnDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParameterisedcolumnList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      growcloud_v1_grid_GridDefinitions_pb.ParameterisedColumn.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ParameterisedColumn parameterisedColumn = 1;
 * @return {!Array<!proto.growcloud.v1.grid.ParameterisedColumn>}
 */
proto.growcloud.v1.grid.GetColumnDataRequest.prototype.getParameterisedcolumnList = function() {
  return /** @type{!Array<!proto.growcloud.v1.grid.ParameterisedColumn>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_grid_GridDefinitions_pb.ParameterisedColumn, 1));
};


/**
 * @param {!Array<!proto.growcloud.v1.grid.ParameterisedColumn>} value
 * @return {!proto.growcloud.v1.grid.GetColumnDataRequest} returns this
*/
proto.growcloud.v1.grid.GetColumnDataRequest.prototype.setParameterisedcolumnList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.growcloud.v1.grid.ParameterisedColumn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.grid.ParameterisedColumn}
 */
proto.growcloud.v1.grid.GetColumnDataRequest.prototype.addParameterisedcolumn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.growcloud.v1.grid.ParameterisedColumn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.grid.GetColumnDataRequest} returns this
 */
proto.growcloud.v1.grid.GetColumnDataRequest.prototype.clearParameterisedcolumnList = function() {
  return this.setParameterisedcolumnList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channel: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest;
  return proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setChannel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string channel = 1;
 * @return {string}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.prototype.getChannel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest} returns this
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsRequest.prototype.setChannel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    gridcolumnconfigurationdefinitionList: jspb.Message.toObjectList(msg.getGridcolumnconfigurationdefinitionList(),
    growcloud_v1_grid_GridDefinitions_pb.GridColumnConfigurationDefinition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess;
  return proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_grid_GridDefinitions_pb.GridColumnConfigurationDefinition;
      reader.readMessage(value,growcloud_v1_grid_GridDefinitions_pb.GridColumnConfigurationDefinition.deserializeBinaryFromReader);
      msg.addGridcolumnconfigurationdefinition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGridcolumnconfigurationdefinitionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      growcloud_v1_grid_GridDefinitions_pb.GridColumnConfigurationDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GridColumnConfigurationDefinition gridColumnConfigurationDefinition = 1;
 * @return {!Array<!proto.growcloud.v1.grid.GridColumnConfigurationDefinition>}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.prototype.getGridcolumnconfigurationdefinitionList = function() {
  return /** @type{!Array<!proto.growcloud.v1.grid.GridColumnConfigurationDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_grid_GridDefinitions_pb.GridColumnConfigurationDefinition, 1));
};


/**
 * @param {!Array<!proto.growcloud.v1.grid.GridColumnConfigurationDefinition>} value
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess} returns this
*/
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.prototype.setGridcolumnconfigurationdefinitionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.prototype.addGridcolumnconfigurationdefinition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.growcloud.v1.grid.GridColumnConfigurationDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess} returns this
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.prototype.clearGridcolumnconfigurationdefinitionList = function() {
  return this.setGridcolumnconfigurationdefinitionList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.StatusCase = {
  STATUS_NOT_SET: 0,
  SUCCESS: 1,
  ERROR: 2
};

/**
 * @return {proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.StatusCase}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.getStatusCase = function() {
  return /** @type {proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.StatusCase} */(jspb.Message.computeOneofCase(this, proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.toObject(includeInstance, f),
    error: (f = msg.getError()) && growcloud_v1_grid_GridCommons_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse;
  return proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess;
      reader.readMessage(value,proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    case 2:
      var value = new growcloud_v1_grid_GridCommons_pb.Error;
      reader.readMessage(value,growcloud_v1_grid_GridCommons_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_grid_GridCommons_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAvailableGridColumnConfigurationDefinitionsSuccess success = 1;
 * @return {?proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess} */ (
    jspb.Message.getWrapperField(this, proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess, 1));
};


/**
 * @param {?proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsSuccess|undefined} value
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse} returns this
*/
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse} returns this
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Error error = 2;
 * @return {?proto.growcloud.v1.grid.Error}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.getError = function() {
  return /** @type{?proto.growcloud.v1.grid.Error} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_grid_GridCommons_pb.Error, 2));
};


/**
 * @param {?proto.growcloud.v1.grid.Error|undefined} value
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse} returns this
*/
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse} returns this
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GetAvailableGridColumnConfigurationDefinitionsResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetColumnDataSuccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetColumnDataSuccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    columndatasetList: jspb.Message.toObjectList(msg.getColumndatasetList(),
    growcloud_v1_grid_GridCommons_pb.ColumnDataSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetColumnDataSuccess}
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetColumnDataSuccess;
  return proto.growcloud.v1.grid.GetColumnDataSuccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetColumnDataSuccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetColumnDataSuccess}
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_grid_GridCommons_pb.ColumnDataSet;
      reader.readMessage(value,growcloud_v1_grid_GridCommons_pb.ColumnDataSet.deserializeBinaryFromReader);
      msg.addColumndataset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetColumnDataSuccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetColumnDataSuccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumndatasetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      growcloud_v1_grid_GridCommons_pb.ColumnDataSet.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ColumnDataSet columnDataSet = 1;
 * @return {!Array<!proto.growcloud.v1.grid.ColumnDataSet>}
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.prototype.getColumndatasetList = function() {
  return /** @type{!Array<!proto.growcloud.v1.grid.ColumnDataSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_grid_GridCommons_pb.ColumnDataSet, 1));
};


/**
 * @param {!Array<!proto.growcloud.v1.grid.ColumnDataSet>} value
 * @return {!proto.growcloud.v1.grid.GetColumnDataSuccess} returns this
*/
proto.growcloud.v1.grid.GetColumnDataSuccess.prototype.setColumndatasetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.growcloud.v1.grid.ColumnDataSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.grid.ColumnDataSet}
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.prototype.addColumndataset = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.growcloud.v1.grid.ColumnDataSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.grid.GetColumnDataSuccess} returns this
 */
proto.growcloud.v1.grid.GetColumnDataSuccess.prototype.clearColumndatasetList = function() {
  return this.setColumndatasetList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.growcloud.v1.grid.GetColumnDataResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.StatusCase = {
  STATUS_NOT_SET: 0,
  SUCCESS: 1,
  ERROR: 2
};

/**
 * @return {proto.growcloud.v1.grid.GetColumnDataResponse.StatusCase}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.getStatusCase = function() {
  return /** @type {proto.growcloud.v1.grid.GetColumnDataResponse.StatusCase} */(jspb.Message.computeOneofCase(this, proto.growcloud.v1.grid.GetColumnDataResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetColumnDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetColumnDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetColumnDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.growcloud.v1.grid.GetColumnDataSuccess.toObject(includeInstance, f),
    error: (f = msg.getError()) && growcloud_v1_grid_GridCommons_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetColumnDataResponse}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetColumnDataResponse;
  return proto.growcloud.v1.grid.GetColumnDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetColumnDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetColumnDataResponse}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.growcloud.v1.grid.GetColumnDataSuccess;
      reader.readMessage(value,proto.growcloud.v1.grid.GetColumnDataSuccess.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    case 2:
      var value = new growcloud_v1_grid_GridCommons_pb.Error;
      reader.readMessage(value,growcloud_v1_grid_GridCommons_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetColumnDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetColumnDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetColumnDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.growcloud.v1.grid.GetColumnDataSuccess.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_grid_GridCommons_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetColumnDataSuccess success = 1;
 * @return {?proto.growcloud.v1.grid.GetColumnDataSuccess}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.getSuccess = function() {
  return /** @type{?proto.growcloud.v1.grid.GetColumnDataSuccess} */ (
    jspb.Message.getWrapperField(this, proto.growcloud.v1.grid.GetColumnDataSuccess, 1));
};


/**
 * @param {?proto.growcloud.v1.grid.GetColumnDataSuccess|undefined} value
 * @return {!proto.growcloud.v1.grid.GetColumnDataResponse} returns this
*/
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.growcloud.v1.grid.GetColumnDataResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GetColumnDataResponse} returns this
 */
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Error error = 2;
 * @return {?proto.growcloud.v1.grid.Error}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.getError = function() {
  return /** @type{?proto.growcloud.v1.grid.Error} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_grid_GridCommons_pb.Error, 2));
};


/**
 * @param {?proto.growcloud.v1.grid.Error|undefined} value
 * @return {!proto.growcloud.v1.grid.GetColumnDataResponse} returns this
*/
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.growcloud.v1.grid.GetColumnDataResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GetColumnDataResponse} returns this
 */
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GetColumnDataResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetConfigurationsSuccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetConfigurationsSuccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    gridconfigurationList: jspb.Message.toObjectList(msg.getGridconfigurationList(),
    growcloud_v1_grid_GridDefinitions_pb.GridConfiguration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetConfigurationsSuccess}
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetConfigurationsSuccess;
  return proto.growcloud.v1.grid.GetConfigurationsSuccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetConfigurationsSuccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetConfigurationsSuccess}
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_grid_GridDefinitions_pb.GridConfiguration;
      reader.readMessage(value,growcloud_v1_grid_GridDefinitions_pb.GridConfiguration.deserializeBinaryFromReader);
      msg.addGridconfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetConfigurationsSuccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetConfigurationsSuccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGridconfigurationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      growcloud_v1_grid_GridDefinitions_pb.GridConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GridConfiguration gridConfiguration = 1;
 * @return {!Array<!proto.growcloud.v1.grid.GridConfiguration>}
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.prototype.getGridconfigurationList = function() {
  return /** @type{!Array<!proto.growcloud.v1.grid.GridConfiguration>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_grid_GridDefinitions_pb.GridConfiguration, 1));
};


/**
 * @param {!Array<!proto.growcloud.v1.grid.GridConfiguration>} value
 * @return {!proto.growcloud.v1.grid.GetConfigurationsSuccess} returns this
*/
proto.growcloud.v1.grid.GetConfigurationsSuccess.prototype.setGridconfigurationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.growcloud.v1.grid.GridConfiguration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.grid.GridConfiguration}
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.prototype.addGridconfiguration = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.growcloud.v1.grid.GridConfiguration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.grid.GetConfigurationsSuccess} returns this
 */
proto.growcloud.v1.grid.GetConfigurationsSuccess.prototype.clearGridconfigurationList = function() {
  return this.setGridconfigurationList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.oneofGroups_ = [[1]];

/**
 * @enum {number}
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.StatusCase = {
  STATUS_NOT_SET: 0,
  SUCCESS: 1
};

/**
 * @return {proto.growcloud.v1.grid.GetConfigurationsResponse.StatusCase}
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.prototype.getStatusCase = function() {
  return /** @type {proto.growcloud.v1.grid.GetConfigurationsResponse.StatusCase} */(jspb.Message.computeOneofCase(this, proto.growcloud.v1.grid.GetConfigurationsResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetConfigurationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetConfigurationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.growcloud.v1.grid.GetConfigurationsSuccess.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetConfigurationsResponse}
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetConfigurationsResponse;
  return proto.growcloud.v1.grid.GetConfigurationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetConfigurationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetConfigurationsResponse}
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.growcloud.v1.grid.GetConfigurationsSuccess;
      reader.readMessage(value,proto.growcloud.v1.grid.GetConfigurationsSuccess.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetConfigurationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetConfigurationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.growcloud.v1.grid.GetConfigurationsSuccess.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetConfigurationsSuccess success = 1;
 * @return {?proto.growcloud.v1.grid.GetConfigurationsSuccess}
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.prototype.getSuccess = function() {
  return /** @type{?proto.growcloud.v1.grid.GetConfigurationsSuccess} */ (
    jspb.Message.getWrapperField(this, proto.growcloud.v1.grid.GetConfigurationsSuccess, 1));
};


/**
 * @param {?proto.growcloud.v1.grid.GetConfigurationsSuccess|undefined} value
 * @return {!proto.growcloud.v1.grid.GetConfigurationsResponse} returns this
*/
proto.growcloud.v1.grid.GetConfigurationsResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.growcloud.v1.grid.GetConfigurationsResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GetConfigurationsResponse} returns this
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GetConfigurationsResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetConfigurationSuccess.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetConfigurationSuccess.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetConfigurationSuccess} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationSuccess.toObject = function(includeInstance, msg) {
  var f, obj = {
    gridconfiguration: (f = msg.getGridconfiguration()) && growcloud_v1_grid_GridDefinitions_pb.GridConfiguration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetConfigurationSuccess}
 */
proto.growcloud.v1.grid.GetConfigurationSuccess.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetConfigurationSuccess;
  return proto.growcloud.v1.grid.GetConfigurationSuccess.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetConfigurationSuccess} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetConfigurationSuccess}
 */
proto.growcloud.v1.grid.GetConfigurationSuccess.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_grid_GridDefinitions_pb.GridConfiguration;
      reader.readMessage(value,growcloud_v1_grid_GridDefinitions_pb.GridConfiguration.deserializeBinaryFromReader);
      msg.setGridconfiguration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetConfigurationSuccess.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetConfigurationSuccess.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetConfigurationSuccess} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationSuccess.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGridconfiguration();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_grid_GridDefinitions_pb.GridConfiguration.serializeBinaryToWriter
    );
  }
};


/**
 * optional GridConfiguration gridConfiguration = 1;
 * @return {?proto.growcloud.v1.grid.GridConfiguration}
 */
proto.growcloud.v1.grid.GetConfigurationSuccess.prototype.getGridconfiguration = function() {
  return /** @type{?proto.growcloud.v1.grid.GridConfiguration} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_grid_GridDefinitions_pb.GridConfiguration, 1));
};


/**
 * @param {?proto.growcloud.v1.grid.GridConfiguration|undefined} value
 * @return {!proto.growcloud.v1.grid.GetConfigurationSuccess} returns this
*/
proto.growcloud.v1.grid.GetConfigurationSuccess.prototype.setGridconfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GetConfigurationSuccess} returns this
 */
proto.growcloud.v1.grid.GetConfigurationSuccess.prototype.clearGridconfiguration = function() {
  return this.setGridconfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GetConfigurationSuccess.prototype.hasGridconfiguration = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.growcloud.v1.grid.GetConfigurationResponse.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.StatusCase = {
  STATUS_NOT_SET: 0,
  SUCCESS: 1,
  ERROR: 2
};

/**
 * @return {proto.growcloud.v1.grid.GetConfigurationResponse.StatusCase}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.getStatusCase = function() {
  return /** @type {proto.growcloud.v1.grid.GetConfigurationResponse.StatusCase} */(jspb.Message.computeOneofCase(this, proto.growcloud.v1.grid.GetConfigurationResponse.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GetConfigurationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GetConfigurationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: (f = msg.getSuccess()) && proto.growcloud.v1.grid.GetConfigurationSuccess.toObject(includeInstance, f),
    error: (f = msg.getError()) && growcloud_v1_grid_GridCommons_pb.Error.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GetConfigurationResponse}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GetConfigurationResponse;
  return proto.growcloud.v1.grid.GetConfigurationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GetConfigurationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GetConfigurationResponse}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.growcloud.v1.grid.GetConfigurationSuccess;
      reader.readMessage(value,proto.growcloud.v1.grid.GetConfigurationSuccess.deserializeBinaryFromReader);
      msg.setSuccess(value);
      break;
    case 2:
      var value = new growcloud_v1_grid_GridCommons_pb.Error;
      reader.readMessage(value,growcloud_v1_grid_GridCommons_pb.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GetConfigurationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GetConfigurationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GetConfigurationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.growcloud.v1.grid.GetConfigurationSuccess.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_grid_GridCommons_pb.Error.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetConfigurationSuccess success = 1;
 * @return {?proto.growcloud.v1.grid.GetConfigurationSuccess}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.getSuccess = function() {
  return /** @type{?proto.growcloud.v1.grid.GetConfigurationSuccess} */ (
    jspb.Message.getWrapperField(this, proto.growcloud.v1.grid.GetConfigurationSuccess, 1));
};


/**
 * @param {?proto.growcloud.v1.grid.GetConfigurationSuccess|undefined} value
 * @return {!proto.growcloud.v1.grid.GetConfigurationResponse} returns this
*/
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.growcloud.v1.grid.GetConfigurationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GetConfigurationResponse} returns this
 */
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.clearSuccess = function() {
  return this.setSuccess(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.hasSuccess = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Error error = 2;
 * @return {?proto.growcloud.v1.grid.Error}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.getError = function() {
  return /** @type{?proto.growcloud.v1.grid.Error} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_grid_GridCommons_pb.Error, 2));
};


/**
 * @param {?proto.growcloud.v1.grid.Error|undefined} value
 * @return {!proto.growcloud.v1.grid.GetConfigurationResponse} returns this
*/
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.setError = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.growcloud.v1.grid.GetConfigurationResponse.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GetConfigurationResponse} returns this
 */
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GetConfigurationResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.growcloud.v1.grid);
