import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const styles = theme => ({
    root: {
        textTransform: 'none',
        boxShadow: '0px 1px 3px 0px rgba(0,0,0, 0.25)',
       	lineHeight: '1em',
        padding: theme.spacing(1.375, 3),
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '&:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
        '&:active': {
            boxShadow: '0px 1px 3px 0px rgba(0,0,0, 0.25)',
        }
    }
});

function SubmitButton(props) {
    const {
        buttonText,
        ...rest
    } = props;

    return (
        <Button
            value={buttonText}
            type="submit"
            variant="contained"
            disableRipple
            disableFocusRipple
            {...rest}
        >
            {buttonText}
        </Button>
    );
}

SubmitButton.defaultProps = {
    color: 'primary',
    fullWidth: false,
};

SubmitButton.propTypes = {
    buttonText: PropTypes.string.isRequired,
};

export default withStyles(styles)(SubmitButton);