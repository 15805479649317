import React from 'react'
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import {popoverBaseStyleSheet} from './../style/BaseStyles';


const PopoverBase = ({
         children,
         classes,
         customClass,
         isOpen, anchorEl,
         onHandleMenuClose,
         disableScrollLock
     }) => {

    return (
        <Popover
            classes={{
                paper: classes.paper
            }}
            className={customClass}
            open={Boolean(isOpen)}
            anchorEl={anchorEl}
            onClose={onHandleMenuClose}
            disableScrollLock={disableScrollLock}
            anchorOrigin={{
                vertical: 49,
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >
            {children}
        </Popover>
    )
}

PopoverBase.propTypes = {
    children: PropTypes.node.isRequired,
    anchorEl: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    isOpen: PropTypes.bool.isRequired
}

export default withStyles(popoverBaseStyleSheet)(PopoverBase)