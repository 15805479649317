import React from 'react'
import {dialogStyleSheet} from '../../style/BaseStyles';
import { withStyles } from '@mui/styles';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DefaultIconButton from '../buttons/DefaultIconButton';

const DefaultDialogTitle = ({
        classes,
        onHandleClose,
        dialogTitle,
        dialogContext,
        underlineTitle,
    }) => {
    return (
        <DialogTitle
            disableTypography
            id="form-dialog-title"
            classes={{root: underlineTitle && classes.slimLineDialog}}
            className={underlineTitle ? classes.underlineTitle : null}
        >
            <Typography
                variant="h6"
                className={classes.fontWeightMedium}
            >
            {dialogTitle}
           </Typography>
            {onHandleClose ? (
                <DefaultIconButton
                    color="inherit"
                    onClick={onHandleClose}
                    ariaLabel="Close"
                    className={classes.closeButton}
                    {...dialogContext}
                >
                    <CloseIcon/>
                </DefaultIconButton>
            ) : null}
        </DialogTitle>
    )
}

export default withStyles(dialogStyleSheet)(DefaultDialogTitle)