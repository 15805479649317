import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@mui/styles';
import {dialogStyleSheet} from '../../style/BaseStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Slide from '@mui/material/Slide'

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class FullScreenDialog extends React.PureComponent {

    render() {
        const {
            children,
            classes,
            isOpen,
            onHandleClose,
            dialogTitle,
            dialogContentText,
            saveSelection,
            defaultPaperBackground
        } = this.props;

        let paperClass = classnames(classes.paperFullScreen, {
            [classes.defaultPaperBackground]: defaultPaperBackground
        });

        return (
            <React.Fragment>
                {/* <DefaultButton onClick={handleClickOpen} buttonText={dialogButtonText}/> */}
                <Dialog
                    open={isOpen}
                    onClose={onHandleClose}
                    fullScreen={true}
                    TransitionComponent={Transition}
                    aria-labelledby={dialogTitle}
                    classes={{paper: paperClass}}
                    disableScrollLock={true}
                >
                    <AppBar className={classes.appbar} component="header">
                        <Toolbar>
                            <IconButton color="inherit" onClick={onHandleClose} aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6" color="inherit" className={classnames(classes.flex, classes.fontWeightMedium)}>
                                {dialogTitle}
                            </Typography>
                            {saveSelection && (
                                <Button color="inherit" onClick={onHandleClose}>
                                    save
                                </Button>
                            )}
                        </Toolbar>
                    </AppBar>
                    <DialogContent>
                        {dialogContentText && (
                            <DialogContentText
                                className={classes.dialogContentTextSpacer}
                            >
                                {dialogContentText}
                            </DialogContentText>
                        )}
                        {children}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }
}

FullScreenDialog.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool.isRequired,
    dialogButton: PropTypes.object,
    dialogTitle: PropTypes.string,
    dialogContentText: PropTypes.string
};

FullScreenDialog.defaultProps = {
    isOpen: false,
};

export default withStyles(dialogStyleSheet)(FullScreenDialog);