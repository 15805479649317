import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const styles = theme => ({
    spanColorPrimary: {
        display: 'inline-flex',
        color: theme.palette.secondary.dark, //brandPrimaryBold
        fontWeight: theme.typography.fontWeightMedium, // 'bold'
    }
});

const LeadText = ({classes, masthead}) => {
    return (
        <Typography
            className={classes.spanColorPrimary}
            component="span"
            noWrap
        >
            {masthead.text}
        </Typography>
    );
}

LeadText.propTypes = {
    masthead: PropTypes.object.isRequired
};

export default withStyles(styles)(LeadText);