import React from 'react'
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { withStyles } from '@mui/styles';
import {listStyleSheet} from '../../style/BaseStyles';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

class ListItemIconLink extends React.PureComponent {

    renderLink = itemProps => this.props.to ? <Link to={this.props.to} {...itemProps} /> : (<span></span>);

    render() {

        const {
            classes,
            icon,
            primary,
            secondary
        } = this.props;

        return (
            <ListItem
                button
                component={this.props.to ? this.renderLink : 'span'}
                className={classes.primaryLink}
            >
                <ListItemIcon>{icon}</ListItemIcon>

                <ListItemText
                    classes={{root: classes.listWithIcon}}
                    primary={primary}
                    secondary={secondary ? secondary : null}
                />
            </ListItem>
        )
    }
}

ListItemIconLink.propTypes = {
    icon: PropTypes.node.isRequired,
    primary: PropTypes.node.isRequired,
    secondary: PropTypes.node,
    to: PropTypes.string,
}

export default withStyles(listStyleSheet)(ListItemIconLink);