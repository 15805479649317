import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@mui/styles';
import List from '@mui/material/List';

import {drawerListStyleSheet} from '@dc/dc/style/BaseStyles';
import ListItemLink from '@dc/dc/components/lists/ListItemLink';

import ContainerX from '@dc/dc/components/layout/ContainerX';

import {FormattedMessage} from 'react-intl';

class MenuDrawerList extends React.PureComponent {

    makeListItem(menuItem) {
        const {classes} = this.props;

        return (
            <ListItemLink
                primary={(
                    <FormattedMessage
                        {...menuItem.message}
                    />
                )}
                key={menuItem.message.id}
                to={menuItem.to}
                id={menuItem.to}
                className={menuItem.nested ? classes.nested : classes.topLevelLink}
            />
        )
    }

    makeMenuItems(menuItems) {
        let itemsArray = [];
        menuItems.forEach(menuItem => itemsArray.push(this.makeListItem(menuItem, menuItems)));
        return itemsArray;
    }

    render() {
        const {classes, menuItems, unSetWidth,} = this.props;

        return (
            <ContainerX
                className={classnames({[classes.list]: !unSetWidth})}
                variant={'fitted'}
            >
                <List
                    disablePadding
                    component="nav"
                    className={classes.list}
                >
                    {this.makeMenuItems(menuItems)}
                </List>
            </ContainerX>
        )
    }
}

MenuDrawerList.propTypes = {
    menuItems: PropTypes.array.isRequired,
}

export default withStyles(drawerListStyleSheet)(MenuDrawerList)