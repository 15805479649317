import React from 'react';

export const makeCommonQualitySidebarConfiguration = (channelPageConfiguration, pageContext, channel) => {
    return {
        searchType: '$$requirements-conformity',
        channelPageConfiguration: channelPageConfiguration,
        pageContext: pageContext,
        channel: channel
    }
}

export const makeSidebarConfigurationForQualityChannel = (channelPageConfiguration, pageContext, channel, thisResource, tabConfiguration) => {
    const baseConfiguration = makeCommonQualitySidebarConfiguration(channelPageConfiguration, pageContext, channel);
    return baseConfiguration;
}

export const makeCommonProcessSidebarConfiguration = (channelPageConfiguration, pageContext, channel) => {
    return {
        searchType: '$$process',
        channelPageConfiguration: channelPageConfiguration,
        pageContext: pageContext,
        channel: channel
    }
}

export const makeSidebarConfigurationForProcessChannel = (channelPageConfiguration, pageContext, channel, thisResource, tabConfiguration) => {
    const baseConfiguration = makeCommonProcessSidebarConfiguration(channelPageConfiguration, pageContext, channel);
    return baseConfiguration;
}

export const makeCommonConfigurationsSidebarConfiguration = (channelPageConfiguration, pageContext, channel) => {
    return {
        searchType: '$$configurations',
        channelPageConfiguration: channelPageConfiguration,
        pageContext: pageContext,
        channel: channel
    }
}

export const makeSidebarConfigurationForConfigurationsChannel = (channelPageConfiguration, pageContext, channel, thisResource, tabConfiguration) => {
    const baseConfiguration = makeCommonConfigurationsSidebarConfiguration(channelPageConfiguration, pageContext, channel);
    let fullConfiguration = Object.assign({
            thisResource: thisResource,
            tabConfiguration: tabConfiguration
        }, baseConfiguration);
    return fullConfiguration;
}

export const makeCommonResourceSidebarConfiguration = (channelPageConfiguration, pageContext, channel) => {
    return {
        searchType: '$$resource',
        channelPageConfiguration: channelPageConfiguration,
        pageContext: pageContext,
        channel: channel
    }
}


export const makeCommonSystemSidebarConfiguration = (channelPageConfiguration, pageContext, channel) => {
    return {
        searchType: '$$system',
        channelPageConfiguration: channelPageConfiguration,
        pageContext: pageContext,
        channel: channel
    }
}

export const makeCommonArtifactSidebarConfiguration = (channelPageConfiguration, pageContext, channel) => {
    return {
        searchType: '$$artifact',
        channelPageConfiguration: channelPageConfiguration,
        pageContext: pageContext,
        channel: channel
    }
}

export const makeCommonLifecycleSidebarConfiguration = (channelPageConfiguration, pageContext, channel) => {
    return {
        searchType: '$$lifecycle',
        channelPageConfiguration: channelPageConfiguration,
        pageContext: pageContext,
        channel: channel
    }
}

const sidebarFamilySelector = (foo, pageContext) => {
    if ('lifecycle-state-roles' == family) {
        if ('stats' == pageContext.axis) {
            return false;
        }
    }
    return true;
}

const matches = (alpha, beta) => {
    if (!alpha) {
        debugger;
    }
    return alpha == beta;
}

export const channelMatches = (alpha, beta) => {
    if (!alpha) {
        debugger;
    }
    return alpha == beta;
}