import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';

import Typography from '@mui/material/Typography';
import MailIcon from '@mui/icons-material/Mail';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
        '& $content': {
            paddingLeft: theme.spacing(2),
        },
    },
    expanded: {},
    selected: {},
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
    },
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const {labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, itemId, ...other} = props;

    return (
        <TreeItem
            itemId={itemId}
            id={itemId}
            key={itemId}
            label={
                <div className={classes.labelRoot}>
                    {/*<LabelIcon color="inherit" className={classes.labelIcon}/>*/}
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.elementType.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
    root: {
        height: 264,
        flexGrow: 1,
        maxWidth: 400,
    },
});


const renderTree = (utilityListData) => {
    let itemsArray = [];
    let counter = 0;
    utilityListData.forEach(item => {
        const itemId = item.id || `${++counter}`;
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> " + itemId)
        itemsArray.push((
            <StyledTreeItem
                itemId={itemId}
                labelText={item.label}
                labelIcon={MailIcon}
                key={itemId}
            >
                {renderTree(item.items || [])}
            </StyledTreeItem>
        ))
    })
    return itemsArray;
}

export const NavigationViewer = ({utilityListData, onNodeSelect}) => {

    const classes = useStyles();

    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = React.useState([]);

    const handleToggle = (event, itemIds) => {
        setExpanded(itemIds);
    };

    const handleSelect = (event, itemIds) => {
        setSelected(itemIds);
    };

    return (
        <SimpleTreeView
            className={classes.root}
            defaultExpanded={['3']}
            defaultCollapseIcon={<ArrowDropDownIcon/>}
            defaultExpandIcon={<ArrowRightIcon/>}
            defaultEndIcon={<div style={{width: 24}}/>}
            onNodeToggle={handleToggle}
            onNodeSelect={onNodeSelect}
            expanded={expanded}
            selected={selected}
        >
            {renderTree(utilityListData)}
        </SimpleTreeView>
    );
}
