import {GrowcloudV1ServiceClient} from 'gc-commons/growcloud/v1/service/GrowcloudV1Service_grpc_web_pb';
import {CurrentReadingsGridServiceClient} from 'gc-commons/growcloud/v1/service/CurrentReadingsGridService_grpc_web_pb';
import {CalculationsGridServiceClient} from 'gc-commons/growcloud/v1/service/CalculationsGridService_grpc_web_pb';
import {AssetStatesGridServiceClient} from 'gc-commons/growcloud/v1/service/AssetStatesGridService_grpc_web_pb';
import {CalendarsGridServiceClient} from 'gc-commons/growcloud/v1/service/CalendarsGridService_grpc_web_pb';
import {ProgramsGridServiceClient} from 'gc-commons/growcloud/v1/service/ProgramsGridService_grpc_web_pb';
import {SchedulesGridServiceClient} from 'gc-commons/growcloud/v1/service/SchedulesGridService_grpc_web_pb';
import {ProductLinesGridServiceClient} from 'gc-commons/growcloud/v1/service/ProductLinesGridService_grpc_web_pb';
import {MeasureValuesV1ServiceClient} from 'gc-commons/growcloud/v1/service/MeasureValuesV1Service_grpc_web_pb';
import {AlarmsV1ServiceClient} from 'gc-commons/growcloud/v1/service/AlarmsV1Service_grpc_web_pb';

import {getToken} from 'gc-authentication/authentication/token-utils';

const responseHandler = (messageConsumer) => {
    return (err, response) => {
        if (err) {
            console.error(err.code);
            console.error(err.message);
            throw err;
        } else {
            messageConsumer(response);
        }
    }
}
export const streamInvocation = (messageConsumer, callProvider) => {
    return new Promise((defer) => {
        const token = getToken();

        const call = callProvider({
            'grpc-timeout': '1M',
            'Authorization': "Bearer " + token
        });
         call.on('data', (data) => {
            messageConsumer(data)
         })
        call.on('status', (status) => {
            //console.log(status.code);
            //console.log(status.details);
            //console.log(status.metadata);
            defer(status.code, status.details, status.metadata)
        })
        call.on('end', (status) => {

        })
    });
}

export default class GrowcloudServiceRegistry {
    constructor(grpcHost) {
        this._growcloudService = new GrowcloudV1Service(grpcHost);
        this._readingsService = new MeasureValuesV1Service(grpcHost);
        this._alarmsService = new AlarmsV1Service(grpcHost);
        this._currentReadingsGridService = new CurrentReadingsGridService(grpcHost);
        this._calculationsGridService = new CalculationsGridService(grpcHost);
        this._assetStatesGridService = new AssetStatesGridService(grpcHost);
        this._calendarsGridService = new CalendarsGridService(grpcHost);
        this._programsGridService = new ProgramsGridService(grpcHost);
        this._schedulesGridService = new SchedulesGridService(grpcHost);
        this._productLinesGridService = new ProductLinesGridService(grpcHost);
    }
    currentReadingsGridService() {
        return this._currentReadingsGridService;
    }
    calculationsGridService() {
        return this._calculationsGridService;
    }
    calendarsGridService() {
        return this._calendarsGridService;
    }
    programsGridService() {
        return this._programsGridService;
    }
    schedulesGridService() {
        return this._schedulesGridService;
    }
    assetStatesGridService() {
        return this._assetStatesGridService;
    }
    productLinesGridService() {
        return this._productLinesGridService;
    }
    growcloudV1Service() {
        return this._growcloudService;
    }
    environmentV1Service() {
        return this._environment1Service;
    }
    measureValuesV1Service() {
        return this._readingsService;
    }
    alarmsService() {
        return this._alarmsService;
    }
}

class MeasureValuesV1Service {
    constructor(grpcHost) {
        this.service = new MeasureValuesV1ServiceClient(grpcHost);
    }
    currentReadings(currentReadingsRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.currentReadings(currentReadingsRequest, standardConfig, handler));
    }
}

class AlarmsV1Service {
    constructor(grpcHost) {
        this.service = new AlarmsV1ServiceClient(grpcHost);
    }
    alarms(alarmsRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.alarms(alarmsRequest, standardConfig, handler));
    }
}

class GrowcloudV1Service {
    constructor(grpcHost) {
        this.service = new GrowcloudV1ServiceClient(grpcHost);
    }
    getGrowcloudConfigurations(userSitesConfigsRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.growcloudConfigurations(userSitesConfigsRequest, standardConfig, handler));
    }
    activeProductionModels(activeProductionModelsRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.activeProductionModels(activeProductionModelsRequest, standardConfig, handler));
    }
    sitesConfigurationsModels(sitesConfigurationsModelsRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.sitesConfigurationsModels(sitesConfigurationsModelsRequest, standardConfig, handler));
    }
}

class CurrentReadingsGridService {
    constructor(grpcHost) {
        this.service = new CurrentReadingsGridServiceClient(grpcHost);
    }
    getGrowcloudGridConfigurations(getConfigurationRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getGrowcloudGridConfigurations(getConfigurationRequest, standardConfig, handler));
    }
    getCurrentReadingsGridData(getColumnDataRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getCurrentReadingsGridData(getColumnDataRequest, standardConfig, handler));
    }
}

class CalculationsGridService {
    constructor(grpcHost) {
        this.service = new CalculationsGridServiceClient(grpcHost);
    }
    getGrowcloudGridConfigurations(getConfigurationRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getGrowcloudGridConfigurations(getConfigurationRequest, standardConfig, handler));
    }
    getCalculationsGridData(getColumnDataRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getCalculationsGridData(getColumnDataRequest, standardConfig, handler));
    }
}

class AssetStatesGridService {
    constructor(grpcHost) {
        this.service = new AssetStatesGridServiceClient(grpcHost);
    }
    getGrowcloudGridConfigurations(getConfigurationRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getGrowcloudGridConfigurations(getConfigurationRequest, standardConfig, handler));
    }
    getAssetStatesGridData(getColumnDataRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getAssetStatesGridData(getColumnDataRequest, standardConfig, handler));
    }
}

class CalendarsGridService {
    constructor(grpcHost) {
        this.service = new CalendarsGridServiceClient(grpcHost);
    }
    getCalendarsGridConfigurations(getConfigurationRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getGrowcloudGridConfigurations(getConfigurationRequest, standardConfig, handler));
    }
    getCalendarsGridData(getColumnDataRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getCalendarsGridData(getColumnDataRequest, standardConfig, handler));
    }
}

class ProductLinesGridService {
    constructor(grpcHost) {
        this.service = new ProductLinesGridServiceClient(grpcHost);
    }
    getProductLinesGridConfigurations(getConfigurationRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getGrowcloudGridConfigurations(getConfigurationRequest, standardConfig, handler));
    }
    getProductLinesGridData(getColumnDataRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getProductLinesGridData(getColumnDataRequest, standardConfig, handler));
    }
}

class ProgramsGridService {
    constructor(grpcHost) {
        this.service = new ProgramsGridServiceClient(grpcHost);
    }
    getProgramsGridConfigurations(getConfigurationRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getGrowcloudGridConfigurations(getConfigurationRequest, standardConfig, handler));
    }
    getProgramsGridData(getColumnDataRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getProgramsGridData(getColumnDataRequest, standardConfig, handler));
    }
}

class SchedulesGridService {
    constructor(grpcHost) {
        this.service = new SchedulesGridServiceClient(grpcHost);
    }
    getSchedulesGridConfigurations(getConfigurationRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getGrowcloudGridConfigurations(getConfigurationRequest, standardConfig, handler));
    }
    getSchedulesGridData(getColumnDataRequest, messageConsumer) {
        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getSchedulesGridData(getColumnDataRequest, standardConfig, handler));
    }
}
//class EventCountersGridService {
//    constructor(grpcHost) {
//        this.service = new GrowcloudEventCountersGridServiceClient(grpcHost);
//        this.tokenKey = tokenKey;
//    }
//    getGrowcloudGridConfigurations(getConfigurationRequest, messageConsumer) {
//        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getGrowcloudGridConfigurations(getConfigurationRequest, standardConfig, handler));
//    }
//    getEventCountersGridData(getColumnDataRequest, messageConsumer) {
//        return streamInvocation(messageConsumer, (standardConfig, handler) => this.service.getEventCountersGridData(getColumnDataRequest, standardConfig, handler));
//    }
//}