import React from 'react'
import { createRoot } from 'react-dom/client'
import {createTheme} from '@mui/material/styles';

import {withDcx} from '@dc/dcx/application/withDcx';
import storage from 'gc-server/application/GrowcloudServerStorage'

import {ThemeProvider} from '@mui/material/styles';

import { GrowcloudServer } from 'gc-server/gc-server'

import { enUS } from '@mui/material/locale';

const theme = createTheme({
  drawerWidth: { width: 240 },
  palette: {
    mode: 'dark',
  },
  typography: {
      fontFamily: 'Roboto, Arial',
  },
  components: {
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Roboto';
            font-style: normal;
            font-display: swap;
            font-weight: 500;
            src: local('Roboto Medium'), local('Roboto-Medium'), url('/fonts/KFOlCnqEu92Fr1MmEU9fBBc9.ttf') format('truetype');
          }
        `,
      }
  }
}, enUS);

const store = storage()

createRoot(document.getElementById('root')).render(<ThemeProvider theme={theme}><GrowcloudServer store={store} theme={theme}/></ThemeProvider>)