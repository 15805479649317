export const AUTHENTICATION = '$$AUTHENTICATION';
export const AUTHENTICATION_SUCCESS = '$$AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAIL = '$$AUTHENTICATION_FAIL';

export const SIGNOUT = '$$SIGNOUT';
export const SIGNOUT_SUCCESS = '$$SIGNOUT_SUCCESS';

export const AUTH_TOKEN = '$$AUTH_TOKEN';
export const AUTH_TOKEN_SUCCESS = '$$AUTH_TOKEN_SUCCESS';
export const AUTH_TOKEN_FAIL = '$$AUTH_TOKEN_FAIL';

export const doAuthentication = (formData) => {
  return { type: AUTHENTICATION, data: formData, }
}

export const doSignout = () => {
  return { type: SIGNOUT, }
}

export const getCurrentUser = () => {
  return { type: AUTH_TOKEN, }
}