import {fork, put, take} from 'redux-saga/effects'

import {GetConfigurationRequest} from 'gc-commons/growcloud/v1/grid/GridServiceMessages_pb';

import {
    GET_CURRENT_READINGS_DATA,
    GET_CALCULATIONS_GRID_DATA,
    GET_EVENTS,
    GET_GRID_CONFIGURATIONS,
    GET_ASSET_STATES_GRID_DATA,
    GET_EVENT_COUNTERS_GRID_DATA,
    GET_CALENDARS_GRID_DATA,
    GET_PRODUCT_LINES_GRID_DATA,
    GET_PROGRAMS_GRID_DATA,
    GET_SCHEDULES_GRID_DATA
} from './../actions/growcloud-grid-services-actions';

import {
    GET_GRID_COLUMN_DATA_FAIL,
    GET_GRID_COLUMN_DATA_SUCCESS,
    GET_GRID_CONFIGURATIONS_FAIL,
    GET_GRID_CONFIGURATIONS_SUCCESS,
} from 'gc-ui-components/ui/mw/grid-service/actions/grid-service-actions';

import GrowcloudGridHelper from '../../GrowcloudGridHelper';

import {httpErrorResponseHandler} from 'gc-authentication/authentication/utils/authentication-utils';

import {makeGetColumnDataRequest} from '../../GrowcloudGridServiceUtils';

const doGrowcloudGridConfigurations = (userContext, getConfigurationRequest) => {
    return new Promise((success, error) => {
        let getGrowcloudResponse1 = null;
        userContext.serviceRegistry.currentReadingsGridService().getGrowcloudGridConfigurations(getConfigurationRequest,
            getGrowcloudResponse => {
                getGrowcloudResponse1 = getGrowcloudResponse;
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(getGrowcloudResponse1);
                return;
            }
            error(code, msg, trailers);
        });
    });
}

export function* getGrowcloudGridConfigurationsConsumer() {
    while (true) {
        const request = yield take(GET_GRID_CONFIGURATIONS)
       // let subjectIdentity = request.configurationObject.urn ? IdentityUtils.fromResourceUrn(request.configurationObject.urn) : new Identity();
        let getConfigurationRequest = new GetConfigurationRequest();
       // getConfigurationRequest.setSubject(subjectIdentity);
        getConfigurationRequest.setChannel(request.channel);
        try {
            let getGrowcloudResponse = yield doGrowcloudGridConfigurations(request.userContext, getConfigurationRequest);
            let gridConfigurations = [];
            getGrowcloudResponse.getSuccess().getGridConfigurationList().forEach(gridConfiguration => {
                let gridConfigurationJson = GridViewHelper.toJsonObject(gridConfiguration);
                gridConfigurations.push(gridConfigurationJson);
            });
            yield put({
                type: GET_GRID_CONFIGURATIONS_SUCCESS,
                data: gridConfigurations,
                channel: request.channel,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: GET_GRID_CONFIGURATIONS_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}

const doCurrentReadingsGridColumnData = (userContext, getColumnDataRequest) => {
    return new Promise((success, error) => {
        let getColumnDataResponse1 = [];
        let counter = 0;
        userContext.serviceRegistry.currentReadingsGridService().getCurrentReadingsGridData(getColumnDataRequest,
            getColumnDataSetResponse => {
                let columnDataSet = getColumnDataSetResponse.toObject();
                let next = { id: 'next' + ++counter }
                columnDataSet.columndataList.forEach(item => {
                   next[item.columnurn.urn] = item.cellvalue.displaytext;
                });
                getColumnDataResponse1.push(next);
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(getColumnDataResponse1);
                return;
            }
            error(code, msg, trailers);
        });
    });
}

export function* getCurrentReadingsGridColumnDataConsumer() {
    while (true) {
        const request = yield take(GET_CURRENT_READINGS_DATA)
        let getColumnDataRequest = makeGetColumnDataRequest(request.gridConfiguration);
        try {
            let getConfigurationResponse = yield doCurrentReadingsGridColumnData(request.userContext, getColumnDataRequest);
            yield put({
                type: GET_GRID_COLUMN_DATA_SUCCESS,
                data: getConfigurationResponse,
                channel: request.channel,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: GET_GRID_COLUMN_DATA_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}

const doCalculationsGridColumnData = (userContext, getColumnDataRequest) => {
    return new Promise((success, error) => {
        let getColumnDataResponse1 = [];
        let counter = 0;
        userContext.serviceRegistry.calculationsGridService().getCalculationsGridData(getColumnDataRequest,
            getColumnDataSetResponse => {
                let columnDataSet = getColumnDataSetResponse.toObject();
                let next = { id: 'next' + ++counter }
                columnDataSet.columndataList.forEach(item => {
                   next[item.columnurn.urn] = item.cellvalue.displaytext;
                });
                getColumnDataResponse1.push(next);
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(getColumnDataResponse1);
                return;
            }
            error(code, msg, trailers);
        });
    });
}

export function* getCalculationsGridColumnDataConsumer() {
    while (true) {
        const request = yield take(GET_CALCULATIONS_GRID_DATA)
        let getColumnDataRequest = makeGetColumnDataRequest(request.gridConfiguration);
        request.subjects.forEach(subject => {
           // getColumnDataRequest.addSubject(IdentityUtils.fromResourceUrn(subject));
        });
        try {
            let getConfigurationResponse = yield doCalculationsGridColumnData(request.userContext, getColumnDataRequest);

            yield put({
                type: GET_GRID_COLUMN_DATA_SUCCESS,
                data: getConfigurationResponse,
                channel: request.channel,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: GET_GRID_COLUMN_DATA_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}
const doAssetStatesGridColumnData = (userContext, getColumnDataRequest) => {
    return new Promise((success, error) => {
        let getColumnDataResponse1 = [];
        let counter = 0;
        userContext.serviceRegistry.assetStatesGridService().getAssetStatesGridData(getColumnDataRequest,
            getColumnDataSetResponse => {
                let columnDataSet = getColumnDataSetResponse.toObject();
                let next = { id: 'next' + ++counter }
                columnDataSet.columndataList.forEach(item => {
                   next[item.columnurn.urn] = item.cellvalue.displaytext;
                });
                getColumnDataResponse1.push(next);
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(getColumnDataResponse1);
                return;
            }
            error(code, msg, trailers);
        });
    });
}

export function* getAssetStatesGridColumnDataConsumer() {
    while (true) {
        const request = yield take(GET_ASSET_STATES_GRID_DATA)
        let getColumnDataRequest = makeGetColumnDataRequest(request.gridConfiguration);
        try {
            let getConfigurationResponse = yield doAssetStatesGridColumnData(request.userContext, getColumnDataRequest);
            yield put({
                type: GET_GRID_COLUMN_DATA_SUCCESS,
                data: getConfigurationResponse,
                channel: request.channel,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: GET_GRID_COLUMN_DATA_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}

const doProductLinesGridColumnData = (userContext, getColumnDataRequest) => {
    return new Promise((success, error) => {
        let getColumnDataResponse1 = [];
        let counter = 0;
        userContext.serviceRegistry.productLinesGridService().getProductLinesGridData(getColumnDataRequest,
            getColumnDataSetResponse => {
                let columnDataSet = getColumnDataSetResponse.toObject();
                let next = { id: 'next' + ++counter }
                columnDataSet.columndataList.forEach(item => {
                   next[item.columnurn.urn] = item.cellvalue.displaytext;
                });
                getColumnDataResponse1.push(next);
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(getColumnDataResponse1);
                return;
            }
            error(code, msg, trailers);
        });
    });
}

export function* getProductLinesGridColumnDataConsumer() {
    while (true) {
        const request = yield take(GET_PRODUCT_LINES_GRID_DATA)
        let getColumnDataRequest = makeGetColumnDataRequest(request.gridConfiguration);
        try {
            let getConfigurationResponse = yield doProductLinesGridColumnData(request.userContext, getColumnDataRequest);
            yield put({
                type: GET_GRID_COLUMN_DATA_SUCCESS,
                data: getConfigurationResponse,
                channel: request.channel,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: GET_GRID_COLUMN_DATA_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}

const doCalendarsGridColumnData = (userContext, getColumnDataRequest) => {
    return new Promise((success, error) => {
        let getColumnDataResponse1 = [];
        let counter = 0;
        userContext.serviceRegistry.calendarsGridService().getCalendarsGridData(getColumnDataRequest,
            getColumnDataSetResponse => {
                let columnDataSet = getColumnDataSetResponse.toObject();
                let next = { id: 'next' + ++counter }
                columnDataSet.columndataList.forEach(item => {
                   next[item.columnurn.urn] = item.cellvalue.displaytext;
                });
                getColumnDataResponse1.push(next);
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(getColumnDataResponse1);
                return;
            }
            error(code, msg, trailers);
        });
    });
}

export function* getCalendarsGridColumnDataConsumer() {
    while (true) {
        const request = yield take(GET_CALENDARS_GRID_DATA)

        let getColumnDataRequest = makeGetColumnDataRequest(request.gridConfiguration);

        try {
            let getConfigurationResponse = yield doCalendarsGridColumnData(request.userContext, getColumnDataRequest);

            yield put({
                type: GET_GRID_COLUMN_DATA_SUCCESS,
                data: getConfigurationResponse,
                channel: request.channel,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: GET_GRID_COLUMN_DATA_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}
const doEventCountersGridColumnData = (userContext, getColumnDataRequest) => {
    return new Promise((success, error) => {
        let getColumnDataResponse1 = null;
        userContext.serviceRegistry.eventCountersGridService().getEventCountersGridData(getColumnDataRequest,
            getColumnDataSetResponse => {

            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(getColumnDataResponse1);
                return;
            }
            error(code, msg, trailers);
        });
    });
}

export function* getEventCountersGridColumnDataConsumer() {
    while (true) {
        const request = yield take(GET_EVENT_COUNTERS_GRID_DATA)
        let getColumnDataRequest = makeGetColumnDataRequest(request.gridConfiguration);

        request.subjects.forEach(subject => {
           // getColumnDataRequest.addSubject(IdentityUtils.fromResourceUrn(subject));
        });
        try {
            let getConfigurationResponse = yield doEventCountersGridColumnData(request.userContext, getColumnDataRequest);

            yield put({
                type: GET_GRID_COLUMN_DATA_SUCCESS,
                data: getConfigurationResponse,
                channel: request.channel,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: GET_GRID_COLUMN_DATA_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}

const doSchedulesGridColumnData = (userContext, getColumnDataRequest) => {
    return new Promise((success, error) => {
        let getColumnDataResponse1 = [];
        let counter = 0;
        userContext.serviceRegistry.schedulesGridService().getSchedulesGridData(getColumnDataRequest,
            getColumnDataSetResponse => {
                let columnDataSet = getColumnDataSetResponse.toObject();
                let next = { id: 'next' + ++counter }
                columnDataSet.columndataList.forEach(item => {
                   next[item.columnurn.urn] = item.cellvalue.displaytext;
                });
                getColumnDataResponse1.push(next);
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(getColumnDataResponse1);
                return;
            }
            error(code, msg, trailers);
        });
    });
}

export function* getSchedulesGridColumnDataConsumer() {
    while (true) {
        const request = yield take(GET_SCHEDULES_GRID_DATA)

        let getColumnDataRequest = makeGetColumnDataRequest(request.gridConfiguration);

        try {
            let getConfigurationResponse = yield doSchedulesGridColumnData(request.userContext, getColumnDataRequest);

            yield put({
                type: GET_GRID_COLUMN_DATA_SUCCESS,
                data: getConfigurationResponse,
                channel: request.channel,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: GET_GRID_COLUMN_DATA_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}

const doProgramsGridColumnData = (userContext, getColumnDataRequest) => {
    return new Promise((success, error) => {
        let getColumnDataResponse1 = [];
        let counter = 0;
        userContext.serviceRegistry.programsGridService().getProgramsGridData(getColumnDataRequest,
            getColumnDataSetResponse => {
                let columnDataSet = getColumnDataSetResponse.toObject();
                let next = { id: 'next' + ++counter }
                columnDataSet.columndataList.forEach(item => {
                   next[item.columnurn.urn] = item.cellvalue.displaytext;
                });
                getColumnDataResponse1.push(next);
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(getColumnDataResponse1);
                return;
            }
            error(code, msg, trailers);
        });
    });
}

export function* getProgramsGridColumnDataConsumer() {
    while (true) {
        const request = yield take(GET_PROGRAMS_GRID_DATA)

        let getColumnDataRequest = makeGetColumnDataRequest(request.gridConfiguration);

        try {
            let getConfigurationResponse = yield doProgramsGridColumnData(request.userContext, getColumnDataRequest);

            yield put({
                type: GET_GRID_COLUMN_DATA_SUCCESS,
                data: getConfigurationResponse,
                channel: request.channel,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: GET_GRID_COLUMN_DATA_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}

export default function* root() {
    yield fork(getGrowcloudGridConfigurationsConsumer)
    yield fork(getCurrentReadingsGridColumnDataConsumer)
    yield fork(getCalculationsGridColumnDataConsumer)
    yield fork(getAssetStatesGridColumnDataConsumer)
    yield fork(getCalendarsGridColumnDataConsumer)
    yield fork(getSchedulesGridColumnDataConsumer)
    yield fork(getProgramsGridColumnDataConsumer)
    yield fork(getEventCountersGridColumnDataConsumer)
    yield fork(getProductLinesGridColumnDataConsumer)
}