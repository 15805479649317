import {defineMessages} from 'react-intl';

import {defaultI18nMessage} from '@dc/dc/i18n/MessageUtils';

export const growcloudServerMessage = (name, message) => {
    return defaultI18nMessage('gcServer', name, message);
}

export const growcloudServerMessages = defineMessages({
    routePlanningLabel: growcloudServerMessage('routePlanningLabel', 'PLANNING'),
    routeCalendarsLabel: growcloudServerMessage('routeCalendarsLabel', 'CALENDARS'),
    routeReadingsLabel: growcloudServerMessage('routeReadingsLabel', 'READINGS'),
    routeStatusLabel: growcloudServerMessage('routeStatusLabel', 'STATUS'),
    routeConfigurationsLabel: growcloudServerMessage('routeConfigurationsLabel', 'CONFIGURATIONS'),
    routeExplorerLabel: growcloudServerMessage('routeExplorerLabel', 'EXPLORER'),
    routeEventsLabel: growcloudServerMessage('routeEventsLabel', 'EVENTS'),
    routeOutputsLabel: growcloudServerMessage('routeOutputsLabel', 'OUTPUTS'),
    routeAssetsLabel: growcloudServerMessage('routeAssetsLabel', 'ASSETS'),
})