import React from 'react'
import {createTheme} from '@mui/material/styles';
import {BASE_TYPOGRAPHY} from "./baseTypography";

import { Theme } from '@mui/material/styles';

export default function resolvePadding(args) {
    return args
        .map((argument) => {
            if (typeof argument === 'string') {
                return argument;
            }
            const output = argument;
            return typeof output === 'number' ? `${output}rem` : output;
        })
        .join(' ');

};

export const enterpriseUI = { // this is our dc theme [palette,type,font, unit spacing]
    drawerWidth: { width: 240 },
    palette: {
        primaryMain: "#5949FB",
        hyperLink: "#5949FB",
        secondaryMain: "#5949FB",
        backgroundDefault: "#F7F8FA",
        backgroundPaper: "#FFFFFF",
        backgroundPaperDark: "#212C4F",
        divider: "#E5E5E5",

        errorMain: "#E10050",
        warningMain: "#FEB32B",
        infoMain: '#2C98F0',
        successMain: '#1FC754',
        textPrimary: '#212C4F',
        textSecondary: '#405161',
        textInfo: '#7A7A7A',
    }
};

export const digitalQualityTechTheme = {//this overrides our dc theme [palette,type,font, unit spacing] and inherits those that aren't customized
    drawerWidth: { width: 240 },
    palette: {
        primaryMain: "#1859FB",
        hyperLink: "#1859FB",
        secondaryMain: "#1859FB",
    }
};

const BRAND_BACKGROUND = '$backgroundColor';
const BRAND_PAPER_BACKGROUND = '$paperBackgroundColor';
const BRAND_BACKGROUND_DARK = '$brandBackgroundDark';
const BRAND_ACTIVE_LINK = '$activeLinkColor';
const BRAND_DEFAULT_LINK = '$vendorDefaultLinkColor';
const BRAND_SECONDARY_MAIN = '$secondaryMain';
const BRAND_PRIMARY_MAIN = '$primaryMain';
const BRAND_PRIMARY_TEXT = '$primaryTextMain';
const BRAND_DIVIDER = '$dividerColor';
const BRAND_SPACING = '$spacing';

const BRAND_ERROR_MAIN = '$errorMain';
const BRAND_FOCUS_MAIN = '$focusMain';

const vendorBrandBackgroundColor = 'rgba(255,255,255,1.0)';
const vendorBrandPaperBackgroundColor = 'rgba(255,255,255,1.0)';
const vendorBrandBackgroundDark = '#0E2846';
const vendorDefaultLinkColor = '#1859FB';
const vendorActiveLinkColor = '#5949FB';
const vendorSecondaryMainColor = '#5385FE';
const vendorBrandPrimaryColor = '#5949FB';
const vendorBrandPrimaryTextColor = '#0E2846'; //systemPrimaryText
const vendorDividerColor = '#E5E5E5'; //enterpriseUI.palette.divider
const vendorSpacing = 0.5; //BRAND_SPACING could be defined by key ie 1 = dense, 2 = normal, 3 = large, 4 = x-large

export const toTheme = (theme) => {
    return createTheme(theme);
}

