import AppbarReducer from './../app/reducers/appbar-reducer';
import NotificationsReducer from './../app/reducers/notifications-reducer';

import appbarSaga from './../app/sagas/appbar-saga';

export const dcxReducers = () => {
    return {appbar: AppbarReducer, notifications: NotificationsReducer}
}

export const dcxSagas = () => {
    return [appbarSaga,]
}
