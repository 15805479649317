import React from 'react';

import { withStyles } from '@mui/styles';
import InternalLink from '@dc/dc/components/links/InternalLink';
import CTAButton from '@dc/dc/components/buttons/CTAButton';
import SecondaryButton from '@dc/dc/components/buttons/SecondaryButton';

import Box from '@mui/material/Box';

const styleSheet = theme => ({
    menu: {
        marginTop: 8,
        marginLeft: -52,
    },
    item: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        '&:focus': {
            background: 'none',
        },
        '&:hover': {
            background: theme.palette.text.divider,
        },
    },
    buttonIconWrap: {
        marginRight: 0,
    },
    icon: {
        '&:hover': {
            color: 'rgba(253, 253, 253, 0.86)',
        },
        '&:focus': {
            background: 'none',
        }
    },
    fontMedium: {
        fontWeight: theme.typography.fontWeightMedium,
    }
});

class AppbarSignin extends React.Component {

    render() {
        const {
            classes,
            location,
            userProfileRoutesConfig
        } = this.props;

        const signInRoute = userProfileRoutesConfig && userProfileRoutesConfig.signInRoute ? userProfileRoutesConfig.signInRoute : '/signin';
        const signUpRoute = userProfileRoutesConfig && userProfileRoutesConfig.signUpRoute ? userProfileRoutesConfig.signUpRoute : '/signup';

        const additionalSigninLinks = userProfileRoutesConfig && userProfileRoutesConfig.additionalSigninLinks ? userProfileRoutesConfig.additionalSigninLinks : [];

        return (
            <Box display="flex" flexDirection="row" justifyContent={'right'} alignItems="center">
                <SecondaryButton
                    buttonText={'SIGN IN'}
                    linkType="Link"
                    toLocation={signInRoute}
                />

                {additionalSigninLinks.map((additionalSigninLink, index) => {
                    return (
                        <InternalLink
                            key={index + additionalSigninLink.linkText}
                            to={additionalSigninLink.route}
                        >{additionalSigninLink.linkText}</InternalLink>
                    )
                })}
                <CTAButton
                    buttonText={'SIGN UP'}
                    //publicButton
                    dense
                    linkType="Link"
                    toLocation={signUpRoute}
                    style={{marginLeft: '16px'}}
                />
            </Box>
        )
    }
}

export default withStyles(styleSheet)(AppbarSignin)
