import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withStyles } from '@mui/styles';

import AuthContainer from 'gc-authentication/authentication/containers/AuthContainer';

import AppbarNotifications from '@dc/dcx/app/appbar/containers/AppbarNotifications';
import AppbarUserProfileMenu from "../components/AppbarUserProfileMenu";

import Box from '@mui/material/Box';

const styleSheet = theme => ({
    userName: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    userWrapper: {
        justifyContent: 'flex-end',
    },
    itemWrap: {
        padding: '4px',
    },
});

class AppbarUserProfile extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            authorisedUserMenuItems,
            user,
            isSubmitting,
            globalConfiguration,
            elideUserProfile,
        } = this.props;

        const finalElideUserProfile = elideUserProfile || (user && user.isFederated ? true : false);
        return (
            <AuthContainer globalConfiguration={globalConfiguration} userRequired>
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={'center'}
                    alignItems="center"
                >
                    <AppbarNotifications/>

                    <AppbarUserProfileMenu
                        user={user}
                        isSubmitting={isSubmitting}
                        authorisedUserMenuItems={authorisedUserMenuItems}
                        elideUserProfile={finalElideUserProfile}
                    />
                </Box>
            </AuthContainer>
        )
    }
}

AppbarUserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
    authorisedUserMenuItems: PropTypes.array,
}

const mapStateToProps = (state) => {
    const {
        auth: {
            isSubmitting,
            user,
        }
    } = state;
    return {
        isSubmitting,
        user,
    }
}

export default withStyles(styleSheet)(connect(mapStateToProps)(AppbarUserProfile))
