import React, {useState} from 'react';

import {withStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import {connect} from 'react-redux';
import {dashboardStyleSheet} from 'gc-ui-components/ui/style/PortalStyles';
import {displayNouveau} from '@dc/dc/components/surface/CardX';

import Text from '@dc/dc/content/Text';

import Loading from '@dc/dcx/app/common/Loading';
import Error from '@dc/dcx/app/common/Error';

import {RequestStatus} from '@dc/dcx/app/util/RequestConstants';

import GenericList from 'gc-ui-components/ui/components/GenericList';
import ListItem from '@mui/material/ListItem';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import IconSVG from "@dc/dc/components/icon/IconSVG";

import ActiveProductionModelIcon from "./icons/ActiveProductionModelIcon"

import Avatar from '@mui/material/Avatar';
export const gcnEquals = (a,b) => {
    return a.resourceid == b.resourceid
    && a.federationid == b.federationid
    && a.typeid == b.typeid
    && a.versionid == b.versionid;
}

export const makeSubtitle = (alpha, beta) => {
    return alpha.label + ' - ' + beta.identifierinfo.slug
}

 const dayOfYear = date => Math.floor((date - new Date(date.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24));

export const makeLabel = (label) => label;

const ActiveProductionModelsPanel = ({activeProductionModel}) => {
    let itemsArray = [];
    const [currentView, setCurrentView] = useState('apm' + activeProductionModel.infoobject.gcn);
    itemsArray.push((
      <>
        <Grid item xs={12}>
            <Grid container justifyContent={'flex-end'}>
                <Grid item>
                    <ButtonGroup
                        id={'apm-button-group'+activeProductionModel.infoobject.gcn}
                        aria-label="Active production model actions"
                        variant="outlined"
                        size="small"
                      >
                        <Button size="small" key="status" value="status"
                        onClick={(event) => {
                            setCurrentView(event.currentTarget.value)
                        }}>Status</Button>
                        <Button size="small" key="schedule" value="schedule"
                        onClick={(event) => {
                            setCurrentView(event.currentTarget.value)
                        }}>Schedule</Button>
                        <Button size="small" key="events" value="events"
                        onClick={(event) => {
                            setCurrentView(event.currentTarget.value)
                        }}>Events</Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            {'status' == currentView && (
                <>status</>
            )}
            {'schedule' == currentView && (
               <>schedule</>
            )}
            {'events' == currentView && (
               <>events</>
            )}
        </Grid>
        </>
    ))
    return itemsArray;
}

class CurrentActiveProductionModels extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            panelStates: {
                inheritedProps: true,
                themeProps: true,
                hasHistory: false
            }
        }
    }

    renderAccordion(panelId, title, content) {
        return (
            <Accordion key={panelId} expanded={this.state.panelStates[panelId]}>
                <AccordionSummary
                    expandIcon={(
                        <ExpandMoreIcon onClick={this.handleStateChange(panelId)} color={'primary'}/>
                    )}
                >
                    <ContainerX variant={'fitted'}>
                        {title}
                    </ContainerX>
                </AccordionSummary>
                <AccordionDetails>
                    {content}
                </AccordionDetails>
            </Accordion>
        )
    }

    handleStateChange = panel => () => {
        const currentPanelStates = this.state.panelStates;
        const currentState = currentPanelStates[panel];
        const nextPanelStates = Object.assign(currentPanelStates, {[panel]: !currentState})
        this.setState({panelStates: nextPanelStates});
    }



    renderActiveProductionModel_DIST(activeProductionModel) {
        let itemsArray = [];
        PRODUCT_LINE_PHASES.forEach((activity, index) => {
            itemsArray.push((
                <Grid item xs={12} key={`line-${index}`}>
                    {this.renderAccordion(`panel-${index}`, (
                            <>
                                {displayNouveau(
                                    activity.key,
                                    null,
                                    whichIcon(activity),
                                    (
                                        <Box display="flex" flexDirection="row" alignItems="center">
                                            {('Design' != activity.key) && (
                                                <>
                                                    <Text
                                                        variant={'caption'}
                                                        LinkProps={{color: 'secondary'}}
                                                        href={`/consumers?realizationItemDefinitionUri=${activeProductionModel.realizationItemDefinition.urn}`}
                                                    >
                                                        Consumers
                                                    </Text>

                                                    <Text
                                                        variant={'caption'}
                                                        LinkProps={{color: 'secondary'}}
                                                    >
                                                        |
                                                    </Text>
                                                </>
                                            )}
                                            <Text
                                                variant={'caption'}
                                                LinkProps={{color: 'secondary'}}
                                                href={`/project-artifacts/${activity.key.toLowerCase()}/${activeProductionModel.realizationItemDefinition.urn}`}
                                            >
                                                Dashboard
                                            </Text>
                                        </Box>
                                    ),
                                    null
                                )}
                            </>
                        ))
                    }
                </Grid>
            ))
        })
        return itemsArray;
    }

    extractActiveProductionModels(enclosure, site){
        const { activeProductionModels } = this.props;
        let nextActiveProductionModels = [];
        activeProductionModels.forEach(activeProductionModel => {
            if(gcnEquals(activeProductionModel.site.gcn, site)
            && gcnEquals(activeProductionModel.enclosure.gcn, enclosure)){
                nextActiveProductionModels.push(activeProductionModel)
            }
        })
        return nextActiveProductionModels;
    }

   renderSitesConfigurationsModels(sitesConfigurationsModels) {
        let itemsArray = [];
        let that = this;
        sitesConfigurationsModels.forEach((sitesConfigurationsModel, index) => {
            sitesConfigurationsModel.enclosuresiteassignmentList.forEach(a => {
                    const activeProductionModels = that.extractActiveProductionModels(a.subject, a.object);
                    itemsArray.push(that.renderActiveProductionModels(activeProductionModels, a.subject, a.object))
            })
        })
        return itemsArray;
    }

    renderActiveProductionModels(activeProductionModels, site, enclosure) {
        let itemsArray = [];

        activeProductionModels.forEach((activeProductionModel, index) => {
            itemsArray.push((
                <Grid item xs={12} key={`grid-${index}-${activeProductionModel.infoobject.gcn}-${activeProductionModel.productline.gcn}`}>
                    {this.renderAccordion(`panel-${index}`, (
                            <>
                                {displayNouveau(
                                    makeLabel(activeProductionModel.infoobject.label),
                                    activeProductionModel.productionlot.identifierinfo.slug,
                                    (
                                        <Box sx={{ bgcolor: 'orange' }} variant="square">
                                          {activeProductionModel.productline.identifierinfo.slug}
                                        </Box>
                                    ),
                                    () => { return (<>hELLO</>) },
                                    (null)
                                )}
                            </>
                        ), (
                            <Grid container>
                                <ActiveProductionModelsPanel activeProductionModel={activeProductionModel}/>
                            </Grid>
                        )
                    )}
                </Grid>
            ))
        })
        return itemsArray;
    }


    render() {
        const {
            userContext,
            activeProductionModels,
            sitesConfigurationsModels
        } = this.props;

        if (!userContext) {
            return <Loading message={'Awaiting authorised user'}/>
        }

        if (!activeProductionModels) {
            return <Loading message={'Awaiting activeProductionModels'}/>
        }

        return (
            <ContainerX AsContainer withLargeBottomMargin withLargeTopMargin>
               <Grid container spacing={3} justifyContent="flex-start">
                    {this.renderSitesConfigurationsModels(sitesConfigurationsModels)}
               </Grid>
            </ContainerX>
        )
    }
}
export default withStyles(dashboardStyleSheet, {withTheme: true})(connect(state => {
    return {
        activeProductionModels: state.growcloud.activeProductionModels,
        sitesConfigurationsModels: state.growcloud.sitesConfigurationsModels
    }
})(CurrentActiveProductionModels))