export const GET_GRID_CONFIGURATIONS = '$$GET_GRID_CONFIGURATIONS';

export const getGrowcloudGridConfigurations = (userContext, contextHash, channel, configurationObject) => {
    return {
        type: GET_GRID_CONFIGURATIONS,
        userContext,
        contextHash,
        channel,
        configurationObject,
    }
}

export const GET_CURRENT_READINGS_DATA = '$$GET_CURRENT_READINGS_DATA';

export const getCurrentReadingsGridData = (userContext, contextHash, channel, gridConfiguration, subjects) => {
    return {
        type: GET_CURRENT_READINGS_DATA,
        userContext,
        contextHash,
        channel,
        gridConfiguration,
        subjects
    }
}

export const GET_CALCULATIONS_GRID_DATA = '$$GET_CALCULATIONS_GRID_DATA';

export const getCalculationsGridData = (userContext, contextHash, channel, gridConfiguration, subjects) => {
    return {
        type: GET_CALCULATIONS_GRID_DATA,
        userContext,
        contextHash,
        channel,
        gridConfiguration,
        subjects
    }
}

export const GET_ASSET_STATES_GRID_DATA = '$$GET_ASSET_STATES_GRID_DATA';

export const getAssetStatesGridData = (userContext, contextHash, channel, gridConfiguration, subjects) => {
    return {
        type: GET_ASSET_STATES_GRID_DATA,
        userContext,
        contextHash,
        channel,
        gridConfiguration,
        subjects
    }
}

export const GET_EVENT_COUNTERS_GRID_DATA = '$$GET_EVENT_COUNTERS_GRID_DATA';

export const getEventCountersGridData = (userContext, contextHash, channel, gridConfiguration, subjects) => {
    return {
        type: GET_EVENT_COUNTERS_GRID_DATA,
        userContext,
        contextHash,
        channel,
        gridConfiguration,
        subjects
    }
}

export const GET_CALENDARS_GRID_DATA = '$$GET_CALENDARS_GRID_DATA';

export const getCalendarsGridData = (userContext, contextHash, channel, gridConfiguration, subjects) => {
    return {
        type: GET_CALENDARS_GRID_DATA,
        userContext,
        contextHash,
        channel,
        gridConfiguration,
        subjects
    }
}

export const GET_PRODUCT_LINES_GRID_DATA = '$$GET_PRODUCT_LINES_GRID_DATA';

export const getProductLinesGridData = (userContext, contextHash, channel, gridConfiguration, subjects) => {
    return {
        type: GET_PRODUCT_LINES_GRID_DATA,
        userContext,
        contextHash,
        channel,
        gridConfiguration,
        subjects
    }
}

export const GET_SCHEDULES_GRID_DATA = '$$GET_SCHEDULES_GRID_DATA';

export const getSchedulesGridData = (userContext, contextHash, channel, gridConfiguration, subjects) => {
    return {
        type: GET_SCHEDULES_GRID_DATA,
        userContext,
        contextHash,
        channel,
        gridConfiguration,
        subjects
    }
}

export const GET_PROGRAMS_GRID_DATA = '$$GET_PROGRAMS_GRID_DATA';

export const getProgramsGridData = (userContext, contextHash, channel, gridConfiguration, subjects) => {
    return {
        type: GET_PROGRAMS_GRID_DATA,
        userContext,
        contextHash,
        channel,
        gridConfiguration,
        subjects
    }
}