import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withStyles } from '@mui/styles';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';

import PrimaryNavigation from '../components/PrimaryNavigation';
import MenuDrawer from '../components/MenuDrawer';
import TwoToneLinkedLeadText from '../../components/surfaces/TwoToneLinkedLeadText';
import LinkedLeadText from '../../components/surfaces/LinkedLeadText';
import LeadText from '../../components/surfaces/LeadText';

//let theme = {};

export const styleSheet = theme => ({
        productWrapper: {
            minWidth: theme.spacing(21),
            flexBasis: theme.spacing(21),

            [theme.breakpoints.down('md')]: {
                minWidth: theme.spacing(24),
                flexBasis: theme.spacing(24),
            },
        },
        appbarMainWrapper: {
            flex: '0 0 auto',
            '@media (max-width: 320px)': {
                overflow: 'hidden'
            },
        },
        title: {
            marginRight: theme.spacing(2),
            textTransform: 'none',
            textDecoration: 'none',
            fontWeight: 'bold',
        },
        titlePrimary: {
            color: theme.palette.secondary.dark,
        },
        isLink: {
            fontWeight: 'bold',
//            color: brandPrimary,
            textDecoration: 'none',
        },
        flexMenu: {
            flex: '1 1 0',
            'overflow-y': 'auto',
            'vertical-align': 'top',
            transition: 'flex .3s,width .3s',
        },
        flexGrow: {
            flex: '1 0 auto',
            maxWidth: '100%'
        },
        flexShrink: {
            display: 'none',
            visibility: 'hidden',
        }
    })

class AppbarContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    renderMasthead = (obj) => {
        const {mainAppbarMasthead, classes} = this.props;
        console.log('Switch component type to: ', mainAppbarMasthead)

        switch (obj.component) {
            case 'LeadText':
                return (
                    <LeadText
                        masthead={obj}
                        className={classes.title}
                    />
                )
                break;
            case 'LinkedLeadText':
                return (
                    <LinkedLeadText
                        masthead={obj}
                        className={classes.title}
                    />
                )
                break;
            case 'TwoToneLinkedLeadText':
                return (
                    <TwoToneLinkedLeadText
                        masthead={obj}
                        className={classes.title}
                    />
                )
                break;
            default:
               throw `Unknown component ${obj.component}`
        }
    }

    render() {
        const {
            mainAppbarLinks,
            mainAppbarMenuDrawerItems=[],
            mainAppbarMasthead,
            responsiveMainNav,
            user,
            userActionsBlockRenderer,
            leadInBlockRenderer,
        } = this.props;


        return (
            <Toolbar disableGutters={(window.width > 1180) ? true : false}>
                <Grid container alignItems="center" justifyContent="space-between" wrap="nowrap" spacing={0}>
                    <Grid item>
                        <Grid container alignItems="center" wrap="nowrap">
                            {mainAppbarMenuDrawerItems.length > 0 && responsiveMainNav ? (
                                    <Hidden mdUp>
                                        <MenuDrawer
                                            nestedList={true}
                                            masthead={mainAppbarMasthead.text}
                                            menuItems={mainAppbarMenuDrawerItems}
                                            menuTabs={leadInBlockRenderer && leadInBlockRenderer}
                                            userActions={userActionsBlockRenderer && userActionsBlockRenderer}
                                        />
                                    </Hidden>
                                ) : (
                                    <MenuDrawer
                                        nestedList={true}
                                        masthead={mainAppbarMasthead.text}
                                        menuItems={mainAppbarMenuDrawerItems}
                                        menuTabs={leadInBlockRenderer && leadInBlockRenderer}
                                    />
                                )
                            }
                            {mainAppbarMasthead.text && (
                                <>{this.renderMasthead(mainAppbarMasthead)}</>
                            )}

                            {leadInBlockRenderer && (
                                <Hidden smDown>{leadInBlockRenderer()}</Hidden>)
                            }

                            {mainAppbarLinks && (
                                <PrimaryNavigation
                                    primaryNavigationLinks={mainAppbarLinks}
                                    user={user}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container alignItems="center" justifyContent="flex-end">
                            {userActionsBlockRenderer()}
                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        )
    }
}

AppbarContainer.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        searchActive: state.appbar.globalSearch.searchActive,
        user: state.auth.user,
    }
};

export default withStyles(styleSheet)(
    connect(mapStateToProps)(AppbarContainer))