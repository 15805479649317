// source: growcloud/v1/domain/Metadata.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var growcloud_v1_identity_Gcn_pb = require('../../../growcloud/v1/identity/Gcn_pb.js');
goog.object.extend(proto, growcloud_v1_identity_Gcn_pb);
goog.exportSymbol('proto.growcloud.v1.domain.AssignmentInfoObject', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.CreateObject', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.CreatedResponse', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.IdentifierInfoObject', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.InfoObject', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.Uri', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.VersionInfoObject', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.VersionInfoObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.VersionInfoObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.VersionInfoObject.displayName = 'proto.growcloud.v1.domain.VersionInfoObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.IdentifierInfoObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.IdentifierInfoObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.IdentifierInfoObject.displayName = 'proto.growcloud.v1.domain.IdentifierInfoObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.AssignmentInfoObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.AssignmentInfoObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.AssignmentInfoObject.displayName = 'proto.growcloud.v1.domain.AssignmentInfoObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.InfoObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.InfoObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.InfoObject.displayName = 'proto.growcloud.v1.domain.InfoObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.CreatedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.CreatedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.CreatedResponse.displayName = 'proto.growcloud.v1.domain.CreatedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.CreateObject = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.CreateObject, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.CreateObject.displayName = 'proto.growcloud.v1.domain.CreateObject';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.Uri = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.Uri, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.Uri.displayName = 'proto.growcloud.v1.domain.Uri';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.VersionInfoObject.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.VersionInfoObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.VersionInfoObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.VersionInfoObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    current: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    versionof: (f = msg.getVersionof()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.VersionInfoObject}
 */
proto.growcloud.v1.domain.VersionInfoObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.VersionInfoObject;
  return proto.growcloud.v1.domain.VersionInfoObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.VersionInfoObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.VersionInfoObject}
 */
proto.growcloud.v1.domain.VersionInfoObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCurrent(value);
      break;
    case 2:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setVersionof(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.VersionInfoObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.VersionInfoObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.VersionInfoObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.VersionInfoObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrent();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getVersionof();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool current = 1;
 * @return {boolean}
 */
proto.growcloud.v1.domain.VersionInfoObject.prototype.getCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.growcloud.v1.domain.VersionInfoObject} returns this
 */
proto.growcloud.v1.domain.VersionInfoObject.prototype.setCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional growcloud.v1.identity.Gcn versionOf = 2;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.VersionInfoObject.prototype.getVersionof = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 2));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.VersionInfoObject} returns this
*/
proto.growcloud.v1.domain.VersionInfoObject.prototype.setVersionof = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.VersionInfoObject} returns this
 */
proto.growcloud.v1.domain.VersionInfoObject.prototype.clearVersionof = function() {
  return this.setVersionof(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.VersionInfoObject.prototype.hasVersionof = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.IdentifierInfoObject.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.IdentifierInfoObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.IdentifierInfoObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.IdentifierInfoObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.IdentifierInfoObject}
 */
proto.growcloud.v1.domain.IdentifierInfoObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.IdentifierInfoObject;
  return proto.growcloud.v1.domain.IdentifierInfoObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.IdentifierInfoObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.IdentifierInfoObject}
 */
proto.growcloud.v1.domain.IdentifierInfoObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.IdentifierInfoObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.IdentifierInfoObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.IdentifierInfoObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.IdentifierInfoObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.growcloud.v1.domain.IdentifierInfoObject.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.domain.IdentifierInfoObject} returns this
 */
proto.growcloud.v1.domain.IdentifierInfoObject.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.growcloud.v1.domain.IdentifierInfoObject.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.domain.IdentifierInfoObject} returns this
 */
proto.growcloud.v1.domain.IdentifierInfoObject.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.AssignmentInfoObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.AssignmentInfoObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.AssignmentInfoObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 2, ""),
    subject: (f = msg.getSubject()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    object: (f = msg.getObject()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.AssignmentInfoObject;
  return proto.growcloud.v1.domain.AssignmentInfoObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.AssignmentInfoObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    case 3:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setSubject(value);
      break;
    case 4:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setObject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.AssignmentInfoObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.AssignmentInfoObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.AssignmentInfoObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSubject();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getObject();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject} returns this
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject} returns this
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional growcloud.v1.identity.Gcn subject = 3;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.getSubject = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 3));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject} returns this
*/
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.setSubject = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject} returns this
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.clearSubject = function() {
  return this.setSubject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.hasSubject = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional growcloud.v1.identity.Gcn object = 4;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.getObject = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 4));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject} returns this
*/
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.setObject = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject} returns this
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.clearObject = function() {
  return this.setObject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.AssignmentInfoObject.prototype.hasObject = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.InfoObject.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.InfoObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.InfoObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.InfoObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    gcn: (f = msg.getGcn()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    label: jspb.Message.getFieldWithDefault(msg, 2, ""),
    versioninfo: (f = msg.getVersioninfo()) && proto.growcloud.v1.domain.VersionInfoObject.toObject(includeInstance, f),
    identifierinfo: (f = msg.getIdentifierinfo()) && proto.growcloud.v1.domain.IdentifierInfoObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.InfoObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.InfoObject;
  return proto.growcloud.v1.domain.InfoObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.InfoObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.InfoObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setGcn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 3:
      var value = new proto.growcloud.v1.domain.VersionInfoObject;
      reader.readMessage(value,proto.growcloud.v1.domain.VersionInfoObject.deserializeBinaryFromReader);
      msg.setVersioninfo(value);
      break;
    case 4:
      var value = new proto.growcloud.v1.domain.IdentifierInfoObject;
      reader.readMessage(value,proto.growcloud.v1.domain.IdentifierInfoObject.deserializeBinaryFromReader);
      msg.setIdentifierinfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.InfoObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.InfoObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.InfoObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.InfoObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGcn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVersioninfo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.growcloud.v1.domain.VersionInfoObject.serializeBinaryToWriter
    );
  }
  f = message.getIdentifierinfo();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.growcloud.v1.domain.IdentifierInfoObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional growcloud.v1.identity.Gcn gcn = 1;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.InfoObject.prototype.getGcn = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 1));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.InfoObject} returns this
*/
proto.growcloud.v1.domain.InfoObject.prototype.setGcn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.InfoObject} returns this
 */
proto.growcloud.v1.domain.InfoObject.prototype.clearGcn = function() {
  return this.setGcn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.InfoObject.prototype.hasGcn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string label = 2;
 * @return {string}
 */
proto.growcloud.v1.domain.InfoObject.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.domain.InfoObject} returns this
 */
proto.growcloud.v1.domain.InfoObject.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional VersionInfoObject versionInfo = 3;
 * @return {?proto.growcloud.v1.domain.VersionInfoObject}
 */
proto.growcloud.v1.domain.InfoObject.prototype.getVersioninfo = function() {
  return /** @type{?proto.growcloud.v1.domain.VersionInfoObject} */ (
    jspb.Message.getWrapperField(this, proto.growcloud.v1.domain.VersionInfoObject, 3));
};


/**
 * @param {?proto.growcloud.v1.domain.VersionInfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.InfoObject} returns this
*/
proto.growcloud.v1.domain.InfoObject.prototype.setVersioninfo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.InfoObject} returns this
 */
proto.growcloud.v1.domain.InfoObject.prototype.clearVersioninfo = function() {
  return this.setVersioninfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.InfoObject.prototype.hasVersioninfo = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional IdentifierInfoObject identifierInfo = 4;
 * @return {?proto.growcloud.v1.domain.IdentifierInfoObject}
 */
proto.growcloud.v1.domain.InfoObject.prototype.getIdentifierinfo = function() {
  return /** @type{?proto.growcloud.v1.domain.IdentifierInfoObject} */ (
    jspb.Message.getWrapperField(this, proto.growcloud.v1.domain.IdentifierInfoObject, 4));
};


/**
 * @param {?proto.growcloud.v1.domain.IdentifierInfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.InfoObject} returns this
*/
proto.growcloud.v1.domain.InfoObject.prototype.setIdentifierinfo = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.InfoObject} returns this
 */
proto.growcloud.v1.domain.InfoObject.prototype.clearIdentifierinfo = function() {
  return this.setIdentifierinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.InfoObject.prototype.hasIdentifierinfo = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.CreatedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.CreatedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.CreatedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.CreatedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gcn: (f = msg.getGcn()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.CreatedResponse}
 */
proto.growcloud.v1.domain.CreatedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.CreatedResponse;
  return proto.growcloud.v1.domain.CreatedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.CreatedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.CreatedResponse}
 */
proto.growcloud.v1.domain.CreatedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setGcn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.CreatedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.CreatedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.CreatedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.CreatedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGcn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional growcloud.v1.identity.Gcn gcn = 1;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.CreatedResponse.prototype.getGcn = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 1));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.CreatedResponse} returns this
*/
proto.growcloud.v1.domain.CreatedResponse.prototype.setGcn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.CreatedResponse} returns this
 */
proto.growcloud.v1.domain.CreatedResponse.prototype.clearGcn = function() {
  return this.setGcn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.CreatedResponse.prototype.hasGcn = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.CreateObject.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.CreateObject.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.CreateObject} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.CreateObject.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slug: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.CreateObject}
 */
proto.growcloud.v1.domain.CreateObject.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.CreateObject;
  return proto.growcloud.v1.domain.CreateObject.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.CreateObject} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.CreateObject}
 */
proto.growcloud.v1.domain.CreateObject.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.CreateObject.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.CreateObject.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.CreateObject} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.CreateObject.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.growcloud.v1.domain.CreateObject.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.domain.CreateObject} returns this
 */
proto.growcloud.v1.domain.CreateObject.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slug = 2;
 * @return {string}
 */
proto.growcloud.v1.domain.CreateObject.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.domain.CreateObject} returns this
 */
proto.growcloud.v1.domain.CreateObject.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.Uri.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.Uri.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.Uri} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Uri.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.Uri}
 */
proto.growcloud.v1.domain.Uri.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.Uri;
  return proto.growcloud.v1.domain.Uri.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.Uri} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.Uri}
 */
proto.growcloud.v1.domain.Uri.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.Uri.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.Uri.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.Uri} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Uri.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.growcloud.v1.domain.Uri.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.domain.Uri} returns this
 */
proto.growcloud.v1.domain.Uri.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.growcloud.v1.domain);
