import React from 'react';

import Grid from '@mui/material/Grid';

import CriticalActionDialog from '@dc/dc/components/dialogs/CriticalActionDialog';

import DcxForm, {AUTOCOMPLETE, VARIANT} from '@dc/dcx/form/DcxForm';

import {connect} from 'react-redux';
import {getFormValues} from '@dc/dcx/shim/ReduxFormShims'
import StartYourGrowStepper, {validateStartYourGrowStepper} from "./StartYourGrowStepper";

import ContainerX from '@dc/dc/components/layout/ContainerX';

import CTAButton from '@dc/dc/components/buttons/CTAButton';

const styles = theme => ({
    root: {
        padding: 0,
    },
});

const makeLaunchButton = (buttonLabel, clickFunction) => {
    return (
        <CTAButton
            onClick={clickFunction}
            buttonText={buttonLabel}
        />
    )
}

class StartYourGrowDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isOpen: false}
    }

    toggleOpenState() {
        const {isOpen} = this.state;
        this.setState({isOpen: !isOpen});
    }

    doStartYourGrow() {
        const {dialogObject, handleClose, startYourGrowFormValues, dispatch, contentId, editorItems} = this.props;
        /*const editorItemData = editorItems[contentId];
        if(editorItemData.data.instances[dialogObject.urn]) {
            editorItemData.data.instances[dialogObject.urn].forEach(instance => {
                if(instance.urn == startYourGrowFormValues.artifact) {
                    let deltaValue = DeltaContext.builder().noDelta({ value_object: instance }).build();
                    const payloadObject = Object.assign(dialogObject, {editorObject: deltaValue})
                    dispatch(addItem(contentId, payloadObject))
                }
            });
        }*/
        //debugger;
        handleClose()
    }

    render() {
        const {dialogObject} = this.props;
        const {isOpen} = this.state;

        let dialogTitle = ``;
        let dialogContentText = `DEFINE YOUR GROW`;

        return (
            <>
                {makeLaunchButton("START YOUR GROW", this.toggleOpenState.bind(this))}
                <CriticalActionDialog
                    dialogTitle={dialogTitle}
                    dialogContentText={dialogContentText}
                    isOpen={isOpen}
                    onHandleClose={this.toggleOpenState.bind(this)}
                >
                        <DcxForm
                            form={'startYourGrow'}
                            commands={[
                                {type: VARIANT, value: 'outlined'},
                                {type: AUTOCOMPLETE, value: true},
                            ]}
                            onSubmit={() => {
                            }}
                            initialValues={{}}
                            validate={validateStartYourGrowStepper}
                        >
                                <StartYourGrowStepper
                                    createAction={this.doStartYourGrow.bind(this)}
                                    createContext={{}}
                                    dialogObject={dialogObject}
                                />
                        </DcxForm>
                </CriticalActionDialog>
            </>
        )
    }
}

export default connect(state => {
    const startYourGrowFormValues = getFormValues('startYourGrow')(state)
    return {startYourGrowFormValues}
})(StartYourGrowDialog)
