// source: growcloud/v1/service/StartYourGrow.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var growcloud_v1_domain_ProductionSystems_pb = require('../../../growcloud/v1/domain/ProductionSystems_pb.js');
goog.object.extend(proto, growcloud_v1_domain_ProductionSystems_pb);
var growcloud_v1_identity_Gcn_pb = require('../../../growcloud/v1/identity/Gcn_pb.js');
goog.object.extend(proto, growcloud_v1_identity_Gcn_pb);
var growcloud_v1_domain_Metadata_pb = require('../../../growcloud/v1/domain/Metadata_pb.js');
goog.object.extend(proto, growcloud_v1_domain_Metadata_pb);
goog.exportSymbol('proto.growcloud.v1.service.AssetRequest', null, global);
goog.exportSymbol('proto.growcloud.v1.service.ContainerRequest', null, global);
goog.exportSymbol('proto.growcloud.v1.service.EnclosureRequest', null, global);
goog.exportSymbol('proto.growcloud.v1.service.LaneRequest', null, global);
goog.exportSymbol('proto.growcloud.v1.service.SiteRequest', null, global);
goog.exportSymbol('proto.growcloud.v1.service.StartYourGrowRequest', null, global);
goog.exportSymbol('proto.growcloud.v1.service.UnitRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.service.UnitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.service.UnitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.service.UnitRequest.displayName = 'proto.growcloud.v1.service.UnitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.service.LaneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.service.LaneRequest.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.service.LaneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.service.LaneRequest.displayName = 'proto.growcloud.v1.service.LaneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.service.EnclosureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.service.EnclosureRequest.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.service.EnclosureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.service.EnclosureRequest.displayName = 'proto.growcloud.v1.service.EnclosureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.service.ContainerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.service.ContainerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.service.ContainerRequest.displayName = 'proto.growcloud.v1.service.ContainerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.service.SiteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.service.SiteRequest.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.service.SiteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.service.SiteRequest.displayName = 'proto.growcloud.v1.service.SiteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.service.AssetRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.service.AssetRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.service.AssetRequest.displayName = 'proto.growcloud.v1.service.AssetRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.service.StartYourGrowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.service.StartYourGrowRequest.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.service.StartYourGrowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.service.StartYourGrowRequest.displayName = 'proto.growcloud.v1.service.StartYourGrowRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.service.UnitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.service.UnitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.service.UnitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.UnitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.service.UnitRequest}
 */
proto.growcloud.v1.service.UnitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.service.UnitRequest;
  return proto.growcloud.v1.service.UnitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.service.UnitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.service.UnitRequest}
 */
proto.growcloud.v1.service.UnitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.service.UnitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.service.UnitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.service.UnitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.UnitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.service.LaneRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.service.LaneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.service.LaneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.service.LaneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.LaneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    unitrequestList: jspb.Message.toObjectList(msg.getUnitrequestList(),
    proto.growcloud.v1.service.UnitRequest.toObject, includeInstance),
    createobject: (f = msg.getCreateobject()) && growcloud_v1_domain_Metadata_pb.CreateObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.service.LaneRequest}
 */
proto.growcloud.v1.service.LaneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.service.LaneRequest;
  return proto.growcloud.v1.service.LaneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.service.LaneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.service.LaneRequest}
 */
proto.growcloud.v1.service.LaneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.growcloud.v1.service.UnitRequest;
      reader.readMessage(value,proto.growcloud.v1.service.UnitRequest.deserializeBinaryFromReader);
      msg.addUnitrequest(value);
      break;
    case 2:
      var value = new growcloud_v1_domain_Metadata_pb.CreateObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.CreateObject.deserializeBinaryFromReader);
      msg.setCreateobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.service.LaneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.service.LaneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.service.LaneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.LaneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUnitrequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.growcloud.v1.service.UnitRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreateobject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_domain_Metadata_pb.CreateObject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UnitRequest unitRequest = 1;
 * @return {!Array<!proto.growcloud.v1.service.UnitRequest>}
 */
proto.growcloud.v1.service.LaneRequest.prototype.getUnitrequestList = function() {
  return /** @type{!Array<!proto.growcloud.v1.service.UnitRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.service.UnitRequest, 1));
};


/**
 * @param {!Array<!proto.growcloud.v1.service.UnitRequest>} value
 * @return {!proto.growcloud.v1.service.LaneRequest} returns this
*/
proto.growcloud.v1.service.LaneRequest.prototype.setUnitrequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.growcloud.v1.service.UnitRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.service.UnitRequest}
 */
proto.growcloud.v1.service.LaneRequest.prototype.addUnitrequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.growcloud.v1.service.UnitRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.service.LaneRequest} returns this
 */
proto.growcloud.v1.service.LaneRequest.prototype.clearUnitrequestList = function() {
  return this.setUnitrequestList([]);
};


/**
 * optional growcloud.v1.domain.CreateObject createObject = 2;
 * @return {?proto.growcloud.v1.domain.CreateObject}
 */
proto.growcloud.v1.service.LaneRequest.prototype.getCreateobject = function() {
  return /** @type{?proto.growcloud.v1.domain.CreateObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.CreateObject, 2));
};


/**
 * @param {?proto.growcloud.v1.domain.CreateObject|undefined} value
 * @return {!proto.growcloud.v1.service.LaneRequest} returns this
*/
proto.growcloud.v1.service.LaneRequest.prototype.setCreateobject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.service.LaneRequest} returns this
 */
proto.growcloud.v1.service.LaneRequest.prototype.clearCreateobject = function() {
  return this.setCreateobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.service.LaneRequest.prototype.hasCreateobject = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.service.EnclosureRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.service.EnclosureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.service.EnclosureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.service.EnclosureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.EnclosureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lanerequestList: jspb.Message.toObjectList(msg.getLanerequestList(),
    proto.growcloud.v1.service.LaneRequest.toObject, includeInstance),
    createobject: (f = msg.getCreateobject()) && growcloud_v1_domain_Metadata_pb.CreateObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.service.EnclosureRequest}
 */
proto.growcloud.v1.service.EnclosureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.service.EnclosureRequest;
  return proto.growcloud.v1.service.EnclosureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.service.EnclosureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.service.EnclosureRequest}
 */
proto.growcloud.v1.service.EnclosureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.growcloud.v1.service.LaneRequest;
      reader.readMessage(value,proto.growcloud.v1.service.LaneRequest.deserializeBinaryFromReader);
      msg.addLanerequest(value);
      break;
    case 2:
      var value = new growcloud_v1_domain_Metadata_pb.CreateObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.CreateObject.deserializeBinaryFromReader);
      msg.setCreateobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.service.EnclosureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.service.EnclosureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.service.EnclosureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.EnclosureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanerequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.growcloud.v1.service.LaneRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreateobject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_domain_Metadata_pb.CreateObject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LaneRequest laneRequest = 1;
 * @return {!Array<!proto.growcloud.v1.service.LaneRequest>}
 */
proto.growcloud.v1.service.EnclosureRequest.prototype.getLanerequestList = function() {
  return /** @type{!Array<!proto.growcloud.v1.service.LaneRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.service.LaneRequest, 1));
};


/**
 * @param {!Array<!proto.growcloud.v1.service.LaneRequest>} value
 * @return {!proto.growcloud.v1.service.EnclosureRequest} returns this
*/
proto.growcloud.v1.service.EnclosureRequest.prototype.setLanerequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.growcloud.v1.service.LaneRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.service.LaneRequest}
 */
proto.growcloud.v1.service.EnclosureRequest.prototype.addLanerequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.growcloud.v1.service.LaneRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.service.EnclosureRequest} returns this
 */
proto.growcloud.v1.service.EnclosureRequest.prototype.clearLanerequestList = function() {
  return this.setLanerequestList([]);
};


/**
 * optional growcloud.v1.domain.CreateObject createObject = 2;
 * @return {?proto.growcloud.v1.domain.CreateObject}
 */
proto.growcloud.v1.service.EnclosureRequest.prototype.getCreateobject = function() {
  return /** @type{?proto.growcloud.v1.domain.CreateObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.CreateObject, 2));
};


/**
 * @param {?proto.growcloud.v1.domain.CreateObject|undefined} value
 * @return {!proto.growcloud.v1.service.EnclosureRequest} returns this
*/
proto.growcloud.v1.service.EnclosureRequest.prototype.setCreateobject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.service.EnclosureRequest} returns this
 */
proto.growcloud.v1.service.EnclosureRequest.prototype.clearCreateobject = function() {
  return this.setCreateobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.service.EnclosureRequest.prototype.hasCreateobject = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.service.ContainerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.service.ContainerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.service.ContainerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.ContainerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createobject: (f = msg.getCreateobject()) && growcloud_v1_domain_Metadata_pb.CreateObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.service.ContainerRequest}
 */
proto.growcloud.v1.service.ContainerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.service.ContainerRequest;
  return proto.growcloud.v1.service.ContainerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.service.ContainerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.service.ContainerRequest}
 */
proto.growcloud.v1.service.ContainerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new growcloud_v1_domain_Metadata_pb.CreateObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.CreateObject.deserializeBinaryFromReader);
      msg.setCreateobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.service.ContainerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.service.ContainerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.service.ContainerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.ContainerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateobject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_domain_Metadata_pb.CreateObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional growcloud.v1.domain.CreateObject createObject = 2;
 * @return {?proto.growcloud.v1.domain.CreateObject}
 */
proto.growcloud.v1.service.ContainerRequest.prototype.getCreateobject = function() {
  return /** @type{?proto.growcloud.v1.domain.CreateObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.CreateObject, 2));
};


/**
 * @param {?proto.growcloud.v1.domain.CreateObject|undefined} value
 * @return {!proto.growcloud.v1.service.ContainerRequest} returns this
*/
proto.growcloud.v1.service.ContainerRequest.prototype.setCreateobject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.service.ContainerRequest} returns this
 */
proto.growcloud.v1.service.ContainerRequest.prototype.clearCreateobject = function() {
  return this.setCreateobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.service.ContainerRequest.prototype.hasCreateobject = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.service.SiteRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.service.SiteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.service.SiteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.service.SiteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.SiteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    enclosurerequestList: jspb.Message.toObjectList(msg.getEnclosurerequestList(),
    proto.growcloud.v1.service.EnclosureRequest.toObject, includeInstance),
    createobject: (f = msg.getCreateobject()) && growcloud_v1_domain_Metadata_pb.CreateObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.service.SiteRequest}
 */
proto.growcloud.v1.service.SiteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.service.SiteRequest;
  return proto.growcloud.v1.service.SiteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.service.SiteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.service.SiteRequest}
 */
proto.growcloud.v1.service.SiteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.growcloud.v1.service.EnclosureRequest;
      reader.readMessage(value,proto.growcloud.v1.service.EnclosureRequest.deserializeBinaryFromReader);
      msg.addEnclosurerequest(value);
      break;
    case 2:
      var value = new growcloud_v1_domain_Metadata_pb.CreateObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.CreateObject.deserializeBinaryFromReader);
      msg.setCreateobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.service.SiteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.service.SiteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.service.SiteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.SiteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEnclosurerequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.growcloud.v1.service.EnclosureRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreateobject();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_domain_Metadata_pb.CreateObject.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EnclosureRequest enclosureRequest = 1;
 * @return {!Array<!proto.growcloud.v1.service.EnclosureRequest>}
 */
proto.growcloud.v1.service.SiteRequest.prototype.getEnclosurerequestList = function() {
  return /** @type{!Array<!proto.growcloud.v1.service.EnclosureRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.service.EnclosureRequest, 1));
};


/**
 * @param {!Array<!proto.growcloud.v1.service.EnclosureRequest>} value
 * @return {!proto.growcloud.v1.service.SiteRequest} returns this
*/
proto.growcloud.v1.service.SiteRequest.prototype.setEnclosurerequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.growcloud.v1.service.EnclosureRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.service.EnclosureRequest}
 */
proto.growcloud.v1.service.SiteRequest.prototype.addEnclosurerequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.growcloud.v1.service.EnclosureRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.service.SiteRequest} returns this
 */
proto.growcloud.v1.service.SiteRequest.prototype.clearEnclosurerequestList = function() {
  return this.setEnclosurerequestList([]);
};


/**
 * optional growcloud.v1.domain.CreateObject createObject = 2;
 * @return {?proto.growcloud.v1.domain.CreateObject}
 */
proto.growcloud.v1.service.SiteRequest.prototype.getCreateobject = function() {
  return /** @type{?proto.growcloud.v1.domain.CreateObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.CreateObject, 2));
};


/**
 * @param {?proto.growcloud.v1.domain.CreateObject|undefined} value
 * @return {!proto.growcloud.v1.service.SiteRequest} returns this
*/
proto.growcloud.v1.service.SiteRequest.prototype.setCreateobject = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.service.SiteRequest} returns this
 */
proto.growcloud.v1.service.SiteRequest.prototype.clearCreateobject = function() {
  return this.setCreateobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.service.SiteRequest.prototype.hasCreateobject = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.service.AssetRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.service.AssetRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.service.AssetRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.AssetRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createobject: (f = msg.getCreateobject()) && growcloud_v1_domain_Metadata_pb.CreateObject.toObject(includeInstance, f),
    onenclosure: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.service.AssetRequest}
 */
proto.growcloud.v1.service.AssetRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.service.AssetRequest;
  return proto.growcloud.v1.service.AssetRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.service.AssetRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.service.AssetRequest}
 */
proto.growcloud.v1.service.AssetRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.CreateObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.CreateObject.deserializeBinaryFromReader);
      msg.setCreateobject(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnenclosure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.service.AssetRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.service.AssetRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.service.AssetRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.AssetRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreateobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.CreateObject.serializeBinaryToWriter
    );
  }
  f = message.getOnenclosure();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional growcloud.v1.domain.CreateObject createObject = 1;
 * @return {?proto.growcloud.v1.domain.CreateObject}
 */
proto.growcloud.v1.service.AssetRequest.prototype.getCreateobject = function() {
  return /** @type{?proto.growcloud.v1.domain.CreateObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.CreateObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.CreateObject|undefined} value
 * @return {!proto.growcloud.v1.service.AssetRequest} returns this
*/
proto.growcloud.v1.service.AssetRequest.prototype.setCreateobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.service.AssetRequest} returns this
 */
proto.growcloud.v1.service.AssetRequest.prototype.clearCreateobject = function() {
  return this.setCreateobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.service.AssetRequest.prototype.hasCreateobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string onEnclosure = 2;
 * @return {string}
 */
proto.growcloud.v1.service.AssetRequest.prototype.getOnenclosure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.service.AssetRequest} returns this
 */
proto.growcloud.v1.service.AssetRequest.prototype.setOnenclosure = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.service.StartYourGrowRequest.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.service.StartYourGrowRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.service.StartYourGrowRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.StartYourGrowRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    siterequestList: jspb.Message.toObjectList(msg.getSiterequestList(),
    proto.growcloud.v1.service.SiteRequest.toObject, includeInstance),
    assetrequestList: jspb.Message.toObjectList(msg.getAssetrequestList(),
    proto.growcloud.v1.service.AssetRequest.toObject, includeInstance),
    containerrequestList: jspb.Message.toObjectList(msg.getContainerrequestList(),
    proto.growcloud.v1.service.ContainerRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.service.StartYourGrowRequest}
 */
proto.growcloud.v1.service.StartYourGrowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.service.StartYourGrowRequest;
  return proto.growcloud.v1.service.StartYourGrowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.service.StartYourGrowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.service.StartYourGrowRequest}
 */
proto.growcloud.v1.service.StartYourGrowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.growcloud.v1.service.SiteRequest;
      reader.readMessage(value,proto.growcloud.v1.service.SiteRequest.deserializeBinaryFromReader);
      msg.addSiterequest(value);
      break;
    case 2:
      var value = new proto.growcloud.v1.service.AssetRequest;
      reader.readMessage(value,proto.growcloud.v1.service.AssetRequest.deserializeBinaryFromReader);
      msg.addAssetrequest(value);
      break;
    case 3:
      var value = new proto.growcloud.v1.service.ContainerRequest;
      reader.readMessage(value,proto.growcloud.v1.service.ContainerRequest.deserializeBinaryFromReader);
      msg.addContainerrequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.service.StartYourGrowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.service.StartYourGrowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.service.StartYourGrowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSiterequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.growcloud.v1.service.SiteRequest.serializeBinaryToWriter
    );
  }
  f = message.getAssetrequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.growcloud.v1.service.AssetRequest.serializeBinaryToWriter
    );
  }
  f = message.getContainerrequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.growcloud.v1.service.ContainerRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SiteRequest siteRequest = 1;
 * @return {!Array<!proto.growcloud.v1.service.SiteRequest>}
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.getSiterequestList = function() {
  return /** @type{!Array<!proto.growcloud.v1.service.SiteRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.service.SiteRequest, 1));
};


/**
 * @param {!Array<!proto.growcloud.v1.service.SiteRequest>} value
 * @return {!proto.growcloud.v1.service.StartYourGrowRequest} returns this
*/
proto.growcloud.v1.service.StartYourGrowRequest.prototype.setSiterequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.growcloud.v1.service.SiteRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.service.SiteRequest}
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.addSiterequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.growcloud.v1.service.SiteRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.service.StartYourGrowRequest} returns this
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.clearSiterequestList = function() {
  return this.setSiterequestList([]);
};


/**
 * repeated AssetRequest assetRequest = 2;
 * @return {!Array<!proto.growcloud.v1.service.AssetRequest>}
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.getAssetrequestList = function() {
  return /** @type{!Array<!proto.growcloud.v1.service.AssetRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.service.AssetRequest, 2));
};


/**
 * @param {!Array<!proto.growcloud.v1.service.AssetRequest>} value
 * @return {!proto.growcloud.v1.service.StartYourGrowRequest} returns this
*/
proto.growcloud.v1.service.StartYourGrowRequest.prototype.setAssetrequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.growcloud.v1.service.AssetRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.service.AssetRequest}
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.addAssetrequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.growcloud.v1.service.AssetRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.service.StartYourGrowRequest} returns this
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.clearAssetrequestList = function() {
  return this.setAssetrequestList([]);
};


/**
 * repeated ContainerRequest containerRequest = 3;
 * @return {!Array<!proto.growcloud.v1.service.ContainerRequest>}
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.getContainerrequestList = function() {
  return /** @type{!Array<!proto.growcloud.v1.service.ContainerRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.service.ContainerRequest, 3));
};


/**
 * @param {!Array<!proto.growcloud.v1.service.ContainerRequest>} value
 * @return {!proto.growcloud.v1.service.StartYourGrowRequest} returns this
*/
proto.growcloud.v1.service.StartYourGrowRequest.prototype.setContainerrequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.growcloud.v1.service.ContainerRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.service.ContainerRequest}
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.addContainerrequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.growcloud.v1.service.ContainerRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.service.StartYourGrowRequest} returns this
 */
proto.growcloud.v1.service.StartYourGrowRequest.prototype.clearContainerrequestList = function() {
  return this.setContainerrequestList([]);
};


goog.object.extend(exports, proto.growcloud.v1.service);
