/**
 * @fileoverview gRPC-Web generated client stub for growcloud.v1.service
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.9
// source: growcloud/v1/service/AlarmsV1Service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var growcloud_v1_domain_ProductionSystems_pb = require('../../../growcloud/v1/domain/ProductionSystems_pb.js')

var growcloud_v1_identity_Gcn_pb = require('../../../growcloud/v1/identity/Gcn_pb.js')
const proto = {};
proto.growcloud = {};
proto.growcloud.v1 = {};
proto.growcloud.v1.service = require('./AlarmsV1Service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.growcloud.v1.service.AlarmsV1ServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.growcloud.v1.service.AlarmsV1ServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


module.exports = proto.growcloud.v1.service;

