import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import Avatar from '@mui/material/Avatar';
import Zoom from '@mui/material/Zoom';

const styles = theme => ({
    avatarButton: {
        backgroundColor: 'transparent',
//		backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[200],
        border: `1px solid ${theme.palette.text.secondary}`,
        color: theme.palette.text.secondary,
        '&:hover': {
            border: `1px solid ${theme.palette.text.primary}`,
            color: theme.palette.text.primary,
//			backgroundColor: theme.palette.type === 'light' ? theme.palette.common.white : theme.palette.common.black,
        },
    },
//  root: {
//    color: theme.palette.background.default,
//    backgroundColor:
//      theme.palette.type === 'light' ? theme.palette.grey[500] : theme.palette.grey[700],
//    },
//    '&:hover': {
//      color: theme.palette.background.paper,
//      backgroundColor:
//        theme.palette.type === 'light' ? theme.palette.grey[400] : theme.palette.grey[600],
//      },
//    },
});

const makeInitials = (str) => {
    let initials = []
    let splitStr = str.split(' ');
    for (let word of splitStr) {
        const letter = word.charAt(0);
        const capitalLetter = letter.toUpperCase();
        initials.push(capitalLetter);
    }
    return initials;
}

const InitialsThumb = ({
       classes,
       theme,
       buttonActive,
       user,
       customClass
   }) => {

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    }

    //TODO there seems to be momentary condition where this can be null
    // this is not a great solution but is preferred to the error upon
    // splitting user.label
    if (!user || !user.label) {
        return (null);
    }

    return (
        <Zoom
            in={buttonActive}
            timeout={transitionDuration}
            style={{
                transitionDelay: buttonActive ? transitionDuration.exit : 0,
            }}
            unmountOnExit
        >
            <Avatar
                alt={user.label}
                className={classnames(classes.avatarButton, {[customClass]: customClass})}
            >
                {makeInitials(user.label)}
            </Avatar>
        </Zoom>
    )
}

InitialsThumb.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

export default withStyles(styles, {withTheme: true})(InitialsThumb);