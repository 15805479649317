import React from 'react'
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import classnames from 'classnames';
import {dialogStyleSheet} from '../../style/BaseStyles';
import { withStyles } from '@mui/styles';
import DefaultDialogTitle from "./DialogCommons";

class ResponsiveDialog extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        }
    }

    handleClickOpen = () => {
        this.setState({isOpen: true});
    }

    handleClose = (e) => {
        this.setState({isOpen: false});
        e.preventDefault()
        e.stopPropagation()
    }


    render() {
        const {
            classes,
            backgroundLight,
            defaultPaperBackground,
            fixedScrollHeight,
            underlineTitle,
            isOpen,
            onHandleClose,
            fullScreen,
            fullWidth,
            maxWidth,
            hasConfirmDialog,
            dialogContentText,
            dialogTitle,
            dialogButton,
            dialogContext,
            children,
            defaultButton,
            actionsBar,
            ...rest
        } = this.props;

        let paperClass = classnames(
            classes.paper,
            classes.setMinHeight,
            {[classes.defaultPaperBackground]: defaultPaperBackground}
        )

        return (
            <React.Fragment>
                {dialogButton && dialogButton}
                <Dialog
                    classes={{paper: paperClass}}
                    open={isOpen}
                    onClose={this.handleClose.bind(this)}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth={maxWidth}
                    fullWidth={fullWidth}
                    fullScreen={fullScreen}
                    disableBackdropClick={hasConfirmDialog ? true : false}
                    disableEscapeKeyDown={hasConfirmDialog ? true : false}
                    TransitionProps={{unmountOnExit: true}}
                    {...dialogContext}
                    {...rest}
                    PaperProps={{
                        style: {height: (fixedScrollHeight && window.innerWidth >= 960) && 'calc(100% - 96px)'},
                        classes: {root: classes.rootTitle},
                    }}
                    disableScrollLock={true}
                >
                    <DefaultDialogTitle
                        onHandleClose={onHandleClose}
                        dialogTitle={dialogTitle}
                        dialogContext={dialogContext}
                        underlineTitle
                    />
                    <DialogContent
                        classes={{
                            root: classes.rootContentBase
                        }
                        }
                        className={underlineTitle && classes.rootContentText}
                    >
                        {children}
                    </DialogContent>
                    {actionsBar && (
                        <DialogActions>{actionsBar}</DialogActions>
                    )}
                </Dialog>
            </React.Fragment>
        )
    }
}

// ResponsiveDialog.defaultProps = {
//     closeicon: <CloseIcon/>,
// }

ResponsiveDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    dialogTitle: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    hasConfirmDialog: PropTypes.bool,
};

export default withStyles(dialogStyleSheet)(ResponsiveDialog)