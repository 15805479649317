import React from 'react';
import {FormattedMessage} from 'react-intl';

import {validationErrorMessages} from '@dc/dc/i18n/ValidationErrorMessages';

export const validateFormValues = (values, keyValueProcessor) => {
    const errors = {}
    Object.keys(values).forEach(key => {
        let errorMessage = null;
        let notNullRequiredValidationMessage = hasNotNullRequiredValidationMessage(values[key])
        if (notNullRequiredValidationMessage) {
            errorMessage = notNullRequiredValidationMessage;
        } else {
            const value = values[key];
            const isString = typeof value === 'string';
            if (isString) {
                const stringValue = value.trim();
                let charactersRequiredValidationMessage = hasCharactersRequiredValidationMessage(stringValue)
                if (charactersRequiredValidationMessage) {
                    errorMessage = charactersRequiredValidationMessage;
                } else {
                    errorMessage = keyValueProcessor(key, stringValue)
                }
            } else {
                errorMessage = keyValueProcessor(key, value)
            }
        }
        if (errorMessage) {
            errors[key] = <FormattedMessage {...errorMessage}/>
        }
    })
    if (0 < Object.keys(errors).length) {
        console.log("VALIDATION ERRORS ", errors)
    }
    return errors
}

export const hasEmailAddressValidationError = (value) => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(value)) {
        return validationErrorMessages.invalidEmailAddressMessage;
    }
    return false;
}

export const hasInternationalPhoneNumberValidationError = (value) => {
    if (!/^[0-9]+$/i.test(value)) {
        return validationErrorMessages.invalidInternationalPhoneNumberMessage;
    }
    return false;
}

export const hasPasswordValidationError = (value) => {
    if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/i.test(value)) {
        return validationErrorMessages.invalidPasswordMessage;
    }
    return false;
}

export const hasSlugValidationError = (value) => {
    if (!/^[A-Za-z0-9._-]{6,12}$/i.test(value)) {
        return validationErrorMessages.invalidSlugMessage;
    }
    return false;
}

const hasNotNullRequiredValidationMessage = (value) => {
    if (typeof (value) === 'undefined') {
        return validationErrorMessages.requiredMessage;
    }
    if (typeof value === 'number' || typeof value === 'boolean') {
        return false;
    }
    if (!value) {
        return validationErrorMessages.requiredMessage;
    }
    return false;
}

const hasCharactersRequiredValidationMessage = (value) => {
    if ('' == value) {
        return validationErrorMessages.requiredMessage;
    }
    return false;
}