const dotProp = require('dot-prop-immutable');

import {
    ADD_BATCH_NOTIFICATION,
    ADD_NOTIFICATION,
    ADD_PENDING_ACTION_BATCH_NOTIFICATION,
    CLEAR_NOTIFICATIONS,
    RESET_CURRENT_VIEW,
    UPDATE_PENDING_ACTION_NOTIFICATION,
} from '../actions/notifications-actions'


const initialNotifications = () => {
    return {
        currentView: [],
        timeWindowView: [],
        pendingActionsView: [],
    }
}


const initialState = () => {
    return {
        notifications: initialNotifications(),
        frozenItemsMap: {},
    }
}

const mergeNotification = (state, action) => {
    let notifications = {...state.notifications};

    let updatedNotifications = notifications;

    if (action.notificationObject) {
        let notificationsView = [action.notificationObject];
        updatedNotifications = dotProp.merge(updatedNotifications, ['currentView'], notificationsView);
        updatedNotifications = dotProp.merge(updatedNotifications, ['timeWindowView'], notificationsView);
    }
    return updatedNotifications;
}

const mergeBatchNotification = (state, action) => {
    let notifications = {...state.notifications};
    let updatedNotifications = notifications;
    let notificationsView = [];
    action.notificationObjects.forEach(notificationObject => {
        notificationsView.push(notificationObject);
    })
    updatedNotifications = dotProp.merge(updatedNotifications, ['currentView'], notificationsView);
    updatedNotifications = dotProp.merge(updatedNotifications, ['timeWindowView'], notificationsView);
    return updatedNotifications;
}


const mergeMatchPendingActionsNotifications = (state, action) => {
    let notifications = {...state.notifications};
    let frozenItemsMap = {...state.frozenItemsMap};

    let updatedNotifications = notifications;
    let notificationsView = [];

    action.notificationObjects.forEach(notificationObject => {
        notificationsView.push(notificationObject);
        if (5 == notificationObject.actionCase) {
            frozenItemsMap[notificationObject.lifecycleEngineInstance] = true;
        }
    })
    updatedNotifications = dotProp.merge(updatedNotifications, ['pendingActionsView'], notificationsView);
    return {notifications: updatedNotifications, frozenItemsMap: frozenItemsMap}
}

const updatePendingActionNotification = (state, action) => {
    let notifications = {...state.notifications};

    let updatedNotifications = notifications;

    /*let notificationsView = [];
     action.notificationObjects.forEach(notificationObject => {
        notificationsView.push(notificationObject);
    })
    updatedNotifications = dotProp.merge(updatedNotifications, ['currentView'], notificationsView);
    updatedNotifications = dotProp.merge(updatedNotifications, ['timeWindowView'], notificationsView);
    */
    return updatedNotifications;
}


const resetCurrentView = (state) => {
    let notifications = {...state.notifications};
    let updatedNotifications = dotProp.set(notifications, ['currentView'], []);
    return updatedNotifications;
}

const reducers = (state = initialState(), action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return {
                ...state,
                notifications: mergeNotification(state, action),
            }
        case ADD_BATCH_NOTIFICATION:
            return {
                ...state,
                notifications: mergeBatchNotification(state, action),
            }
        case RESET_CURRENT_VIEW:
            return {
                ...state,
                notifications: resetCurrentView(state),
            }
        case ADD_PENDING_ACTION_BATCH_NOTIFICATION:
            let updatedNotifications = mergeMatchPendingActionsNotifications(state, action);
            return {
                ...state,
                ...updatedNotifications,
            }
        case UPDATE_PENDING_ACTION_NOTIFICATION:
            return {
                ...state,
                notifications: updatePendingActionNotification(state, action),
            }
        case CLEAR_NOTIFICATIONS:
            return initialState();
        default:
            return state;
    }
    return state;
}

export default reducers;
