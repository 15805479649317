export const TOGGLE_MENU_DRAWER = '$$TOGGLE_MENU_DRAWER';
export const CLOSE_MENU_DRAWER = '$$CLOSE_MENU_DRAWER';
export const CONFIGURE_APPBAR = '$$CONFIGURE_APPBAR';
export const UPDATE_MASTHEAD_CONFIG = '$$UPDATE_MASTHEAD_CONFIG';
export const RESET_MASTHEAD = '$$RESET_MASTHEAD';
export const RENDER_SIGN_UP_STEP = '$$RENDER_SIGN_UP_STEP';
export const RENDER_LICENSE_REQUEST_STEP = '$$RENDER_LICENSE_REQUEST_STEP';
export const TOGGLE_SIGNUP_ERROR = '$$TOGGLE_SIGNUP_ERROR';
export const TOGGLE_LICENSE_REQUEST_ERROR = '$$TOGGLE_LICENSE_REQUEST_ERROR';
export const SET_STATE_ON_FLOW = '$$SET_STATE_ON_FLOW';
export const RENDER_NEXT_SIGN_UP_STEP = '$$RENDER_NEXT_SIGN_UP_STEP';
export const CONFIGURE_MENUBAR = '$$CONFIGURE_MENUBAR';
export const TOGGLE_MENUBAR_DRAWER = '$$TOGGLE_MENUBAR_DRAWER';
export const CLOSE_MENUBAR_DRAWER = '$$CLOSE_MENUBAR_DRAWER';
export const CONFIGURE_SECTION_MENUBAR_DRAWER = '$$CONFIGURE_SECTION_MENUBAR_DRAWER';
export const TOGGLE_GLOBAL_SEARCH_ACTIVE = '$$TOGGLE_GLOBAL_SEARCH_ACTIVE';
export const SET_GLOBAL_SEARCH_NOT_ACTIVE = '$$SET_GLOBAL_SEARCH_NOT_ACTIVE';

export const GET_MESSAGES = '$$GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = '$$GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAIL = '$$GET_MESSAGES_FAIL';

export const SET_MENU_BAR_DRAWER_CONFIGURATION = '$$SET_MENU_BAR_DRAWER_CONFIGURATION';
export const RESET_MENU_BAR_DRAWER_CONFIGURATION = '$$RESET_MENU_BAR_DRAWER_CONFIGURATION';

export const getMessages = () => {
    return {type: GET_MESSAGES}
}

export const SET_SPACE = '$$SET_SPACE';

export const setSpace = (space) => {
    return {type: SET_SPACE, space}
}

export const GET_USER_INTERFACE_CONFIGURATION = '$$GET_USER_INTERFACE_CONFIGURATION';
export const GET_USER_INTERFACE_CONFIGURATION_SUCCESS = '$$GET_USER_INTERFACE_CONFIGURATION_SUCCESS';
export const GET_USER_INTERFACE_CONFIGURATION_FAIL = '$$GET_USER_INTERFACE_CONFIGURATION_FAIL';

export const getUserInterfaceConfiguration = (configurationOptions) => {
    return {type: GET_USER_INTERFACE_CONFIGURATION, configurationOptions}
}

export function publicAppbar(publicRoutes, publicMenuDrawerItems=[], globalSearchData={}, menuBarDrawerItems=[]) {
    return {
        type: CONFIGURE_APPBAR,
        publicRoutes,
        publicMenuDrawerItems,
        globalSearchData,
        menuBarDrawerItems
    }
}

export function protectedAppbar(protectedRoutes, menuBarDrawerItems) {
    return {
        type: CONFIGURE_APPBAR,
        protectedRoutes,
        menuBarDrawerItems
    }
}

export function sectionMenuBarDrawerItems(menuBarDrawerItems) {
    return {
        type: CONFIGURE_SECTION_MENUBAR_DRAWER,
        menuBarDrawerItems
    }
}

export function configureMenuBarActive(state) {
    return {
        type: CONFIGURE_MENUBAR,
        active: state
    }
}

export function renderSignUpStep(activeStep) {
    return {
        type: RENDER_SIGN_UP_STEP,
        activeStep: activeStep,
    }
}

export function renderLicenseRequestStep(activeStep) {
    return {
        type: RENDER_LICENSE_REQUEST_STEP,
        activeStep: activeStep,
    }
}

export function toggleSignUpError(active) {
    return {
        type: TOGGLE_SIGNUP_ERROR,
        error: active,
    }
}

export function toggleLicenseRequestError(active) {
    return {
        type: TOGGLE_LICENSE_REQUEST_ERROR,
        error: active,
    }
}

export function toggleMenuDrawer() {
    return {
        type: TOGGLE_MENU_DRAWER
    }
}

export function closeMenuDrawer() {
    return {
        type: CLOSE_MENU_DRAWER
    }
}

export function closeMenuBarDrawer() {
    return {
        type: CLOSE_MENUBAR_DRAWER
    }
}

export function toggleMenuBarDrawer() {
    return {
        type: TOGGLE_MENUBAR_DRAWER
    }
}

export function toggleGlobalSearchActive() {
    return {
        type: TOGGLE_GLOBAL_SEARCH_ACTIVE,
    }
}

export function setGlobalSearchNotActive() {
    return {
        type: SET_GLOBAL_SEARCH_NOT_ACTIVE,
    }
}

export function updateMastheadConfig(component, text, to) {
    return {
        type: UPDATE_MASTHEAD_CONFIG,
        component: component,
        text: text,
        to: to
    }
}

export function resetMasthead(config, text, to) {
    return {
        type: RESET_MASTHEAD,
        component: component,
        text: text,
        to: to
    }
}

export const setMenuBarDrawerConfiguration = (menuBarDrawerConfiguration) => {
    return {type: SET_MENU_BAR_DRAWER_CONFIGURATION, menuBarDrawerConfiguration}
}

export const resetMenuBarDrawerConfiguration = () => {
    return {type: RESET_MENU_BAR_DRAWER_CONFIGURATION}
}

export function setStateOnFlow(flowDefinitionId, processStateId, recordCaptureId) {
    return {type: SET_STATE_ON_FLOW, flowDefinitionId, processStateId, recordCaptureId,}
}

export const TOGGLE_OPEN_STATE = '$$TOGGLE_OPEN_STATE';

export const toggleOpenState = (hash) => {
    return {type: TOGGLE_OPEN_STATE, hash}
}

export const CLEAR_OPEN_STATE = '$$CLEAR_OPEN_STATE';

export const clearOpenState = (hashPrefix) => {
    return {type: CLEAR_OPEN_STATE, hashPrefix}
}

export const SET_OPEN_STATE = '$$SET_OPEN_STATE';

export const setOpenState = (hashPrefix) => {
    return {type: SET_OPEN_STATE, hashPrefix}
}

export const APPLICATION_LOADED_EVENT = '$$APPLICATION_LOADED_EVENT';

export const applicationLoadedEvent = () => {
    return {type: APPLICATION_LOADED_EVENT}
}

export const ROUTE_CHANGED_EVENT = '$$ROUTE_CHANGED_EVENT';

export const routeChangedEvent = (from, to) => {
    return {type: ROUTE_CHANGED_EVENT, from, to}
}

export const TOGGLE_REQUIRES_APPBAR = '$$TOGGLE_REQUIRES_APPBAR';

export const toggleRequiresAppbar = () => {
    return {type: TOGGLE_REQUIRES_APPBAR,}
}