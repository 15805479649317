import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import NoResults from '@dc/dcx/app/common/NoResults';
import ContainerX from '@dc/dc/components/layout/ContainerX';

import DynamicGrid from 'gc-ui-components/ui/grid/DynamicGrid';
import {setCurrentGridConfiguration} from 'gc-ui-components/ui/mw/grid-service/actions/grid-service-actions';

import {
    currentReadingsGridCellComponentProvider,
    currentReadingsGridExpandedRowComponentProvider,
    makeEventCountersGridConfiguration
} from './GrowcloudGridWrapperHelper';

import {
    getEventCountersGridData,
} from './mw/actions/growcloud-grid-services-actions';

const GRID_CHANNEL = 'event-counters';

const styleSheet = theme => ({ });

class EventCountersGrid extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        const{dispatch,userContext} = this.props;
        let gridConfiguration = makeEventCountersGridConfiguration()
        dispatch(setCurrentGridConfiguration(GRID_CHANNEL, GRID_CHANNEL, gridConfiguration));
        dispatch(getEventCountersGridData(userContext, GRID_CHANNEL, GRID_CHANNEL, gridConfiguration, []))
    }


    renderGridLayout() {

        const {
            readings,
            gridConfigurations,
            dispatch,
            theme,
            withStylesDynamicGrid: StyledDynamicGrid
        } = this.props;

        const currentDynamicGrid = gridConfigurations[GRID_CHANNEL];

        if(!currentDynamicGrid){
            return (<>Loading</>)
        }

        return (
             <ContainerX AsContainer withLargeBottomMargin>
                    <DynamicGrid
                        hideToolbar
                        pageContext={{page: readings}}
                        currentDynamicGrid={currentDynamicGrid}
                        cellComponentProvider={currentReadingsGridCellComponentProvider}
                        expandedRowComponentProvider={currentReadingsGridExpandedRowComponentProvider}
                        gridContext={{dispatch}}
                        elideTableRowDetail={true}
                    />
                {/*</StyledDynamicGrid>*/}
             </ContainerX>
        )
    }

    render() {
        const {readings} = this.props;
        return this.renderGridLayout()
    }
}

export default connect(state => {
    return {
        gridConfigurations: state.dynamicGrid.gridConfigurations,
        contextConfigurations: state.application.contextConfigurations
    }
})(
    withStyles(styleSheet, {withTheme: true})(EventCountersGrid)
)