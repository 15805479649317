import { defineMessages } from 'react-intl';

import { defaultI18nMessage } from '@dc/dc/i18n/MessageUtils';

export const gcAuthenticationMessage = (name, message) => {
    return defaultI18nMessage('gcAuthentication', name, message);
}

export const gcAuthenticationMessages = defineMessages({
    signInContainerPageTitle: gcAuthenticationMessage('signInContainerPageTitle', 'GROWCLOUD AUTHENTICATION'),
    signInContainerSubHeader: gcAuthenticationMessage('signInContainerSubHeader', ''),
    signInContainerForgotPasswordLinkLabel: gcAuthenticationMessage('signInContainerForgotPasswordLinkLabel', 'Forgot password?'),
    signInContainerForgotPasswordDialogTitle: gcAuthenticationMessage('signInContainerForgotPasswordDialogTitle', 'Reset your password'),
    signInContainerForgotPasswordDialogContentText: gcAuthenticationMessage('signInContainerForgotPasswordDialogContentText', 'Enter your email address and we will send you a link to reset your password.'),
    signInContainerDontHaveAnAccountText: gcAuthenticationMessage('signInContainerDontHaveAnAccountText', 'Don\'t have an account?'),
    signInContainerSignUpLinkLabel: gcAuthenticationMessage('signInContainerSignUpLinkLabel', 'Sign up'),
})