// source: growcloud/v1/grid/GridDefinitions.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var growcloud_v1_grid_GridCommons_pb = require('../../../growcloud/v1/grid/GridCommons_pb.js');
goog.object.extend(proto, growcloud_v1_grid_GridCommons_pb);
goog.exportSymbol('proto.growcloud.v1.grid.ColumnDefinition', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.ColumnDefinition.Field', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.ColumnDefinitionEnum', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.ColumnDefinitionEnum.Name', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GridColumnConfigurationDefinition', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.GridConfiguration', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.Item', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.Item.ItemCase', null, global);
goog.exportSymbol('proto.growcloud.v1.grid.ParameterisedColumn', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GridConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.grid.GridConfiguration.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.grid.GridConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GridConfiguration.displayName = 'proto.growcloud.v1.grid.GridConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.grid.ColumnDefinitionEnum.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.grid.ColumnDefinitionEnum, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.ColumnDefinitionEnum.displayName = 'proto.growcloud.v1.grid.ColumnDefinitionEnum';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.grid.GridColumnConfigurationDefinition.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.grid.GridColumnConfigurationDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.GridColumnConfigurationDefinition.displayName = 'proto.growcloud.v1.grid.GridColumnConfigurationDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.growcloud.v1.grid.Item.oneofGroups_);
};
goog.inherits(proto.growcloud.v1.grid.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.Item.displayName = 'proto.growcloud.v1.grid.Item';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.ColumnDefinition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.grid.ColumnDefinition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.ColumnDefinition.displayName = 'proto.growcloud.v1.grid.ColumnDefinition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.grid.ParameterisedColumn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.grid.ParameterisedColumn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.grid.ParameterisedColumn.displayName = 'proto.growcloud.v1.grid.ParameterisedColumn';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.grid.GridConfiguration.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GridConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GridConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GridConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    gridcolumnconfigurationdefinitionList: jspb.Message.toObjectList(msg.getGridcolumnconfigurationdefinitionList(),
    proto.growcloud.v1.grid.GridColumnConfigurationDefinition.toObject, includeInstance),
    columndefinitionenumList: jspb.Message.toObjectList(msg.getColumndefinitionenumList(),
    proto.growcloud.v1.grid.ColumnDefinitionEnum.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    urn: (f = msg.getUrn()) && growcloud_v1_grid_GridCommons_pb.GridUrn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GridConfiguration}
 */
proto.growcloud.v1.grid.GridConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GridConfiguration;
  return proto.growcloud.v1.grid.GridConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GridConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GridConfiguration}
 */
proto.growcloud.v1.grid.GridConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.growcloud.v1.grid.GridColumnConfigurationDefinition;
      reader.readMessage(value,proto.growcloud.v1.grid.GridColumnConfigurationDefinition.deserializeBinaryFromReader);
      msg.addGridcolumnconfigurationdefinition(value);
      break;
    case 2:
      var value = new proto.growcloud.v1.grid.ColumnDefinitionEnum;
      reader.readMessage(value,proto.growcloud.v1.grid.ColumnDefinitionEnum.deserializeBinaryFromReader);
      msg.addColumndefinitionenum(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = new growcloud_v1_grid_GridCommons_pb.GridUrn;
      reader.readMessage(value,growcloud_v1_grid_GridCommons_pb.GridUrn.deserializeBinaryFromReader);
      msg.setUrn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GridConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GridConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GridConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGridcolumnconfigurationdefinitionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.growcloud.v1.grid.GridColumnConfigurationDefinition.serializeBinaryToWriter
    );
  }
  f = message.getColumndefinitionenumList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.growcloud.v1.grid.ColumnDefinitionEnum.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUrn();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      growcloud_v1_grid_GridCommons_pb.GridUrn.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GridColumnConfigurationDefinition gridColumnConfigurationDefinition = 1;
 * @return {!Array<!proto.growcloud.v1.grid.GridColumnConfigurationDefinition>}
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.getGridcolumnconfigurationdefinitionList = function() {
  return /** @type{!Array<!proto.growcloud.v1.grid.GridColumnConfigurationDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.grid.GridColumnConfigurationDefinition, 1));
};


/**
 * @param {!Array<!proto.growcloud.v1.grid.GridColumnConfigurationDefinition>} value
 * @return {!proto.growcloud.v1.grid.GridConfiguration} returns this
*/
proto.growcloud.v1.grid.GridConfiguration.prototype.setGridcolumnconfigurationdefinitionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition}
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.addGridcolumnconfigurationdefinition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.growcloud.v1.grid.GridColumnConfigurationDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.grid.GridConfiguration} returns this
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.clearGridcolumnconfigurationdefinitionList = function() {
  return this.setGridcolumnconfigurationdefinitionList([]);
};


/**
 * repeated ColumnDefinitionEnum columnDefinitionEnum = 2;
 * @return {!Array<!proto.growcloud.v1.grid.ColumnDefinitionEnum>}
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.getColumndefinitionenumList = function() {
  return /** @type{!Array<!proto.growcloud.v1.grid.ColumnDefinitionEnum>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.grid.ColumnDefinitionEnum, 2));
};


/**
 * @param {!Array<!proto.growcloud.v1.grid.ColumnDefinitionEnum>} value
 * @return {!proto.growcloud.v1.grid.GridConfiguration} returns this
*/
proto.growcloud.v1.grid.GridConfiguration.prototype.setColumndefinitionenumList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.growcloud.v1.grid.ColumnDefinitionEnum=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.grid.ColumnDefinitionEnum}
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.addColumndefinitionenum = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.growcloud.v1.grid.ColumnDefinitionEnum, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.grid.GridConfiguration} returns this
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.clearColumndefinitionenumList = function() {
  return this.setColumndefinitionenumList([]);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.grid.GridConfiguration} returns this
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional GridUrn urn = 5;
 * @return {?proto.growcloud.v1.grid.GridUrn}
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.getUrn = function() {
  return /** @type{?proto.growcloud.v1.grid.GridUrn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_grid_GridCommons_pb.GridUrn, 5));
};


/**
 * @param {?proto.growcloud.v1.grid.GridUrn|undefined} value
 * @return {!proto.growcloud.v1.grid.GridConfiguration} returns this
*/
proto.growcloud.v1.grid.GridConfiguration.prototype.setUrn = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GridConfiguration} returns this
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.clearUrn = function() {
  return this.setUrn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GridConfiguration.prototype.hasUrn = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.ColumnDefinitionEnum.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.ColumnDefinitionEnum} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.toObject = function(includeInstance, msg) {
  var f, obj = {
    columndefinitionList: jspb.Message.toObjectList(msg.getColumndefinitionList(),
    proto.growcloud.v1.grid.ColumnDefinition.toObject, includeInstance),
    label: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.ColumnDefinitionEnum}
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.ColumnDefinitionEnum;
  return proto.growcloud.v1.grid.ColumnDefinitionEnum.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.ColumnDefinitionEnum} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.ColumnDefinitionEnum}
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.growcloud.v1.grid.ColumnDefinition;
      reader.readMessage(value,proto.growcloud.v1.grid.ColumnDefinition.deserializeBinaryFromReader);
      msg.addColumndefinition(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 4:
      var value = /** @type {!proto.growcloud.v1.grid.ColumnDefinitionEnum.Name} */ (reader.readEnum());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.ColumnDefinitionEnum.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.ColumnDefinitionEnum} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumndefinitionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.growcloud.v1.grid.ColumnDefinition.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.Name = {
  SUBJECT: 0
};

/**
 * repeated ColumnDefinition columnDefinition = 2;
 * @return {!Array<!proto.growcloud.v1.grid.ColumnDefinition>}
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.getColumndefinitionList = function() {
  return /** @type{!Array<!proto.growcloud.v1.grid.ColumnDefinition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.grid.ColumnDefinition, 2));
};


/**
 * @param {!Array<!proto.growcloud.v1.grid.ColumnDefinition>} value
 * @return {!proto.growcloud.v1.grid.ColumnDefinitionEnum} returns this
*/
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.setColumndefinitionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.growcloud.v1.grid.ColumnDefinition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.grid.ColumnDefinition}
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.addColumndefinition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.growcloud.v1.grid.ColumnDefinition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.grid.ColumnDefinitionEnum} returns this
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.clearColumndefinitionList = function() {
  return this.setColumndefinitionList([]);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.grid.ColumnDefinitionEnum} returns this
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Name name = 4;
 * @return {!proto.growcloud.v1.grid.ColumnDefinitionEnum.Name}
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.getName = function() {
  return /** @type {!proto.growcloud.v1.grid.ColumnDefinitionEnum.Name} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.growcloud.v1.grid.ColumnDefinitionEnum.Name} value
 * @return {!proto.growcloud.v1.grid.ColumnDefinitionEnum} returns this
 */
proto.growcloud.v1.grid.ColumnDefinitionEnum.prototype.setName = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.GridColumnConfigurationDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    urn: (f = msg.getUrn()) && growcloud_v1_grid_GridCommons_pb.ColumnUrn.toObject(includeInstance, f),
    itemList: jspb.Message.toObjectList(msg.getItemList(),
    proto.growcloud.v1.grid.Item.toObject, includeInstance),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition}
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.GridColumnConfigurationDefinition;
  return proto.growcloud.v1.grid.GridColumnConfigurationDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition}
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_grid_GridCommons_pb.ColumnUrn;
      reader.readMessage(value,growcloud_v1_grid_GridCommons_pb.ColumnUrn.deserializeBinaryFromReader);
      msg.setUrn(value);
      break;
    case 2:
      var value = new proto.growcloud.v1.grid.Item;
      reader.readMessage(value,proto.growcloud.v1.grid.Item.deserializeBinaryFromReader);
      msg.addItem(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.GridColumnConfigurationDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_grid_GridCommons_pb.ColumnUrn.serializeBinaryToWriter
    );
  }
  f = message.getItemList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.growcloud.v1.grid.Item.serializeBinaryToWriter
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ColumnUrn urn = 1;
 * @return {?proto.growcloud.v1.grid.ColumnUrn}
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.getUrn = function() {
  return /** @type{?proto.growcloud.v1.grid.ColumnUrn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_grid_GridCommons_pb.ColumnUrn, 1));
};


/**
 * @param {?proto.growcloud.v1.grid.ColumnUrn|undefined} value
 * @return {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition} returns this
*/
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.setUrn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition} returns this
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.clearUrn = function() {
  return this.setUrn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.hasUrn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Item item = 2;
 * @return {!Array<!proto.growcloud.v1.grid.Item>}
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.getItemList = function() {
  return /** @type{!Array<!proto.growcloud.v1.grid.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.growcloud.v1.grid.Item, 2));
};


/**
 * @param {!Array<!proto.growcloud.v1.grid.Item>} value
 * @return {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition} returns this
*/
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.setItemList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.growcloud.v1.grid.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.grid.Item}
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.addItem = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.growcloud.v1.grid.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition} returns this
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.clearItemList = function() {
  return this.setItemList([]);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.grid.GridColumnConfigurationDefinition} returns this
 */
proto.growcloud.v1.grid.GridColumnConfigurationDefinition.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.growcloud.v1.grid.Item.oneofGroups_ = [[4]];

/**
 * @enum {number}
 */
proto.growcloud.v1.grid.Item.ItemCase = {
  ITEM_NOT_SET: 0,
  COLUMNDEFINITION: 4
};

/**
 * @return {proto.growcloud.v1.grid.Item.ItemCase}
 */
proto.growcloud.v1.grid.Item.prototype.getItemCase = function() {
  return /** @type {proto.growcloud.v1.grid.Item.ItemCase} */(jspb.Message.computeOneofCase(this, proto.growcloud.v1.grid.Item.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    columndefinition: (f = msg.getColumndefinition()) && proto.growcloud.v1.grid.ColumnDefinition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.Item}
 */
proto.growcloud.v1.grid.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.Item;
  return proto.growcloud.v1.grid.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.Item}
 */
proto.growcloud.v1.grid.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = new proto.growcloud.v1.grid.ColumnDefinition;
      reader.readMessage(value,proto.growcloud.v1.grid.ColumnDefinition.deserializeBinaryFromReader);
      msg.setColumndefinition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumndefinition();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.growcloud.v1.grid.ColumnDefinition.serializeBinaryToWriter
    );
  }
};


/**
 * optional ColumnDefinition columnDefinition = 4;
 * @return {?proto.growcloud.v1.grid.ColumnDefinition}
 */
proto.growcloud.v1.grid.Item.prototype.getColumndefinition = function() {
  return /** @type{?proto.growcloud.v1.grid.ColumnDefinition} */ (
    jspb.Message.getWrapperField(this, proto.growcloud.v1.grid.ColumnDefinition, 4));
};


/**
 * @param {?proto.growcloud.v1.grid.ColumnDefinition|undefined} value
 * @return {!proto.growcloud.v1.grid.Item} returns this
*/
proto.growcloud.v1.grid.Item.prototype.setColumndefinition = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.growcloud.v1.grid.Item.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.Item} returns this
 */
proto.growcloud.v1.grid.Item.prototype.clearColumndefinition = function() {
  return this.setColumndefinition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.Item.prototype.hasColumndefinition = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.ColumnDefinition.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.ColumnDefinition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.ColumnDefinition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.ColumnDefinition.toObject = function(includeInstance, msg) {
  var f, obj = {
    columnurn: (f = msg.getColumnurn()) && growcloud_v1_grid_GridCommons_pb.ColumnUrn.toObject(includeInstance, f),
    field: jspb.Message.getFieldWithDefault(msg, 2, 0),
    label: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.ColumnDefinition}
 */
proto.growcloud.v1.grid.ColumnDefinition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.ColumnDefinition;
  return proto.growcloud.v1.grid.ColumnDefinition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.ColumnDefinition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.ColumnDefinition}
 */
proto.growcloud.v1.grid.ColumnDefinition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_grid_GridCommons_pb.ColumnUrn;
      reader.readMessage(value,growcloud_v1_grid_GridCommons_pb.ColumnUrn.deserializeBinaryFromReader);
      msg.setColumnurn(value);
      break;
    case 2:
      var value = /** @type {!proto.growcloud.v1.grid.ColumnDefinition.Field} */ (reader.readEnum());
      msg.setField(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.ColumnDefinition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.ColumnDefinition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.ColumnDefinition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.ColumnDefinition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getColumnurn();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_grid_GridCommons_pb.ColumnUrn.serializeBinaryToWriter
    );
  }
  f = message.getField();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.growcloud.v1.grid.ColumnDefinition.Field = {
  ORIGINATOR: 0,
  TIMESTAMP: 1,
  READING: 2
};

/**
 * optional ColumnUrn columnUrn = 1;
 * @return {?proto.growcloud.v1.grid.ColumnUrn}
 */
proto.growcloud.v1.grid.ColumnDefinition.prototype.getColumnurn = function() {
  return /** @type{?proto.growcloud.v1.grid.ColumnUrn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_grid_GridCommons_pb.ColumnUrn, 1));
};


/**
 * @param {?proto.growcloud.v1.grid.ColumnUrn|undefined} value
 * @return {!proto.growcloud.v1.grid.ColumnDefinition} returns this
*/
proto.growcloud.v1.grid.ColumnDefinition.prototype.setColumnurn = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.grid.ColumnDefinition} returns this
 */
proto.growcloud.v1.grid.ColumnDefinition.prototype.clearColumnurn = function() {
  return this.setColumnurn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.grid.ColumnDefinition.prototype.hasColumnurn = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Field field = 2;
 * @return {!proto.growcloud.v1.grid.ColumnDefinition.Field}
 */
proto.growcloud.v1.grid.ColumnDefinition.prototype.getField = function() {
  return /** @type {!proto.growcloud.v1.grid.ColumnDefinition.Field} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.growcloud.v1.grid.ColumnDefinition.Field} value
 * @return {!proto.growcloud.v1.grid.ColumnDefinition} returns this
 */
proto.growcloud.v1.grid.ColumnDefinition.prototype.setField = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string label = 3;
 * @return {string}
 */
proto.growcloud.v1.grid.ColumnDefinition.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.grid.ColumnDefinition} returns this
 */
proto.growcloud.v1.grid.ColumnDefinition.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.grid.ParameterisedColumn.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.grid.ParameterisedColumn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.grid.ParameterisedColumn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.ParameterisedColumn.toObject = function(includeInstance, msg) {
  var f, obj = {
    slug: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.grid.ParameterisedColumn}
 */
proto.growcloud.v1.grid.ParameterisedColumn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.grid.ParameterisedColumn;
  return proto.growcloud.v1.grid.ParameterisedColumn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.grid.ParameterisedColumn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.grid.ParameterisedColumn}
 */
proto.growcloud.v1.grid.ParameterisedColumn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlug(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.grid.ParameterisedColumn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.grid.ParameterisedColumn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.grid.ParameterisedColumn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.grid.ParameterisedColumn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSlug();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string slug = 1;
 * @return {string}
 */
proto.growcloud.v1.grid.ParameterisedColumn.prototype.getSlug = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.growcloud.v1.grid.ParameterisedColumn} returns this
 */
proto.growcloud.v1.grid.ParameterisedColumn.prototype.setSlug = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.growcloud.v1.grid);
