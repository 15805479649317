import React from 'react';
import PropTypes from 'prop-types';
import MessageDisplay from './MessageDisplay';
import {dcxMessages} from './../i18n/BaseAppMessages';

import LoadingProgress from '@mui/material/LinearProgress';

const Submitting = ({message, withLargeTopMargin = false}) => {
    return (
        <MessageDisplay
            message={message}
            withLargeTopMargin={withLargeTopMargin}
        >
            <LoadingProgress/>
        </MessageDisplay>
    )
}

Submitting.defaultProps = {
    message: dcxMessages.defaultSubmittingMessage,
}
Submitting.propTypes = {
    message: PropTypes.object,
}
export default (Submitting)