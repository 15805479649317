import {defineMessages} from 'react-intl';

import {defaultI18nMessage} from './MessageUtils';

export const validationErrorMessage = (name, message) => {
    return defaultI18nMessage('validationErrorMessages', name, message);
}

export const validationErrorMessages = defineMessages({
    requiredMessage: validationErrorMessage('requiredMessage', 'Required'),
    unmatchedPasswordMessage: validationErrorMessage('unmatchedPasswordMessage', 'Passwords do not match'),
    invalidPasswordMessage: validationErrorMessage('invalidPasswordMessage', 'Password must be minimum of 6 characters and contain capital & lowercase letters and a number.'),
    invalidEmailAddressMessage: validationErrorMessage('invalidEmailAddressMessage', 'Invalid email address'),
    invalidInternationalPhoneNumberMessage: validationErrorMessage('invalidInternationalPhoneNumberMessage', 'Invalid international phone number'),
    invalidSlugMessage: validationErrorMessage('invalidSlugMessage', 'Invalid slug - slug must be between 6 and 12 characters allowing for dashes, underscores and all alphanumerics'),
})