/**
 * @fileoverview gRPC-Web generated client stub for growcloud.v1.service
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.9
// source: growcloud/v1/service/MeasureValuesV1Service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var growcloud_v1_domain_MeasureValue_pb = require('../../../growcloud/v1/domain/MeasureValue_pb.js')
const proto = {};
proto.growcloud = {};
proto.growcloud.v1 = {};
proto.growcloud.v1.service = require('./MeasureValuesV1Service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.growcloud.v1.service.MeasureValuesV1ServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.growcloud.v1.service.MeasureValuesV1ServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.growcloud.v1.service.CurrentMeasureValuesRequest,
 *   !proto.growcloud.v1.domain.MeasureValue>}
 */
const methodDescriptor_MeasureValuesV1Service_currentMeasureValues = new grpc.web.MethodDescriptor(
  '/growcloud.v1.service.MeasureValuesV1Service/currentMeasureValues',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.growcloud.v1.service.CurrentMeasureValuesRequest,
  growcloud_v1_domain_MeasureValue_pb.MeasureValue,
  /**
   * @param {!proto.growcloud.v1.service.CurrentMeasureValuesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  growcloud_v1_domain_MeasureValue_pb.MeasureValue.deserializeBinary
);


/**
 * @param {!proto.growcloud.v1.service.CurrentMeasureValuesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.domain.MeasureValue>}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.MeasureValuesV1ServiceClient.prototype.currentMeasureValues =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/growcloud.v1.service.MeasureValuesV1Service/currentMeasureValues',
      request,
      metadata || {},
      methodDescriptor_MeasureValuesV1Service_currentMeasureValues);
};


/**
 * @param {!proto.growcloud.v1.service.CurrentMeasureValuesRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.domain.MeasureValue>}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.MeasureValuesV1ServicePromiseClient.prototype.currentMeasureValues =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/growcloud.v1.service.MeasureValuesV1Service/currentMeasureValues',
      request,
      metadata || {},
      methodDescriptor_MeasureValuesV1Service_currentMeasureValues);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.growcloud.v1.service.CurrentMeasureValueRequest,
 *   !proto.growcloud.v1.domain.MeasureValue>}
 */
const methodDescriptor_MeasureValuesV1Service_currentMeasureValue = new grpc.web.MethodDescriptor(
  '/growcloud.v1.service.MeasureValuesV1Service/currentMeasureValue',
  grpc.web.MethodType.UNARY,
  proto.growcloud.v1.service.CurrentMeasureValueRequest,
  growcloud_v1_domain_MeasureValue_pb.MeasureValue,
  /**
   * @param {!proto.growcloud.v1.service.CurrentMeasureValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  growcloud_v1_domain_MeasureValue_pb.MeasureValue.deserializeBinary
);


/**
 * @param {!proto.growcloud.v1.service.CurrentMeasureValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.growcloud.v1.domain.MeasureValue)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.domain.MeasureValue>|undefined}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.MeasureValuesV1ServiceClient.prototype.currentMeasureValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/growcloud.v1.service.MeasureValuesV1Service/currentMeasureValue',
      request,
      metadata || {},
      methodDescriptor_MeasureValuesV1Service_currentMeasureValue,
      callback);
};


/**
 * @param {!proto.growcloud.v1.service.CurrentMeasureValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.growcloud.v1.domain.MeasureValue>}
 *     Promise that resolves to the response
 */
proto.growcloud.v1.service.MeasureValuesV1ServicePromiseClient.prototype.currentMeasureValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/growcloud.v1.service.MeasureValuesV1Service/currentMeasureValue',
      request,
      metadata || {},
      methodDescriptor_MeasureValuesV1Service_currentMeasureValue);
};


module.exports = proto.growcloud.v1.service;

