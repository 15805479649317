import React from 'react';

import { withStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';

import Text from '@dc/dc/content/Text';
import Grid from '@mui/material/Grid';

import GrowcloudSignInForm from './../forms/GrowcloudSignInForm';
import {doAuthentication} from './../actions/authentication-actions';
import ContentBlockHeader from '@dc/dc/components/surface/ContentBlockHeader';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import InternalLink from '@dc/dc/components/links/InternalLink';
import ResponsiveDialog from '@dc/dc/components/dialogs/ResponsiveDialog';

import Error from '@dc/dcx/app/common/Error';
import Success from '@dc/dcx/app/common/Success';

import {RequestStatus} from '@dc/dcx/app/util/RequestConstants';

import {FormattedMessage} from 'react-intl';

import {gcAuthenticationMessages} from './../i18n/GcAuthenticationMessages';

import ContentBlock from '@dc/dc/components/surface/ContentBlock';

const styleSheet = theme => ({
    icon: {
        marginLeft: 8,
    },
    flexRight: {
        flex: '100%',
    },
    textLowercase: {
        textTransform: 'none'
    }
});

const extractError = (operationStatus, itemSelector) => {
    let itemsArray = [];
    if (RequestStatus.REJECTED == operationStatus.currentStatus) {
        operationStatus.data.forEach(item => {
            if (itemSelector(item)) {
                itemsArray.push(item)
            }
        })
    }
    return itemsArray;
}

const extractErrorOnSignin = operationStatus => {
    return extractError(operationStatus, item => {
        return true;
    })
}

const extractMBoxPasswordErrorOnSignin = operationStatus => {
    return extractError(operationStatus, item => {
        if ('USER_NOT_FOUND' == item.name) {
            return true;
        }
        return false;
    })
}

const extractCriticalErrorOnSignin = operationStatus => {
    return extractError(operationStatus, item => {
        if ('NOT_AUTHORISED_FOR_DOMAIN' == item.name) {
            return true;
        }
        return false;
    })
}

class GrowcloudSignInContainer extends React.Component {

    onSubmit(values) {
        this.values = values;
        const {onDoLogin} = this.props;
        onDoLogin(values);
    }

    getSignInMessage() {
        const {authenticationStatus} = this.props;
        if (RequestStatus.NONE == authenticationStatus.currentStatus) {
            return null;
        }
        if (RequestStatus.ACTIVE == authenticationStatus.currentStatus) {
            return null;
        }
        if (RequestStatus.REJECTED == authenticationStatus.currentStatus) {

            let errorOnSignin = extractErrorOnSignin(authenticationStatus);
            if (0 < errorOnSignin.length) {
                return (
                    <Error message={`REJECTED ${errorOnSignin[0].name}`}/>
                )
            }
            return (
                <Error message={'REJECTED - unknown error'}/>
            )
        }
        return (
            <Error message={`Unhandled status ${authenticationStatus.currentStatus}`}/>
        )
    }

    isActive() {
        const {authenticationStatus} = this.props;
        if (authenticationStatus.currentStatus === RequestStatus.ACTIVE) {
            return true;
        }
        return false;
    }

    renderGrowcloudSignInForm() {
        const {authenticationStatus, error} = this.props;
        const isActive = this.isActive()
        if (RequestStatus.SUCCESS == authenticationStatus.currentStatus) {
            const redirectRoute ='/';
            return (
                <Navigate
                    to={redirectRoute}
                />
            )
        }

        let criticalErrorOnSignin = extractCriticalErrorOnSignin(authenticationStatus);
        if (0 < criticalErrorOnSignin.length) {
            return (
                <Error message={`Critical error: ${criticalErrorOnSignin[0].message}`}/>
            )
        }

        return (
            <ContentBlock elevation={0}>
                <GrowcloudSignInForm
                    onSubmit={this.onSubmit.bind(this)}
                    signInMessage={this.getSignInMessage()}
                    signinDomain={window.location.hostname}
                    isActive={isActive}
                />
            </ContentBlock>
        )
    }

    render() {
        const {user} = this.props;

        return (
            <>
                <ContainerX AsContainer withLargeTopMargin backgroundColor={'paper'}>
                    <ContentBlockHeader
                        Alignment={'center'}
                        PageTitle={(
                            <Text variant="h3" gutterBottom>
                                <FormattedMessage {...gcAuthenticationMessages.signInContainerPageTitle}/>
                            </Text>
                        )}
                        TitleColor="textPrimary"
                        // SubHeader={<FormattedMessage {...gcAuthenticationMessages.signInContainerSubHeader}/>}
                        heroTitle_X
                        heroMargin
                        hasDivider
                    />
                    <Grid container justifyContent="flex-start">
                        {this.renderGrowcloudSignInForm()}
                    </Grid>
                </ContainerX>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        authenticationStatus: state.auth.authenticationStatus,
        user: state.auth.user,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onDoLogin: (values) => dispatch(doAuthentication(values))
    }
}

GrowcloudSignInContainer.propTypes = {
    location: PropTypes.object,
    onDoLogin: PropTypes.func.isRequired,
    authenticationStatus: PropTypes.object.isRequired,
    error: PropTypes.object
}

export default withStyles(styleSheet)(connect(mapStateToProps, mapDispatchToProps)(GrowcloudSignInContainer));
