import React from 'react';
import {Form} from 'react-final-form';
import {reduceFormValues} from "./utils/FormUtils";

const DcxFormContext = React.createContext({})

export const READONLY = "%%READONLY";
export const AUTOCOMPLETE = "%%AUTOCOMPLETE";
export const VARIANT = "%%VARIANT";

const calculateStateDelta = (augFormName, commands) => {
    let delta = {augFormName}
    commands.forEach(command => {
        switch (command.type) {
            case AUTOCOMPLETE:
                delta.autoComplete = command.value;
                break;
            case READONLY:
                delta.readOnly = command.value;
                break;
            case VARIANT:
                delta.variant = command.value;
                break;
        }
    })
    return {formControlContext: delta}
}

class InnerForm extends React.Component {
    render() {
        const {
            formControlContext,
            style,
            handleSubmit,
            children
        } = this.props;

        return (
        <Form>
            {() => (
                 <form
                    noValidate
                    autoComplete={formControlContext.autoComplete ? 'on' : 'off'}
                    style={style || {}}
                    onSubmit={handleSubmit}
                >
                    {children}
                </form>
            )}
         </Form>
        )
    }
}

//let ConnectedInnerForm = reduxForm({
//    destroyOnUnmount: false,
//    enableReinitialize: true,
//    keepDirtyOnReinitialize: true,
//    updateUnregisteredFields: true,
//})(InnerForm)

let ConnectedInnerForm = (
    <InnerForm
        destroyOnUnmount={false}
        enableReinitialize={true}
        keepDirtyOnReinitialize={true}
        updateUnregisteredFields={true}
     />
)

class DcxForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formControlContext: {
                autoComplete: true,
                readOnly: false,
                variant: 'filled',
                augFormName: props.form
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {formControlContext} = state;
        if (props.command) {
            return calculateStateDelta(formControlContext.augFormName, [props.command])
        }
        if (props.commands) {
            return calculateStateDelta(formControlContext.augFormName, props.commands)
        }
        return null;
    }

    submitter(onSubmit, formValues) {
        const {formControlContext} = this.state;
        //sometimes this is called when no onSubmit has been provided to DcxForm (USER_ACTIONS_FORM) as the submission is likely to be handled otherwise
        if(onSubmit) {
            const reducedFormValues = reduceFormValues(formControlContext.augFormName, formValues)
            onSubmit(reducedFormValues);
        }
    }

    renderForm(formControlContext, props) {
        const {initialValues, onSubmit, ...rest} = props;
        /*let scopedInitialValues = {}
        Object.keys(initialValues || {}).forEach(key => {
            scopedInitialValues[`${formControlContext.augFormName}#${key}`] = initialValues[key];
        })*/
        return (
            <ConnectedInnerForm
                formControlContext={formControlContext}
                initialValues={initialValues}
                onSubmit={this.submitter.bind(this, onSubmit)}
                {...rest}
            />
        )
    }

    render() {
        const {children, nestedForm} = this.props;
        const {formControlContext} = this.state;
        return (
            <DcxFormContext.Provider value={{formControlContext}}>
                {!nestedForm ? this.renderForm(formControlContext, this.props) : (
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                )}
            </DcxFormContext.Provider>
        )
    }
}

export default (DcxForm)

export const withDcxFormContext = (Component) => {
    return (props) => {
        return (
            <DcxFormContext.Consumer>
                {({formControlContext}) => (
                    <Component
                        {...props}
                        formControlContext={formControlContext}
                    />
                )}
            </DcxFormContext.Consumer>
        )
    }
}