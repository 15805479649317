import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from '@mui/styles';

import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";

const styles = theme => ({
    button: {
    	fontSize: theme.typography.caption.fontSize,
    },
//  listItemIcon: {
//    color: "inherit"
//  }
    mediumText: {
        fontWeight: theme.typography.fontWeightMedium
    },
    removeBottomPadding: {
        paddingBottom: 0,
    },
    resetCursor: {
        cursor: 'auto'
    }
});

class RenderButtonListItem extends React.PureComponent {
    renderButton = React.forwardRef((itemProps, ref) => (
        <ButtonBase {...itemProps} disableRipple disableTouchRipple/>
    ));

    render() {
        const {icon, primary, classes, children, elideCollapsing, overrideClasses} = this.props;
        console.log('renderButtonListitem: ', this.props);

        console.log('elideCollapsing:', elideCollapsing);
        console.log('overrideClasses?', overrideClasses);
        const myClasses = classnames(
            overrideClasses,
            {
                [classes.mediumText]: elideCollapsing,
                [classes.removeBottomPadding]: elideCollapsing,
                [classes.resetCursor]: elideCollapsing
            }
        );

        return (
            <li>
                <ListItem
                    // button={elideCollapsing ? false : true}
                    disableRipple
                    component={this.renderButton}
                    classes={{button: classes.button}}
                    onClick={this.props.onClick}
                >
                    {icon && (
                        <ListItemIcon classes={{root: classes.listItemIcon}}>
                            {icon}
                        </ListItemIcon>
                    )}
                        <ListItemText
                            disableTypography
                            className={myClasses}
                            primary={(
                                <Typography
                                    variant="body2"
                                    color={icon ? "textSecondary" : "textPrimary"}
                                >
                                    {primary}
                                </Typography>
                            )}
                        />
                    {children}
                </ListItem>
            </li>
        );
    }
}

// todo add prop conditional if use case arises ot accommodate [ inset={icon ? false : true} ]

RenderButtonListItem.propTypes = {
    icon: PropTypes.node,
    primary: PropTypes.node.isRequired,
    onClick: PropTypes.func
};

export default withStyles(styles)(RenderButtonListItem);
// elideCollapsing
