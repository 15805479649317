import React from 'react';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import {connect} from 'react-redux';
import NoResults from '@dc/dcx/app/common/NoResults';
import ContainerX from '@dc/dc/components/layout/ContainerX';

import DynamicGrid from 'gc-ui-components/ui/grid/DynamicGrid';
import {setCurrentGridConfiguration} from 'gc-ui-components/ui/mw/grid-service/actions/grid-service-actions';

import {
    currentReadingsGridCellComponentProvider,
    currentReadingsGridExpandedRowComponentProvider,
    makeCalculationsGridConfiguration
} from './GrowcloudGridWrapperHelper';

import {
    getCalculationsGridData,
} from './mw/actions/growcloud-grid-services-actions';

import {handleInitialCurrentContext} from './StylesUtils'

const GRID_CHANNEL = 'calculations';

const styleSheet = theme => ({ });

class CalculationsGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: { page: [] }
        }
    }

    componentDidMount(){
        const{dispatch,contextConfigurations,userContext} = this.props;
        handleInitialCurrentContext(dispatch,
                    {contextConfigurations},
                    GRID_CHANNEL,
                    [GRID_CHANNEL]);
        let gridConfiguration = makeCalculationsGridConfiguration()
        dispatch(setCurrentGridConfiguration(GRID_CHANNEL, GRID_CHANNEL, gridConfiguration));
        dispatch(getCalculationsGridData(userContext, GRID_CHANNEL, GRID_CHANNEL, gridConfiguration, []))

    }

    static getDerivedStateFromProps(props, state) {
        const {contextConfigurations} = props;
        const {currentPage} = state;
        if (contextConfigurations) {
            if (currentPage && contextConfigurations[GRID_CHANNEL]) {
                if(contextConfigurations[GRID_CHANNEL].currentPage){
                    const nextCurrentPage = contextConfigurations[GRID_CHANNEL].currentPage[GRID_CHANNEL];
                    if (nextCurrentPage) {
                        return {currentPage: nextCurrentPage}
                    }
                }
            }
        }
        return null;
    }

    renderGridLayout() {
        const {currentPage} = this.state;
        const {
            gridConfigurations,
            dispatch,
            theme,
            withStylesDynamicGrid: StyledDynamicGrid
        } = this.props;

        const currentDynamicGrid = gridConfigurations[GRID_CHANNEL];

        if(!currentDynamicGrid){
            return (<>Loading</>)
        }

        return (
             <ContainerX AsContainer withLargeBottomMargin>
                    <DynamicGrid
                        hideToolbar
                        pageContext={currentPage}
                        currentDynamicGrid={currentDynamicGrid}
                        cellComponentProvider={currentReadingsGridCellComponentProvider}
                        expandedRowComponentProvider={currentReadingsGridExpandedRowComponentProvider}
                        gridContext={{dispatch}}
                        elideTableRowDetail={true}
                    />
                {/*</StyledDynamicGrid>*/}
             </ContainerX>
        )
    }

    render() {
        const {readings} = this.props;
        return this.renderGridLayout()
    }
}

export default connect(state => {
    return {
        gridConfigurations: state.dynamicGrid.gridConfigurations,
        contextConfigurations: state.application.contextConfigurations
    }
})(
    withStyles(styleSheet, {withTheme: true})(CalculationsGrid)
)