import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';

import Hidden from '@mui/material/Hidden';

import {appbarHeight, drawerWidth} from '../style/BaseStyles';

const styles = theme => ({
    root: {
        position: 'fixed',
        top: appbarHeight,
        left: 0,
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        display: 'flex',
        width: 0,
        [theme.breakpoints.up('md')]: {
            top: appbarHeight,
            width: drawerWidth
        },
    },
    navIconHide: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    drawerPaper: {
//    backgroundColor: theme.palette.common.white,
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            position: 'relative',
        },
    },
    disableClipped: {
        top: 0
    }
//  content: {
////    flexGrow: 1,
//    backgroundColor: theme.palette.grey[200],
//    padding: theme.spacing(3),
//  }
});

class DrawerBase extends React.Component {
    render() {
        const {
            classes,
            theme,
            children,
            menuBarDrawerOpen,
            onHandleMenuBarDrawerClose,
            lastScrollPosition,
            disableClipped
        } = this.props;
        //console.log('Drawerbase open prop: ', menuBarDrawerOpen);
        return (
            <div
                className={classnames(classes.root, {
                    [classes.disableClipped]: disableClipped
                })}
                style={{top: lastScrollPosition > appbarHeight && 0}}
            >
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={menuBarDrawerOpen}
                        onClose={onHandleMenuBarDrawerClose}
                        onChange={onHandleMenuBarDrawerClose}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {children}
                    </Drawer>
                </Hidden>
                <Hidden smDown>
                    <Drawer
                        variant="permanent"
                        open
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {children}
                    </Drawer>
                </Hidden>
            </div>
        );
    }
}

DrawerBase.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

// todo expand PropType checks

export default withStyles(styles, {withTheme: true})(DrawerBase);