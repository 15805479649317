
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import AppBar from '@mui/material/AppBar';

import {connect} from 'react-redux';

import AppbarContainer from '../appbar/containers/AppbarContainer';
import ContainerX from '@dc/dc/components/layout/ContainerX';

import {appbarHeight} from '@dc/dc/style/BaseStyles';

import { publicAppbar } from './../actions/appbar-actions'

const styleSheet = theme => {

  return ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        minWidth: '100vw',
//    alignItems: 'stretch',
    },
    content: {
        display: 'flex',
        flex: '1 0 100%',
        justifyContent: 'center',
        alignItems: 'flex-start',
        maxWidth: '100%',
        padding: 0,
//        paddingTop: '5rem',
//    '@media (max-width: 1180px)': {
//      padding: `6rem ${theme.content.gutter.nm}px ${theme.content.gutter.nm}px`,
//    },
//    '@media (min-width: 600px)': {
//      paddingLeft: `${theme.content.gutter.nm}px`,
//      paddingRight: `${theme.content.gutter.nm}px`,
//    }
    },
    removePageGutters: {
        '@media (max-width: 1180px)': {
            paddingRight: 0,
            paddingLeft: 0,
        },
        '@media (min-width: 600px)': {
            paddingRight: 0,
            paddingLeft: 0,
        }
    },
    contentWithPermanentDrawer: {
        [theme.breakpoints.up('md')]: {
            marginLeft: `${theme.drawerWidth.width}px`,
            width: `calc(100% - ${theme.drawerWidth.width}px)`,
            //paddingLeft: theme.spacing(5),
            //paddingRight: theme.spacing(3),
        },
    },
    footerWithPermanentDrawer: {
        [theme.breakpoints.up('md')]: {
            marginLeft: `${theme.drawerWidth.width}px`,
            width: `calc(100% - ${theme.drawerWidth.width}px)`,
            //paddingLeft: theme.spacing(5),
            //paddingRight: theme.spacing(3),
        },
    },
    appbarRoot: {
        zIndex: theme.zIndex.drawer,
        boxShadow: 'none',
        borderBottom: `2px solid`,
    },
    appbar_shadow10: {
        border: '0 none',
        boxShadow: '4px 3px 3px 0px rgba(0, 0, 0, 0.1)',
    },
    menubar: {
        top: appbarHeight,
//        backgroundColor: 'transparent',
//    [theme.breakpoints.up('sm')]: {
//      top: 56,
//    },
        zIndex: 1000,
    },
    transparent: {
        backgroundColor: 'transparent',
    },
    menubarWithDrawer: {
        left: 'auto',
        right: 0,
        [theme.breakpoints.up('md')]: {
//      marginLeft: `${theme.drawerWidth.width}px`,
            width: `calc(100% - ${theme.drawerWidth.width}px)`,
        },
    },
    appbar: {
        //  position: 'absolute',
    },
    noShadow: {
        boxShadow: 'none'
    },
    drawerTitle: {
        //borderBottom: `1px solid ${theme.palette.grey[400]}`,
        [theme.breakpoints.up('md')]: {
            //borderTop: `1px solid ${theme.palette.grey[400]}`,
        },
//    fontWeight: theme.typography.fontWeightNormal,
        height: `${theme.mixins.toolbar.minHeight}px`,
        lineHeight: `${theme.mixins.toolbar.minHeight}px`,
        paddingLeft: theme.spacing(3),
     //   backgroundColor: `${theme.palette.background.paper}`,
    }
})
}

class Layout extends React.Component {
    constructor(props) {
        super(props);
        const {masthead} = props;
        const thisMasthead = masthead || {
            text: '',
            to: '/',
            component: 'TwoToneLinkedLeadText'
        }
        this.state = {
            thisMasthead,
            currentRoute: null,
            solutionSpace: 'none',
            thisLocation: null,
        }
    }

    componentDidMount() {
        const {dispatch, globalConfiguration} = this.props;
        if(globalConfiguration.application){
            if(globalConfiguration.application.publicRoutes && 0 < globalConfiguration.application.publicRoutes.length){
                dispatch(publicAppbar(
                    globalConfiguration.application.publicRoutes,
                    globalConfiguration.application.publicMenuDrawerItems,
                    globalConfiguration.application.globalSearchData,
                    globalConfiguration.application.profileMenuItems
               ))
           }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {thisMasthead} = state;
        const {masthead} = props;
        if (masthead.to != thisMasthead.to
            || masthead.text != thisMasthead.text) {
            return {['thisMasthead']: masthead}
        }
        return null;
    }

    /*static getDerivedStateFromProps(props, state) {
        const {thisLocation, numProtectedRoutes, thisMasthead, currentRoute, currentRouteProbed} = state;
        const {masthead, dispatch} = props;
        let thisCurrentRoute = determineCurrentRoute(dispatch, props);
        if (!currentRouteProbed) {
            return { currentRouteProbed: true }
        }
        if (!thisCurrentRoute) {
            return null;
        }
        let requiresUpdate = !currentRoute || (currentRoute.to != thisCurrentRoute);
        if (!thisLocation) {
            dispatchSubMenuActivation(dispatch, thisCurrentRoute);
            requiresUpdate = true;
        } else if (props.location.pathname !== thisLocation.pathname
            || ((props.location.pathname === thisLocation.pathname) && (props.location.hash !== thisLocation.hash))) {
            dispatchSubMenuActivation(dispatch, thisCurrentRoute);
            requiresUpdate = true;
        } else if (numProtectedRoutes != props.protectedMenuDrawerItems.length) {
            dispatchSubMenuActivation(dispatch, thisCurrentRoute);
            requiresUpdate = true;
        }

        let newMasthead = null;
        if (masthead.to != thisMasthead.to
            || masthead.text != thisMasthead.text) {
            newMasthead = masthead;
            requiresUpdate = true;
        }
        if (requiresUpdate) {
            let partial = {
                currentRoute: thisCurrentRoute,
                thisLocation: props.location,
                numProtectedRoutes: props.protectedMenuDrawerItems.length
            }
            if (newMasthead) {
                partial['thisMasthead'] = newMasthead;
            }
            return partial;
        }
        return null;
    }

    routeHasSubMenus() {
        if (!this.state.currentRoute) {
            return false;
        }
        return null != this.state.currentRoute.subMenu;
    }*/


    renderNoAppbar() {
        return (null)
    }

    renderDefaultAppbar() {

        const {
            classes,
            user,
            userActionsBlockRenderer,
            leadInBlockRenderer,
            responsiveMainNav,
            space,
            mainAppbarMenuDrawerItems,
            routes,
        } = this.props;

        const {thisMasthead} = this.state;

        let appBarClasses = classnames(classes.appbarRoot, {});

        let mainAppbarLinks = routes.publicRoutes || [];

        return (
            <AppBar
                classes={{root: appBarClasses}}
                color="inherit"
                elevation={5}
            >
                <AppbarContainer
                    flexAlign={'space-between'}
                    mainAppbarLinks={mainAppbarLinks}
                    mainAppbarMenuDrawerItems={mainAppbarMenuDrawerItems}
                    mainAppbarMasthead={thisMasthead}
                    searchEnabled="true"
                    User={user}
                    userActionsBlockRenderer={userActionsBlockRenderer}
                    leadInBlockRenderer={leadInBlockRenderer}
                    solutionSpace={space}
                    responsiveMainNav={responsiveMainNav}
                />
            </AppBar>
        )
    }

    render() {
        const {
            children,
            classes,
            requiresDefaultAppbar,
            contentWithMenuBar = false,
            contentWithPermanentDrawer = false,
            menuBarDrawer = null,
        } = this.props;
        if(menuBarDrawer){
            debugger;
        }
        return (
            <>
                <main className={classnames({
                    [classes.content]: true,
                    [classes.contentWithMenuBar]: contentWithMenuBar,
                    [classes.contentWithPermanentDrawer]: contentWithPermanentDrawer,
                    [classes.removePageGutters]: this.props.removePageGutters,
                })}
                >
                    {requiresDefaultAppbar ? this.renderDefaultAppbar() : this.renderNoAppbar()}

                    {/* menuBarDrawer && menuBarDrawer */}

                    <ContainerX
                        variant={'fitted'}
                        style={{
                            position: 'absolute',
                            top: appbarHeight
                        }}
                    >
                        {children}
                    </ContainerX>
                </main>
                {/*thisMasthead.text && (!currentRoute || !currentRoute.elideFooter) && (
                    <SiteFooter
                        overrideClasses={classnames({
                            [classes.content]: true,
                            [classes.contentWithMenuBar]: subMenuRoute,
                            [classes.footerWithPermanentDrawer]: subMenuRoute && subMenuRoute,
//                            [classes.menubarWithDrawer]: subMenuRoute ? true : false,
                        })}
                        masthead={thisMasthead}
                        leadInBlockRenderer={leadInBlockRenderer}
                        footerBlockRenderer={footerBlockRenderer}
                        mainAppbarLinks={this.makeRoutes()}
                        companySection={companySection}
                    />
                )*/}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const {
        auth: {
            user
        },
        appbar: {
            masthead,
            routes,
        }
    } = state;


    return {
        user,
        masthead,
        routes,
        requiresDefaultAppbar: state.appbar.requiresDefaultAppbar,
        space: state.appbar.space
    }
}

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    classes: PropTypes.object.isRequired,
    masthead: PropTypes.object.isRequired,
    routes: PropTypes.object.isRequired,
};

export default withStyles(styleSheet, {withTheme: true})(connect(mapStateToProps)(Layout))