import React from 'react'
import classnames from 'classnames';

import { withStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';

import DefaultIconButton from './DefaultIconButton';

const styleSheet = theme => ({
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1.5),
        right: theme.spacing(2),
    },
    relativePosition: {
        position: 'relative',
    },
    menuDrawerCloseButton: {
    	right: 0,
    	top: 'auto',
    },
    scale: {
        '&:hover': {
            transform: 'scale(1.1);'
        }
    }
});

function CloseButton(props) {
    const {
        classes,
        onHandleClose,
        dialogContext,
        menuDrawerCloseButton
    } = props;

    return (
        <DefaultIconButton
            ariaLabel="Close"
            positionRelative
            className={classnames(classes.closeButton, {
                [classes.relativePosition]: props.positionRelative,
                [classes.menuDrawerCloseButton]: menuDrawerCloseButton
            })}
            onClick={onHandleClose}
            dialogContext={dialogContext ? dialogContext : null}
        >
            <CloseIcon
                fontSize="small"
                className={classes.scale}
            />
        </DefaultIconButton>
    )
}

export default withStyles(styleSheet)(CloseButton);