import React, {useState} from 'react';
import {connect} from 'react-redux';
import {withUserContext} from './../user/UserContextProvider';
import {applicationLoadedEvent, getMessages, getUserInterfaceConfiguration} from './../app/actions/appbar-actions';

class ThemedComponent extends React.Component {
    componentDidMount() {
        const {dispatch, isLoaded, setLoaded} = this.props;
        if(!isLoaded()) {
            dispatch(getUserInterfaceConfiguration(this.props.configurationOptions || {}))
            setLoaded(true)
        }
        //dispatch(applicationLoadedEvent());
    }

   static getDerivedStateFromProps(props, state) {
       const {  userInterfaceConfiguration } = props;
       if(userInterfaceConfiguration
           && userInterfaceConfiguration.configuration
           && userInterfaceConfiguration.configuration['theme-object']
       ){
           let theme = toTheme(userInterfaceConfiguration.configuration['theme-object']);
           if(userInterfaceConfiguration.configuration['css']) {
               const sheet = document.createElement('style')
               const cssLiteral = atob(userInterfaceConfiguration.configuration['css']);
               sheet.innerHTML = cssLiteral;
               document.body.appendChild(sheet);
           }
           setTheme(theme)
           return {}
       }
       return null;
   }

    render() {

        const {
             WrappedComponent,
             getTheme,
            ...rest
        } = this.props;
        return (
            <WrappedComponent
                theme={getTheme()}
                {...rest}
            />
        )
    }
}

let loaded = false;
const setLoaded = nextLoaded => { loaded = nextLoaded }
const isLoaded  = () => loaded;
let theme = null;
const setTheme = nextTheme => { theme = nextTheme }
const getTheme  = () => { return theme; }

export const withDcx = (WrappedComponent) => connect(state => {

return {
     userInterfaceConfiguration: state.appbar.userInterfaceConfiguration ,
     isLoaded, setLoaded, getTheme, setTheme, WrappedComponent
}})(ThemedComponent)