export const GET_GRID_COLUMN_DATA = '$$GET_GRID_COLUMN_DATA';
export const GET_GRID_COLUMN_DATA_SUCCESS = '$$GET_GRID_COLUMN_DATA_SUCCESS';
export const GET_GRID_COLUMN_DATA_FAIL = '$$GET_GRID_COLUMN_DATA_FAIL';

export const GET_GRID_CONFIGURATIONS = '$$GET_GRID_CONFIGURATIONS';
export const GET_GRID_CONFIGURATIONS_SUCCESS = '$$GET_GRID_CONFIGURATIONS_SUCCESS';
export const GET_GRID_CONFIGURATIONS_FAIL = '$$GET_GRID_CONFIGURATIONS_FAIL';

export const SET_AXIS = '$$SET_AXIS';

export const setAxis = (contextHash, axis, channel) => {
    return {type: SET_AXIS, contextHash, axis, channel}
}

export const SET_CURRENT_GRID_CONFIGURATION = '$$SET_CURRENT_GRID_CONFIGURATION';

export const setCurrentGridConfiguration = (contextHash, channel, data) => {
    return {type: SET_CURRENT_GRID_CONFIGURATION, contextHash, channel, data}
}


export const DYNAMIC_GRID_STATE_CHANGE_ACTION = '$$DYNAMIC_GRID_STATE_CHANGE_ACTION';

export const createDynamicGridAction = (channel, partialStateName, partialStateValue) => ({
    type: DYNAMIC_GRID_STATE_CHANGE_ACTION,
    channel,
    partialStateName,
    partialStateValue,
});
