import {RequestStatus} from "../util/RequestConstants";
import {
    CLEAR_OPEN_STATE,
    CLOSE_MENU_DRAWER,
    CLOSE_MENUBAR_DRAWER,
    CONFIGURE_APPBAR,
    CONFIGURE_SECTION_MENUBAR_DRAWER,
    GET_MESSAGES,
    GET_MESSAGES_FAIL,
    GET_MESSAGES_SUCCESS,
    GET_USER_INTERFACE_CONFIGURATION,
    GET_USER_INTERFACE_CONFIGURATION_FAIL,
    GET_USER_INTERFACE_CONFIGURATION_SUCCESS,
    RENDER_LICENSE_REQUEST_STEP,
    RENDER_NEXT_SIGN_UP_STEP,
    RENDER_SIGN_UP_STEP,
    RESET_MASTHEAD,
    RESET_MENU_BAR_DRAWER_CONFIGURATION,
    SET_GLOBAL_SEARCH_NOT_ACTIVE,
    SET_MENU_BAR_DRAWER_CONFIGURATION,
    SET_OPEN_STATE,
    SET_SPACE,
    SET_STATE_ON_FLOW,
    TOGGLE_GLOBAL_SEARCH_ACTIVE,
    TOGGLE_LICENSE_REQUEST_ERROR,
    TOGGLE_MENU_DRAWER,
    TOGGLE_MENUBAR_DRAWER,
    TOGGLE_OPEN_STATE,
    TOGGLE_REQUIRES_APPBAR,
    TOGGLE_SIGNUP_ERROR,
    UPDATE_MASTHEAD_CONFIG,
} from '../actions/appbar-actions'

const dotProp = require('dot-prop-immutable');

const initialMenuDrawer = () => {
    return {
        open: false,
        publicMenuDrawerItems: [],
        protectedMenuDrawerItems: [],
    }
}

const initialMenuBar = () => {
    return {
        active: false,
        drawerOpen: false,
        menuBarDrawerItems: [],
        menuBarDrawerConfiguration: {}
    }
}

const initialUserProfileMenu = () => {
    return {
        open: false,
        anchor: null,
        protectedUserProfileMenuItems: [],
    }
}

const initialMasthead = () => {
    return {
        text: 'GROWCLOUD',
        component: 'LinkedLeadText',
        to: '/'
    }
}

const initialRoutes = () => {
    return {
        publicRoutes: [],
        protectedRoutes: []
    }
}

const initialGlobalSearch = () => {
    return {
        searchData: [],
        searchActive: false
    }
}

const initialSignUp = () => {
    return {
        steps: ['Establish identity', 'Verify identity', 'Get started'],
        activeStep: 0,
        isSubmitting: false,
        error: null
    }
}


const initialLicenseRequest = () => {
    return {
        steps: ['Select package', 'Select support', 'Select billing', 'Get started'],
        activeStep: 0,
        isSubmitting: false,
        error: null
    }
}

const initialNotificationsMenu = () => {
    return {
        open: false,
        anchor: null,
    }
}

const initialState = () => {
    return {
        notificationsMenu: initialNotificationsMenu(),
        menuDrawer: initialMenuDrawer(),
        masthead: initialMasthead(),
        routes: initialRoutes(),
        globalSearch: initialGlobalSearch(),
        userProfileMenu: initialUserProfileMenu(),
        menuBar: initialMenuBar(),
        openStates: {},
        space: {},
        flows: {},
        requiresDefaultAppbar: true,
        messages: {currentActionStatus: RequestStatus.NONE, table: {}}
    }
}

const setMessagesStatus = (state, currentStatus) => {
    let messages = {...state.messages};
    let updatedMessages = dotProp.set(messages, 'currentActionStatus', currentStatus);
    return updatedMessages;
}

const setUserInterfaceConfigurationStatus = (state, currentStatus) => {
    let userInterfaceConfiguration = {...state.userInterfaceConfiguration};
    let updatedUserInterfaceConfiguration = dotProp.set(userInterfaceConfiguration, 'currentActionStatus', currentStatus);
    return updatedUserInterfaceConfiguration;
}

const mergeMessages = (state, actionData) => {
    let messages = {...state.messages};
    return {currentActionStatus: RequestStatus.SUCCESS, table: actionData.data};
}

const mergeUserInterfaceConfiguration = (state, actionData) => {
    let userInterfaceConfiguration = {...state.userInterfaceConfiguration};
    return {currentActionStatus: RequestStatus.SUCCESS, configuration: actionData.data};
}

const mergeRoutes = (state, action) => {
    let routes = {...state.routes};

    let updatedRoutes = routes;

    if (action.publicRoutes) {
        updatedRoutes = dotProp.set(updatedRoutes, ['publicRoutes'], action.publicRoutes);
    }
    if (action.protectedRoutes) {
        updatedRoutes = dotProp.set(updatedRoutes, ['protectedRoutes'], action.protectedRoutes);
    }
    return updatedRoutes;
}

const mergeMenuDrawer = (state, action) => {

    let menuDrawer = {...state.menuDrawer};

    let updatedMenuDrawer = menuDrawer;

    if (action.publicRoutes) {
        let menuDrawerItems = []
        action.publicRoutes.forEach(route => {
            if (route.menuDrawer) {
                menuDrawerItems.push(route);
            }
        })
        updatedMenuDrawer = dotProp.set(updatedMenuDrawer, ['publicMenuDrawerItems'], menuDrawerItems);
    }

    if (action.protectedRoutes) {
        let menuDrawerItems = []
        action.protectedRoutes.forEach(route => {
            if (route.menuDrawer) {
                menuDrawerItems.push(route);
            }
        })
        updatedMenuDrawer = dotProp.set(updatedMenuDrawer, ['protectedMenuDrawerItems'], menuDrawerItems);
        return updatedMenuDrawer;
    }
    return updatedMenuDrawer;
}

const setMenuBarDrawerConfiguration = (state, action) => {
    let menuBar = {...state.menuBar};
    let updatedMenuBar = menuBar;
    if (action.menuBarDrawerConfiguration) {
        updatedMenuBar = dotProp.set(updatedMenuBar, ['menuBarDrawerConfiguration'], action.menuBarDrawerConfiguration);
    }
    return updatedMenuBar;
}

const mergeMenuBar = (state, action) => {
    let menuBar = {...state.menuBar};
    let updatedMenuBar = menuBar;

    if (action.menuBar) {
        updatedMenuBar = dotProp.set(updatedMenuBar, ['active'], action.active);
    }
    if (action.menuBarDrawerItems) {
        updatedMenuBar = dotProp.set(updatedMenuBar, ['menuBarDrawerItems'], action.menuBarDrawerItems);
    }

    return updatedMenuBar;
}

const mergeGlobalSearch = (state, action) => {

    let globalSearch = {...state.globalSearch};

    let updatedGlobalSearch = globalSearch;

    if (action.globalSearchData) {
        updatedGlobalSearch = dotProp.set(updatedGlobalSearch, ['searchData'], action.globalSearchData);
    }

    if (action.globalSearchActive) {
        updatedGlobalSearch = dotProp.set(updatedGlobalSearch, ['searchActive'], action.globalSearchData);
    }
    return updatedGlobalSearch;
}

const mergeMasthead = (state, action) => {
    let masthead = {...state.masthead};

    let updatedMasthead = masthead;

    if (action.masthead) {
        updatedMasthead = dotProp.set(updatedMasthead, ['text'], action.masthead);
    }
    return updatedMasthead
}

const mergeUserProfileMenu = (state, action) => {
    let userProfileMenu = {...state.userProfileMenu};
    let updatedUserProfileMenu = userProfileMenu;

    if (action.protectedRoutes) {
        let protectedUserProfileMenuItems = [];
        action.protectedRoutes.forEach(route => {
            if (route.userProfileMenuDrawer) {
                protectedUserProfileMenuItems.push(route);
            }
        })
        updatedUserProfileMenu = dotProp.set(updatedUserProfileMenu, ['protectedUserProfileMenuItems'], protectedUserProfileMenuItems);
    }
    return updatedUserProfileMenu;
}

const mergeFlowState = (state, action) => {
    let flows = {...state.flows};
    let updatedFlows = flows;
    if (action.processStateId) {
        updatedFlows = dotProp.set(updatedFlows, [`${action.flowDefinitionId}-${action.recordCaptureId}`, 'currentState'], action.processStateId);
        updatedFlows = dotProp.set(updatedFlows, [`${action.flowDefinitionId}-${action.recordCaptureId}`, 'submissionDialogOpen'], false);
    }
    return updatedFlows;
}

const mergeSignUp = (state, action) => {
    let signUp = {...state.signUp};
    let updatedSignUp = signUp;
    if (typeof (action.activeStep) !== 'undefined') {
        updatedSignUp = dotProp.set(updatedSignUp, action.activeStep);
    }
    return updatedSignUp;
}

const mergeNotificationsMenu = (state, action) => {
    let notificationsMenu = {...state.notificationsMenu};
    let updatedNotificationsMenu = notificationsMenu;
    if (action.anchor) {
        updatedNotificationsMenu = dotProp.set(updatedNotificationsMenu, action.anchor);
    }

    if (action.open) {
        updatedNotificationsMenu = dotProp.set(updatedNotificationsMenu, action.anchor);
    }
    return updatedNotificationsMenu;
}

const toggleSignUpError = (state, action) => {
    let signUp = {...state.signUp};
    let updatedSignUp = signUp;
    if (action.active) {
        updatedSignUp = dotProp.set(signUp.error, action.active);
    }
    return updatedSignUp;
}

const toggleLicenseRequestError = (state, action) => {
    let licenseRequest = {...state.licenseRequest};
    let updatedLicenseRequest = licenseRequest;
    if (action.active) {
        updatedLicenseRequest = dotProp.set(updatedLicenseRequest.error, action.active);
    }
    return updatedLicenseRequest;
}

const renderSignUpStep = (state, action) => {
    let signUp = {...state.signUp};
    let updatedSignUp = signUp;
    if (typeof (action.activeStep) !== 'undefined') {
        updatedSignUp = dotProp.set(signUp, "activeStep", action.activeStep);
    }
    return updatedSignUp;
}

const renderNextSignUpStep = (state) => {
    let signUp = {...state.signUp};
    let updatedSignUp = dotProp.set(signUp, "activeStep", signUp.activeStep + 1);
    return updatedSignUp;
}

const renderLicenseRequestStep = (state, action) => {
    let licenseRequest = {...state.licenseRequest};
    let updateLicenseRequest = licenseRequest;
    if (typeof (action.activeStep) !== 'undefined') {
        updateLicenseRequest = dotProp.set(updateLicenseRequest, "activeStep", action.activeStep);
    }
    return updateLicenseRequest;
}

const toggleMenuDrawer = (state) => {
    let menuDrawer = {...state.menuDrawer};
    let updatedMenuDrawer = dotProp.toggle(menuDrawer, "open");
    return updatedMenuDrawer;
}

const closeMenuDrawer = (state) => {
    let menuDrawer = {...state.menuDrawer};
    let updatedMenuDrawer = dotProp.set(menuDrawer, "open", false);
    return updatedMenuDrawer;
}

const toggleMenuBarDrawer = (state) => {
    let menuBar = {...state.menuBar};
    let updatedMenuBar = dotProp.toggle(menuBar, "drawerOpen");
    return updatedMenuBar;
}

const closeMenuBarDrawer = (state) => {
    let menuBar = {...state.menuBar};
    let updatedMenuBar = dotProp.set(menuBar, "drawerOpen", false);
    return updatedMenuBar;
}

const setSpace = (state, action) => {
    return action.space;
}

const updateMastheadConfig = (state, action) => {
    let masthead = {...state.masthead};
    if(action) {
        let updatedMasthead = masthead;

        if (action.component) {
            updatedMasthead = dotProp.set(updatedMasthead, "component", action.component);
        }
        if (action.text) {
            updatedMasthead = dotProp.set(updatedMasthead, "text", action.text);
        }
        if (action.to) {
            updatedMasthead = dotProp.set(updatedMasthead, "to", action.to);
        }
        return updatedMasthead;
    }
    return masthead;
}

const sectionMenuBarDrawerItems = (state, action) => {
    let menuBar = {...state.menuBar};
    let updatedMenuBar = menuBar;
    if (action.menuBarDrawerItems) {
        updatedMenuBar = dotProp.set(updatedMenuBar, "menuBarDrawerItems", action.menuBarDrawerItems);
    }
    return updatedMenuBar;
}

const toggleGlobalSearchActive = (state) => {
    let globalSearch = {...state.globalSearch};
    let updatedGlobalSearch = dotProp.toggle(globalSearch, "searchActive");
    return updatedGlobalSearch;
}

const setGlobalSearchNotActive = (state) => {
    let globalSearch = {...state.globalSearch};
    let updatedGlobalSearch = dotProp.set(globalSearch, "searchActive", false);
    return updatedGlobalSearch;
}

const toggleOpenStates = (state, action) => {
    let openStates = {...state.openStates}
    if (action.hash) {
        let nextState = openStates[action.hash] ? false : true;
        let updatedOpenStates = dotProp.set(openStates, action.hash, nextState);
        return updatedOpenStates;
    }
    return openStates;
}

const clearOpenStates = (state, action) => {
    let openStates = {...state.openStates}
    let itemsToEffect = [];
    Object.keys(openStates).forEach(hash => {
        if (hash.startsWith(action.hashPrefix) && openStates[hash]) {
            itemsToEffect.push(hash);
        }
    })
    let updatedOpenStates = openStates;
    itemsToEffect.forEach(hash => {
        let nextState = false;
        updatedOpenStates = dotProp.set(updatedOpenStates, hash, nextState);
    })
    return updatedOpenStates;
}

const setOpenStates = (state, action) => {
    let openStates = {...state.openStates}
    let itemsToEffect = [];
    //TODO this doesn't work - and it's questionable as to whether it's needed (i.e. non-open items don't exist in openStates - so we have to dig around for them)
    Object.keys(openStates).forEach(hash => {
        if (hash.startsWith(action.hashPrefix) && !openStates[hash]) {
            itemsToEffect.push(hash);
        }
    })
    let updatedOpenStates = openStates;
    itemsToEffect.forEach(hash => {
        let nextState = true;
        updatedOpenStates = dotProp.set(updatedOpenStates, hash, nextState);
    })
    return updatedOpenStates;
}

const toggleRequiresAppbar = (state, action) => {
    return !state.requiresDefaultAppbar;
}


const reducers = (state = initialState(), action) => {
    switch (action.type) {

        case GET_MESSAGES:
            return {
                ...state,
                messages: setMessagesStatus(state, RequestStatus.ACTIVE)
            }
        case GET_MESSAGES_FAIL:
            return {
                ...state,
                messages: setMessagesStatus(state, RequestStatus.FAIL)
            }
        case GET_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: mergeMessages(state, action)
            }
        case GET_USER_INTERFACE_CONFIGURATION:
            return {
                ...state,
                userInterfaceConfiguration: setUserInterfaceConfigurationStatus(state, RequestStatus.ACTIVE)
            }
        case GET_USER_INTERFACE_CONFIGURATION_FAIL:
            return {
                ...state,
                userInterfaceConfiguration: setUserInterfaceConfigurationStatus(state, RequestStatus.FAIL)
            }
        case GET_USER_INTERFACE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                userInterfaceConfiguration: mergeUserInterfaceConfiguration(state, action),
                masthead: updateMastheadConfig(state, action.data['masthead'])
            }


        case CONFIGURE_APPBAR:
            return {
                ...state,
                routes: mergeRoutes(state, action),
                menuDrawer: mergeMenuDrawer(state, action),
                globalSearch: mergeGlobalSearch(state, action),
                userProfileMenu: mergeUserProfileMenu(state, action),
                notificationsMenu: mergeNotificationsMenu(state, action),
                signUp: mergeSignUp(state, action),
                menuBar: mergeMenuBar(state, action),
            };
        case TOGGLE_GLOBAL_SEARCH_ACTIVE: {
            return {
                ...state,
                globalSearch: toggleGlobalSearchActive(state, action)
            }
        }
        case SET_GLOBAL_SEARCH_NOT_ACTIVE: {
            return {
                ...state,
                globalSearch: setGlobalSearchNotActive(state)
            }
        }
        case TOGGLE_SIGNUP_ERROR: {
            return {
                ...state,
                signUp: toggleSignUpError(state, action)
            };
        }
        case TOGGLE_LICENSE_REQUEST_ERROR: {
            return {
                ...state,
                licenseRequest: toggleLicenseRequestError(state, action)
            };
        }
        case RENDER_SIGN_UP_STEP:
            return {
                ...state,
                signUp: renderSignUpStep(state, action)
            };
        case RENDER_NEXT_SIGN_UP_STEP:
            return {
                ...state,
                signUp: renderNextSignUpStep(state)
            };
        case RENDER_LICENSE_REQUEST_STEP:
            return {
                ...state,
                licenseRequest: renderLicenseRequestStep(state, action)
            };
        case TOGGLE_MENUBAR_DRAWER:
            return {
                ...state,
                menuBar: toggleMenuBarDrawer(state)
            };
        case CLOSE_MENUBAR_DRAWER:
            return {
                ...state,
                menuBar: closeMenuBarDrawer(state)
            };
        case CONFIGURE_SECTION_MENUBAR_DRAWER:
            return {
                ...state,
                menuBar: sectionMenuBarDrawerItems(state, action)
            };
        case TOGGLE_MENU_DRAWER:
            return {
                ...state,
                menuDrawer: toggleMenuDrawer(state)
            };
        case CLOSE_MENU_DRAWER:
            return {
                ...state,
                menuDrawer: closeMenuDrawer(state)
            };
        case UPDATE_MASTHEAD_CONFIG:
            return {
                ...state,
                masthead: updateMastheadConfig(state, action)
            };
        case SET_SPACE:
            return {
                ...state,
                space: setSpace(state, action),
            }
        case RESET_MASTHEAD:
            return {
                ...state,
                masthead: initialMasthead(),
            }
        case RESET_MENU_BAR_DRAWER_CONFIGURATION:
            return {
                ...state,
                menuBar: setMenuBarDrawerConfiguration(state, {menuBarDrawerConfiguration: {}})
            }
        case SET_MENU_BAR_DRAWER_CONFIGURATION:
            return {
                ...state,
                menuBar: setMenuBarDrawerConfiguration(state, action)
            }
        case SET_STATE_ON_FLOW:
            return {
                ...state,
                flows: mergeFlowState(state, action)
            };
        case TOGGLE_OPEN_STATE:
            return {
                ...state,
                openStates: toggleOpenStates(state, action)
            }
        case CLEAR_OPEN_STATE:
            return {
                ...state,
                openStates: clearOpenStates(state, action)
            }
        case SET_OPEN_STATE:
            return {
                ...state,
                openStates: setOpenStates(state, action)
            }
        case TOGGLE_REQUIRES_APPBAR:
            return {
                ...state,
                requiresDefaultAppbar: toggleRequiresAppbar(state, action)
            }
        default:
            return state;
    }
    return state;
}

export default reducers;
