import {
    loadActiveProductionModels,
    loadSitesConfigurationsModels
} from 'gc-webapp/gc/mw/actions/growcloud-v1-service-actions';

import {
    loadCurrentReadings,
    clearCurrentReadings,
    LOAD_CURRENT_READINGS_SUCCESS
} from 'gc-webapp/gc/mw/actions/measure-values-v1-service-actions';

import GrowcloudServiceRegistry from 'gc-webapp/gc/GrowcloudServiceRegistry'

import {
    getCurrentUser,
    AUTH_TOKEN_FAIL,
    AUTH_TOKEN_SUCCESS,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_SUCCESS,
    SIGNOUT_SUCCESS
} from 'gc-authentication/authentication/actions/authentication-actions'

import {makeGrowcloudServerConfiguration} from './GrowcloudServerConfiguration';

let globalConfiguration = makeGrowcloudServerConfiguration('GROWCLOUD');

export default {
  types: [
      AUTH_TOKEN_SUCCESS,
      AUTHENTICATION_SUCCESS,
      SIGNOUT_SUCCESS,
      AUTH_TOKEN_FAIL,
      AUTHENTICATION_FAIL,
      LOAD_CURRENT_READINGS_SUCCESS,
  ],
  setStore (store) {
    this.store = store;
  },
  handleAction ( action, dispatched, store ) {
    switch(dispatched.type){
        case AUTH_TOKEN_SUCCESS:
            if(dispatched.data && globalConfiguration){
                const userContext = { serviceRegistry: new GrowcloudServiceRegistry(globalConfiguration['grpcHost']) }
                store.dispatch(loadActiveProductionModels(userContext))
                store.dispatch(loadSitesConfigurationsModels(userContext))
                return
            }
            break;
        case AUTHENTICATION_SUCCESS:
            store.dispatch(getCurrentUser());
            break;
        case SIGNOUT_SUCCESS:
        case AUTH_TOKEN_FAIL:
        case AUTHENTICATION_FAIL:
            store.dispatch(clearCurrentReadings());
            break;
        default:
            throw "ShouldHaveBeenHandled";
    }
    return dispatched;
  }
}