import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import { withStyles } from '@mui/styles';
import {profileIconMenuBaseStyleSheet} from '@dc/dc/style/BaseStyles';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIconLink from '@dc/dc/components/lists/ListItemIconLink';
import PopoverBase from '@dc/dc/components/PopoverBase';
import {connect} from 'react-redux';
import InitialsThumb from '@dc/dc/components/modules/InitialsThumb';
import Submitting from '@dc/dcx/app/common/Submitting';

import MenuDrawerList from '@dc/dcx/app/appbar/components/MenuDrawerList';

import Loading from '@dc/dcx/app/common/Loading';

import {RequestStatus} from '@dc/dcx/app/util/RequestConstants';

import {FormattedMessage} from 'react-intl';
import {commonWebApplicationMessages} from './../../i18n/CommonWebApplicationMessages';

import ContainerX from '@dc/dc/components/layout/ContainerX';

class AppbarUserProfileMenu extends React.PureComponent {

    state = {
        open: false,
        anchorElement: null
    }

    openUserProfileMenu(element) {
        this.setState({open: true, anchorElement: element})
    }

    closeUserProfileMenu() {
        this.setState({open: false})
    }

    render() {
        const {
            classes,
            user,
            authTokenAuthorised,
            authorisedUserMenuItems,
            elideUserProfile
        } = this.props;
        const {open, anchorElement} = this.state;

        if (!authTokenAuthorised) {
            return <Submitting/>
        }

        if (authTokenAuthorised && !user) {
            return <Loading withProgress/>
        }
        const latestNotifications = !authorisedUserMenuItems || 0 == authorisedUserMenuItems.length ? 0 : 1;

        const userProfileLink = elideUserProfile ? null : '/user-profile';

        return (
            <>
                <IconButton
                    id="userProfileMenuButton"
                    data-id="userProfileMenuButton"
                    onClick={() => this.openUserProfileMenu(userProfileMenuButton)}
                    className={classes.avatarWrap}
                    disableRipple
                >
                    {authTokenAuthorised && user.label && (
                        <InitialsThumb
                            user={user}
                            customClass={classes.avatar}
                            buttonActive={true}
                        />
                    )}
                    {latestNotifications > 0 && (
                        <span className={classes.notificationIndicator}/>
                    )}
                </IconButton>
                <PopoverBase
                    isOpen={open}
                    anchorEl={anchorElement}
                    onHandleMenuClose={(event) => this.closeUserProfileMenu(event)}
                    disableScrollLock={true}
                >
                    <ContainerX variant={'fitted'}>
                        <List
                            component="nav"
                            disablePadding
                            classes={{root: classes.popoverList}}
                        >
                            <ListItemIconLink
                                to={userProfileLink}
                                primary={user.label}
                                secondary={'helo'}
                                icon={authTokenAuthorised && (
                                    <InitialsThumb
                                        user={user}
                                        buttonActive={true}
                                        customClass={classes.avatar}
                                    />
                                )}
                                onClick={(event) => {
                                    closeUserProfileMenu();
                                }}
                            />
                        </List>
                        <MenuDrawerList
                            menuItems={authorisedUserMenuItems}
                            isLink
                            hasBadge
                            unSetWidth
                            spaceBetween
                            onClick={(event) => this.closeUserProfileMenu()}
                        />
                        <List>
                            <ListItem
                                className={classnames(classes.actionsRow, classes.guttersFix)}
                                style={{justifyContent: 'flex-end'}}
                            >
                                <Button
                                    variant="text"
                                    className={classes.defaultButton}
                                    size="small"
                                    component={Link} to="/signout"
                                    onClick={(event) => this.closeUserProfileMenu()}
                                >
                                    <FormattedMessage
                                        {...commonWebApplicationMessages.userProfileMenuSignoutLinkLabel}
                                    />
                                </Button>
                            </ListItem>
                        </List>
                    </ContainerX>
                </PopoverBase>
            </>
        )
    }
}

AppbarUserProfileMenu.propTypes = {
    classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    let authTokenAuthorised = RequestStatus.SUCCESS == state.auth.authTokenStatus.currentStatus;
    return {
        authTokenAuthorised: authTokenAuthorised
    }
}

export default withStyles(profileIconMenuBaseStyleSheet)(
    connect(mapStateToProps)(AppbarUserProfileMenu));
