import React from 'react'
import PropTypes from 'prop-types';
//import { withWidth }, {isWidthDown} from '@mui/material/Hidden';
import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';

import ActionsBar from './ActionsBar';
import ContainerX from "./layout/ContainerX";

import AppBar from '@mui/material/AppBar';

const actionStepperStyles = theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        boxShadow: 'none'
    },
    defaultPaperBackground: {
        backgroundColor: theme.palette.background.default,
    },
    paperBackground: {
        backgroundColor: theme.palette.background.paper,
    },
    paper: {
        display: 'flex',
        flex: '0 0 100%',
        padding: 0,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepperHeader: {
        marginBottom: theme.spacing(3),
        border: `1px solid ${theme.palette.grey[400]}`,
        borderRadius: theme.spacing(0.5),
    },
    stepCustom: {
        padding: 0,
        backgroundColor: theme.palette.background.paper
    },
    stepLabelCustom: {
//    display: 'none',
        opacity: '0.5',
        display: 'flex',
        flex: '1 1 auto',
        flexWrap: 'wrap',
        padding: theme.spacing(2),
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        '&:first-child': {
            padding: theme.spacing(2),
            borderTopLeftRadius: theme.spacing(0.5),
            borderBottomLeftRadius: theme.spacing(0.5),
        },
        '&:last-child': {
            padding: theme.spacing(2),
            borderRight: '0 none',
            borderTopRightRadius: theme.spacing(0.5),
            borderBottomRightRadius: theme.spacing(0.5),
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    stepLabelCustomActive: {
//    backgroundColor: theme.palette.background.default,
        opacity: 1,
        color: theme.palette.default
    },
    stepLabelCustomCompleted: {
//    backgroundColor: theme.palette.background.default,
        opacity: 1,
        color: 'inherit'
    },
    stepLabelContainer: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
    },
    customGridItem: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: '166px'
        },
        "@media(max-width: 375px)": {
            maxWidth: '93px'
        }
    }
});

export const PREVIOUS = 'previous';
export const NEXT = 'next';
export const RESET = 'reset';

export const SHOW = 'show';
export const HIDE = 'hide';
export const DISABLE = 'disable';

const actionMaker = () => {
    return {
        label: 'none',
        variant: 'cta',
        clickFunction: () => {
        },
        disabled: true,
    }
}

const appbarStyles = theme => ({
    appbar: {
        top: 'auto',
        bottom: 0,
    }
})

const BottomDockedAppbar = ({classes, children}) => {
    return (
        <AppBar
            color={'transparent'}
            className={classes.appbar}
            elevation={0}
        >
            <ContainerX AsContainer>
                {children}
            </ContainerX>
        </AppBar>
    )
}

const StyledBottomDockedAppbar = withStyles(appbarStyles)(BottomDockedAppbar)

class ActionStepper extends React.Component {
    static propTypes = {
        classes: PropTypes.object,
    };

    constructor(props){
        super(props);
        this.state = {}
    }

    isStepOptional = step => {
        return false;
    };

    handleCommand = (command) => {
        const {stepCommandHandler} = this.props;
        stepCommandHandler(command);
    }

    handleNext = (e) => {
        this.handleCommand(NEXT);
        e.stopPropagation();
        e.preventDefault();
    };

    handlePrevious = (e) => {
        this.handleCommand(PREVIOUS);
        e.stopPropagation();
        e.preventDefault();
    };

    handleReset = (e) => {
        this.handleCommand(RESET);
        e.stopPropagation();
        e.preventDefault();
    };

    makeActions(activeStep, steps) {

        const {
            stepperButtonsActive,
            backButtonLabel='BACK',
            nextButtonLabel='NEXT'
        } = this.props;

        const isBackStepperButtonsActive = stepperButtonsActive(activeStep, PREVIOUS);
        const isNextStepperButtonsActive = stepperButtonsActive(activeStep, NEXT);

        let isBoolean = typeof isBackStepperButtonsActive === 'boolean';

        let actions = [];

        if (!isBoolean) {
            switch (isBackStepperButtonsActive.action) {
                case HIDE:
                    //do nothing
                    break;
                case DISABLE:
                    actions.push(Object.assign(actionMaker(), {
                        label: backButtonLabel,
                        disabled: true,
                        clickFunction: this.handlePrevious
                    }))
                    break;
                default:
                    if (activeStep == 0) {
                        break;
                    }
                    actions.push(Object.assign(actionMaker(), {
                        label: backButtonLabel,
                        disabled: false,
                        clickFunction: this.handlePrevious
                    }))
                    break;
            }
            if (activeStep < steps.length - 1) {
                switch (isNextStepperButtonsActive.action) {
                    case HIDE:
                        //do nothing
                        break;
                    case DISABLE:
                        actions.push(Object.assign(actionMaker(), {
                            label: nextButtonLabel,
                            disabled: true,
                            clickFunction: this.handleNext
                        }));
                        break;
                    default:
                        actions.push(Object.assign(actionMaker(), {
                            label: nextButtonLabel,
                            disabled: false,
                            clickFunction: this.handleNext
                        }));
                        break;
                }
            }
        } else {
            //TODO remove need for
            if (activeStep == 0) {
                actions.push(Object.assign(actionMaker(), {
                    label: backButtonLabel,
                    disabled: true,
                    clickFunction: this.handlePrevious
                }))
            } else {
                actions.push(Object.assign(actionMaker(), {
                    label: backButtonLabel,
                    disabled: !isBackStepperButtonsActive,
                    clickFunction: this.handlePrevious
                }))
            }

            if ((activeStep < steps.length - 1)) {
                actions.push(Object.assign(actionMaker(), {
                    label: nextButtonLabel,
                    disabled: !isNextStepperButtonsActive,
                    clickFunction: this.handleNext,
                    variant: 'action'
                }))
            }
        }
        return actions;
    }

    renderDefaultAppbar() {
        const {
            steps,
            activeStep,
        } = this.props;
        return (
        <ContainerX AsContainer>
            <ActionsBar
                actions={this.makeActions(activeStep, steps)}
            />
        </ContainerX>
        )
    }

    renderDockedAppbar(){
        const {
            steps,
            activeStep,
        } = this.props;
        return (
            <StyledBottomDockedAppbar>
                <ActionsBar
                    actions={this.makeActions(activeStep, steps)}
                />
            </StyledBottomDockedAppbar>
        )
    }

    render() {
        const {
            classes,
            getStepContent,
            steps,
            context,
            activeStep,
            elideSteps = false,
            dockedActionBar = false,
        } = this.props;

        const classNamesStepper = classnames(
            classes.stepCustom,
            classes.stepperHeader
        );

        //console.log('Action Stepper active step props: ', activeStep)
        return (
            <>
                {!elideSteps && (
                    <ContainerX AsContainer>
                        <Stepper
                            activeStep={activeStep}
                            className={classNamesStepper}
                            connector={null}
                        >
                            {steps.map((label, index) => {
                                const props = {};
                                const labelProps = {};
                                if (this.isStepOptional(index)) {
                                    labelProps.optional = (
                                        <Typography variant="caption">Optional</Typography>
                                    )
                                }
                                const className = classnames(
                                    classes.stepLabelCustom, {
                                        [classes.stepLabelCustomActive]: index === activeStep,
                                        [classes.stepLabelCustomCompleted]: index <= activeStep,
                                    }
                                )
                                return (
                                    <Step
                                        key={label}
                                        {...props}
                                        className={className}
                                    >
                                        <StepLabel
                                            {...labelProps}
                                            classes={{
                                                active: classes.stepLabelCustomActive,
                                                completed: classes.stepLabelCustomCompleted,
                                                labelContainer: index !== activeStep && classes.stepLabelContainer
                                            }}
                                        >
                                            {label}
                                        </StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </ContainerX>
                )}


                {activeStep === steps.length ? (
                    <Grid container>
                        <Grid item sm style={{flexGrow: '2'}}>
                            <Typography className={classes.instructions}>
                                All steps completed - you're done
                            </Typography>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {getStepContent(activeStep, context)}

                        {dockedActionBar ? this.renderDockedAppbar() : this.renderDefaultAppbar() }

                    </>
                )}
            </>
        )
    }
}

export default withStyles(actionStepperStyles)(ActionStepper)