import React from 'react';
import { withStyles } from '@mui/styles';
import SvgIcon from '@mui/material/SvgIcon';
// svg optimized by SVGO https://jakearchibald.github.io/svgomg/

const rootStyles = (theme => ({
	root: {
		marginRight: theme.spacing(1),
		fontSize: '1.1em',
	}
}));
const ActiveProductionModelsIcon = (props) => {
    const {fillColor, classes, ...other} = props;
    return (
        <SvgIcon {...other} className={classes.root}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 30">
            	<path fill="#212C4F" d="M18.7433 2.41133C18.7173 1.07767 17.6257 0 16.286 0H7.28267C5.94233 0 4.85133 1.07767 4.82533 2.41133H3.746C1.68033 2.41133 0 4.092 0 6.15767V26.2537C-.00033 28.3193 1.68033 30 3.746 30h16.0767c2.0656 0 3.746-1.6807 3.746-3.746V6.158c0-2.06567-1.6804-3.74633-3.746-3.74633h-1.0794v-.00034zM5.88434 2.46c0-.771.62733-1.39867 1.39833-1.39867H16.286c.771 0 1.3983.62767 1.3983 1.39867v.96467c0 .771-.6273 1.39866-1.3983 1.39866H7.28267c-.771 0-1.39833-.62766-1.39833-1.39866V2.46zM22.5077 6.158v20.096c0 1.4803-1.204 2.685-2.685 2.685H3.746c-1.48033 0-2.685-1.2043-2.685-2.685V6.158c0-1.48033 1.20433-2.685 2.685-2.685h1.07933c.026 1.33367 1.11734 2.41133 2.45734 2.41133H16.286c1.34 0 2.4313-1.07766 2.4573-2.41133h1.0794c1.481 0 2.685 1.20433 2.685 2.685z"/>
            	<rect width="16" height="1.33333" x="3.78467" y="9.66693" fill="#212C4F" rx=".66667"/>
            	<rect width="16" height="1.33333" x="3.78467" y="15.6667" fill="#212C4F" rx=".66667"/>
            	<rect width="16" height="1.33333" x="3.78467" y="21.6666" fill="#212C4F" rx=".66667"/>
            	<path fill="#fff" stroke="#212C4F" strokeWidth="1.5" d="M33.5355 14.1429c0 5.5032-4.4612 9.9643-9.9643 9.9643s-9.9643-4.4611-9.9643-9.9643c0-5.50309 4.4612-9.96425 9.9643-9.96425s9.9643 4.46116 9.9643 9.96425z"/><path fill="#212C4F" d="M23.5747 13.6329c-.5201 0-1.0285-.1543-1.4609-.4432a2.62867 2.62867 0 01-.9685-1.1801 2.6295 2.6295 0 01-.1497-1.5193 2.62959 2.62959 0 012.0661-2.06605 2.62964 2.62964 0 011.5193.14963 2.63037 2.63037 0 011.1801.9685c.2889.43243.4431.94082.4431 1.46092a2.63252 2.63252 0 01-.7712 1.8583 2.63304 2.63304 0 01-1.8583.7713zm0-4.47594c-.3651 0-.722.10825-1.0255.31106a1.84582 1.84582 0 00-.785 1.89478c.0712.3581.2469.687.5051.9452.2581.2582.5869.434.945.5053a1.847 1.847 0 001.0665-.1049c.3373-.1396.6256-.3762.8285-.6797.2029-.3035.3112-.6603.3113-1.0254a1.84815 1.84815 0 00-.5415-1.30425 1.84781 1.84781 0 00-1.3044-.54105v-.00104zM27.7703 19.0878c0-2.3045-1.8746-4.1792-4.1789-4.1792-2.3044 0-4.179 1.8747-4.179 4.1792h-.7862c.0001-.9821.2913-1.942.8369-2.7585a4.9649 4.9649 0 012.2282-1.8287 4.9649 4.9649 0 012.8687-.2826 4.96596 4.96596 0 012.5422 1.3588 4.93305 4.93305 0 011.4543 3.511h-.7862z"/>
            </svg>
        </SvgIcon>
    )
};

ActiveProductionModelsIcon.displayName = 'ActiveProductionModelsIcon';
ActiveProductionModelsIcon.muiName = 'ActiveProductionModelsIcon';

export default withStyles(rootStyles)(ActiveProductionModelsIcon);