import React from 'react';

import {setMenuBarDrawerConfiguration} from '@dc/dcx/app/actions/appbar-actions';

export const makeUtilityListFormName = (raw) => {
    return raw.replace(/\:/g, '-').replace(/\_/g, '-')
}

const makeFieldArrayData = (parent, items) => {
    let itemsArray = [];
    items.forEach(item => {
        let itemObject = {label: item.label, value: `${item.name}-${item.itemvalue}`};
        if (parent['with-links'] && item['resource-urn']) {
            itemObject.url = `/page/resource/${item['resource-urn']}`;
        }
        itemsArray.push(itemObject);
    });
    return itemsArray;
}

export const extractInitialValues = (channelPageConfiguration) => {
    let initialValues = {};
    if (channelPageConfiguration) {
        channelPageConfiguration.forEach(item => {
            if (!item[0]) {
                return;
            }
            if (item[0].family) {
                item[0].items.forEach(item2 => {
                    initialValues[`${item2.name}-${item2.itemvalue}`] = {picked: item2.checked};
                });
            }
        });
    }
    if (Object.keys(initialValues).length > 0) {
        //debugger;
    }
    return initialValues;
}

export const extractUtilityListData = (channelPageConfiguration) => {
    let itemsArray = [{
        heading: 'please',
        fieldType: 'searchInput',
        label: "Search for term",
    }]
    if (channelPageConfiguration) {
        channelPageConfiguration.forEach(item => {
            if (0 < item.length) {
                item.forEach(item1 => {
                    if (item1.items && 0 < item1.items.length && !item1.elide) {
                        itemsArray.push({
                            label: item1.label,
                            collapseOpen: item1.open || false,
                            key: item1.family,
                            fields: makeFieldArrayData(item, item1.items)
                        })
                    }
                })
            }
        })
    }
    return itemsArray;
}

export const makeLifecycleSearchActionObject = (channelPageConfiguration, pageContext, channel, actionObject={}) => {
    return {
        searchType: '$$lifecycle',
        channelPageConfiguration: channelPageConfiguration,
        pageContext: pageContext,
        channel: channel,
        ...actionObject
    }
}

export const registerEmptySidebar = (dispatch) => {
    dispatch(setMenuBarDrawerConfiguration({
        listType: '$$utility',
        listData: []
    }))
}

export const registerSidebar = (dispatch, props) => {
    const {
        channelPageConfiguration,
        pageContext,
        channel,
        userContext,
        thisResource,
        searchType,
        tabConfiguration,
        which,
        queryObject,
        initialSearchObject,
    } = props;

    let initialValues = extractInitialValues(channelPageConfiguration || []);
    let utilityListData = extractUtilityListData(channelPageConfiguration || []);
    let utilityListFormName = makeUtilityListFormName(`filtersForm-${channel}`);

    dispatch(setMenuBarDrawerConfiguration({
        actionObject: {
            channel,
            pageContext,
            userContext,
            thisResource,
            channelPageConfiguration,
            searchType,
            tabConfiguration,
            which,
            queryObject,
            initialSearchObject
        },
        listType: '$$utility',
        listData: utilityListData,
        initialValues,
        utilityListFormName
    }))
}