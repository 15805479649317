/**
 * @fileoverview gRPC-Web generated client stub for growcloud.v1.service
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.9
// source: growcloud/v1/service/GrowcloudV1Service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var growcloud_v1_domain_ActiveSystems_pb = require('../../../growcloud/v1/domain/ActiveSystems_pb.js')

var growcloud_v1_domain_ProductionSystems_pb = require('../../../growcloud/v1/domain/ProductionSystems_pb.js')

var growcloud_v1_identity_Gcn_pb = require('../../../growcloud/v1/identity/Gcn_pb.js')

var growcloud_v1_domain_Metadata_pb = require('../../../growcloud/v1/domain/Metadata_pb.js')

var growcloud_v1_service_StartYourGrow_pb = require('../../../growcloud/v1/service/StartYourGrow_pb.js')
const proto = {};
proto.growcloud = {};
proto.growcloud.v1 = {};
proto.growcloud.v1.service = require('./GrowcloudV1Service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.growcloud.v1.service.GrowcloudV1ServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.growcloud.v1.service.GrowcloudV1ServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.growcloud.v1.service.ActiveProductionModelsRequest,
 *   !proto.growcloud.v1.domain.ActiveProductionModel>}
 */
const methodDescriptor_GrowcloudV1Service_activeProductionModels = new grpc.web.MethodDescriptor(
  '/growcloud.v1.service.GrowcloudV1Service/activeProductionModels',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.growcloud.v1.service.ActiveProductionModelsRequest,
  growcloud_v1_domain_ActiveSystems_pb.ActiveProductionModel,
  /**
   * @param {!proto.growcloud.v1.service.ActiveProductionModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  growcloud_v1_domain_ActiveSystems_pb.ActiveProductionModel.deserializeBinary
);


/**
 * @param {!proto.growcloud.v1.service.ActiveProductionModelsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.domain.ActiveProductionModel>}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.GrowcloudV1ServiceClient.prototype.activeProductionModels =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/growcloud.v1.service.GrowcloudV1Service/activeProductionModels',
      request,
      metadata || {},
      methodDescriptor_GrowcloudV1Service_activeProductionModels);
};


/**
 * @param {!proto.growcloud.v1.service.ActiveProductionModelsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.domain.ActiveProductionModel>}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.GrowcloudV1ServicePromiseClient.prototype.activeProductionModels =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/growcloud.v1.service.GrowcloudV1Service/activeProductionModels',
      request,
      metadata || {},
      methodDescriptor_GrowcloudV1Service_activeProductionModels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.growcloud.v1.service.CreateActiveProductionModelRequest,
 *   !proto.growcloud.v1.domain.CreatedResponse>}
 */
const methodDescriptor_GrowcloudV1Service_createActiveProductionModel = new grpc.web.MethodDescriptor(
  '/growcloud.v1.service.GrowcloudV1Service/createActiveProductionModel',
  grpc.web.MethodType.UNARY,
  proto.growcloud.v1.service.CreateActiveProductionModelRequest,
  growcloud_v1_domain_Metadata_pb.CreatedResponse,
  /**
   * @param {!proto.growcloud.v1.service.CreateActiveProductionModelRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  growcloud_v1_domain_Metadata_pb.CreatedResponse.deserializeBinary
);


/**
 * @param {!proto.growcloud.v1.service.CreateActiveProductionModelRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.growcloud.v1.domain.CreatedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.domain.CreatedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.GrowcloudV1ServiceClient.prototype.createActiveProductionModel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/growcloud.v1.service.GrowcloudV1Service/createActiveProductionModel',
      request,
      metadata || {},
      methodDescriptor_GrowcloudV1Service_createActiveProductionModel,
      callback);
};


/**
 * @param {!proto.growcloud.v1.service.CreateActiveProductionModelRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.growcloud.v1.domain.CreatedResponse>}
 *     Promise that resolves to the response
 */
proto.growcloud.v1.service.GrowcloudV1ServicePromiseClient.prototype.createActiveProductionModel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/growcloud.v1.service.GrowcloudV1Service/createActiveProductionModel',
      request,
      metadata || {},
      methodDescriptor_GrowcloudV1Service_createActiveProductionModel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.growcloud.v1.service.SitesConfigurationsModelsRequest,
 *   !proto.growcloud.v1.domain.SitesConfigurationsModel>}
 */
const methodDescriptor_GrowcloudV1Service_sitesConfigurationsModels = new grpc.web.MethodDescriptor(
  '/growcloud.v1.service.GrowcloudV1Service/sitesConfigurationsModels',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.growcloud.v1.service.SitesConfigurationsModelsRequest,
  growcloud_v1_domain_ActiveSystems_pb.SitesConfigurationsModel,
  /**
   * @param {!proto.growcloud.v1.service.SitesConfigurationsModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  growcloud_v1_domain_ActiveSystems_pb.SitesConfigurationsModel.deserializeBinary
);


/**
 * @param {!proto.growcloud.v1.service.SitesConfigurationsModelsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.domain.SitesConfigurationsModel>}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.GrowcloudV1ServiceClient.prototype.sitesConfigurationsModels =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/growcloud.v1.service.GrowcloudV1Service/sitesConfigurationsModels',
      request,
      metadata || {},
      methodDescriptor_GrowcloudV1Service_sitesConfigurationsModels);
};


/**
 * @param {!proto.growcloud.v1.service.SitesConfigurationsModelsRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.domain.SitesConfigurationsModel>}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.GrowcloudV1ServicePromiseClient.prototype.sitesConfigurationsModels =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/growcloud.v1.service.GrowcloudV1Service/sitesConfigurationsModels',
      request,
      metadata || {},
      methodDescriptor_GrowcloudV1Service_sitesConfigurationsModels);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.growcloud.v1.service.StartYourGrowRequest,
 *   !proto.growcloud.v1.domain.CreatedResponse>}
 */
const methodDescriptor_GrowcloudV1Service_startYourGrow = new grpc.web.MethodDescriptor(
  '/growcloud.v1.service.GrowcloudV1Service/startYourGrow',
  grpc.web.MethodType.UNARY,
  growcloud_v1_service_StartYourGrow_pb.StartYourGrowRequest,
  growcloud_v1_domain_Metadata_pb.CreatedResponse,
  /**
   * @param {!proto.growcloud.v1.service.StartYourGrowRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  growcloud_v1_domain_Metadata_pb.CreatedResponse.deserializeBinary
);


/**
 * @param {!proto.growcloud.v1.service.StartYourGrowRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.growcloud.v1.domain.CreatedResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.domain.CreatedResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.GrowcloudV1ServiceClient.prototype.startYourGrow =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/growcloud.v1.service.GrowcloudV1Service/startYourGrow',
      request,
      metadata || {},
      methodDescriptor_GrowcloudV1Service_startYourGrow,
      callback);
};


/**
 * @param {!proto.growcloud.v1.service.StartYourGrowRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.growcloud.v1.domain.CreatedResponse>}
 *     Promise that resolves to the response
 */
proto.growcloud.v1.service.GrowcloudV1ServicePromiseClient.prototype.startYourGrow =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/growcloud.v1.service.GrowcloudV1Service/startYourGrow',
      request,
      metadata || {},
      methodDescriptor_GrowcloudV1Service_startYourGrow);
};


module.exports = proto.growcloud.v1.service;

