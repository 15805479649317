import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseButton from '@dc/dc/components/buttons/CloseButton';

import {toggleMenuDrawer} from '../../actions/appbar-actions';
import {connect} from 'react-redux';

import {drawerListStyleSheet} from '@dc/dc/style/BaseStyles';

import MenuDrawerList from './MenuDrawerList';

import NestedList from '@dc/dc/components/lists/NestedList';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {injectIntl} from 'react-intl';

const makeNestedList = (formatMessage, menuItems) => {

    let orderedMap = new Map();
    const nestedListArray = [];

    menuItems.forEach(menuItem => {
        if (menuItem.group) {
            let items = orderedMap.get(menuItem.group);
            if (!items) {
                items = [];
                orderedMap.set(menuItem.group, items);
            }
            items.push(menuItem);
        } else {
            //debugger;
        }
    });

    for (let [groupLabel, menuItems] of orderedMap) {
        let items = [];
        menuItems.forEach(menuItem => {
            let label = menuItem.message; //formatMessage(menuItem.message);
            let href = menuItem.to;
            items.push({label, href})
        });
        nestedListArray.push({label: groupLabel, items})
    }

    //TODO this is a hack - menuItems is coming through in two different formats
    if(0 == nestedListArray.length){
        return menuItems;
    }

    return nestedListArray;
}

class MenuDrawer extends React.Component {

    render() {
        const {
            classes,
            menuDrawerOpen,
            onToggleMenuDrawer,
            masthead,
            menuItems,
            nestedList,
            menuTabs,
            userActions,
            intl
        } = this.props;

        console.log("menuItems", menuItems)

        const listData = makeNestedList(intl.formatMessage, menuItems);

        return (
            <>
                <IconButton
                    title='App navigation menu icon'
                    onClick={() => onToggleMenuDrawer()}
                    className={classes.buttonIconWrap}
                >
                    <MenuIcon/>
                </IconButton>
                <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    open={menuDrawerOpen}
                    //onChange={() => onToggleMenuDrawer()}
                    onClick={() => onToggleMenuDrawer()}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent={masthead ? 'space-around' : 'flex-end'}
                        alignItems="center"
                        spacing={2}
                    >
                        {masthead && (
                            <Grid item>
                                {masthead}
                            </Grid>
                        )}
                        <Grid item>
                            <CloseButton
                                positionRelative
                                menuDrawerCloseButton
                                onHandleClose={(e) => {
                                    e.stopPropagation();
                                    onToggleMenuDrawer()
                                }}
                            />
                        </Grid>
                    </Grid>
                    {menuTabs && (
                        <Grid container item>
                            {menuTabs()}
                        </Grid>
                    )}
                    {nestedList ? (
                        <>
                            <NestedList
                                selectedBackgroundColor
                                withDrawerTitle
                                elideCollapsing
                                noIndentList
                                listData={listData}
                                excludeDropdownArrows={false}
                                ArrowUp={KeyboardArrowUpIcon}
                                ArrowDown={KeyboardArrowDownIcon}
                            />
                        </>
                    ) : (
                        <MenuDrawerList menuItems={menuItems}/>
                    )}
                    {userActions && userActions()}

                </Drawer>
            </>
        )
    }
}

MenuDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    onToggleMenuDrawer: PropTypes.func.isRequired,
    menuItems: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
    return {
        menuDrawerOpen: state.appbar.menuDrawer.open
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onToggleMenuDrawer: () => {
            dispatch(toggleMenuDrawer());
        },
    };
};

export default injectIntl(withStyles(drawerListStyleSheet)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MenuDrawer)))