import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Text from '@dc/dc/content/Text';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import Worksheet, {WorksheetNode} from '@dc/dc/components/surface/Worksheet';
import AssetStatesGrid from 'gc-webapp/gc/AssetStatesGrid'
import CalculationsGrid from 'gc-webapp/gc/CalculationsGrid'
import EventCountersGrid from 'gc-webapp/gc/EventCountersGrid'
import Loading from '@dc/dcx/app/common/Loading';
import NavigationDrawer from 'gc-ui-components/ui/navigation/NavigationDrawer';
import { a11yProps, CustomTabPanel } from './TabsCommons'

const Assets = ({userContext}) => {

 const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <>
        <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="ASSIGNMENTS" {...a11yProps(0)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                    ASSIGNMENTS
              </CustomTabPanel>
            </Box>

        </>
    )
}
export default connect(state => { })(Assets)