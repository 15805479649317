import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import classnames from 'classnames';

import {Link as RouterLink} from 'react-router-dom';
import Link from '@mui/material/Link';

import InternalLink from '../links/InternalLink';

const styles = theme => ({
    root: {
        borderColor: theme.palette.primary.main,
        lineHeight: '1em',
        padding: theme.spacing(1.25, 3),
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark,
        },
    },
    smallButton: {
        minWidth: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        margin: 0,
    },
    dense: {
		padding: theme.spacing(1.25, 2.75),
	},
    publicButton: {
        lineHeight: '1em',
        padding: theme.spacing(1.625, 4),
    },
    withMargin: {
        margin: '1em',
    }
});

const SecondaryButton = (props) => {
    const {
        classes,
        buttonText,
        buttonColor,
        linkType,
        toLocation,
        fullWidth,
        ActionBtnLight,
        onClick,
        smallButton,
        publicButton,
        withMargin,
        dense,
        ...rest
    } = props;

    const className = classnames(
        classes.root,
//    ActionBtnLight && classes.ActionBtnLight,
		dense && classes.dense,
        smallButton && classes.smallButton,
        publicButton && classes.publicButton,
        withMargin && classes.withMargin
    );

    if (linkType === 'Link') {
        return (
            <Button
                component={InternalLink}
                fullWidth={fullWidth}
                variant='outlined'
                color="primary"
                className={className}
                to={toLocation ? toLocation : null}
                disableRipple
                disableFocusRipple
                {...rest}
                onClick={(e)=> {
                    e.stopPropagation();
                }}
            >
                {buttonText}
            </Button>
        )
    }

    return (
        <Button
            variant='outlined'
            color="primary"
            disableRipple
            disableFocusRipple
            className={className}
            onClick={onClick}
            {...rest}
        >
            {buttonText}
        </Button>
    )
}

SecondaryButton.defaultProps = {
    color: 'primary',
    fullWidth: false,
    disableRipple: true,
    variant: 'outlined'
}

SecondaryButton.propTypes = {
    classes: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
}

export default withStyles(styles)(SecondaryButton)