import React from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import {makeSurfaceStyleSheet} from "../../style/SurfaceStyles";
import {makeClassNames} from "../../style/StylesUtils";

const useStyles = makeStyles(theme => {
    let thisStyles = {
        customMaxWidth: props => ({
            maxWidth: props.containerWidth,
        }),
        asContainer: {
            position: 'relative',
            width: '100%',
            maxWidth: '1180px',
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    }
    const styleSheet = makeSurfaceStyleSheet(theme, thisStyles)
    return styleSheet;
})

export default function ContainerX(props) {
    const {
        disableGutters = false,
        fixed = false,
        maxWidth = false,
        containerWidth=false,
        AsContainer=false,
        variant='medium',
        displayVariant='none',
        mode='paper',
        oversizeContainerX=null,
        children,
        ...rest
    } = props

    const thisProps = { containerWidth: props.containerWidth}
    const classes = useStyles(thisProps)

    const classNameObj = makeClassNames(classes, {variant, displayVariant}, rest, {
        [classes.customMaxWidth]: containerWidth,
        [classes.asContainer]: AsContainer,
    }, {})

    //TODO - this is a hack: Container somehow inherits backgroundColor=paper, which is not what we want
    // once all such overfittings are removed, we can possibly use Container - or, renamed this to DivX (or similar)
    if('block' == mode || !children){
        return (
            <div
                className={classNameObj.classNames}
                {...classNameObj.props}
            />
        )
    }
    return (
        <Container
            component="div"
            maxWidth={maxWidth}
            disableGutters={disableGutters}
            className={classNameObj.classNames}
            {...classNameObj.props}
        >
            {children}
        </Container>
    )
}

ContainerX.propTypes = {
    // ----------------------------- Warning --------------------------------
    // | These PropTypes are generated from the TypeScript type definitions |
    // |     To update them edit the d.ts file and run "yarn proptypes"     |
    // ----------------------------------------------------------------------
    /**
     * @ignore
     */
    children: PropTypes /* @typescript-to-proptypes-ignore */.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    // component: PropTypes /* @typescript-to-proptypes-ignore */.elementType,
    /**
     * If `true`, the left and right padding is removed.
     */
    disableGutters: PropTypes.bool,
    /**
     * Set the max-width to match the min-width of the current breakpoint.
     * This is useful if you'd prefer to design for a fixed set of sizes
     * instead of trying to accommodate a fully fluid viewport.
     * It's fluid by default.
     */
    fixed: PropTypes.bool,
    /**
     * Determine the max-width of the container.
     * The container width grows with the size of the screen.
     * Set to `false` to disable `maxWidth`.
     */
    maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false]),
    /**
     * Determine the max-width of the container.
     * The container width grows with the size of the screen.
     * Set to `false` to disable `maxWidth`.
     */
    customMaxWidth: PropTypes.number,

    /**
     * The variant to use.
     */
    variant: PropTypes.oneOf(['small', 'medium', 'large', 'dense', 'mobileOnly', 'fitted', 'minimal']),
    /**
     * If `true`, top padding is removed.
     */
    removeTopPadding: PropTypes.bool,
    /**
     * If `true`, bottom padding is removed.
     */
    removeBottomPadding: PropTypes.bool,
};