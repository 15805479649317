import React from 'react';

import PropTypes from 'prop-types';
import {formValueSelector} from '@dc/dcx/shim/ReduxFormShims'

import { withStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

import TextField from '@dc/dcx/form/Field/TextField';
import SubmitButton from '@dc/dc/components/buttons/SubmitButton';

import {connect} from 'react-redux';

import DcxFormField from '@dc/dcx/form/DcxFormField';
import DcxForm, {AUTOCOMPLETE, READONLY, VARIANT} from '@dc/dcx/form/DcxForm';

import {
	hasEmailAddressValidationError,
	hasPasswordValidationError,
	validateFormValues
} from '@dc/dcx/form/utils/ValidationUtils';

import LoadingProgress from '@dc/dc/components/modules/LoadingProgress';

const validateSignInFields = values => {
	const errors = validateFormValues(values, (key, value) => {
		let errorMessage = null;
		switch (key) {
			case 'mbox':
				if (!value) {
					errorMessage = 'Required';
				} else {
					errorMessage = hasEmailAddressValidationError(value);
				}
				break;
			default:
				break;
		}
		return errorMessage;
	})

	return errors
}

const styleSheet = theme => ({
	footer: {
		paddingTop: 16,
	},
})

class GrowcloudSignInForm extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			invalidForm: true
		}
	}

	canSubmitForm() {
		const {username} = this.props;
		//console.log('username', username);
		if (username) {
			this.setState({invalidForm: false})
			//	todo add validation check on fields then setState
		} else {
			this.setState({invalidForm: true})
		}
	}

	render() {
		const {
			classes,
			onSubmit,
			signInMessage,
			signinDomain,
			isActive,
		} = this.props;
		const {invalidForm} = this.state;
		//console.log('validate errors: ', validateSignInFields)
		const onChange = this.canSubmitForm.bind(this);
		return (
			<DcxForm
				noValidate
				onSubmit={onSubmit}
				form={'signIn'}
				validate={validateSignInFields}
				initialValues={{signinDomain}}
				commands={[
					{type: VARIANT, value: 'filled'},
					{type: AUTOCOMPLETE, value: false},
					{type: READONLY, value: isActive}
				]}
			>


				{signInMessage && (
					<Grid item xs={12}>
						{signInMessage}
					</Grid>
				)}

				{isActive && (
					<Grid item xs={12}>
						<LoadingProgress/>
					</Grid>
				)}

				<Grid item xs={12}>
					<DcxFormField
						component={TextField}
						label='Mailbox'
						name='mbox'
						required
						onChange={onChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<Grid container justifyContent='space-between' alignItems='center' className={classes.footer}>
						<Grid item xs={12}>
							<SubmitButton
								buttonText={'CONNECT'}
								fullWidth
								disabled={invalidForm || isActive}
							/>
						</Grid>
					</Grid>
				</Grid>
			</DcxForm>
		)
	}
}

const selector = formValueSelector('signIn');

const mapStateToProps = (state) => {
	const username = selector(state, 'mbox');
	const password = selector(state, 'password');
	return {username, password,}
}

GrowcloudSignInForm.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styleSheet)(connect(mapStateToProps)(GrowcloudSignInForm))
