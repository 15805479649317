import {AUTH_TOKEN_FAIL, getCurrentUser,} from './../actions/authentication-actions'

import {setToken} from './../token-utils';

export const getCurrentUserIfRequired = (action, dispatched, store) => {
    const state = store.getState();
    const user =  state.auth.user;
    debugger;
    if(!user || !user.id) {
        store.dispatch(getCurrentUser());
    }
}

export const httpErrorResponseHandler = (error, errorObject) => {
     if(error.response){
        switch(error.response.status){
            case 401:
            case 403:
                setToken(null);
                debugger;
                console.error(`RESPONSE error ${error.response.status}: forwarding error to ${AUTH_TOKEN_FAIL}`, error.response);
                return { type: AUTH_TOKEN_FAIL }
        }
        errorObject.response = error.response;
        console.error(`RESPONSE error ${error.response.status}: forwarding error to ${errorObject.type}`, error.response);
        return errorObject;
     }
     console.error(`UNKNOWN error: forwarding error to ${errorObject.type}`, error);
     return errorObject;
}