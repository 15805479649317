import React from 'react';
import PropTypes from 'prop-types';

import MessageDisplay from './MessageDisplay';
import {dcxMessages} from './../i18n/BaseAppMessages';

const Error = ({children, message, withLargeTopMargin = false}) => {
    return (
        <MessageDisplay
            message={message}
            typeVariant={'error'}
            withLargeTopMargin={withLargeTopMargin}
        >
            {children}
        </MessageDisplay>
    )
}

Error.defaultProps = {
    message: dcxMessages.defaultErrorMessage,
}
Error.propTypes = {
    message: PropTypes.object,
}
export default (Error);