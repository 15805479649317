import React from 'react';
import Grid from '@mui/material/Grid';
import Text from '@dc/dc/content/Text';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import InternalLink from '@dc/dc/components/links/InternalLink';
import TableCell from '@mui/material/TableCell';

import ContentBlock from '@dc/dc/components/surface/ContentBlock';

import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { withStyles } from '@mui/styles';
import {richSubHeaderListStyleSheet} from '@dc/dc/style/BaseStyles';

import {connect} from 'react-redux';
                    
const GROWCLOUD_CURRENT_READINGS_GRID_HEADERS = [
    'SITE',
    'ENCLOSURE',
    'ASSET',
    'SUBJECT',
    'MEASUREMENT',
    'VALUE',
    'TIMESTAMP',
    'OPTIONS'
];

const GROWCLOUD_CALCULATIONS_GRID_HEADERS = [
    'SITE',
    'ENCLOSURE',
    'CALCULATION',
    'INPUTS',
    'VALUE',
    'TIMESTAMP',
    'OPTIONS'
];

const GROWCLOUD_EVENT_COUNTERS_GRID_HEADERS = [
    'SITE',
    'ENCLOSURE',
    'ASSET',
    'EVENT',
    'EVENT_COUNT_EXPECTED',
    'EVENT_COUNT_ACTUAL',
    'UNEXPECTED_EVENT_COUNT_ACTUAL',
    'TIMESTAMP',
    'OPTIONS'
];

const GROWCLOUD_ASSET_STATES_GRID_HEADERS = [
    'SITE',
    'ENCLOSURE',
    'PROGRAM',
    'SCHEDULE',
    'ASSET',
    'SUBJECT',
    'FROM',
    'TO',
    'SCHEDULED_FOR',
    'TIMESTAMP',
    'OPTIONS'
];

export const currentReadingsGridCellComponentProvider = ({column, row, dispatch}) => {

    if ('solution' == column.name) {
        return (
            <TableCell>
                {row.resource.label}
            </TableCell>
        )
    }
    return (
        <TableCell>{column.name}</TableCell>
    )
};



export const SolutionDetails = ({item}) => {
    return (
        <ContentBlock elevation={0} style={{marginTop: '3rem'}}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Text
                        variant="body2"
                        gutterBottom
                        style={{fontWeight: '700'}}
                    >
                        Solution details
                    </Text>
                </Grid>
            </Grid>
        </ContentBlock>
    )
};

export const currentReadingsGridExpandedRowComponentProvider = ({row}) => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item xs={12}>
                       haro
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const makeGridName = (header) => {
    return `${header.replace(/ /g, '-').toLowerCase()}`
};

export const makeCurrentReadingsGridConfiguration = () => {

    let columns = [];
    let columnOrder = [];
    let columnWidths = [];

    GROWCLOUD_CURRENT_READINGS_GRID_HEADERS.forEach(header => {
        const headerName = makeGridName(header);
        let obj = {
            field: header,
            headerName: header
        };

        if('TIMESTAMP' == header){
            obj.valueFormatter = params => {
               return new Date().toUTCString()
            }
        }
        columns.push(obj);

        columnOrder.push(name)
        columnWidths.push({
            columnName: name,
            width: 200
        })
    });

    let gridConfiguration = {columns, columnOrder, columnWidths}
    return gridConfiguration;
};

export const makeEventCountersGridConfiguration = () => {

    let columns = [];
    let columnOrder = [];
    let columnWidths = [];

    GROWCLOUD_EVENT_COUNTERS_GRID_HEADERS.forEach(header => {
        const headerName = makeGridName(header);
        columns.push({
            field: header,
            headerName: header,
        });
        columnOrder.push(name)
        columnWidths.push({
            columnName: name,
            width: 200
        })
    });

    let gridConfiguration = {columns, columnOrder, columnWidths}
    return gridConfiguration;
};

export const makeCalculationsGridConfiguration = () => {

    let columns = [];
    let columnOrder = [];
    let columnWidths = [];

    GROWCLOUD_CALCULATIONS_GRID_HEADERS.forEach(header => {
        const headerName = makeGridName(header);
        columns.push({
            field: header,
            headerName: header,
        });
        columnOrder.push(name)
        columnWidths.push({
            columnName: name,
            width: 200
        })
    });

    let gridConfiguration = {columns, columnOrder, columnWidths}
    return gridConfiguration;
};

export const makeAssetStatesGridConfiguration = () => {

    let columns = [];
    let columnOrder = [];
    let columnWidths = [];

    GROWCLOUD_ASSET_STATES_GRID_HEADERS.forEach(header => {
        const headerName = makeGridName(header);
        columns.push({
            field: header,
            headerName: header,
        });
        columnOrder.push(name)
        columnWidths.push({
            columnName: name,
            width: 200
        })
    });

    let gridConfiguration = {columns, columnOrder, columnWidths}
    return gridConfiguration;
};

export const makeProgramsGridConfiguration = (headers) => {

    let columns = [];
    let columnOrder = [];
    let columnWidths = [];

    headers.forEach(header => {
        const headerName = makeGridName(header);
        columns.push({
            field: header,
            headerName: header,
        });
        columnOrder.push(name)
        columnWidths.push({
            columnName: name,
            width: 60
        })
    });

    let gridConfiguration = {columns, columnOrder, columnWidths}
    return gridConfiguration;
};

export const makeSchedulesGridConfiguration = (headers) => {

    let columns = [];
    let columnOrder = [];
    let columnWidths = [];

    headers.forEach(header => {
        const headerName = makeGridName(header);
        columns.push({
            field: header,
            headerName: header,
        });
        columnOrder.push(name)
        columnWidths.push({
            columnName: name,
            width: 60
        })
    });

    let gridConfiguration = {columns, columnOrder, columnWidths}
    return gridConfiguration;
};

export const makeCalendarsGridConfiguration = (headers) => {

    let columns = [];
    let columnOrder = [];
    let columnWidths = [];

    headers.forEach(header => {
        const headerName = makeGridName(header);
        columns.push({
            field: header,
            headerName: header,
        });
        columnOrder.push(name)
        columnWidths.push({
            columnName: name,
            width: 60
        })
    });

    let gridConfiguration = {columns, columnOrder, columnWidths}
    return gridConfiguration;
};

export const makeProductLinesGridConfiguration = (headers) => {

    let columns = [];
    let columnOrder = [];
    let columnWidths = [];
   
    headers.forEach(header => {
        const headerName = makeGridName(header);
        let obj = {
            field: header,
            headerName: header,
            width: 80
        }
        if('OPTIONS' == header){
            obj.renderCell = params => {
                return (
                  <ButtonGroup
                    id={'apm-button-group'}
                    aria-label="Production permit actions"
                    variant="text"
                    orientation="vertical"
                    size="small"
                  >
                    <Button size="small" key="add-production-line" value="Add production line"
                    onClick={(event) => {
                        //setCurrentView(event.currentTarget.value)
                    }}>Add production line</Button>
                    <Button size="small" key="add-production-run" value="Add production run"
                    onClick={(event) => {
                        //setCurrentView(event.currentTarget.value)
                    }}>Add production run</Button>
                </ButtonGroup>
                )
            }
        }
        columns.push(obj);
        columnOrder.push(name)
        columnWidths.push({
            columnName: name,
            width: 80
        })
    });

    let gridConfiguration = {columns, columnOrder, columnWidths}
    return gridConfiguration;
};


