import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { withStyles } from '@mui/styles';
import {FormattedMessage} from 'react-intl';

import CounterSVG from '@dc/dc/components/icon/CounterSVG';

import {Link as NavLink} from 'react-router-dom';

const styleSheet = theme => ({
    container: {
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        flex: '1 0 auto'
    },
    list: {
        width: 'auto',
        'list-style-type': 'none',
        padding: '0',
        display: 'inline-flex',
    },
    listItem: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: 0,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),

        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        }
    },
    toolbar: theme.mixins.toolbar,
    link: {
        position: 'relative',
        padding: theme.spacing(1),
        alignItems: 'center',
        textDecoration: 'none',
        textTransform: 'capitalize',
//	color: theme.palette.text.primary,
        color: theme.palette.text.secondary,

        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },

        '&:after': {
            content: '" "',
            position: 'absolute',
            top: 'auto',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '3px',
            background: theme.palette.text.hint,
            opacity: 0,
            transition: 'opacity 0.2s',
        },
        '&:hover': {
            textDecoration: 'none',
            color: theme.palette.text.primary,
        },
        '&.active': {
            textDecoration: 'none',
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightMedium,

            '&:hover': {
                color: theme.palette.text.primary
            },
//		'&:after': {
//			opacity: 1,
//			background: theme.palette.text.hint,
//			transition: 'opacity 0.3s',
//		}
        },
    },
    listItemText: {
        paddingLeft: 'inherit',
        fontWeight: 'inherit',
//	color: 'inherit',
//    '&:first-child': {
//        paddingLeft: 'inherit'
//    },
    },
    textStyle: {
        fontWeight: 'inherit',
        //	color: 'inherit',
//	'&:hover': {
//		fontWeight: theme.typography.fontWeightMedium,
//	},
    }
});

// todo refactor further to forward ListItem props to NavLink & remove Typography node
class MenuLinks extends React.Component {

    renderLinks() {
        const {primaryNavigationLinks, classes, user} = this.props;
        let itemsArray = [];
        let count = 0;
        primaryNavigationLinks.forEach(route => {
            if (user && route.public) {
                return;
            }
            if (route.message) {
                count++;
                itemsArray.push((
                    <li key={route.to}>
                        <ListItem
                            component={NavLink}
                            activeClassName="active"
                            to={route.to}
                            className={classnames(classes.link, classes.toolbar)}
                        >
                            <ListItemText
                                disableTypography
                                primary={(
                                    <>
                                        <Typography
                                            className={classes.textStyle}
                                            style={{color: 'inherit'}}
                                            variant="body2"
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                justifyContent={'flex-end'}
                                            >
                                                <FormattedMessage {...route.message}/>
                                                {route.counterBadge && (
                                                    <>
                                                        <Box flexGrow={1}></Box>
                                                        <Box style={{marginLeft: '0.3em'}}>
                                                            <CounterSVG
                                                                label={route.counterBadge.count}
                                                                color={route.counterBadge.color}
                                                            />
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>
                                        </Typography>
                                    </>
                                )}
                                classes={{root: classes.listItemText}}
                            />

                        </ListItem>
                    </li>
                ))
            } else {
               // debugger;
            }
        })
        return itemsArray;
    }

    render() {
        const {primaryNavigationLinks, classes} = this.props;
        return (
            <List className={classes.list} dense={primaryNavigationLinks.length > 3 && true}>
                {this.renderLinks()}
            </List>
        )
    }
}

class PrimaryNavigation extends React.Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    }

    render() {
        const {
            classes,
            primaryNavigationLinks,
            user,
        } = this.props;

        return (
            <nav className={classes.container}>
                <MenuLinks
                    classes={classes}
                    primaryNavigationLinks={primaryNavigationLinks}
                    user={user}
                />
            </nav>
        )
    }
}

export default withStyles(styleSheet)(PrimaryNavigation)
