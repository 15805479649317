import {defineMessages} from 'react-intl';

import {defaultI18nMessage} from '@dc/dc/i18n/MessageUtils';

export const commonWebApplicationMessage = (name, message) => {
    return defaultI18nMessage('commonWebApplication', name, message);
}

export const commonWebApplicationMessages = defineMessages({

    userProfileMenuEditProfileButtonLabel: commonWebApplicationMessage('userProfileMenuEditProfileButtonLabel', 'Edit profile'),
    userProfileMenuSignoutLinkLabel: commonWebApplicationMessage('userProfileMenuSignoutLinkLabel', 'Sign out'),

    routeUserProfileLabel: commonWebApplicationMessage('routeUserProfileLabel', 'Profile'),

    routeOrganisationDetailsLabel: commonWebApplicationMessage('routeOrganisationDetailsLabel', 'Details'),
    routePersonnelLabel: commonWebApplicationMessage('routePersonnelLabel', 'Personnel'),
    routePrincipalsLabel: commonWebApplicationMessage('routePrincipalsLabel', 'Principals'),
    routeProjectsLabel: commonWebApplicationMessage('routeProjectsLabel', 'Projects'),
    routeUserInfoLabel: commonWebApplicationMessage('routeUserInfoLabel', 'User Info'),
    routeUserPreferencesLabel: commonWebApplicationMessage('routeUserPreferencesLabel', 'Preferences'),
    routeUserProfilesLabel: commonWebApplicationMessage('routeUserProfilesLabel', 'Profiles'),

    routeUserNotificationsLabel: commonWebApplicationMessage('routeUserNotificationsLabel', 'Notifications')
})