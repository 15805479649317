import React from "react";
import PropTypes from "prop-types";

import ListItem from "@mui/material/ListItem";

import InternalLink from '../links/InternalLink';

import Link from '@mui/material/Link';

import {withRouter} from '../../shim/withRouter';


function RenderListItemLink(props) {
    const {primary, isSelected, to, className, icon, bolder} = props;

    return (
        <ListItem
            button
            disableRipple
            disableTouchRipple
            to={to}
            onClick={props.onClick}
            selected={isSelected}
            className={className}
        >
            {bolder ? (
                <strong>
                    {primary}
                </strong>
            ) : (
                <>
                    {primary}
                </>
            )}
        </ListItem>
    );
}

RenderListItemLink.propTypes = {
    primary: PropTypes.node.isRequired,
    onClick: PropTypes.func
};

export default RenderListItemLink;