import React from 'react';
import PropTypes from 'prop-types';

import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';

import { withStyles } from '@mui/styles';;

const styleSheet = theme => ({
    input: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    marginNormal: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    }
});

class TextFieldWrapper extends React.PureComponent {

    makeIconButton() {
        const {custom} = this.props;
        if (custom && custom.submitHandler) {
            return (
                <IconButton
                    disableRipple
                    aria-label="Search Term"
                    onClick={custom.submitHandler}
                >
                    <SearchIcon color="secondary"/>
                </IconButton>
            )
        }
        return (
            <IconButton
                disableRipple
                aria-label="Search Term"
                type="submit"
            >
                <SearchIcon color="secondary"/>
            </IconButton>
        )
    }

    render() {
        const {
            Input,
            meta,
            component: TextFieldComponent,
            classes,
            type,
            ...otherProps
        } = this.props;

        let {error, helperText, ...other} = otherProps;

        if (meta.touched) {
            error = typeof meta.error !== 'undefined';
            helperText = error ? meta.error : helperText;
        }
        console.log('classes passed to textFieldWrapper: ', classes);
        console.log('...other spread props to textFieldWrapper: ', other)
        return (
            <TextFieldComponent
                fullWidth
                type={type}
                error={error}
                helperText={helperText}
                disabled={other.readOnly}
                InputProps={{
                    startAdornment: type === 'search' && (
                        <InputAdornment position="start">{this.makeIconButton()}</InputAdornment>
                    ),
                    classes: {
                        input: type !== 'search' && classes.input,
//				root: type !== 'search' && classes.marginNormal,
                    },
                }}
                InputLabelProps={{
                    shrink: this.props.multiline,
                }}
                {...Input}
                {...other}
            />
        )
    }
}

TextFieldWrapper.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
};

export default withStyles(styleSheet)(TextFieldWrapper);
