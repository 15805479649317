export const reduceFormValues = (finalFormName, filtersFormValues) => {
    /*let reducedFiltersForm = {}
    Object.keys(filtersFormValues).forEach(key => {
        if(-1 < key.indexOf(finalFormName)) {
            let finalValue;
            if (Array.isArray(filtersFormValues[key])) {
                let newValuesData = [];
                filtersFormValues[key].forEach(entry => {
                    if(-1 < entry.value.indexOf(finalFormName)) {
                        const value = entry.value.replace(`${finalFormName}#`, '')
                        const newValueOption = Object.assign(entry,{value});
                        newValuesData.push(newValueOption)
                    } else {
                        newValuesData.push(entry)
                    }
                })
                finalValue = newValuesData;
            } else {
                finalValue = filtersFormValues[key];
            }
            reducedFiltersForm[key.replace(`${finalFormName}#`, '')] = finalValue;
        }
    })
    return reducedFiltersForm;*/
    return filtersFormValues;
}