import React from 'react'

import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

const styles = theme => ({
    root: {
        paddingTop: 16,
        paddingBottom: 16,
        marginTop: theme.spacing(3),
    },
    heroMargin: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(7),
        }
    },
    gutterBottom: {
        marginBottom: '0.35em',
//		[theme.breakpoints.up('md')]: {
//			marginBottom: '0.65em',
//		}
    },
    gutterBottomExtra: {
        marginBottom: theme.spacing(3),
    },
    pageHeader: {
        transition: 'padding 0.2s ease',
        paddingTop: '1em',
        [theme.breakpoints.up('md')]: {
            paddingTop: '3em',
        }
    }
});

class ContentBlockHeader extends React.Component {

    render() {
        const {
            classes,
            Alignment,
            heroTitle,
            PageTitle,
            TitleColor,
            TitleVariant,
            TitleComponent,
            SubHeader,
            heroMargin,
            hasDivider,
            PageHeader,
            width
        } = this.props;

        return (
            <Grid
                container
                spacing={0}
                justifyContent={(Alignment === 'center' ? 'center' : 'flex-start')}
                className={PageHeader ? classes.pageHeader : null}
            >
                {typeof (PageTitle) === "string" ? (
                    width === 'xs' ? (
                            <Grid item xs={12}>
                                <Typography
                                    color={TitleColor || `inherit`}
                                    variant={TitleVariant ? TitleVariant : `h3`}
                                    component={TitleComponent ? TitleComponent : `h3`}
                                    align={Alignment || 'center'}
                                    classes={{
                                        gutterBottom: heroTitle ? classes.heroMargin : classes.gutterBottom
                                    }}
                                >
                                    {PageTitle}
                                </Typography>
                            </Grid>
                        )
                        : (
                            <Grid item xs={12}>
                                <Typography
                                    color={TitleColor || `inherit`}
                                    variant={TitleVariant ? TitleVariant : `h2`}
                                    component={TitleComponent ? TitleComponent : `h2`}
                                    align={Alignment || 'center'}
                                    gutterBottom={SubHeader ? true : true}
                                    classes={{
                                        gutterBottom: heroTitle ? classes.heroMargin : classes.gutterBottom
                                    }}
                                >
                                    {PageTitle}
                                </Typography>
                            </Grid>)
                ) : (
                    <Grid item>{PageTitle}</Grid>
                )
                }
                {SubHeader && (
                    <Grid item xs={12}>
                        <Typography
                            color="textPrimary"
                            classes={{
                                gutterBottom: classes.gutterBottomExtra
                            }}
                            variant={'h6'}
                            component="p"
                            align={Alignment || 'center'}
                            gutterBottom={true}
                        >
                            {SubHeader}
                        </Typography>
                    </Grid>
                )}
                {hasDivider && (
                    <Divider
                        light
                        variant="fullWidth"
                        className={heroMargin ? classes.heroMargin : null}
                    />
                )}
            </Grid>
        )
    }
}

export default withStyles(styles)(ContentBlockHeader)