import React from 'react';

import { withStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import InternalLink from '@dc/dc/components/links/InternalLink';

import {connect} from 'react-redux';
import {toggleRequiresAppbar} from "../actions/appbar-actions";
import AppbarNotifications from "../appbar/containers/AppbarNotifications";

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

const styleSheet = theme => ({
    withPermanentDrawer: {
        [theme.breakpoints.up('md')]: {
            marginLeft: `${theme.drawerWidth.width}px`,
            width: `calc(100% - ${theme.drawerWidth.width}px)`,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(3),
        },
    },
});

function BackButton({dispatch}) {
   // let history = useHistory()
    return (
        <IconButton
            color="inherit"
            onClick={() => {
                dispatch(toggleRequiresAppbar())
                //history.goBack()
            }}
            aria-label="Close"
        >
            <CloseIcon/>
        </IconButton>
    )
}

class GlobalControlToolBar extends React.Component {
    render() {
        const {
            dispatch,
            actionButtons,
            classes,
            withPermanentDrawer,
            primaryBreadcrumbItemObject,
            additionalBreadcrumbItemObjects,
            children
        } = this.props;

        return (
            <AppBar
                position="fixed"
                elevation={1}
                color="inherit"
                style={{top: '0px'}}
                className={withPermanentDrawer ? classes.withPermanentDrawer : (null)}
            >
                <Toolbar>
                    <Grid container justifyContent={"space-between"} alignItems={'center'}>
                        <Grid item xs={10}>
                            <Box display="flex" flexDirection="row" alignItems="center">
                                <Breadcrumbs separator="-" aria-label="breadcrumb">
                                    {primaryBreadcrumbItemObject && (
                                        <InternalLink
                                            color="primary"
                                            to={primaryBreadcrumbItemObject.to}
                                            variant={'body1'}
                                        >
                                            {primaryBreadcrumbItemObject.label}
                                       </InternalLink>
                                    )}
                                    {additionalBreadcrumbItemObjects && additionalBreadcrumbItemObjects.map(additionalBreadcrumbItemObject => {
                                            if (additionalBreadcrumbItemObject.to) {
                                                return (
                                                    <InternalLink
                                                        key={`breadCrumb-${additionalBreadcrumbItemObject.to}`}
                                                        color="primary"
                                                        to={additionalBreadcrumbItemObject.to}
                                                        variant={'body1'}
                                                    >
                                                        {additionalBreadcrumbItemObject.label}
                                                   </InternalLink>
                                                )
                                            }
                                            return (
                                                <Typography
                                                    key={`breadCrumb-${additionalBreadcrumbItemObject.to}`}
                                                    color="textPrimary"
                                                    variant="body1"
                                                >
                                                    {additionalBreadcrumbItemObject.label}
                                                </Typography>
                                            )
                                        }
                                    )}
                                </Breadcrumbs>
                                {children && children}
                            </Box>
                        </Grid>

                        <Grid item xs={2}>
                            <Box display="flex" flexDirection="row" alignItems="center" justifyContent={'flex-end'}>
                                {actionButtons && actionButtons}
                                <Box display="flex" justifyContent={'flex-end'}>
                                    <AppbarNotifications/>
                                    <BackButton dispatch={dispatch}/>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        )
    }
}

export default connect(state => {
    return {}
})(withStyles(styleSheet)(GlobalControlToolBar));

export const GlobalControlToolBarWithSideBar = withStyles(theme => ({
    withPermanentDrawer: {
        [theme.breakpoints.up('md')]: {
            marginLeft: `${theme.drawerWidth.width}px`,
            width: `calc(100% - ${theme.drawerWidth.width}px)`,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(3),
        },
    },
}))(GlobalControlToolBar);
