import React from 'react';

import List from '@mui/material/List';

import { withStyles } from '@mui/styles';
import {genericListStyleSheet} from "../style/PortalStyles";

class GenericList extends React.PureComponent {
    render() {
        const {classes, children, NoBorder, Dense} = this.props;
        return (
            <List
                component="div"
                dense={Dense}
                className={
                    NoBorder ? classes.listContainerNoBorder : classes.listContainer
                }
            >
                {children}
            </List>
        )
    }
}

export default withStyles(genericListStyleSheet)(GenericList)