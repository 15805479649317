import React from 'react';

import {Field} from 'react-final-form';

import {withDcxFormContext} from './DcxForm';

const reduceControl = (formControlContext) => {
    const {readOnly} = formControlContext;
    return {readOnly}
}

const reduceInput = (formControlContext, other) => {
    const {variant} = formControlContext;
    if (other.Input) {
        let reduced = {
            Input: Object.assign({variant}, {...other.Input})
        }
        return reduced;
    }
    return {Input: {variant}}
}

class DcxFormField extends React.PureComponent {
    render() {
        const {formControlContext, name, ...muiProps} = this.props;
        return (
            <Field
               name={name}
               {...muiProps}
               {...reduceControl(formControlContext, muiProps)}
               {...reduceInput(formControlContext, muiProps)}
            />
        )
    }
}

export default withDcxFormContext(DcxFormField)