
import {growcloudServerMessages} from "../i18n/GrowcloudServerMessages";
import GrowcloudServiceRegistry from "gc-webapp/gc/GrowcloudServiceRegistry"

const filterProtectedRoutes = (protectedRoutes) => {
    let filteredProtectedRoutes = [];
    protectedRoutes.forEach(protectedRoute => {
        if(-1 < protectedRoute.to.indexOf('/notifications')) {
            filteredProtectedRoutes.push(protectedRoute)
        }
    })
    return filteredProtectedRoutes;
}

const filterDashboardRoutes = (dashboardRoutes) => {
    return dashboardRoutes;
}

export const makeGrowcloudServerConfiguration = (applicationName) => {

    const publicRoutes = [{
      to: '/status',
      message: growcloudServerMessages.routeStatusLabel,
      elideFooter: true,
      navigationRoute: true,
    }, {
      to: '/configurations',
      message: growcloudServerMessages.routeConfigurationsLabel,
      elideFooter: true,
      navigationRoute: true,
    }, {
      to: '/planning',
      message: growcloudServerMessages.routePlanningLabel,
      elideFooter: true,
      navigationRoute: true,
    },/*
    {
      to: '/assets',
      message: growcloudServerMessages.routeAssetsLabel,
      elideFooter: true,
      navigationRoute: true,
    },*/
    /*{
      to: '/events',
      message: growcloudServerMessages.routeEventsLabel,
      elideFooter: true,
      navigationRoute: true,
    },*/
    {
      to: '/outputs',
      message: growcloudServerMessages.routeOutputsLabel,
      elideFooter: true,
      navigationRoute: true,
    }]

    const defaultAppConfiguration = {
        grpcHost: window.location.origin,
        'token-key': 'x-growcloud',
        application: {
            name: applicationName,
            protectedRoutes: []
        }
    }

    const protectedRoutes = filterProtectedRoutes(defaultAppConfiguration.application.protectedRoutes)

    const dashboardRoutes = filterDashboardRoutes([])

    const growcloudConfiguration = Object.assign(defaultAppConfiguration, {
        'token-key': 'growcloud',
        signinRedirectRoute: '/landing',
        application: Object.assign(defaultAppConfiguration.application, {
            publicRoutes,
            protectedRoutes,
            dashboardRoutes
        })
    });
    return growcloudConfiguration;
}
