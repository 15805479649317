import React from 'react';
import {connect} from 'react-redux';

import {doSignout} from './../actions/authentication-actions';
import AuthContainer from './../../authentication/containers/AuthContainer';
import Grid from '@mui/material/Grid';
import Text from '@dc/dc/content/Text';

class SignOutContainer extends React.Component {

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(doSignout())
  }

  render() {
    const {redirectRoute='/',globalConfiguration} = this.props;
    debugger;
    return (
        <Grid container justifyContent='center'>
          <Grid item xs>
            <AuthContainer globalConfiguration={globalConfiguration} userRequired redirect={redirectRoute}>
              <Text type='body2'>
                Signing out...
              </Text>
            </AuthContainer>
          </Grid>
        </Grid>
    )
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(SignOutContainer);
