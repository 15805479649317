import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Text from '@dc/dc/content/Text';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import Worksheet, {WorksheetNode} from '@dc/dc/components/surface/Worksheet';
import CurrentReadingsGrid from 'gc-webapp/gc/CurrentReadingsGrid'
import CalculationsGrid from 'gc-webapp/gc/CalculationsGrid'
import EventCountersGrid from 'gc-webapp/gc/EventCountersGrid'
import AssetStatesGrid from 'gc-webapp/gc/AssetStatesGrid'
import Loading from '@dc/dcx/app/common/Loading';
import NavigationDrawer from 'gc-ui-components/ui/navigation/NavigationDrawer';
import { a11yProps, CustomTabPanel } from './TabsCommons'

const Status = ({userContext}) => {

 const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <>
        <ContainerX AsContainer withLargeBottomMargin withLargeTopMargin>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Status Tabs">
                  <Tab label="READINGS" {...a11yProps(0)} />
                  <Tab label="CALCULATIONS" {...a11yProps(1)} />
                  <Tab label="ASSET STATE EVENTS" {...a11yProps(2)} />
                  {/*<Tab label="EVENT COUNTERS" {...a11yProps(3)} />*/}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                   <CurrentReadingsGrid
                       userContext={userContext}
                   />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                  <CalculationsGrid
                      userContext={userContext}
                  />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                 <AssetStatesGrid
                     userContext={userContext}
                 />
              </CustomTabPanel>
              {/*<CustomTabPanel value={value} index={3}>
                  <EventCountersGrid
                      userContext={userContext}
                  />
              </CustomTabPanel>*/}
            </ContainerX>
        </>
    )
}
export default connect(state => { return {} })(Status)