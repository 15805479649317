import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import { formReducer} from '@dc/dcx/shim/ReduxFormShims';
import { ofType, combineEpics, createEpicMiddleware } from "redux-observable";
import listen from 'redux-action-listeners';
import { map, flatMap } from "rxjs/operators";
import createSagaMiddleware from 'redux-saga';

import gcServerInitialiser from './gc-server-initialiser'

import currentReadingsGridServiceSaga from 'gc-webapp/gc/mw/sagas/growcloud-grid-services-saga.js';

import growcloudV1Reducer from 'gc-webapp/gc/mw/reducers/growcloud-v1-service-reducer';
import measureValuesV1Reducer from 'gc-webapp/gc/mw/reducers/measure-values-v1-service-reducer';

import growcloudV1ServiceSaga from 'gc-webapp/gc/mw/sagas/growcloud-v1-service-saga.js';
import measureValuesV1ServiceSaga from 'gc-webapp/gc/mw/sagas/measure-values-v1-service-saga.js';

import authReducer from 'gc-authentication/authentication/reducers/authentication-reducer';

import dynamicGridReducer from 'gc-ui-components/ui/mw/grid-service/reducers/dynamic-grid-reducer';
import applicationReducer from 'gc-ui-components/ui/mw/application/reducers/application-reducer';

import {dcxReducers, dcxSagas} from '@dc/dcx/application/BaseApp';

import {
    AUTH_TOKEN,
    AUTH_TOKEN_FAIL,
    AUTH_TOKEN_SUCCESS,
    AUTHENTICATION,
    AUTHENTICATION_FAIL,
    AUTHENTICATION_SUCCESS,
    SIGNOUT,
    SIGNOUT_SUCCESS
} from 'gc-authentication/authentication/actions/authentication-actions'

import {
    authenticate,
    currentUser,
    signOut
} from 'gc-authentication/authentication/api/authentication-api';

import {setToken} from 'gc-authentication/authentication/token-utils';

export const getCurrentUserSuccess  = data => ({
 type: AUTH_TOKEN_SUCCESS, data
})

export const getAuthenticationSuccess = data => {
    setToken(data.token);
    return ({
      type: AUTHENTICATION_SUCCESS, data
    })
}

export const getSignoutSuccess = data => {
    setToken(null);
    return ({
      type: SIGNOUT_SUCCESS, data
    })
}

const storage = () => {

    const epicMiddleware = createEpicMiddleware();
    const sagaMiddleware = createSagaMiddleware()

    let middlewares = [
        sagaMiddleware,
        epicMiddleware,
        listen(gcServerInitialiser)
    ];


    // if (SiteConfig.env !== 'production') {
    const {createLogger} = require('redux-logger');
    middlewares.push(createLogger());
    // }

    let reducers = {
        auth: authReducer,
        form: formReducer,
        measureValues: measureValuesV1Reducer,
        growcloud: growcloudV1Reducer,
        dynamicGrid: dynamicGridReducer,
        application: applicationReducer
    }

    Object.assign(reducers, dcxReducers())

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        combineReducers(reducers),
        composeEnhancers(applyMiddleware(...middlewares))
    )
    const sagas = [
        currentReadingsGridServiceSaga,
        growcloudV1ServiceSaga,
        measureValuesV1ServiceSaga
    ];
    dcxSagas().forEach(dcxSaga => sagas.push(dcxSaga));
    sagas.forEach(saga => sagaMiddleware.run(saga))

    const authenticateEpic = action$ =>
      action$.pipe(
        ofType(AUTHENTICATION),
        flatMap((e) => authenticate(e.data)),
        map((e) => getAuthenticationSuccess(e))
      );

    const currentUserEpic = action$ =>
      action$.pipe(
        ofType(AUTH_TOKEN),
        flatMap((e) => currentUser(e.data)),
        map((e) => getCurrentUserSuccess(e))
      );

    const signOutEpic = action$ =>
      action$.pipe(
        ofType(SIGNOUT),
        flatMap((e) => signOut(e.data)),
        map((e) => getSignoutSuccess(e))
      );

    epicMiddleware.run(combineEpics(
     authenticateEpic, currentUserEpic, signOutEpic
    ))
    return store;
}

export default storage;
