import {getRequest, postRequest} from './../apis/appbar-api';
import {call, fork, put, take} from 'redux-saga/effects'

import {
    GET_MESSAGES,
    GET_MESSAGES_FAIL,
    GET_MESSAGES_SUCCESS,
    GET_USER_INTERFACE_CONFIGURATION,
    GET_USER_INTERFACE_CONFIGURATION_FAIL,
    GET_USER_INTERFACE_CONFIGURATION_SUCCESS,
} from '../actions/appbar-actions'

export function* getMessagesConsumer() {
    while (true) {
        const request = yield take(GET_MESSAGES)
        try {
            const response = yield call(getRequest, 'messages');
            yield put({
                type: GET_MESSAGES_SUCCESS,
                data: response.data,
            });
        } catch (error) {
            let errorObject = {
                type: GET_MESSAGES_FAIL,
                error,
            }
            yield put(errorObject);
        }
    }
}

export function* getUserInterfaceConfigurationConsumer() {
    while (true) {
        const request = yield take(GET_USER_INTERFACE_CONFIGURATION)
        try {
            const response = yield call(postRequest, 'user-interface', request.configurationOptions);
            yield put({
                type: GET_USER_INTERFACE_CONFIGURATION_SUCCESS,
                data: response.data,
            });
        } catch (error) {
            let errorObject = {
                type: GET_USER_INTERFACE_CONFIGURATION_FAIL,
                error,
            }
            yield put(errorObject);
        }
    }
}

export default function * root () {
    yield fork(getMessagesConsumer)
    yield fork(getUserInterfaceConfigurationConsumer)
}