import React from 'react';
import Divider from '@mui/material/Divider';

import Text from '@dc/dc/content/Text';
import {FormattedMessage} from 'react-intl';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import CallOut from '@dc/dc/components/dataDisplay/CallOut';

const MessageDisplay = ({
                            children,
                            TextProps={variant: 'subtitle1'},
                            message,
                            typeVariant = 'info',
                            withLargeTopMargin = false
}) => {
    return (
        <CallOut
            typeVariant={typeVariant}
            withLargeTopMargin={withLargeTopMargin}
        >
            {message.id && (
                <Text {...TextProps}>
                    <FormattedMessage {...message}/>
                </Text>
            )}
            {!message.id && (
                <Text {...TextProps}>
                    {message}
                </Text>
            )}
            {children && (
                <ContainerX>
                    <Divider/>
                    {children}
                </ContainerX>
            )}
        </CallOut>
    )
}
export default (MessageDisplay)