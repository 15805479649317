import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';

import ContainerX from '@dc/dc/components/layout/ContainerX';
import {connect} from 'react-redux';

import Grid from '@mui/material/Grid';

import Worksheet from '@dc/dc/components/surface/Worksheet';

import {registerEmptySidebar} from "web-application-commons/ui/sidebar/SidebarUtils";
import SitesExplorerWrapper from "./SitesExplorerWrapper";

import {NavigationViewer} from 'gc-ui-components/ui/components/NavigationViewer'

const styles = theme => ({
	root: {
		padding: 0,
	},
});

class SitesExplorer extends React.Component {

	constructor(props) {
		super(props);
		this.state = { }
	}

	componentDidMount() {
		const {dispatch} = this.props;
		registerEmptySidebar(dispatch)
	}

	makeSelectedItemContentNode(selectedItem) {
		return this.makeDefaultWorksheetContentNode()
	}

	makeDefaultWorksheetContentNode() {
		return (
			<span></span>
		)
	}

	render() {
		const {chartConfigurations, vendingContext} = this.props;
		const {chartChannel} = this.state;

		let selectedItem = null;

        const controlClick = () => {}
        const utilityListData = []
        const items = []

        this.props.environment.assetSiteAssignments.forEach(assetSiteAssignment => {
            items.push({
                id: assetSiteAssignment.asset.identifier.label,
                label: assetSiteAssignment.asset.identifier.label,
                key: assetSiteAssignment.asset.identifier.label,
            })
        });

        if(0 < this.props.environment.assetSiteAssignments.length){
        utilityListData.push({
            id: this.props.environment.assetSiteAssignments[0].site.identifier.label,
            label: this.props.environment.assetSiteAssignments[0].site.identifier.label,
            key: this.props.environment.assetSiteAssignments[0].site.identifier.label,
            items
        })
        }

        const header = 'Sites Explorer'
		return (
			<SitesExplorerWrapper
				header
				additionalBreadcrumbs={[
                    { label: header }
				]}
			>
				<Grid container spacing={3} justifyContent="left">
					<Grid item xs={12} sm={3}>
						<ContainerX
                              AsContainer WithLargeTopMargin
                              width={'50vh'}
                              >
                          <NavigationViewer
                                onNodeSelect={controlClick}
                                utilityListData={utilityListData}
                            />
						</ContainerX>
					</Grid>

				</Grid>
			</SitesExplorerWrapper>
		)
	}
}

SitesExplorer.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default connect(state => {
	return { environment: state.environment }
})(
	withStyles(styles)(SitesExplorer)
)
