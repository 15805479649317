import React from 'react';
import Grid from '@mui/material/Grid';
import Text from '@dc/dc/content/Text';

import {connect} from 'react-redux';

import AppbarUserProfile from 'web-application-commons/app/appbar/containers/AppbarUserProfile';
import AppbarSignin from 'web-application-commons/app/appbar/containers/AppbarSignin';

import AuthContainer from 'gc-authentication/authentication/containers/AuthContainer';

import {protectedAppbar} from '@dc/dcx/app/actions/appbar-actions';
import CallOut from '@dc/dc/components/dataDisplay/CallOut';
import ContainerX from '@dc/dc/components/layout/ContainerX';

import {
    makeCommonArtifactSidebarConfiguration,
    makeCommonLifecycleSidebarConfiguration,
    makeCommonResourceSidebarConfiguration,
    makeCommonSystemSidebarConfiguration,
    makeSidebarConfigurationForQualityChannel
} from "web-application-commons/ui/sidebar/SidebarHelper";

import {setMenuBarDrawerConfiguration} from '@dc/dcx/app/actions/appbar-actions';

//routePlanningLabel: growcloudServerMessage('routePlanningLabel', 'PLANNING'),
//    routeCalendarsLabel: growcloudServerMessage('routeCalendarsLabel', 'CALENDARS'),
//    routeReadingsLabel: growcloudServerMessage('routeReadingsLabel', 'READINGS'),
//    routeStatusLabel: growcloudServerMessage('routeStatusLabel', 'STATUS'),
//    routeConfigurationsLabel: growcloudServerMessage('routeConfigurationsLabel', 'CONFIGURATIONS'),
//    routeExplorerLabel: growcloudServerMessage('routeExplorerLabel', 'EXPLORER'),
//    routeEventsLabel: growcloudServerMessage('routeEventsLabel', 'EVENTS'),
//    routeOutputsLabel: growcloudServerMessage('routeOutputsLabel', 'OUTPUTS'),
//    routeAssetsLabel: growcloudServerMessage('routeAssetsLabel', 'ASSETS'),

const makeConfigurationsItems = () => {
    return [{
        label: 'Programs',
        href: '/programs'
    },{
        label: 'Schedules',
        href: '/schedules'
    }]
}

const makePlanningItems = () => {
    return [{
        label: 'Permits',
        href: '/permits'
    },{
        label: 'Calendars',
        href: '/calendars'
    }]
}

const makeStatusItems = () => {
    return [{
        label: 'Readings',
        href: '/readings'
    },{
        label: 'Calculations',
        href: '/calculations'
    },{
         label: 'Events',
         href: '/events'
     }]
}

import { growcloudServerMessages } from '../i18n/GrowcloudServerMessages'

const makeGrowcloudMenuDrawerItems = (formatMessage) => {
    let finalItems = [];
    finalItems.push({
        label: formatMessage(growcloudServerMessages.routeConfigurationsLabel),
        items: makeConfigurationsItems(formatMessage)
    })
    finalItems.push({
        label: formatMessage(growcloudServerMessages.routePlanningLabel),
        items: makePlanningItems(formatMessage)
    })
    finalItems.push({
        label: formatMessage(growcloudServerMessages.routeStatusLabel),
        items: makeStatusItems(formatMessage)
    })
    finalItems.push({
        label: formatMessage(growcloudServerMessages.routeOutputsLabel),
        items: makeStatusItems(formatMessage)
    })
    return finalItems;
}

export const withGrowcloudLayout = (WrappedComponent) => {

    return connect(state => {
        return {
            user: state.auth.user
        }
    })(class extends React.Component {

        constructor(props) {
            super(props);
            this.state = {}
        }

        componentDidMount() {
            const {dispatch, user} = this.props;
//            dispatch(setMenuBarDrawerConfiguration({
//                listType: '$$nested'
//            }))
        }

        userActionsBlockRenderer() {
            const {user,globalConfiguration} = this.props;
            if (user) {
                return (
                    <>
                        <Grid item>
                            <AppbarUserProfile
                                globalConfiguration={globalConfiguration}
                                flexPosition="flex-end"
                                user={user}
                                elideUserProfile={false}
                                authorisedUserMenuItems={[/*{
                                    message: metroExpressMessages.routeOrganisationsLabel,
                                    to: '/organisations',
                                }*//*, {
                                    message: metroExpressMessages.routeRolesLabel,
                                    to: '/roles',
                                }*//*, {
                                    message: metroExpressMessages.routeTeamsLabel,
                                    to: '/teams',
                                }, {
                                    message: metroExpressMessages.routeUsersLabel,
                                    to: '/users',
                                }*//*, {
                                    message: metroExpressMessages.routeTagsLabel,
                                    to: '/tags',
                                }*//*, {
                                    message: metroExpressMessages.routeFlowsLabel,
                                    to: '/flows',
                                }, {
                                    label: metroExpressMessages.routeConsumerPermitsLabel,
                                    href: '/consumer-permits'
                                }, {
                                    label: metroExpressMessages.routeConsumerExchangesLabel,
                                    href: '/consumer-exchanges'
                                }, {
                                    label: metroExpressMessages.routeProviderPermitsLabel,
                                    href: '/provider-permits'
                                }, {
                                    label: metroExpressMessages.routeProviderExchangesLabel,
                                    href: '/provider-exchanges'
                                }*/]}
                            />
                        </Grid>
                    </>
                )
            }
            return (
                <AppbarSignin
                    userProfileRoutesConfig={{
                        signInRoute: '/growcloud-signin',
                        signUpRoute: '/signup',
                        additionalSigninLinks: [],
                    }}
                />
            )
        }


        render() {
            const { worksheetNode} = this.props;

            const menuDrawerItems = []; //makeGrowcloudMenuDrawerItems(intl.formatMessage);

            if (!menuDrawerItems) {
                return (null);
            }

            return (
                <>
                    <WrappedComponent
                        userActionsBlockRenderer={this.userActionsBlockRenderer.bind(this)}
                        mainAppbarMenuDrawerItems={menuDrawerItems}
                        {...this.props}
                    />
                    {worksheetNode && (
                        <Worksheet
                            Title={null}
                            ContentNode={worksheetNode}
                            clearContents={() => {
                            }}
                            isDirty={false}
                        />
                    )}
                </>
            )
        }
    })
}

export const withAuthentication = (WrappedComponent) => {

    return connect(state => {
        return {
            user: state.auth.user,
        }
    })(class extends React.Component {

        render() {
            const {user, globalConfiguration} = this.props;
            return (
                <AuthContainer globalConfiguration={globalConfiguration} userRequired redirect={'/signin'}>
                    <WrappedComponent
                        {...this.props}
                    />
                </AuthContainer>
            )
        }
    })
}
