// source: growcloud/v1/domain/ProductionSystems.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var growcloud_v1_identity_Gcn_pb = require('../../../growcloud/v1/identity/Gcn_pb.js');
goog.object.extend(proto, growcloud_v1_identity_Gcn_pb);
var growcloud_v1_domain_Metadata_pb = require('../../../growcloud/v1/domain/Metadata_pb.js');
goog.object.extend(proto, growcloud_v1_domain_Metadata_pb);
goog.exportSymbol('proto.growcloud.v1.domain.ActiveTrigger', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.Alarm', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.Asset', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.AssetEnclosureAssignment', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.Enclosure', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.EnclosureSiteAssignment', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.ProductLine', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.ProductLineProductionPermitAssignment', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.ProductionLot', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.ProductionPermit', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.Program', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.ProgramEnclosureAssignment', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.Schedule', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.ScheduleProgramAssignment', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.Site', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.Tag', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.TagAssignment', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.ProductionPermit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.ProductionPermit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.ProductionPermit.displayName = 'proto.growcloud.v1.domain.ProductionPermit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.ActiveTrigger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.ActiveTrigger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.ActiveTrigger.displayName = 'proto.growcloud.v1.domain.ActiveTrigger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.Alarm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.Alarm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.Alarm.displayName = 'proto.growcloud.v1.domain.Alarm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.ProductLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.ProductLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.ProductLine.displayName = 'proto.growcloud.v1.domain.ProductLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.ProductLineProductionPermitAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.displayName = 'proto.growcloud.v1.domain.ProductLineProductionPermitAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.ProductionLot = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.ProductionLot, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.ProductionLot.displayName = 'proto.growcloud.v1.domain.ProductionLot';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.AssetEnclosureAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.AssetEnclosureAssignment.displayName = 'proto.growcloud.v1.domain.AssetEnclosureAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.EnclosureSiteAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.EnclosureSiteAssignment.displayName = 'proto.growcloud.v1.domain.EnclosureSiteAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.ProgramEnclosureAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.ProgramEnclosureAssignment.displayName = 'proto.growcloud.v1.domain.ProgramEnclosureAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.ScheduleProgramAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.ScheduleProgramAssignment.displayName = 'proto.growcloud.v1.domain.ScheduleProgramAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.TagAssignment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.TagAssignment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.TagAssignment.displayName = 'proto.growcloud.v1.domain.TagAssignment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.Schedule = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.Schedule, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.Schedule.displayName = 'proto.growcloud.v1.domain.Schedule';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.Program = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.Program, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.Program.displayName = 'proto.growcloud.v1.domain.Program';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.Enclosure = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.Enclosure, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.Enclosure.displayName = 'proto.growcloud.v1.domain.Enclosure';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.Site = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.Site, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.Site.displayName = 'proto.growcloud.v1.domain.Site';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.Asset.displayName = 'proto.growcloud.v1.domain.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.Tag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.Tag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.Tag.displayName = 'proto.growcloud.v1.domain.Tag';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.ProductionPermit.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.ProductionPermit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.ProductionPermit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProductionPermit.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    totalquotaamount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    maxquotasperrun: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.ProductionPermit}
 */
proto.growcloud.v1.domain.ProductionPermit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.ProductionPermit;
  return proto.growcloud.v1.domain.ProductionPermit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.ProductionPermit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.ProductionPermit}
 */
proto.growcloud.v1.domain.ProductionPermit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalquotaamount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxquotasperrun(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.ProductionPermit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.ProductionPermit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.ProductionPermit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProductionPermit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getTotalquotaamount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getMaxquotasperrun();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ProductionPermit.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductionPermit} returns this
*/
proto.growcloud.v1.domain.ProductionPermit.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductionPermit} returns this
 */
proto.growcloud.v1.domain.ProductionPermit.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductionPermit.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 totalQuotaAmount = 2;
 * @return {number}
 */
proto.growcloud.v1.domain.ProductionPermit.prototype.getTotalquotaamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.growcloud.v1.domain.ProductionPermit} returns this
 */
proto.growcloud.v1.domain.ProductionPermit.prototype.setTotalquotaamount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 maxQuotasPerRun = 3;
 * @return {number}
 */
proto.growcloud.v1.domain.ProductionPermit.prototype.getMaxquotasperrun = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.growcloud.v1.domain.ProductionPermit} returns this
 */
proto.growcloud.v1.domain.ProductionPermit.prototype.setMaxquotasperrun = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.ActiveTrigger.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.ActiveTrigger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.ActiveTrigger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ActiveTrigger.toObject = function(includeInstance, msg) {
  var f, obj = {
    originator: (f = msg.getOriginator()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.ActiveTrigger}
 */
proto.growcloud.v1.domain.ActiveTrigger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.ActiveTrigger;
  return proto.growcloud.v1.domain.ActiveTrigger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.ActiveTrigger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.ActiveTrigger}
 */
proto.growcloud.v1.domain.ActiveTrigger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setOriginator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.ActiveTrigger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.ActiveTrigger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.ActiveTrigger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ActiveTrigger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOriginator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional growcloud.v1.identity.Gcn originator = 1;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ActiveTrigger.prototype.getOriginator = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 1));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ActiveTrigger} returns this
*/
proto.growcloud.v1.domain.ActiveTrigger.prototype.setOriginator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ActiveTrigger} returns this
 */
proto.growcloud.v1.domain.ActiveTrigger.prototype.clearOriginator = function() {
  return this.setOriginator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ActiveTrigger.prototype.hasOriginator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.Alarm.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.Alarm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.Alarm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Alarm.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.Alarm}
 */
proto.growcloud.v1.domain.Alarm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.Alarm;
  return proto.growcloud.v1.domain.Alarm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.Alarm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.Alarm}
 */
proto.growcloud.v1.domain.Alarm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.Alarm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.Alarm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.Alarm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Alarm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.Alarm.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.Alarm} returns this
*/
proto.growcloud.v1.domain.Alarm.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.Alarm} returns this
 */
proto.growcloud.v1.domain.Alarm.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.Alarm.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.ProductLine.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.ProductLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.ProductLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProductLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.ProductLine}
 */
proto.growcloud.v1.domain.ProductLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.ProductLine;
  return proto.growcloud.v1.domain.ProductLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.ProductLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.ProductLine}
 */
proto.growcloud.v1.domain.ProductLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.ProductLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.ProductLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.ProductLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProductLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ProductLine.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductLine} returns this
*/
proto.growcloud.v1.domain.ProductLine.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductLine} returns this
 */
proto.growcloud.v1.domain.ProductLine.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductLine.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    productline: (f = msg.getProductline()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    productionpermit: (f = msg.getProductionpermit()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.ProductLineProductionPermitAssignment;
  return proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setProductline(value);
      break;
    case 3:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setProductionpermit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getProductline();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getProductionpermit();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment} returns this
*/
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment} returns this
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional growcloud.v1.identity.Gcn productLine = 2;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.getProductline = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 2));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment} returns this
*/
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.setProductline = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment} returns this
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.clearProductline = function() {
  return this.setProductline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.hasProductline = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional growcloud.v1.identity.Gcn productionPermit = 3;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.getProductionpermit = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 3));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment} returns this
*/
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.setProductionpermit = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductLineProductionPermitAssignment} returns this
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.clearProductionpermit = function() {
  return this.setProductionpermit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductLineProductionPermitAssignment.prototype.hasProductionpermit = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.ProductionLot.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.ProductionLot} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProductionLot.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifierinfo: (f = msg.getIdentifierinfo()) && growcloud_v1_domain_Metadata_pb.IdentifierInfoObject.toObject(includeInstance, f),
    startyear: jspb.Message.getFieldWithDefault(msg, 2, 0),
    durationdays: jspb.Message.getFieldWithDefault(msg, 3, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    productline: (f = msg.getProductline()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    quotaamount: jspb.Message.getFieldWithDefault(msg, 8, 0),
    startday: jspb.Message.getFieldWithDefault(msg, 6, 0),
    site: (f = msg.getSite()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    enclosure: (f = msg.getEnclosure()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    sitesconfigurationsmodel: (f = msg.getSitesconfigurationsmodel()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    gcn: (f = msg.getGcn()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.ProductionLot}
 */
proto.growcloud.v1.domain.ProductionLot.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.ProductionLot;
  return proto.growcloud.v1.domain.ProductionLot.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.ProductionLot} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.ProductionLot}
 */
proto.growcloud.v1.domain.ProductionLot.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.IdentifierInfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.IdentifierInfoObject.deserializeBinaryFromReader);
      msg.setIdentifierinfo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartyear(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurationdays(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 7:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setProductline(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuotaamount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStartday(value);
      break;
    case 9:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 10:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setEnclosure(value);
      break;
    case 11:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setSitesconfigurationsmodel(value);
      break;
    case 4:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setGcn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.ProductionLot.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.ProductionLot} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProductionLot.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifierinfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.IdentifierInfoObject.serializeBinaryToWriter
    );
  }
  f = message.getStartyear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDurationdays();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getProductline();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getQuotaamount();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getStartday();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getEnclosure();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getSitesconfigurationsmodel();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getGcn();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional IdentifierInfoObject identifierInfo = 1;
 * @return {?proto.growcloud.v1.domain.IdentifierInfoObject}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getIdentifierinfo = function() {
  return /** @type{?proto.growcloud.v1.domain.IdentifierInfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.IdentifierInfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.IdentifierInfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
*/
proto.growcloud.v1.domain.ProductionLot.prototype.setIdentifierinfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.clearIdentifierinfo = function() {
  return this.setIdentifierinfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.hasIdentifierinfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 startYear = 2;
 * @return {number}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getStartyear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.setStartyear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 durationDays = 3;
 * @return {number}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getDurationdays = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.setDurationdays = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 timestamp = 5;
 * @return {number}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional growcloud.v1.identity.Gcn productLine = 7;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getProductline = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 7));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
*/
proto.growcloud.v1.domain.ProductionLot.prototype.setProductline = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.clearProductline = function() {
  return this.setProductline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.hasProductline = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional int32 quotaAmount = 8;
 * @return {number}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getQuotaamount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.setQuotaamount = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 startDay = 6;
 * @return {number}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getStartday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.setStartday = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional growcloud.v1.identity.Gcn site = 9;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getSite = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 9));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
*/
proto.growcloud.v1.domain.ProductionLot.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.hasSite = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional growcloud.v1.identity.Gcn enclosure = 10;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getEnclosure = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 10));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
*/
proto.growcloud.v1.domain.ProductionLot.prototype.setEnclosure = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.clearEnclosure = function() {
  return this.setEnclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.hasEnclosure = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional growcloud.v1.identity.Gcn sitesConfigurationsModel = 11;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getSitesconfigurationsmodel = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 11));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
*/
proto.growcloud.v1.domain.ProductionLot.prototype.setSitesconfigurationsmodel = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.clearSitesconfigurationsmodel = function() {
  return this.setSitesconfigurationsmodel(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.hasSitesconfigurationsmodel = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional growcloud.v1.identity.Gcn gcn = 4;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.getGcn = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 4));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
*/
proto.growcloud.v1.domain.ProductionLot.prototype.setGcn = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProductionLot} returns this
 */
proto.growcloud.v1.domain.ProductionLot.prototype.clearGcn = function() {
  return this.setGcn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProductionLot.prototype.hasGcn = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.AssetEnclosureAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.AssetEnclosureAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    asset: (f = msg.getAsset()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    enclosure: (f = msg.getEnclosure()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.AssetEnclosureAssignment}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.AssetEnclosureAssignment;
  return proto.growcloud.v1.domain.AssetEnclosureAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.AssetEnclosureAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.AssetEnclosureAssignment}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setAsset(value);
      break;
    case 3:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setEnclosure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.AssetEnclosureAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.AssetEnclosureAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getAsset();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getEnclosure();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.AssetEnclosureAssignment} returns this
*/
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.AssetEnclosureAssignment} returns this
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional growcloud.v1.identity.Gcn asset = 2;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.getAsset = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 2));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.AssetEnclosureAssignment} returns this
*/
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.setAsset = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.AssetEnclosureAssignment} returns this
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.clearAsset = function() {
  return this.setAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.hasAsset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional growcloud.v1.identity.Gcn enclosure = 3;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.getEnclosure = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 3));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.AssetEnclosureAssignment} returns this
*/
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.setEnclosure = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.AssetEnclosureAssignment} returns this
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.clearEnclosure = function() {
  return this.setEnclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.AssetEnclosureAssignment.prototype.hasEnclosure = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.EnclosureSiteAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.EnclosureSiteAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    enclosure: (f = msg.getEnclosure()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    site: (f = msg.getSite()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.EnclosureSiteAssignment}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.EnclosureSiteAssignment;
  return proto.growcloud.v1.domain.EnclosureSiteAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.EnclosureSiteAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.EnclosureSiteAssignment}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setEnclosure(value);
      break;
    case 3:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.EnclosureSiteAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.EnclosureSiteAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getEnclosure();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.EnclosureSiteAssignment} returns this
*/
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.EnclosureSiteAssignment} returns this
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional growcloud.v1.identity.Gcn enclosure = 2;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.getEnclosure = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 2));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.EnclosureSiteAssignment} returns this
*/
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.setEnclosure = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.EnclosureSiteAssignment} returns this
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.clearEnclosure = function() {
  return this.setEnclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.hasEnclosure = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional growcloud.v1.identity.Gcn site = 3;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.getSite = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 3));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.EnclosureSiteAssignment} returns this
*/
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.EnclosureSiteAssignment} returns this
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.EnclosureSiteAssignment.prototype.hasSite = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.ProgramEnclosureAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.ProgramEnclosureAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    program: (f = msg.getProgram()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    enclosure: (f = msg.getEnclosure()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.ProgramEnclosureAssignment}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.ProgramEnclosureAssignment;
  return proto.growcloud.v1.domain.ProgramEnclosureAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.ProgramEnclosureAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.ProgramEnclosureAssignment}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    case 3:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setEnclosure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.ProgramEnclosureAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.ProgramEnclosureAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getEnclosure();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ProgramEnclosureAssignment} returns this
*/
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProgramEnclosureAssignment} returns this
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional growcloud.v1.identity.Gcn program = 2;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.getProgram = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 2));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ProgramEnclosureAssignment} returns this
*/
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProgramEnclosureAssignment} returns this
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional growcloud.v1.identity.Gcn enclosure = 3;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.getEnclosure = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 3));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ProgramEnclosureAssignment} returns this
*/
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.setEnclosure = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ProgramEnclosureAssignment} returns this
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.clearEnclosure = function() {
  return this.setEnclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ProgramEnclosureAssignment.prototype.hasEnclosure = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.ScheduleProgramAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.ScheduleProgramAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    schedule: (f = msg.getSchedule()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    program: (f = msg.getProgram()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.ScheduleProgramAssignment}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.ScheduleProgramAssignment;
  return proto.growcloud.v1.domain.ScheduleProgramAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.ScheduleProgramAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.ScheduleProgramAssignment}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setSchedule(value);
      break;
    case 3:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setProgram(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.ScheduleProgramAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.ScheduleProgramAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getSchedule();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getProgram();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ScheduleProgramAssignment} returns this
*/
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ScheduleProgramAssignment} returns this
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional growcloud.v1.identity.Gcn schedule = 2;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.getSchedule = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 2));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ScheduleProgramAssignment} returns this
*/
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.setSchedule = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ScheduleProgramAssignment} returns this
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.clearSchedule = function() {
  return this.setSchedule(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.hasSchedule = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional growcloud.v1.identity.Gcn program = 3;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.getProgram = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 3));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.ScheduleProgramAssignment} returns this
*/
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.setProgram = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ScheduleProgramAssignment} returns this
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.clearProgram = function() {
  return this.setProgram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ScheduleProgramAssignment.prototype.hasProgram = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.TagAssignment.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.TagAssignment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.TagAssignment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.TagAssignment.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    tag: (f = msg.getTag()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f),
    item: (f = msg.getItem()) && growcloud_v1_identity_Gcn_pb.Gcn.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.TagAssignment}
 */
proto.growcloud.v1.domain.TagAssignment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.TagAssignment;
  return proto.growcloud.v1.domain.TagAssignment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.TagAssignment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.TagAssignment}
 */
proto.growcloud.v1.domain.TagAssignment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setTag(value);
      break;
    case 3:
      var value = new growcloud_v1_identity_Gcn_pb.Gcn;
      reader.readMessage(value,growcloud_v1_identity_Gcn_pb.Gcn.deserializeBinaryFromReader);
      msg.setItem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.TagAssignment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.TagAssignment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.TagAssignment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.TagAssignment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getTag();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
  f = message.getItem();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      growcloud_v1_identity_Gcn_pb.Gcn.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.TagAssignment.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.TagAssignment} returns this
*/
proto.growcloud.v1.domain.TagAssignment.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.TagAssignment} returns this
 */
proto.growcloud.v1.domain.TagAssignment.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.TagAssignment.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional growcloud.v1.identity.Gcn tag = 2;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.TagAssignment.prototype.getTag = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 2));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.TagAssignment} returns this
*/
proto.growcloud.v1.domain.TagAssignment.prototype.setTag = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.TagAssignment} returns this
 */
proto.growcloud.v1.domain.TagAssignment.prototype.clearTag = function() {
  return this.setTag(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.TagAssignment.prototype.hasTag = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional growcloud.v1.identity.Gcn item = 3;
 * @return {?proto.growcloud.v1.identity.Gcn}
 */
proto.growcloud.v1.domain.TagAssignment.prototype.getItem = function() {
  return /** @type{?proto.growcloud.v1.identity.Gcn} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_identity_Gcn_pb.Gcn, 3));
};


/**
 * @param {?proto.growcloud.v1.identity.Gcn|undefined} value
 * @return {!proto.growcloud.v1.domain.TagAssignment} returns this
*/
proto.growcloud.v1.domain.TagAssignment.prototype.setItem = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.TagAssignment} returns this
 */
proto.growcloud.v1.domain.TagAssignment.prototype.clearItem = function() {
  return this.setItem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.TagAssignment.prototype.hasItem = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.Schedule.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.Schedule.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.Schedule} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Schedule.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    activity: (f = msg.getActivity()) && growcloud_v1_domain_Metadata_pb.Uri.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.Schedule}
 */
proto.growcloud.v1.domain.Schedule.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.Schedule;
  return proto.growcloud.v1.domain.Schedule.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.Schedule} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.Schedule}
 */
proto.growcloud.v1.domain.Schedule.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_domain_Metadata_pb.Uri;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.Uri.deserializeBinaryFromReader);
      msg.setActivity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.Schedule.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.Schedule.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.Schedule} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Schedule.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getActivity();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_domain_Metadata_pb.Uri.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.Schedule.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.Schedule} returns this
*/
proto.growcloud.v1.domain.Schedule.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.Schedule} returns this
 */
proto.growcloud.v1.domain.Schedule.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.Schedule.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Uri activity = 2;
 * @return {?proto.growcloud.v1.domain.Uri}
 */
proto.growcloud.v1.domain.Schedule.prototype.getActivity = function() {
  return /** @type{?proto.growcloud.v1.domain.Uri} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.Uri, 2));
};


/**
 * @param {?proto.growcloud.v1.domain.Uri|undefined} value
 * @return {!proto.growcloud.v1.domain.Schedule} returns this
*/
proto.growcloud.v1.domain.Schedule.prototype.setActivity = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.Schedule} returns this
 */
proto.growcloud.v1.domain.Schedule.prototype.clearActivity = function() {
  return this.setActivity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.Schedule.prototype.hasActivity = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.Program.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.Program.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.Program} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Program.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    system: (f = msg.getSystem()) && growcloud_v1_domain_Metadata_pb.Uri.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.Program}
 */
proto.growcloud.v1.domain.Program.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.Program;
  return proto.growcloud.v1.domain.Program.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.Program} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.Program}
 */
proto.growcloud.v1.domain.Program.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_domain_Metadata_pb.Uri;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.Uri.deserializeBinaryFromReader);
      msg.setSystem(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.Program.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.Program.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.Program} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Program.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getSystem();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_domain_Metadata_pb.Uri.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.Program.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.Program} returns this
*/
proto.growcloud.v1.domain.Program.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.Program} returns this
 */
proto.growcloud.v1.domain.Program.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.Program.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Uri system = 2;
 * @return {?proto.growcloud.v1.domain.Uri}
 */
proto.growcloud.v1.domain.Program.prototype.getSystem = function() {
  return /** @type{?proto.growcloud.v1.domain.Uri} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.Uri, 2));
};


/**
 * @param {?proto.growcloud.v1.domain.Uri|undefined} value
 * @return {!proto.growcloud.v1.domain.Program} returns this
*/
proto.growcloud.v1.domain.Program.prototype.setSystem = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.Program} returns this
 */
proto.growcloud.v1.domain.Program.prototype.clearSystem = function() {
  return this.setSystem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.Program.prototype.hasSystem = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.Enclosure.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.Enclosure.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.Enclosure} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Enclosure.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.Enclosure}
 */
proto.growcloud.v1.domain.Enclosure.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.Enclosure;
  return proto.growcloud.v1.domain.Enclosure.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.Enclosure} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.Enclosure}
 */
proto.growcloud.v1.domain.Enclosure.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.Enclosure.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.Enclosure.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.Enclosure} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Enclosure.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.Enclosure.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.Enclosure} returns this
*/
proto.growcloud.v1.domain.Enclosure.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.Enclosure} returns this
 */
proto.growcloud.v1.domain.Enclosure.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.Enclosure.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.Site.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.Site.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.Site} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Site.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.Site}
 */
proto.growcloud.v1.domain.Site.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.Site;
  return proto.growcloud.v1.domain.Site.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.Site} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.Site}
 */
proto.growcloud.v1.domain.Site.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.Site.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.Site.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.Site} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Site.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.Site.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.Site} returns this
*/
proto.growcloud.v1.domain.Site.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.Site} returns this
 */
proto.growcloud.v1.domain.Site.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.Site.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.Asset}
 */
proto.growcloud.v1.domain.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.Asset;
  return proto.growcloud.v1.domain.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.Asset}
 */
proto.growcloud.v1.domain.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.Asset.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.Asset} returns this
*/
proto.growcloud.v1.domain.Asset.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.Asset} returns this
 */
proto.growcloud.v1.domain.Asset.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.Asset.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.Tag.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.Tag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.Tag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Tag.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.Tag}
 */
proto.growcloud.v1.domain.Tag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.Tag;
  return proto.growcloud.v1.domain.Tag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.Tag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.Tag}
 */
proto.growcloud.v1.domain.Tag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.Tag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.Tag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.Tag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.Tag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.Tag.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.Tag} returns this
*/
proto.growcloud.v1.domain.Tag.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.Tag} returns this
 */
proto.growcloud.v1.domain.Tag.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.Tag.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.growcloud.v1.domain);
