import React from 'react'

function IconSVG(props) {
    const {
        theme,
        toRoute='/',
        label,
        color='white',
        ...rest
    } = props;

    return (
        <svg width="32" height="24" xmlns="http://www.w3.org/2000/svg">
            <rect
                width="32"
                height="24"
                rx="5"
                ry="5"
                style={{
                    fill: color,
                    stroke: 'white',
                    strokeWidth: 0,
                    fillOpacity: 0.6,
                    strokeOpacity: 0.9
                }}
            />
            {label && (
                <text
                    x={16}
                    y={18}
                    textAnchor="middle"
                    fill={`charcoal`}
                    fontWeight={`bolder`}
                >
                    <tspan
                    >
                        {label}
                    </tspan>
                </text>
            )}
        </svg>
    )
}

export default (IconSVG);