import React from 'react';
import {connect} from 'react-redux';
import {
    createDynamicCalendarChartAction,
    setSelectedItem
} from './../mw/calendar-chart-service/actions/calendar-chart-service-actions';

import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';
//import OrdinalFrame from "graphing-commons-x#semiotic/lib/OrdinalFrame"
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Skeleton from '@mui/material/Skeleton';

const styles = theme => ({
	frameContainer: {
		position: 'relative',
		overflowX: 'scroll',
		width: '1440px',
		overflowY: 'hidden',
	}
})

const makeAxes = (tickValues) => {
    return [{
        key: "totals-axis",
        orient: "left",
        className: "totals",
        name: "CountAxis",
        tickValues: tickValues,
        tickFormat: d => d,
        label: ""
    }
    ]
}

class DynamicCalendarChart extends React.Component {

    expandedRowHandler(context) {
        const {expandedRowComponentProvider: ExpandedRowComponentProvider} = this.props;
        if (ExpandedRowComponentProvider) {
            return (
                <ExpandedRowComponentProvider {...context}/>
            )
        }
        return (null)
    }

    contextualCellComponentProvider(gridContext, context, e) {
        const {cellComponentProvider: CellComponentProvider} = this.props;
        return (
            <CellComponentProvider {...context} {...gridContext}/>
        )
    }

    onSortingChange(sorting) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'sorting', sorting))
    }

    onSelectionChange(selection) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'selection', selection))
    }

    onExpandedRowIdsChange(expandedRowIds) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'expandedRowIds', expandedRowIds))
    }

    onGroupingChange(grouping) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'grouping', grouping))
    }

    onExpandedGroupsChange(expandedGroups) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'expandedGroups', expandedGroups))
    }

    onFiltersChange(filters) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'filters', filters))
    }

    onCurrentPageChange(currentPage) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'currentPage', currentPage))
    }

    onPageSizeChange(pageSize) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'pageSize', pageSize))
    }

    onColumnOrderChange(order) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'columnOrder', order))
    }

    onColumnWidthsChange(widths) {
        const {dispatch, channel, currentDynamicCalendarChart} = this.props;
        dispatch(createDynamicCalendarChartAction(channel, 'columnWidths', widths))
    }

    render() {

        const {
        	classes,
            pageContext,
            currentDynamicCalendarChart,
            dispatch,
            channel,
            rightDisabled,
            leftDisabled,
            rightAction,
            leftAction,
            chartData,
        } = this.props;

        if (!currentDynamicCalendarChart) {
            return <Loading message={'Loading (awaiting current grid'}/>
        }

        if (!pageContext.page) {
            return <Loading message={'Loading (awaiting page) ..'}/>
        }

        const {
            rAccessor,
            colorHash,
            tickValues,
        } = currentDynamicCalendarChart.currentCurrentConfiguration;

        //TODO should be set when bounds are met

        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                    AA
                     </Grid>
                    <Grid item xs={3}>
                    AB
                     </Grid>
                      <Grid item xs={3}>
                    Ac
                     </Grid>
                      <Grid item xs={3}>
                    Ad
                     </Grid>

                        {/*<div
                            size={[1440, 810]}
                            data={chartData}
                            type={"bar"}
                            oAccessor={"month"}
                            rAccessor={rAccessor}
                            oPadding={20}
                            multiAxis={false}
                            style={d => {
                                return {fill: colorHash[rAccessor[d.rIndex]], stroke: "white"}
                            }}
                            axes={makeAxes(tickValues)}
                            customClickBehavior={(item) => {
                                dispatch(setSelectedItem(channel, item))
                            }}
                            renderOrder={["pieces"]}
                            oLabel={true}
                            margin={{top: 10, bottom: 50, left: 60, right: 60}}
                            className={classes.frameContainer}
                        />*/}


                    {rightAction && leftAction && (
                        <React.Fragment>
                            <Grid item xs={6}>
                                <Grid container justifyContent={'flex-start'}>
                                    <Grid item>
                                        <IconButton
                                            onClick={leftAction}
                                            disabled={leftDisabled}
                                        >
                                            <ChevronLeftIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container justifyContent={'flex-end'}>
                                    <Grid item>
                                        <IconButton
                                            onClick={rightAction}
                                            disabled={rightDisabled}
                                        >
                                            <ChevronRightIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>

            </React.Fragment>
        )
    }
}

const mapToProps = state => {
    return {}
}

export default withStyles(styles)(connect(mapToProps)(DynamicCalendarChart));