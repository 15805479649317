import React from 'react'
import PropTypes from 'prop-types';
import {Link as RouterLink} from 'react-router-dom';
import { withStyles } from '@mui/styles';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import InternalLink from '../links/InternalLink';

const styles = theme => ({
    root: {
        margin: 0,
//		padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
        lineHeight: '1em',
        padding: theme.spacing(1.375, 3),
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightMedium,

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText
        },
        '&:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
        '&:active': {
            boxShadow: '0px 1px 3px 0px rgba(0,0,0, 0.25)',
        }
    },
    containedSecondary: {
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.secondary.contrastText
        },
        '&:focus': {
            backgroundColor: theme.palette.secondary.dark,
        }
    },
    mini: {
        padding: theme.spacing(0.8, 3),
    },
    dense: {
		padding: theme.spacing(1.25, 2.75),
	},
    publicButton: {
        padding: theme.spacing(1.75, 4),
    },
});


const CTAButton = (props) => {
    const {
        classes,
        buttonId,
        buttonText,
        buttonColor,
        buttonIcon,
        iconPosition,
        linkType,
        toLocation,
        fullWidth,
        publicButton,
        dense,
        mini,
        style,
        ...rest
    } = props;

    const className = classnames(
		dense && classes.dense,
		publicButton && classes.publicButton
	);

    if (linkType === 'Link') {
        return (
            <Button
                id={buttonId}
                component={InternalLink}
                fullWidth={fullWidth}
                variant="contained"
                color={buttonColor ? buttonColor : 'primary'}
                classes={{root: classes.root, sizeSmall: mini && classes.mini}}
//                className={classnames({[classes.containedSecondary]: buttonColor === 'secondary'})}
				className={className}
                to={toLocation}
                disableFocusRipple
                disableRipple
                style={style}
                disabled={rest.disabled}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {buttonIcon && iconPosition === 'left' && (
                    <span style={{marginRight: '8px'}}>
                        {buttonIcon}
                    </span>
                )}
                {buttonText}
                {buttonIcon && iconPosition === 'right' && (
                    <span style={{marginLeft: '8px'}}>
                        {buttonIcon}
                    </span>
                )}
            </Button>
        )
    }

    return (
        <Button
            id={buttonId}
            variant="contained"
            color={buttonColor ? buttonColor : 'primary'}
            classes={{root: classes.root, sizeSmall: mini && classes.mini}}
            className={className}
            disableFocusRipple
            disableRipple
            {...rest}
        >
            {buttonIcon && iconPosition === 'left' && (
                <span className={classes.leftIcon}>
                    {buttonIcon}
                </span>
            )}
            {buttonText}
            {buttonIcon && iconPosition === 'right' && (
                <span className={classes.rightIcon}>
                  {buttonIcon}
                </span>
            )}
        </Button>
    )
}

CTAButton.defaultProps = {
    color: 'primary',
    fullWidth: false,
    disabled: false,
};

CTAButton.propTypes = {
    classes: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
    color: PropTypes.string,
    disabled: PropTypes.bool,
};

export default withStyles(styles)(CTAButton);