import React from 'react';

const UserContext = React.createContext({
})

class UserContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStatus: 'Determining identity...',
        }
    }

    componentDidMount() {
        try {
            this.setState({
                currentStatus: 'active',
            })
        } catch (error) {
            this.setState({currentStatus: 'fail'});
        }
    }

    render() {
        const {children} = this.props;
        const {currentStatus} = this.state;

        if ('active' == currentStatus) {
            return (
                <UserContext.Provider value={{}}>
                    {children}
                </UserContext.Provider>
            )
        }
        if ('fail' == currentStatus) {
            return <div>Failed to probe device</div>;
        }
        return <div>{currentStatus} ... </div>
    }
}

export default (UserContextProvider)

export const withUserContext = (Component) => {
    return (props) => {
        return (
            <UserContext.Consumer>
                {({deviceContext, preferencesContext}) => (
                    <Component
                        {...props}
                        deviceContext={deviceContext}
                        preferencesContext={preferencesContext}
                    />
                )}
            </UserContext.Consumer>
        )
    }
}