let counter = 0;

export const makeActionId = (type) => {
    return type.toLowerCase().concat('-').concat(""+Date.now()).concat("" + ++counter);
}

export const LOAD_GC_CONFIGURATIONS = '$$LOAD_GC_CONFIGURATIONS';

export const LOAD_GC_CONFIGURATIONS_SUCCESS = '$$LOAD_GC_CONFIGURATIONS_SUCCESS';
export const LOAD_GC_CONFIGURATIONS_FAIL = '$$LOAD_GC_CONFIGURATIONS_FAIL';

export const loadGcConfigurations = (userContext) => {
    if(userContext){
        return { type: LOAD_GC_CONFIGURATIONS, actionId: makeActionId(LOAD_GC_CONFIGURATIONS), userContext }
    }
    return clearGcConfigurations()
}

export const CLEAR_GC_CONFIGURATIONS = '$$CLEAR_GC_CONFIGURATIONS_';
export const clearGcConfigurations = () => {
    return { type: CLEAR_GC_CONFIGURATIONS}
}

export const LOAD_ACTIVE_PRODUCTION_MODELS = '$$LOAD_ACTIVE_PRODUCTION_MODELS';
export const LOAD_ACTIVE_PRODUCTION_MODELS_SUCCESS = '$$LOAD_ACTIVE_PRODUCTION_MODELS_SUCCESS';
export const LOAD_ACTIVE_PRODUCTION_MODELS_FAIL = '$$LOAD_ACTIVE_PRODUCTION_MODELS_FAIL';

export const loadActiveProductionModels = (userContext) => {
    if(userContext){
        return { type: LOAD_ACTIVE_PRODUCTION_MODELS, actionId: makeActionId(LOAD_ACTIVE_PRODUCTION_MODELS), userContext }
    }
    return clearGcConfigurations()
}

export const LOAD_SITES_CONFIGURATIONS_MODELS = '$$LOAD_SITES_CONFIGURATIONS_MODELS';
export const LOAD_SITES_CONFIGURATIONS_MODELS_SUCCESS = '$$LOAD_SITES_CONFIGURATIONS_MODELS_SUCCESS';
export const LOAD_SITES_CONFIGURATIONS_MODELS_FAIL = '$$LOAD_SITES_CONFIGURATIONS_MODELS_FAIL';

export const loadSitesConfigurationsModels = (userContext) => {
    if(userContext){
        return { type: LOAD_SITES_CONFIGURATIONS_MODELS, actionId: makeActionId(LOAD_SITES_CONFIGURATIONS_MODELS), userContext }
    }
    return clearGcConfigurations()
}

export const LOAD_PRODUCT_LINES_CONFIGURATIONS = '$$LOAD_PRODUCT_LINES_CONFIGURATIONS';

export const LOAD_PRODUCT_LINES_CONFIGURATIONS_SUCCESS = '$$LOAD_PRODUCT_LINES_CONFIGURATIONS_SUCCESS';
export const LOAD_PRODUCT_LINES_CONFIGURATIONS_FAIL = '$$LOAD_PRODUCT_LINES_CONFIGURATIONS_FAIL';

export const loadProductionPermitsConfigurations = (userContext) => {
    if(userContext){
        return { type: LOAD_PRODUCT_LINES_CONFIGURATIONS, actionId: makeActionId(LOAD_PRODUCT_LINES_CONFIGURATIONS), userContext }
    }
    return clearCalendarsConfigurations()
}

export const LOAD_CALENDARS_CONFIGURATIONS = '$$LOAD_CALENDARS_CONFIGURATIONS';

export const LOAD_CALENDARS_CONFIGURATIONS_SUCCESS = '$$LOAD_CALENDARS_CONFIGURATIONS_SUCCESS';
export const LOAD_CALENDARS_CONFIGURATIONS_FAIL = '$$LOAD_CALENDARS_CONFIGURATIONS_FAIL';

export const loadCalendarsConfigurations = (userContext) => {
    if(userContext){
        return { type: LOAD_CALENDARS_CONFIGURATIONS, actionId: makeActionId(LOAD_CALENDARS_CONFIGURATIONS), userContext }
    }
    return clearCalendarsConfigurations()
}