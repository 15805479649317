import React from 'react';

import DrawerBase from '@dc/dc/components/DrawerBase';
import NavigationList from "./NavigationList";


class NavigationDrawer extends React.Component {

    render() {
        const {
            children,
            items,
        } = this.props;

        return (
            <>
                <DrawerBase
                    menuBarDrawerOpen={true}
                    onHandleMenuBarDrawerClose={() => {
                    }}
                    PaperProps={{elevation: 2}}
                >
                    <NavigationList items={items}/>

                    {children && children}
                </DrawerBase>
            </>
        )
    }
}

export default (NavigationDrawer)
