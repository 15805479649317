import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

import {navigationLinkStyleSheet} from '@dc/dc/style/BaseStyles';

const styles = theme => (Object.assign({
    spanColorPrimary: {
        display: 'inline-flex',
        color: theme.palette.secondary.main,
    },
    spanColorActive: {
        display: 'inline-flex',
        color: theme.palette.primary.main,
    }
}, navigationLinkStyleSheet))

const TwoToneLinkedLeadText = ({classes, theme, masthead, responsiveText, width, style}) => {
    console.log('passed styles: ', style)
    let newTxt = masthead.text.split(' ')

    return (
        <Link to={masthead.to} className={classes.linkWrapper}>
            <Typography
                variant="body1"
                className={classes.spanColorPrimary}
                component="span">
                {responsiveText ? width === 'xs' ? newTxt[0].substring(0, 1) : newTxt[0] : newTxt[0]}
            </Typography>
            <Typography
                variant="body1"
                className={classes.spanColorActive}
                component="span"
                style={{fontWeight: '500'}}
                noWrap
            >
                {responsiveText ? width === 'xs' ? newTxt[1].substring(0, 1) : newTxt[1] : newTxt[1]}
            </Typography>
        </Link>
    )
}

TwoToneLinkedLeadText.propTypes = {
    masthead: PropTypes.object.isRequired
}

export default withStyles(styles, {withTheme: true})(TwoToneLinkedLeadText)


