import React from 'react';
import {connect} from 'react-redux';
import Layout from "./Layout";
import {toggleRequiresAppbar} from "../actions/appbar-actions";

export const withDefaultLayout = (WrappedComponent) => {

    return connect(state => {
        return {user: state.auth.user}
    })(class extends React.Component {

        constructor(props) {
            super(props);
            this.state = {}
        }

        render() {
            const {
                userActionsBlockRenderer,
                mainAppbarMenuDrawerItems,
                menuBarDrawer,
                globalConfiguration,
                ...rest
            } = this.props;

            return (
                <Layout
                    pathName={window.location.pathname}
                    menuBarDrawer={menuBarDrawer}
                    userActionsBlockRenderer={userActionsBlockRenderer || (() => {})}
                    mainAppbarMenuDrawerItems={mainAppbarMenuDrawerItems}
                    globalConfiguration={globalConfiguration}
                >
                    <WrappedComponent
                        {...rest}
                    />
                </Layout>
            )
        }
    })
}

export const withFocusModeLayout = (WrappedComponent) => {

    return connect(state => {
        return {
            requiresDefaultAppbar: state.appbar.requiresDefaultAppbar,
        }
    })(class extends React.Component {

        constructor(props) {
            super(props);
            this.state = {}
        }

        componentDidMount() {
            const {dispatch, requiresDefaultAppbar} = this.props;
            if (requiresDefaultAppbar) {
                dispatch(toggleRequiresAppbar())
            }
        }

        render() {
            const {userActionsBlockRenderer,globalConfiguration} = this.props;
            return (
                <Layout
                    pathName={window.location.pathname}
                    globalConfiguration={globalConfiguration}
                    userActionsBlockRenderer={userActionsBlockRenderer || (() => {
                    })}
                >
                    <WrappedComponent
                        {...this.props}
                    />
                </Layout>
            )
        }
    })
}