import React from 'react'
import PropTypes from 'prop-types';
import FullScreenDialog from './FullScreenDialog';
import DefaultDialog from './DefaultDialog';

class CriticalActionDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            loaded: false,
            stateExists: true,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {loaded, stateExists} = state;
        const {onOpen, initialValues} = props;
        if (stateExists && !loaded) {
            if (onOpen) {
                onOpen(initialValues)
                return {loaded: true};
            }
        }
        return null;
    }

    render() {
        const {
            width,
            children,
            ...rest
        } = this.props;

        const Dialog = width === 'xs' || width === 'sm' ? FullScreenDialog : DefaultDialog;

        return (
            <Dialog
                {...rest}
            >
                {children}
            </Dialog>
        )
    }
}

CriticalActionDialog.defaultProps = {
    dialogTitle: 'TODO'
}

CriticalActionDialog.propTypes = {
    dialogTitle: PropTypes.string,
};

export default (CriticalActionDialog)