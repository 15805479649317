import React from 'react'
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function ListItemLink(props) {
    const {primary, to, children} = props;
    console.log('ListItemLink props: ', props)
    return (
        <ListItem
            button
            component={Link}
            to={to}
        >
            <ListItemText
                primary={primary}
                primaryTypographyProps={{variant: 'body2'}}
            />
            {children && children}
        </ListItem>
    );
}

ListItemLink.propTypes = {
    primary: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
};

export default ListItemLink;