// source: growcloud/v1/domain/ActiveSystems.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var growcloud_v1_identity_Gcn_pb = require('../../../growcloud/v1/identity/Gcn_pb.js');
goog.object.extend(proto, growcloud_v1_identity_Gcn_pb);
var growcloud_v1_domain_Metadata_pb = require('../../../growcloud/v1/domain/Metadata_pb.js');
goog.object.extend(proto, growcloud_v1_domain_Metadata_pb);
var growcloud_v1_domain_ProductionSystems_pb = require('../../../growcloud/v1/domain/ProductionSystems_pb.js');
goog.object.extend(proto, growcloud_v1_domain_ProductionSystems_pb);
goog.exportSymbol('proto.growcloud.v1.domain.ActiveProductionModel', null, global);
goog.exportSymbol('proto.growcloud.v1.domain.SitesConfigurationsModel', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.ActiveProductionModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.growcloud.v1.domain.ActiveProductionModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.ActiveProductionModel.displayName = 'proto.growcloud.v1.domain.ActiveProductionModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.growcloud.v1.domain.SitesConfigurationsModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.growcloud.v1.domain.SitesConfigurationsModel.repeatedFields_, null);
};
goog.inherits(proto.growcloud.v1.domain.SitesConfigurationsModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.growcloud.v1.domain.SitesConfigurationsModel.displayName = 'proto.growcloud.v1.domain.SitesConfigurationsModel';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.ActiveProductionModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.ActiveProductionModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ActiveProductionModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    productionlot: (f = msg.getProductionlot()) && growcloud_v1_domain_ProductionSystems_pb.ProductionLot.toObject(includeInstance, f),
    site: (f = msg.getSite()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    enclosure: (f = msg.getEnclosure()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    productline: (f = msg.getProductline()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    productionpermit: (f = msg.getProductionpermit()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel}
 */
proto.growcloud.v1.domain.ActiveProductionModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.ActiveProductionModel;
  return proto.growcloud.v1.domain.ActiveProductionModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.ActiveProductionModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel}
 */
proto.growcloud.v1.domain.ActiveProductionModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_domain_ProductionSystems_pb.ProductionLot;
      reader.readMessage(value,growcloud_v1_domain_ProductionSystems_pb.ProductionLot.deserializeBinaryFromReader);
      msg.setProductionlot(value);
      break;
    case 7:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setSite(value);
      break;
    case 8:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setEnclosure(value);
      break;
    case 9:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setProductline(value);
      break;
    case 10:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setProductionpermit(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.ActiveProductionModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.ActiveProductionModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.ActiveProductionModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getProductionlot();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      growcloud_v1_domain_ProductionSystems_pb.ProductionLot.serializeBinaryToWriter
    );
  }
  f = message.getSite();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getEnclosure();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getProductline();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getProductionpermit();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
*/
proto.growcloud.v1.domain.ActiveProductionModel.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ProductionLot productionLot = 2;
 * @return {?proto.growcloud.v1.domain.ProductionLot}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.getProductionlot = function() {
  return /** @type{?proto.growcloud.v1.domain.ProductionLot} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_ProductionSystems_pb.ProductionLot, 2));
};


/**
 * @param {?proto.growcloud.v1.domain.ProductionLot|undefined} value
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
*/
proto.growcloud.v1.domain.ActiveProductionModel.prototype.setProductionlot = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.clearProductionlot = function() {
  return this.setProductionlot(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.hasProductionlot = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional InfoObject site = 7;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.getSite = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 7));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
*/
proto.growcloud.v1.domain.ActiveProductionModel.prototype.setSite = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.clearSite = function() {
  return this.setSite(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.hasSite = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional InfoObject enclosure = 8;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.getEnclosure = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 8));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
*/
proto.growcloud.v1.domain.ActiveProductionModel.prototype.setEnclosure = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.clearEnclosure = function() {
  return this.setEnclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.hasEnclosure = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional InfoObject productLine = 9;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.getProductline = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 9));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
*/
proto.growcloud.v1.domain.ActiveProductionModel.prototype.setProductline = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.clearProductline = function() {
  return this.setProductline(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.hasProductline = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional InfoObject productionPermit = 10;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.getProductionpermit = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 10));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
*/
proto.growcloud.v1.domain.ActiveProductionModel.prototype.setProductionpermit = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.clearProductionpermit = function() {
  return this.setProductionpermit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.hasProductionpermit = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool active = 5;
 * @return {boolean}
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.growcloud.v1.domain.ActiveProductionModel} returns this
 */
proto.growcloud.v1.domain.ActiveProductionModel.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.repeatedFields_ = [2,3,4,8,9,5,6,7,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.toObject = function(opt_includeInstance) {
  return proto.growcloud.v1.domain.SitesConfigurationsModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.growcloud.v1.domain.SitesConfigurationsModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    infoobject: (f = msg.getInfoobject()) && growcloud_v1_domain_Metadata_pb.InfoObject.toObject(includeInstance, f),
    siteList: jspb.Message.toObjectList(msg.getSiteList(),
    growcloud_v1_domain_Metadata_pb.InfoObject.toObject, includeInstance),
    enclosureList: jspb.Message.toObjectList(msg.getEnclosureList(),
    growcloud_v1_domain_Metadata_pb.InfoObject.toObject, includeInstance),
    assetList: jspb.Message.toObjectList(msg.getAssetList(),
    growcloud_v1_domain_Metadata_pb.InfoObject.toObject, includeInstance),
    programList: jspb.Message.toObjectList(msg.getProgramList(),
    growcloud_v1_domain_Metadata_pb.InfoObject.toObject, includeInstance),
    scheduleList: jspb.Message.toObjectList(msg.getScheduleList(),
    growcloud_v1_domain_Metadata_pb.InfoObject.toObject, includeInstance),
    enclosuresiteassignmentList: jspb.Message.toObjectList(msg.getEnclosuresiteassignmentList(),
    growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.toObject, includeInstance),
    programenclosureassignmentList: jspb.Message.toObjectList(msg.getProgramenclosureassignmentList(),
    growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.toObject, includeInstance),
    scheduleprogramassignmentList: jspb.Message.toObjectList(msg.getScheduleprogramassignmentList(),
    growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.toObject, includeInstance),
    assetenclosureassignmentList: jspb.Message.toObjectList(msg.getAssetenclosureassignmentList(),
    growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.growcloud.v1.domain.SitesConfigurationsModel;
  return proto.growcloud.v1.domain.SitesConfigurationsModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.growcloud.v1.domain.SitesConfigurationsModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.setInfoobject(value);
      break;
    case 2:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.addSite(value);
      break;
    case 3:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.addEnclosure(value);
      break;
    case 4:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.addAsset(value);
      break;
    case 8:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.addProgram(value);
      break;
    case 9:
      var value = new growcloud_v1_domain_Metadata_pb.InfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.InfoObject.deserializeBinaryFromReader);
      msg.addSchedule(value);
      break;
    case 5:
      var value = new growcloud_v1_domain_Metadata_pb.AssignmentInfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.deserializeBinaryFromReader);
      msg.addEnclosuresiteassignment(value);
      break;
    case 6:
      var value = new growcloud_v1_domain_Metadata_pb.AssignmentInfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.deserializeBinaryFromReader);
      msg.addProgramenclosureassignment(value);
      break;
    case 7:
      var value = new growcloud_v1_domain_Metadata_pb.AssignmentInfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.deserializeBinaryFromReader);
      msg.addScheduleprogramassignment(value);
      break;
    case 10:
      var value = new growcloud_v1_domain_Metadata_pb.AssignmentInfoObject;
      reader.readMessage(value,growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.deserializeBinaryFromReader);
      msg.addAssetenclosureassignment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.growcloud.v1.domain.SitesConfigurationsModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.growcloud.v1.domain.SitesConfigurationsModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfoobject();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getSiteList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getEnclosureList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getAssetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getProgramList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getScheduleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      growcloud_v1_domain_Metadata_pb.InfoObject.serializeBinaryToWriter
    );
  }
  f = message.getEnclosuresiteassignmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.serializeBinaryToWriter
    );
  }
  f = message.getProgramenclosureassignmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.serializeBinaryToWriter
    );
  }
  f = message.getScheduleprogramassignmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.serializeBinaryToWriter
    );
  }
  f = message.getAssetenclosureassignmentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      growcloud_v1_domain_Metadata_pb.AssignmentInfoObject.serializeBinaryToWriter
    );
  }
};


/**
 * optional InfoObject infoObject = 1;
 * @return {?proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getInfoobject = function() {
  return /** @type{?proto.growcloud.v1.domain.InfoObject} */ (
    jspb.Message.getWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 1));
};


/**
 * @param {?proto.growcloud.v1.domain.InfoObject|undefined} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setInfoobject = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearInfoobject = function() {
  return this.setInfoobject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.hasInfoobject = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated InfoObject site = 2;
 * @return {!Array<!proto.growcloud.v1.domain.InfoObject>}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getSiteList = function() {
  return /** @type{!Array<!proto.growcloud.v1.domain.InfoObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 2));
};


/**
 * @param {!Array<!proto.growcloud.v1.domain.InfoObject>} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setSiteList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.growcloud.v1.domain.InfoObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.addSite = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.growcloud.v1.domain.InfoObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearSiteList = function() {
  return this.setSiteList([]);
};


/**
 * repeated InfoObject enclosure = 3;
 * @return {!Array<!proto.growcloud.v1.domain.InfoObject>}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getEnclosureList = function() {
  return /** @type{!Array<!proto.growcloud.v1.domain.InfoObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 3));
};


/**
 * @param {!Array<!proto.growcloud.v1.domain.InfoObject>} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setEnclosureList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.growcloud.v1.domain.InfoObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.addEnclosure = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.growcloud.v1.domain.InfoObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearEnclosureList = function() {
  return this.setEnclosureList([]);
};


/**
 * repeated InfoObject asset = 4;
 * @return {!Array<!proto.growcloud.v1.domain.InfoObject>}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getAssetList = function() {
  return /** @type{!Array<!proto.growcloud.v1.domain.InfoObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 4));
};


/**
 * @param {!Array<!proto.growcloud.v1.domain.InfoObject>} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setAssetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.growcloud.v1.domain.InfoObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.addAsset = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.growcloud.v1.domain.InfoObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearAssetList = function() {
  return this.setAssetList([]);
};


/**
 * repeated InfoObject program = 8;
 * @return {!Array<!proto.growcloud.v1.domain.InfoObject>}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getProgramList = function() {
  return /** @type{!Array<!proto.growcloud.v1.domain.InfoObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 8));
};


/**
 * @param {!Array<!proto.growcloud.v1.domain.InfoObject>} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setProgramList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.growcloud.v1.domain.InfoObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.addProgram = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.growcloud.v1.domain.InfoObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearProgramList = function() {
  return this.setProgramList([]);
};


/**
 * repeated InfoObject schedule = 9;
 * @return {!Array<!proto.growcloud.v1.domain.InfoObject>}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getScheduleList = function() {
  return /** @type{!Array<!proto.growcloud.v1.domain.InfoObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_domain_Metadata_pb.InfoObject, 9));
};


/**
 * @param {!Array<!proto.growcloud.v1.domain.InfoObject>} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setScheduleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.growcloud.v1.domain.InfoObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.domain.InfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.addSchedule = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.growcloud.v1.domain.InfoObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearScheduleList = function() {
  return this.setScheduleList([]);
};


/**
 * repeated AssignmentInfoObject enclosureSiteAssignment = 5;
 * @return {!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getEnclosuresiteassignmentList = function() {
  return /** @type{!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_domain_Metadata_pb.AssignmentInfoObject, 5));
};


/**
 * @param {!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setEnclosuresiteassignmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.growcloud.v1.domain.AssignmentInfoObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.addEnclosuresiteassignment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.growcloud.v1.domain.AssignmentInfoObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearEnclosuresiteassignmentList = function() {
  return this.setEnclosuresiteassignmentList([]);
};


/**
 * repeated AssignmentInfoObject programEnclosureAssignment = 6;
 * @return {!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getProgramenclosureassignmentList = function() {
  return /** @type{!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_domain_Metadata_pb.AssignmentInfoObject, 6));
};


/**
 * @param {!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setProgramenclosureassignmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.growcloud.v1.domain.AssignmentInfoObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.addProgramenclosureassignment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.growcloud.v1.domain.AssignmentInfoObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearProgramenclosureassignmentList = function() {
  return this.setProgramenclosureassignmentList([]);
};


/**
 * repeated AssignmentInfoObject scheduleProgramAssignment = 7;
 * @return {!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getScheduleprogramassignmentList = function() {
  return /** @type{!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_domain_Metadata_pb.AssignmentInfoObject, 7));
};


/**
 * @param {!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setScheduleprogramassignmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.growcloud.v1.domain.AssignmentInfoObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.addScheduleprogramassignment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.growcloud.v1.domain.AssignmentInfoObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearScheduleprogramassignmentList = function() {
  return this.setScheduleprogramassignmentList([]);
};


/**
 * repeated AssignmentInfoObject assetEnclosureAssignment = 10;
 * @return {!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.getAssetenclosureassignmentList = function() {
  return /** @type{!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>} */ (
    jspb.Message.getRepeatedWrapperField(this, growcloud_v1_domain_Metadata_pb.AssignmentInfoObject, 10));
};


/**
 * @param {!Array<!proto.growcloud.v1.domain.AssignmentInfoObject>} value
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
*/
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.setAssetenclosureassignmentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.growcloud.v1.domain.AssignmentInfoObject=} opt_value
 * @param {number=} opt_index
 * @return {!proto.growcloud.v1.domain.AssignmentInfoObject}
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.addAssetenclosureassignment = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.growcloud.v1.domain.AssignmentInfoObject, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.growcloud.v1.domain.SitesConfigurationsModel} returns this
 */
proto.growcloud.v1.domain.SitesConfigurationsModel.prototype.clearAssetenclosureassignmentList = function() {
  return this.setAssetenclosureassignmentList([]);
};


goog.object.extend(exports, proto.growcloud.v1.domain);
