import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';

const styles = {
    root: {
        flexGrow: 1,
    },
};

const LoadingProgress = ({classes}) => {
    return (
        <div className={classes.root}>
            <LinearProgress/>
        </div>
    );
}

LoadingProgress.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoadingProgress);