import React from 'react';
import {connect} from 'react-redux';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Text from '@dc/dc/content/Text';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import Worksheet, {WorksheetNode} from '@dc/dc/components/surface/Worksheet';
import NavigationDrawer from 'gc-ui-components/ui/navigation/NavigationDrawer';
import CalendarTool from 'gc-webapp/gc/CalendarTool'
import { a11yProps, CustomTabPanel } from './TabsCommons'
import CalendarsGrid from 'gc-webapp/gc/CalendarsGrid'
import ProductLinesGrid from 'gc-webapp/gc/ProductLinesGrid'
import {connect} from 'react-redux';
import Loading from '@dc/dcx/app/common/Loading';

import ImportProductLineDialog from 'gc-webapp/gc/ImportProductLineDialog'

const Planning = ({userContext}) => {

 const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <>
       <ContainerX AsContainer withLargeBottomMargin withLargeTopMargin>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="PRODUCT LINES" {...a11yProps(0)} />
                  <Tab label="CALENDARS" {...a11yProps(1)} />
                  {/*<Tab label="SCHEDULING EVENT FEED" {...a11yProps(2)} />*/}
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid container spacing={3}>
                	<Grid item xs={10}>
                        <ProductLinesGrid
                              userContext={userContext}
                          />
                    </Grid>
                    <Grid item xs={2}>
                        <ImportProductLineDialog/>
                    </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <CalendarsGrid
                      userContext={userContext}
                  />
              </CustomTabPanel>
              {/*<CustomTabPanel value={value} index={2}>
                    EVENTS
              </CustomTabPanel>*/}
            </ContainerX>
        </>
    )
}
export default connect(state => { })(Planning)