import React from 'react';
import PropTypes from 'prop-types';

import MessageDisplay from './MessageDisplay';
import {dcxMessages} from './../i18n/BaseAppMessages';

import LoadingProgress from '@dc/dc/components/modules/LoadingProgress';

const Loading = ({message, withProgress, withLargeTopMargin = false}) => {
    if(withProgress){
        return (
            <MessageDisplay
                message={message}
                withLargeTopMargin={withLargeTopMargin}
            >
                <LoadingProgress/>
            </MessageDisplay>
        )
    }
    return (
        <MessageDisplay
            withLargeTopMargin={withLargeTopMargin}
            message={message}
        />
    )
}

Loading.defaultProps = {
    message: dcxMessages.defaultLoadingMessage,
}
Loading.propTypes = {
    message: PropTypes.object,
}

export default (Loading);