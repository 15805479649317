//import {IdentityUtils} from 'standard-core/metro/QueryHelper';

import {ColumnDefinition, ColumnDefinitionEnum, Item} from 'gc-commons/growcloud/v1/grid/GridDefinitions_pb';
import {Axis, ColumnData, Metric} from 'gc-commons/growcloud/v1/grid/GridCommons_pb';

export default class GrowcloudGridHelper {
    static descendItemDefinition(gridColumnConfigurationDefinition, parentItemDefinitionObject) {
        /*JSONObjectBuilder itemDefinitionObjectBuilder = JSON.objectBuilder();
        itemDefinitionObject.put("title", gridColumnConfigurationDefinition.getLabel());
        if(gridColumnConfigurationDefinition.hasGridColumnConfigurationDefinition()){
           descendItemDefinition(gridColumnConfigurationDefinition.getGridColumnconfigurationdefinition(), itemDefinitionObject);
        } else {
           gridColumnConfigurationDefinition.getProcessmetricdefinitionList().forEach(processMetricDefinition -> {
              itemDefinitionObject.append("children", JSON.objectBuilder().put("columnName", processMetricDefinition.getName()));
           });
        }
        parentItemDefinitionObject.append("children", itemDefinitionObject);*/
    }


    static toColumnDataJson(columnDataSetList, columns) {
        let columnData = {};
        columnDataSetList.forEach(columnDataSet => {
            let columnDataItems = {};
            //columns.forEach(column => {
            //columnDataItems[column.uuid] = {};
            // });
            columnDataSet.getColumndataList().forEach(columnDataItem => {
                let itemCase = columnDataItem.getItemCase();
                switch (itemCase) {
                    case ColumnData.ItemCase.COUNT:
                        let count = columnDataItem.getCount().getCount();
                        columnDataItems[columnDataItem.getUuid().getUuid()] = {count};
                        break;
                    default:
                        debugger;
                }
            });
            //columnData[IdentityUtils.fromIdentity(columnDataSet.getSubject())] = columnDataItems;
        });
        return columnData;
    }

    static toJsonObject(gridConfiguration) {

        const gridConfigurationIdentity = gridConfiguration.getIdentity();

        let viewDefinitionObject = {
            columns: [],
            columnOrder: [],
            columnWidths: [],
            columnBands: [],
            name: gridConfiguration.getName(),
            uuid: gridConfigurationIdentity
        }

        let fieldMap = {};
        Object.keys(ColumnDefinition.Field).forEach(key => {
            fieldMap[ColumnDefinition.Field[key]] = key
        });

        let metricMap = {};
        Object.keys(Metric).forEach(key => {
            metricMap[Metric[key]] = key
        });

        let axisMap = {};
        Object.keys(Axis).forEach(key => {
            axisMap[Axis[key]] = key
        });

        let nameMap = {};
        Object.keys(ColumnDefinitionEnum.Name).forEach(key => {
            nameMap[ColumnDefinitionEnum.Name[key]] = key
        });

        gridConfiguration.getGridColumnconfigurationdefinitionList().forEach(gridColumnConfigurationDefinition => {
            if (!gridColumnConfigurationDefinition.getUuid()) {
                debugger;
            }
            let itemDefinitionObject = {
                title: gridColumnConfigurationDefinition.getLabel(),
                children: [],
                uuid: gridColumnConfigurationDefinition.getUuid().getUuid()
            };
            gridColumnConfigurationDefinition.getItemList().forEach(item => {
                const itemCase = item.getItemCase();
                switch (itemCase) {
                    case Item.ItemCase.GRIDCOLUMNCONFIGURATIONDEFINITION:
                        debugger;
                        GrowcloudGridHelper.descendItemDefinition(item.getGridColumnconfigurationdefinition(), itemDefinitionObject);
                        break;

                    case Item.ItemCase.COLUMNDEFINITION:

                        let columnDefinition = item.getColumndefinition();

                        const columnName1 = fieldMap[columnDefinition.getField()]
                        .toLowerCase().replace(/\_/g, '-')
                        .concat('@').concat(columnDefinition.getColumnurn().getUrn());

                        viewDefinitionObject['columnOrder'].push(columnName1);
                        viewDefinitionObject['columnWidths'].push({
                            columnName: columnName1,
                            width: 300
                        });
                        viewDefinitionObject['columns'].push({
                            title: columnDefinition.getLabel(),
                            name: columnName1,
                            uuid: columnDefinition.getColumnurn().getUrn(),
                        });
                        itemDefinitionObject['children'].push({
                            columnName: columnName1
                        });
                        break;
                    default:

                }
            });
            viewDefinitionObject['columnBands'].push(itemDefinitionObject);
        });
        return viewDefinitionObject;
    }

//    static extractStateRequestObject(gridConfiguration) {
//        let requestObject = {};
//
//        gridConfiguration.getGridColumnconfigurationdefinitionList().forEach(gridColumnConfigurationDefinition => {
//            gridColumnConfigurationDefinition.getItemList().forEach(item => {
//                const itemCase = item.getItemCase();
//                switch (itemCase) {
//                    case Item.COLUMNDEFINITIONENUMENTRY:
//                        let columnDefinitionEnumEntry = item.getColumndefinitionenumentry();
//                        let entryDefaultColumnDefinition = columnDefinitionEnumEntry.getDefaultcolumndefinition();
//                        let entryColumnDefinitionEnum = columnDefinitionEnumEntry.getColumndefinitionenum();
//
//                        gridConfiguration.getColumndefinitionenumList().forEach(columnDefinitionEnum => {
//                            if (entryColumnDefinitionEnum.getUuid() == columnDefinitionEnum.getColumnurn().getUrn()) {
//                                columnDefinitionEnum.getColumndefinitionList().forEach(columnDefinition => {
//                                    if (entryDefaultColumnDefinition.getUuid() == columnDefinition.getColumnurn().getUrn()) {
//                                        requestObject.columnDefinitionEnum = {
//                                            columnDefinitionEnum: entryColumnDefinitionEnum.getUuid(),
//                                            columnDefinition: entryDefaultColumnDefinition.getUuid()
//                                        }
//                                    }
//                                })
//                            }
//                        })
//                        break;
//                    default:
//                }
//            });
//        });
//        return requestObject;
//    }
}