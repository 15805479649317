export const GET_PAGE_CONFIGURATION = '$$GET_PAGE_CONFIGURATION';
export const GET_PAGE_CONFIGURATION_SUCCESS = '$$GET_PAGE_CONFIGURATION_SUCCESS';
export const GET_PAGE_CONFIGURATION_FAIL = '$$GET_PAGE_CONFIGURATION_FAIL';

export const getPageConfiguration = (contextHash, pageDetails, endpointUrl) => {
    return {type: GET_PAGE_CONFIGURATION, contextHash, pageDetails, endpointUrl}
}

export const SET_CURRENT_TAB = '$$SET_CURRENT_TAB';

export const setCurrentTab = (contextHash, tabName) => {
    return {type: SET_CURRENT_TAB, contextHash, tabName}
}

export const SET_CURRENT_CONTEXT = '$$SET_CURRENT_CONTEXT';

export const setCurrentContext = (contextHash) => {
    return {type: SET_CURRENT_CONTEXT, contextHash}
}