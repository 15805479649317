export const SET_AXIS = '$$SET_AXIS';

export const setAxis = (contextHash, axis, channel) => {
    return {type: SET_AXIS, contextHash, axis, channel}
}


export const SET_CURRENT_CHART_CONFIGURATION = '$$SET_CURRENT_CHART_CONFIGURATION';

export const setCurrentChartConfiguration = (contextHash, channel, data) => {
    return {type: SET_CURRENT_CHART_CONFIGURATION, contextHash, channel, data}
}


export const SET_SELECTED_ITEM = '$$SET_SELECTED_ITEM';

export const setSelectedItem = (channel, item) => {
    return {type: SET_SELECTED_ITEM, channel, item}
}

export const CLEAR_SELECTED_ITEM = '$$CLEAR_SELECTED_ITEM';

export const clearSelectedItem = (channel) => {
    return {type: CLEAR_SELECTED_ITEM, channel}
}

export const SET_MONTH_RANGE = '$$SET_MONTH_RANGE';

export const setMonthRange = (channel, fromMonth, toMonth) => {
    return {type: SET_MONTH_RANGE, channel, fromMonth, toMonth}
}

export const createDynamicCalendarChartAction = () => {
    return {}
}