export const initialChartData = [
    { consulting: 500, storage: 500, cloud: 200, licensing: 200, month: "May 20" },
    { consulting: 500, storage: 500, cloud: 300, licensing: 175, month: "Jun 20" },
    { consulting: 500, storage: 500, cloud: 500, licensing: 125, month: "Jul 20" },
    { consulting: 500, storage: 500, cloud: 500, licensing: 150, month: "Aug 20" },
    { consulting: 500, storage: 500, cloud: 700, licensing: 100, month: "Sep 20" },
    { consulting: 500, storage: 500, cloud: 700, licensing: 75, month: "Oct 20" },
    { consulting: 500, storage: 500, cloud: 400, licensing: 50, month: "Nov 20" },
    { consulting: 500, storage: 500, cloud: 200, licensing: 200, month: "Dec 20" },
    { consulting: 500, storage: 500, cloud: 500, licensing: 150, month: "Jan 21" },
    { consulting: 500, storage: 500, cloud: 700, licensing: 100, month: "Feb 21" },
    { consulting: 500, storage: 500, cloud: 700, licensing: 75, month: "Mar 21" },
    { consulting: 500, storage: 500, cloud: 400, licensing: 50, month: "Apr 21" },
    { consulting: 500, storage: 500, cloud: 200, licensing: 200, month: "May 21" },
    { consulting: 500, storage: 500, cloud: 300, licensing: 175, month: "Jun 21" },
    { consulting: 500, storage: 500, cloud: 500, licensing: 125, month: "Jul 21" },
    { consulting: 500, storage: 500, cloud: 500, licensing: 150, month: "Aug 21" },
    { consulting: 500, storage: 500, cloud: 700, licensing: 100, month: "Sep 21" },
    { consulting: 500, storage: 500, cloud: 700, licensing: 75, month: "Oct 21" },
    { consulting: 500, storage: 500, cloud: 400, licensing: 50, month: "Nov 21" },
    { consulting: 500, storage: 500, cloud: 200, licensing: 200, month: "Dec 21" },
]

export const monthIndex = (chartData, thisMonth) => {
    let thisMonthIndex = 0;
    for (let i = 0; i < chartData.length; i++) {
        let month = chartData[i];
        if(thisMonth == month.month){
            thisMonthIndex = i;
        }
    }
    return thisMonthIndex;
}

export const nextMonth = (chartData, thisMonth) => {
    const thisMonthIndex = monthIndex(chartData, thisMonth);
    if((thisMonthIndex+1) < chartData.length){
        return chartData[thisMonthIndex+1].month;
    }
    return null;
}

export const previousMonth = (chartData, thisMonth) => {
    const thisMonthIndex = monthIndex(chartData, thisMonth);
    if(thisMonthIndex > 0){
        return chartData[thisMonthIndex-1].month;
    }
    return null;
}

export const monthSlice = (chartData, fromMonth, toMonth) => {
    const fromMonthIndex = monthIndex(chartData, fromMonth);
    const toMonthIndex = monthIndex(chartData, toMonth);
    if(toMonthIndex < fromMonthIndex){
        debugger;
        throw `IllegalRange`;
    }
    if(11 != (toMonthIndex - fromMonthIndex)){
        debugger;
        throw `IllegalRange`;
    }
    return chartData.slice(fromMonthIndex, toMonthIndex + 1);
}

export const rAccessor = ["licensing", "cloud", "consulting", "storage"];

export const colorHash = {
    licensing: "#0456F6",
    cloud: "#957CFC",
    storage: "#5949FB",
    consulting: "#1B2F6C"
}

export const tickValues = [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000]