import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
  try {
    let location = useLocation();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, params }}
      />
    );
    }catch (error){
        console.error("ERROR (withRouter) " + error)
        //console.error("Component " + Component)
    }
  }
  return ComponentWithRouterProp;
}