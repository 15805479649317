import React from 'react';
import PropTypes from 'prop-types';

import MessageDisplay from './MessageDisplay';
import {dcxMessages} from './../i18n/BaseAppMessages';

const NoResults = ({TextProps, message, children, withLargeTopMargin = false}) => {
    return (
        <MessageDisplay
            TextProps={TextProps}
            message={message}
            withLargeTopMargin={withLargeTopMargin}
        >
            {children}
        </MessageDisplay>
    )
}

NoResults.defaultProps = {
    message: dcxMessages.defaultNoResultsMessage,
}
NoResults.propTypes = {
    message: PropTypes.object,
}

export default (NoResults)