import React from 'react';

import {connect, Provider} from 'react-redux';

import { createBrowserRouter, Router } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";

import {withAuthentication,withGrowcloudLayout} from "./application/GrowcloudLayouts";

import {makeGrowcloudServerConfiguration} from './application/GrowcloudServerConfiguration';

import {IntlProvider} from 'react-intl';

import {withDefaultLayout,withFocusModeLayout} from '@dc/dcx/app/components/Layouts';

import {Route, Routes, RouterProvider, createBrowserRouter,Link, Outlet} from 'react-router-dom';

import GrowcloudServerStorage from './application/GrowcloudServerStorage';

import UserContextProvider from '@dc/dcx/user/UserContextProvider';

let globalConfiguration = makeGrowcloudServerConfiguration('GROWCLOUD');

import NotificationsContainer from '@dc/dcx/app/containers/NotificationsContainer'

import ExternalSignInsContainer from 'gc-authentication/authentication/containers/ExternalSignInsContainer'
import GrowcloudSignInContainer from 'gc-authentication/authentication/containers/GrowcloudSignInContainer'

import SignOutContainer from 'gc-authentication/authentication/containers/SignOutContainer';

const NotificationContainerWithLayout = withDefaultLayout(NotificationsContainer)

const ExternalSignInsContainerWithLayout = ExternalSignInsContainer
const GrowcloudSignInContainerWithLayout = GrowcloudSignInContainer

const SignOutContainerWithLayout = withDefaultLayout(SignOutContainer)

const GlobalError = () => (<>OOPS</>);

const ErrorBoundaryLayout = () => (
  <ErrorBoundary FallbackComponent={GlobalError}>
     <Outlet />
  </ErrorBoundary>)

export const withUserContext = (WrappedComponent) => {

    return connect(state => {
        return {user: state.auth.user}
    })(class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {userContext: null};
        }

        static getDerivedStateFromProps(props, state) {
            const {user,globalConfiguration} = props;
            const {userContext} = state;
            if (user && !userContext) {
                const userContext1 = { serviceRegistry: new GrowcloudServiceRegistry(globalConfiguration['grpcHost']) }
                return {userContext: userContext1};
            }
            return null;
        }

        render() {
            const {userContext} = this.state;
            if (!userContext) {
                return (
                    <RenderMessage>
                        <Loading message={'Establishing context'}/>
                    </RenderMessage>
                )
            }
            return (
                <WrappedComponent
                    userContext={userContext} {...this.props}
                />
            )
        }
    });
}
import Internationalisation from './component/Internationalisation'
const InternationalisationWithLayout = Internationalisation;//withDefaultLayout(Internationalisation)

import GrowcloudLanding from './application/GrowcloudLanding'
const GrowcloudLandingWithLayout = withGrowcloudLayout(withAuthentication(withUserContext(withDefaultLayout(GrowcloudLanding))))

import Planning from './application/Planning'
const PlanningWithLayout = withGrowcloudLayout(withAuthentication(withUserContext(withDefaultLayout(Planning))))

import Status from './application/Status'
const StatusWithLayout = withGrowcloudLayout(withAuthentication(withUserContext(withDefaultLayout(Status))))

import Configurations from './application/Configurations'
const ConfigurationsWithLayout = withGrowcloudLayout(withAuthentication(withUserContext(withDefaultLayout(Configurations))))

import Assets from './application/Assets'
const AssetsWithLayout = withGrowcloudLayout(withAuthentication(withUserContext(withDefaultLayout(Assets))))

import Outputs from './application/Outputs'
const OutputsWithLayout = withGrowcloudLayout(withAuthentication(withUserContext(withDefaultLayout(Outputs))))

import ScheduleViewer from './application/ScheduleViewer';
import ProgramViewer from './application/ProgramViewer';
import DailyScheduleViewer from './application/DailyScheduleViewer';

const ScheduleViewerWithLayout = withFocusModeLayout(withAuthentication(withUserContext(withDefaultLayout(ScheduleViewer))))
const ProgramViewerWithLayout = withFocusModeLayout(withAuthentication(withUserContext(withDefaultLayout(ProgramViewer))))
const DailyScheduleViewerWithLayout = withFocusModeLayout(withAuthentication(withUserContext(withDefaultLayout(DailyScheduleViewer))))
import CssBaseline from '@mui/material/CssBaseline';

import SitesExplorer from './component/SitesExplorer'

const makeRouter = theme => createBrowserRouter([{
    element: (<ErrorBoundaryLayout />),
    children: [{
        path: "*",
        element: (
            <Routes>
                <Route
                    key={`route-/'`}
                    path={'/'}
                    element={<GrowcloudLandingWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                />
                <Route
                    key={`route-/configurations'`}
                    path={'/configurations'}
                    element={<ConfigurationsWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                />
                 <Route
                    key={`route-/planning'`}
                    path={'/planning'}
                    element={<PlanningWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                />
                 <Route
                    key={`route-/status'`}
                    path={'/status'}
                    element={<StatusWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                />
                 <Route
                    key={`route-/assets'`}
                    path={'/assets'}
                    element={<AssetsWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                />
                 <Route
                      key={`route-/signin`}
                      path='/signin'
                      element={<ExternalSignInsContainerWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                  />

                  <Route
                      key={`route-/signin`}
                      path='/growcloud-signin'
                      element={<GrowcloudSignInContainerWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                  />

                  <Route
                      key={`route-/signout`}
                      path='/signout'
                      element={<SignOutContainerWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                  />
                  <Route
                      key={`route-notifications`}
                      path={'/notifications'}
                      element={<NotificationContainerWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                  />
                 <Route
                      key={`route-/outputs`}
                      path='/xoutputs'
                      element={<OutputsWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                  />
                   <Route
                      key={`route-/schedule-viewer`}
                      path='/outputs'
                      xpath='/schedule-viewer'
                      element={<DailyScheduleViewerWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                   />
                   <Route
                      key={`route-/program-viewer`}
                      path='/program-viewer'
                      element={<ProgramViewerWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                  />
                  <Route
                        key={`route-/i18n`}
                        path='/i18n'
                        element={<InternationalisationWithLayout theme={theme} globalConfiguration={globalConfiguration}/>}
                    />
            </Routes>
        )
      }
    ],
  }
]);

import GrowcloudServiceRegistry from 'gc-webapp/gc/GrowcloudServiceRegistry'

export const GrowcloudServer = ({theme,store}) => {
    if(!theme){
        return (<>no theme</>)
    }
    return (
         <>
         <CssBaseline/>}
         <IntlProvider
            locale={'en'}
            messages={{}}>
                <Provider store={store}>
                   <RouterProvider router={makeRouter(theme)} />
                </Provider>
            </IntlProvider>
        </>
    )
}