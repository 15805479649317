import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';

import {connect} from 'react-redux';

import Grid from '@mui/material/Grid';
import Text from '@dc/dc/content/Text';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import GlobalControlToolBar from "@dc/dcx/app/components/GlobalControlToolBar";

const styles = theme => ({
	root: {
		padding: 0,
	},
});


class SitesExplorerWrapper extends React.Component {

	render() {
		const {children, additionalBreadcrumbs, header} = this.props;

		return (
			<React.Fragment>
				<GlobalControlToolBar
					primaryBreadcrumbItemObject={{to: '/', label: 'Dashboard'}}
					additionalBreadcrumbItemObjects={additionalBreadcrumbs || []}
					actionButtons={null}
				/>
				<ContainerX withLargeTopMargin>
					{children}
				</ContainerX>
			</React.Fragment>
		)
	}
}

SitesExplorerWrapper.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default connect(state => {
	return {  }
})(
	withStyles(styles)(SitesExplorerWrapper)
)
