import React from 'react';

import { withStyles } from '@mui/styles';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Navigate} from 'react-router-dom';

import Button from '@mui/material/Button';
import Text from '@dc/dc/content/Text';
import Grid from '@mui/material/Grid';

import {doAuthentication} from './../actions/authentication-actions';
import ContentBlockHeader from '@dc/dc/components/surface/ContentBlockHeader';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import InternalLink from '@dc/dc/components/links/InternalLink';
import ResponsiveDialog from '@dc/dc/components/dialogs/ResponsiveDialog';

import Error from '@dc/dcx/app/common/Error';
import Success from '@dc/dcx/app/common/Success';

import {RequestStatus} from '@dc/dcx/app/util/RequestConstants';

import {closeForgotPassword, openForgotPassword, resetPassword} from '../actions/authentication-actions';

import {FormattedMessage} from 'react-intl';

import {gcAuthenticationMessages} from './../i18n/GcAuthenticationMessages';

import ContentBlock from '@dc/dc/components/surface/ContentBlock';

const styleSheet = theme => ({
    icon: {
        marginLeft: 8,
    },
    flexRight: {
        flex: '100%',
    },
    textLowercase: {
        textTransform: 'none'
    }
});

class ExternalSignInsContainer extends React.Component {

    renderExternalSignIns() {
        return (
            <>
               <ContainerX AsContainer>
                   SIGN IN WITH GOOGLE
               </ContainerX>
               <ContainerX AsContainer>
                   <Button
                     onClick={() => {
                       window.location.href = '/growcloud-signin';
                     }}
                   >
                     SIGN IN WITH GROWCLOUD
                   </Button>
               </ContainerX>
            </>
        )
    }

    render() {
        const {user} = this.props;

        return (
            <>
                <Grid container justifyContent="center">
                    <Grid item>
                        <ContainerX AsContainer withLargeTopMargin backgroundColor={'paper'}>
                            {this.renderExternalSignIns()}
                        </ContainerX>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withStyles(styleSheet)(ExternalSignInsContainer)
