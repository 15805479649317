import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import Text from '@dc/dc/content/Text';

import {navigationLinkStyleSheet} from '@dc/dc/style/BaseStyles';

import classnames from 'classnames';

const LinkedLeadText = ({classes, masthead}) => {

    const linkClassName = classnames(classes.linkWrapper, classes.defaultNavigationLinkText);

    return (
        <Text
            component="span"
            toRoute={masthead.to}
            linkClassName={linkClassName}
        >
            {masthead.text}
        </Text>
    )
}

LinkedLeadText.propTypes = {
    masthead: PropTypes.object.isRequired
}

export default withStyles(navigationLinkStyleSheet)(LinkedLeadText)