import React from 'react';
import {connect} from 'react-redux';
import {createDynamicGridAction,} from './../mw/grid-service/actions/grid-service-actions';

//import {
//    FilteringState,
//    GroupingState,
//    IntegratedPaging,
//    IntegratedSorting,
//    PagingState,
//    RowDetailState,
//    SelectionState,
//    SortingState,
//} from 'devexpress/dx-react-grid';

//import {
//    DragDropProvider,
//    Grid as DevExtremeGrid,
//    PagingPanel,
//    Table,
//    TableBandHeader,
//    TableColumnReordering,
//    TableColumnResizing,
//    TableHeaderRow,
//    TableRowDetail,
//    Toolbar,
//} from 'devexpress/dx-react-grid-material-ui';
//import CellComponentProvider from './CellComponentProvider';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';

import Loading from '@dc/dcx/app/common/Loading';

class DynamicGrid extends React.Component {

    expandedRowHandler(context) {
        const {expandedRowComponentProvider: ExpandedRowComponentProvider} = this.props;
        if(ExpandedRowComponentProvider){
            return (
                <ExpandedRowComponentProvider {...context}/>
            )
        }
        return (null)
    }

    contextualCellComponentProvider(gridContext, context, e) {
        const {cellComponentProvider: CellComponentProvider} = this.props;
        return (
            <CellComponentProvider {...context} {...gridContext}/>
        )
    }

    onSortingChange(sorting) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'sorting', sorting))
    }

    onSelectionChange(selection) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'selection', selection))
    }

    onExpandedRowIdsChange(expandedRowIds) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'expandedRowIds', expandedRowIds))
    }

    onGroupingChange(grouping) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'grouping', grouping))
    }

    onExpandedGroupsChange(expandedGroups) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'expandedGroups', expandedGroups))
    }

    onFiltersChange(filters) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'filters', filters))
    }

    onCurrentPageChange(currentPage) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'currentPage', currentPage))
    }

    onPageSizeChange(pageSize) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'pageSize', pageSize))
    }

    onColumnOrderChange(order) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'columnOrder', order))
    }

    onColumnWidthsChange(widths) {
        const {dispatch, channel, currentDynamicGrid} = this.props;
        dispatch(createDynamicGridAction(channel, 'columnWidths', widths))
    }

    extractNextColumns(
        columnOrder,
        columnWidths,
        columnBands,
        columns) {
         const columnsZ = []
         columns.forEach(column => {
            columnsZ.push(column);
         })
         return columnsZ;
     }

    render() {

        const {
            sorting,
            selection,
            expandedRowIds,
            grouping,
            expandedGroups,
            filters,
            currentPage,
            pageSizes,
            pageContext,
            currentDynamicGrid,
            hideToolbar,
            gridContext,
            elideTableRowDetail,
            gridStyles = {}
        } = this.props;

        if (!currentDynamicGrid) {
            return <Loading message={'Loading (awaiting current grid) ..'}/>
        }

        if (!pageContext.page) {
            return <Loading message={'Loading (awaiting page) .'}/>
        }

        const {
            columnOrder,
            columnWidths,
            columnBands,
            columns,
        } = currentDynamicGrid.currentCurrentConfiguration;

        const nextColumns = this.extractNextColumns(columnOrder,
                columnWidths,
                columnBands,
                columns)

        let pageSize = 250;

        return (
            <DataGrid sx={gridStyles}
                rows={pageContext.page}
                columns={nextColumns}
                autoHeight
                disableRowSelectionOnClick
                hideFooter
                showCellVerticalBorder
                showColumnVerticalBorder
                disableColumnReorder
                getRowHeight={() => 'auto'}
            >
                {/*<FilteringState
                    filters={filters}
                    onFiltersChange={this.onFiltersChange.bind(this)}
                />
                <SortingState
                    sorting={sorting}
                    onSortingChange={this.onSortingChange.bind(this)}
                />
                <GroupingState
                    grouping={grouping}
                    onGroupingChange={this.onGroupingChange.bind(this)}
                    expandedGroups={expandedGroups}
                    onExpandedGroupsChange={this.onExpandedGroupsChange.bind(this)}
                />
                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={this.onCurrentPageChange.bind(this)}
                    pageSize={pageSize}
                    onPageSizeChange={this.onPageSizeChange.bind(this)}
                />
                <RowDetailState
                    expandedRowIds={expandedRowIds}
                    onExpandedRowIdsChange={this.onExpandedRowIdsChange.bind(this)}
                />
                <SelectionState
                    selection={selection}
                    onSelectionChange={this.onSelectionChange.bind(this)}
                />


                <IntegratedSorting/>
                <IntegratedPaging/>

                <DragDropProvider/>

                <Table cellComponent={this.contextualCellComponentProvider.bind(this, gridContext)}/>

                <TableColumnReordering
                    order={columnOrder}
                    onOrderChange={this.onColumnOrderChange.bind(this)}
                />

                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={this.onColumnWidthsChange.bind(this)}
                />

                <TableHeaderRow showSortingControls/>
*/}
                {/*!elideTableRowDetail && (
                    <TableRowDetail contentComponent={this.expandedRowHandler.bind(this)}/>
                )*/}

                {/*hideToolbar ? null :  <Toolbar/> */}
                {/*<PagingPanel
                    pageSizes={pageSizes}
                />*/}

                {/*columnBands && (
                    <TableBandHeader
                        columnBands={columnBands}
                     />
                )*/}
            </DataGrid>
        )
    }
}

const mapToProps = state => {
    return {}
}

export default connect(mapToProps)(DynamicGrid)