import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import NotificationsList from './NotificationsList';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import Loading from "../common/Loading";
import {RenderMessage} from "../common/MessageUtils";

class NotificationsContainer extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {notifications} = this.props;

        if (!notifications || !notifications.timeWindowView) {
            return (
                <RenderMessage>
                    <Loading withProgress/>
                </RenderMessage>
            )
        }

        return (
            <ContainerX AsContainer withLargeTopMargin>
                <NotificationsList
                    timeWindowNotifications={notifications.timeWindowView}
                    pendingActionsNotifications={notifications.pendingActionsView}
                />
            </ContainerX>
        )
    }
}

NotificationsContainer.propTypes = {
    classes: PropTypes.object,
}

const mapStateToProps = (state) => {
    return {
        notifications: state.notifications.notifications
    }
}

export default connect(mapStateToProps)(NotificationsContainer)