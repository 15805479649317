import React from 'react'
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';

import { withStyles } from '@mui/styles';
import SubmitButton from './buttons/SubmitButton';
import SecondaryButton from './buttons/SecondaryButton';
import CTAButton from "./buttons/CTAButton";

const styleSheet = theme => ({
    toolbarButton: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        backgroundColor: 'slategray'
    },
});

class ActionsBar extends React.PureComponent {

    renderActions() {
        const {actions, classes} = this.props;
        let actionsArr = [];

        for (let action of actions) {
            let disabled = action.disabled;
            if (action.variant === 'submit') {
                actionsArr.push((
                    <SubmitButton
                        key={action.label}
                        className={classes.toolbarButton}
                        disabled={disabled}
                        buttonText={action.label}
                        onClick={action.clickFunction}
                    />
                ))
            } /*else if (action.variant === 'default' && action.label == 'Next') {
			   actionsArr.push((
				   <CTAButton key={action.label}
				   		mini
					   	disabled={disabled}
					   	buttonText={action.label}
					   	onClick={action.clickFunction}
				   />
			   ))
           }*/ else if (action.variant === 'action') {
                actionsArr.push((
                    <SecondaryButton
                        key={action.label}
                        className={classes.toolbarButton}
                        disabled={disabled}
                        buttonText={action.label}
                        onClick={action.clickFunction}
                    />
                ))
            } else if (action.variant === 'cta') {
                actionsArr.push((
                    <CTAButton
                        key={action.label}
                        className={classes.toolbarButton}
                        disabled={disabled}
                        buttonText={action.label}
                        onClick={action.clickFunction}
                    />
                ))
            } else {
                actionsArr.push((
                    <Button
                        key={action.label}
                        disabled={disabled}
                        className={classes.toolbarButton}
                        onClick={action.clickFunction}
                    >
                        {action.label}
                    </Button>

                ))
            }
        }
        return actionsArr;
    };

    render() {
        return (
            <Grid container alignItems={'center'} justifyContent={'flex-end'}>
                {this.renderActions()}
            </Grid>
        )
    }
}

ActionsBar.propTypes = {
    actions: PropTypes.array.isRequired,
}

export default withStyles(styleSheet)(ActionsBar)