import React from 'react';
import {connect} from 'react-redux';
import Grid from '@mui/material/Grid';
import Text from '@dc/dc/content/Text';
import Box from '@mui/material/Box';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import Worksheet, {WorksheetNode} from '@dc/dc/components/surface/Worksheet';
import Loading from '@dc/dcx/app/common/Loading';
import NavigationDrawer from 'gc-ui-components/ui/navigation/NavigationDrawer';

class Outputs extends React.PureComponent {

    render() {
        const {userContext} = this.props;

        return (
            <ContainerX AsContainer>
            TODO
                {/*<ProducedItemsWithLayout
                    userContext={userContext}
                    channel={Channel.PRODUCED_ITEMS}
                    productLine={productLine}
                    inlineRoutes={projectsInlineRoutes(qualityManagementActivity, productLine)}
                    sidebarConfigurationAugmentor={(realizationModel, sidebarConfiguration) => {
                        return Object.assign(sidebarConfiguration, {channelPageConfiguration: [] });
                    }}
                />*/}
            </ContainerX>
        )
    }
}
export default connect(state => { })(Outputs)