import {
    CLEAR_CURRENT_READINGS, 
    LOAD_CURRENT_READINGS_FAIL, 
    LOAD_CURRENT_READINGS_SUCCESS
    } from "../actions/measure-values-v1-service-actions";

const newInitialState = () => {
    return {
        currentReadings: [],
        hotModel: {}
    }
}
const loadCurrentReadings = (state, actionData) => {
    /*let components = {...state.components};
    let currentComponent = dotProp.get(components, [actionData.id]) || newComponentState();
    currentComponent.componentActionStatus.currentActionStatus = RequestStatus.SUCCESS;
    let updatedComponent = dotProp.set(currentComponent, [which], actionData.data);
    let updatedComponents = dotProp.set(components, [actionData.id], updatedComponent);
    return updatedComponents;
    */
    return actionData.currentReadings;
}

const clearCurrentReadings = (state,  actionData) => {
    return [];
}

const initialState = newInitialState();

const reducers = (state = initialState, actionData = null) => {
    switch (actionData.type) {
        case LOAD_CURRENT_READINGS_SUCCESS:
            return {
                ...state,
                currentReadings: loadCurrentReadings(state, actionData)
            };
        case LOAD_CURRENT_READINGS_FAIL:
            return {
                ...state,
                currentReadings: []
            };
        case CLEAR_CURRENT_READINGS:
            return {
                ...state,
                currentReadings: clearCurrentReadings(state, actionData)
            };
        default:
            return state;
    }
};

export default reducers;

