import React from 'react'
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {makeSurfaceStyleSheet} from "../../style/SurfaceStyles";
import {makeClassNames} from "../../style/StylesUtils";
import { makeStyles } from '@mui/styles';
import ContainerX from "../layout/ContainerX";
import {appbarHeight, worksheetWidth} from "../../style/BaseStyles";

const useStyles = makeStyles(theme => {
	let thisStyles = {
		worksheet: props => ({
			position: props.position ? props.position : 'fixed',
			top: appbarHeight,
			bottom: 0,
			left: props.anchor === "left" ? 0 : 'auto',
			right: props.anchor === "right" ? 0 : 'auto',
			height: '100%',
			width: props.paperWidth,
			backgroundColor: '#f5f5f5',
		}),
		button: {
			position: 'absolute',
			top: theme.spacing(8),
			left: theme.spacing(2),
		},
	};
	const styleSheet = makeSurfaceStyleSheet(theme, thisStyles);
	return styleSheet;
});

export const WorksheetNode = ({children}) => {
	if (children) {
		return (
			<ContainerX
				style={{width: '100%', flexGrow: 1}}
				variant={'minimal'}
				backgroundColorNone
			>
				SSSSSSSSS {children}
			</ContainerX>
		)
	}
	return (null)
}

const Worksheet = (props) => {
	const {
		Title,
		ContentNode,
		isDirty,
		variant = 'medium',
		displayVariant = 'none',
		anchor = 'right',
		width=worksheetWidth,
		...rest
	} = props;
debugger;
	const classes = useStyles({
		paperWidth: width,
		anchor: anchor,
		position: props.position
	})

	const classNameObj = makeClassNames(classes, {variant, displayVariant}, rest, {}, {});

	return (
		<ContainerX
			classes={{
				root: classes.worksheet,
			}}
			className={classNameObj.classNames}
			variant={`fitted`}
		>
			{isDirty && (
				<IconButton
					className={classes.button}
					edge="start"
					onClick={(event) => {
						if (props.clearContents) {
							props.clearContents();
						}
						event.stopPropagation();
					}}
				>
					<CloseIcon color="primary"/>
				</IconButton>
			)}

			{Title && (
				<Typography variant="h4" color="textPrimary">
					{Title}
				</Typography>
			)}
			{ContentNode && ContentNode}
		</ContainerX>
	)
}
export default (Worksheet)