import {GetColumnDataRequest, ParameterisedColumn} from 'gc-commons/growcloud/v1/grid/GridServiceMessages_pb';
import {UUID} from 'gc-commons/growcloud/v1/grid/GridDefinitions_pb';
//import {IdentityUtils} from 'standard-core/metro/QueryHelper';

export const makeGetColumnDataRequest = (gridConfiguration) => {
    let getColumnDataRequest = new GetColumnDataRequest();
   // getColumnDataRequest.setGridConfiguration(IdentityUtils.fromResourceUrn(gridConfiguration.uuid));
    gridConfiguration.columns.forEach(column => {
        if(gridConfiguration.columnBands){
            gridConfiguration.columnBands.forEach(columnBand => {
                columnBand.children.forEach(columnDefinition => {
                    if (columnDefinition.columnName == column.name) {
                        let parameterisedColumn = new ParameterisedColumn();
                        let uuid = new UUID();
                        uuid.setUuid(column.uuid);
                        parameterisedColumn.setUuid(uuid);
                        let columnBandUuid = new UUID();
                        columnBandUuid.setUuid(columnBand.uuid);
                        parameterisedColumn.setColumnconfigurationdefinition(columnBandUuid);
                        getColumnDataRequest.addParameterisemetroolumn(parameterisedColumn);
                    }
                })
            })
        }
    })
    return getColumnDataRequest;
}