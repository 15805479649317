import React from 'react'
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import TextFieldWrapper from '../redux-form/TextFieldWrapper';
import TextMaskField from './TextMaskField';

const styleSheet = theme => ({
//	customTextField: {
//		marginTop: theme.spacing(2),
//		borderTopLeftRadius: theme.spacing(0.5),
//		borderTopRightRadius: theme.spacing(0.5),
//		backgroundColor: theme.palette.background.level1,
//	},
//	input: {
//		paddingLeft: theme.spacing(1.5),
//		paddingRight: theme.spacing(1.5),
//	},
    marginNormal: {
//		marginTop: theme.spacing(2),
//		marginBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2.5),
        marginBottom: theme.spacing(1),
        width: '100%'
    },
    marginDense: {
//		marginTop: theme.spacing.unit*2,
//		marginBottom: theme.spacing.unit,
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1.5),
        marginBottom: theme.spacing(0.5),
        width: '100%'
    },
    denseTextField: {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    }
//	fieldWrapper: {
//		paddingTop: theme.spacing(1),
//		paddingBottom: theme.spacing(2),
//		marginBottom: theme.spacing(1),
//	}
});

const TextField = (props) => {
    const {
        classes,
        denseFormField,
        ...rest
    } = props;

    if (props.type === 'search') {
        debugger;
        return (
            <TextFieldWrapper
                component={"TextField_reduxFormMaterial"}
                //          className={classes.customTextField}
                //			className={classes.marginNormal}
                {...rest}
            />
        )
    }

    if (props.mask) {
//debugger;
        return (
            <div className={props.denseFormField ? classes.marginDense : classes.marginNormal}>
                <TextMaskField
                    //				{...classes}
                    {...rest}
                />
            </div>
        )
    }
//  debugger;
    return (
        <div className={props.denseFormField ? classes.marginDense : classes.marginNormal}>
            <TextFieldWrapper
                component={"TextField_reduxFormMaterial"}
                InputProps={{
                    readOnly: props.readOnly ? props.readOnly : false,
                    classes: {marginDense: classes.denseTextField}
                }}
                {...rest}
            />
        </div>
    )
}

TextField.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styleSheet, {withTheme: true})(TextField)
