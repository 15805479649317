import classnames from 'classnames';
import { capitalize } from '../shim/capitalize';

const extract = (classes, variants, props) => {

    const {
        withNoTopPadding=false,
        removeTopPadding = false,
        removeBottomPadding = false,
        removeRightPadding = false,
        removeLeftPadding = false,
        withLargeTopPadding = false,
        withMediumTopMargin = false,
        withMediumBottomMargin = false,
        withSmallBottomMargin = false,
        removeTopMargin = false,
        removeBottomMargin = false,
        removeRightMargin = false,
        removeLeftMargin = false,
        backgroundColor = 'transparent',
        withNoPadding = false,
        withNoMargins = false,
        withMinimalMargin=false,
        withMinimalTopMargin=false,
        withMinimalLeftMargin=false,
        withMinimalBottomMargin=false,
        withMinimalRightMargin=false,
        withMinimalPadding=false,
        withMinimalTopPadding=false,
        withMinimalLeftPadding=false,
        withMediumLeftPadding=false,
        withMinimalBottomPadding=false,
        withMinimalRightPadding=false,
        withLargeTopMargin=false,
        withLargeLeftMargin=false,
        withLargeBottomMargin=false,
        withLargeRightMargin=false,
        withOutline=false,
        withNoBoxShadow=false,
        backgroundColorNone=false,
        withLargeBottomPadding=false,
        withLargeMargins=false,
        withSmallMargins=false,
        ...rest
    } = props;

    const {
        variant = 'medium',
        displayVariant = 'none'
    } = variants;

    return {
        rest,
        extracted: {
            [classes.removeTopPadding]: removeTopPadding,
            [classes.removeBottomPadding]: removeBottomPadding,
            [classes.removeLeftPadding]: removeLeftPadding,
            [classes.removeRightPadding]: removeRightPadding,

            [classes.withLargeBottomPadding]: withLargeBottomPadding,
            [classes.withNoTopPadding]: withNoTopPadding,

            [classes.withNoPadding]: withNoPadding,
            [classes.removeTopMargin]: removeTopMargin,
            [classes.removeBottomMargin]: removeBottomMargin,
            [classes.removeLeftMargin]: removeLeftMargin,
            [classes.removeRightMargin]: removeRightMargin,

            [classes.withLargeTopPadding]: withLargeTopPadding,

            [classes.withMediumTopMargin]: withMediumTopMargin,
            [classes.withMediumBottomMargin]: withMediumBottomMargin,
            [classes.withSmallBottomMargin]: withSmallBottomMargin,
            [classes.withNoMargins]: withNoMargins,
            [classes.withSmallMargins]: withSmallMargins,
            [classes.withMinimalMargin]: withMinimalMargin,
            [classes.withMinimalTopMargin]: withMinimalTopMargin,
            [classes.withMinimalLeftMargin]: withMinimalLeftMargin,
            [classes.withMinimalBottomMargin]: withMinimalBottomMargin,
            [classes.withMinimalRightMargin]: withMinimalRightMargin,

            [classes.withLargeTopMargin]: withLargeTopMargin,
            [classes.withLargeLeftMargin]: withLargeLeftMargin,
            [classes.withLargeBottomMargin]: withLargeBottomMargin,
            [classes.withLargeRightMargin]: withLargeRightMargin,

            [classes.withLargeMargins]: withLargeMargins,

            [classes.withMinimalPadding]: withMinimalPadding,
            [classes.withMinimalTopPadding]: withMinimalTopPadding,
            [classes.withMinimalLeftPadding]: withMinimalLeftPadding,
            [classes.withMediumLeftPadding]: withMediumLeftPadding,
            [classes.withMinimalBottomPadding]: withMinimalBottomPadding,
            [classes.withMinimalRightPadding]: withMinimalRightPadding,

            [classes[`backgroundColor${capitalize(backgroundColor)}`]]: backgroundColor !== 'none',
            [classes[`variant${capitalize(variant)}`]]: variant,
            [classes[`displayVariant${capitalize(displayVariant)}`]]: displayVariant,
            [classes.noElevation]: displayVariant === 'outlined' || displayVariant === 'outlinedHover',

            [classes.withOutline]: withOutline,
            [classes.withNoBoxShadow]: withNoBoxShadow,
            [classes.backgroundColorNone]: backgroundColorNone,
        }
    }
}

export const makeClassNames = (classes, variants, props, thisClasses, overrideClasses) => {
    const extractionObject = extract(classes, variants, props);
    const classNames0 = classnames(
        Object.assign(extractionObject.extracted, thisClasses),
        overrideClasses || {}
    );
    return { props: extractionObject.rest, extracted: extractionObject.extracted, classNames: classNames0 };
}