import React from 'react';
import PropTypes from 'prop-types';
import {drawerListStyleSheet} from '@dc/dc/style/BaseStyles';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { withStyles } from '@mui/styles';

import InternalLink from '@dc/dc/components/links/InternalLink';

import {LocalDate} from './../../i18n/GlobalisationUtils';

class NotificationsList extends React.PureComponent {

    renderTimeWindowNotification(notification) {

        if (!notification.link) {
            return (
                <ListItemText
                    primary={notification.message}
                    secondary={(
                        <LocalDate
                            timestamp={notification.timestamp}
                            when={false}
                            divider
                        />
                    )}
                />
            )
        }

        return (
            <ListItemText
                primary={(
                    <InternalLink
                        linkLocation={notification.link}
                        linkText={notification.message}
                    />
                )}
                secondary={(
                    <LocalDate
                        timestamp={notification.timestamp}
                        when={false}
                        divider
                    />
                )}
            />
        )
    }

    renderPendingActionsNotification(notification) {
        return (
            <ListItemText
                primary={notification.message}
                secondary={(
                    <LocalDate
                        timestamp={notification.timestamp}
                        when={false}
                        divider
                    />
                )}
            />
        )
    }

    renderPendingActionsNotifications() {
        const {classes, pendingActionsNotifications, clickHandler} = this.props;
        let itemsArray = [];
        let index = 0;
        pendingActionsNotifications.forEach((item) => {
            itemsArray.push((
                <ListItem
                    onClick={clickHandler ? clickHandler : null}
                    component="div"
                    key={item + (++index)}
                    className={classes.nestedTrigger}
                    classes={{root: classes.nestedListCollapse}}
                >
                    {this.renderPendingActionsNotification(item)}
                </ListItem>
            ))
        })
        return itemsArray;
    }

    renderTimeWindowNotifications() {
        const {classes, timeWindowNotifications, clickHandler} = this.props;
        let itemsArray = [];
        let index = 0;
        timeWindowNotifications.forEach((item) => {
            itemsArray.push((
                <ListItem
                    onClick={clickHandler ? clickHandler : null}
                    component="div"
                    key={item + (++index)}
                    className={classes.nestedTrigger}
                    classes={{root: classes.nestedListCollapse}}
                >
                    {this.renderTimeWindowNotification(item)}
                </ListItem>
            ))
        })
        return itemsArray;
    }

    render() {
        const {
            classes,
            timeWindowNotifications,
            listGroup
        } = this.props;
        return (
            <List component="div" className={listGroup ? classes.listGroup : undefined}>
                {this.renderPendingActionsNotifications()}
                {this.renderTimeWindowNotifications()}
            </List>
        )
    }
}

NotificationsList.propTypes = {
    classes: PropTypes.object.isRequired,
    timeWindowNotifications: PropTypes.array.isRequired
}

export default withStyles(drawerListStyleSheet)(NotificationsList)