
import React from "react";
import PropTypes from "prop-types";

import ListItem from "@mui/material/ListItem";

import { withStyles } from '@mui/styles';

import {Link as RouterLink} from 'react-router-dom';

import {withRouter} from '../../shim/withRouter';

import { alpha } from '@mui/material/styles';

const styles = theme => ({
    linkWrapper: {
        flex: '1 1 auto',
    },
    link: {
        fontSize: 'inherit',
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'space-between',
        alignItems: 'center',
        textDecoration: 'none',
        color: theme.palette.action.activeRoute,
        backgroundColor: 'transparent',
        padding: 0,
        fontWeight: theme.typography.fontWeightNormal,

        '&:hover': {
            textDecoration: 'none',
            backgroundColor: 'transparent',
            color: theme.palette.action.activeRoute,
        },
    },
    brandLinkColor: {
		color: theme.palette.action.brandDefaultLink,
		'&:hover': {
//			color: alpha(theme.palette.action.brandDefaultLinkHover, 0.3),
		},
    },
    inverseLink: {
//        color: theme.palette.action.activeRoute,
        [theme.breakpoints.up('sm')]: {
//            color: theme.palette.secondary.main
//				color: theme.palette.action.activeRoute,
        },
        // todo add query for no pointer events
        '&:hover': {
//            color: theme.palette.text.link,
//			color: theme.palette.action.activeRoute,
//            [theme.breakpoints.up('sm')]: {
//                color: theme.palette.action.activeRoute,
//            }
        }
    },
    lowercase: {
        textTransform: 'none',
    },
    capitalize: {
        textTransform: 'uppercase'
    },
    inlineFlex: {
        display: 'inline-flex',
        margin: theme.spacing(0, 0.5)
    },
})

export default withStyles(styles)(props => (
  <RouterLink
      {...props}
      ref={props.ref}
      to={props.to}
      onClick={props.linkAction}
      align={props.alignText || `inherit`}
  >
     {props.children}
  </RouterLink>
 ))
