import {fork, put, take} from 'redux-saga/effects'

import {
    LOAD_CURRENT_READINGS, LOAD_CURRENT_READINGS_FAIL, LOAD_CURRENT_READINGS_SUCCESS
} from './../actions/measure-values-v1-service-actions';

import {httpErrorResponseHandler} from 'gc-authentication/authentication/utils/authentication-utils';

import {makeCurrentReadingsRequest} from '../../MeasureValuesV1ServiceHelper';

const doCurrentReadings = (userContext, currentReadingsRequest) => {
    return new Promise((success, error) => {
        let currentReadings = []
        userContext.serviceRegistry.measureValuesV1Service().currentReadings(currentReadingsRequest,
            doCurrentReadingsResponse => {
                currentReadings.push(doCurrentReadingsResponse.toObject())
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(currentReadings);
                return;
            }
            error(code, msg, trailers)
        })
    })
}

export function* currentReadingsConsumer() {
    while (true) {
        const request = yield take(LOAD_CURRENT_READINGS)
        let currentReadingsRequest = makeCurrentReadingsRequest()
        try {
            let currentReadingsResponse = yield doCurrentReadings(request.userContext, currentReadingsRequest);
            yield put({
                type: LOAD_CURRENT_READINGS_SUCCESS,
                currentReadings: currentReadingsResponse,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: LOAD_CURRENT_READINGS_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}


export default function* root() {
    yield fork(currentReadingsConsumer)
}