import React from 'react'
import {withStyles} from '@mui/styles';
import {FormattedMessage} from 'react-intl';

import ActionStepper, {DISABLE, NEXT, PREVIOUS, SHOW} from '@dc/dc/components/ActionStepper';

import {validateFormValues} from '@dc/dcx/form/utils/ValidationUtils';
import {validationErrorMessages} from '@dc/dc/i18n/ValidationErrorMessages';
import {StartYourGrowCommit, ChooseOptions} from "./StartYourGrowSteps";

import ContainerX from '@dc/dc/components/layout/ContainerX';

import Loading from '@dc/dcx/app/common/Loading';

const styleSheet = theme => ({
    defaultPaperBackground: {
        backgroundColor: theme.palette.background.systemSubZero,
    }
});

export const validateStartYourGrowStepper = (values) => {
    return []
};

export const validateComponentDetailsScreen = (values) => {
    let hasLabel = false;
    let hasSummary = false;
    if(!values){ values = {} }
    const errors = validateFormValues(values, (key, value) => {
        let errorMessage = null;
        switch (key) {
            case 'label':
                hasLabel = true;
                break;
            case 'summary':
                hasSummary = true;
                break;
            default:
            //TBD
        }
        return errorMessage;
    })
    if (!hasLabel) {
        errors.label = <FormattedMessage {...validationErrorMessages.requiredMessage}/>
    }
    if (!hasSummary) {
        errors.label = <FormattedMessage {...validationErrorMessages.requiredMessage}/>
    }
    return errors;
};

export const validateStateSelectorScreen = (values) => {
    let hasInitialState = false;
    if(!values){ values = {} }
    const errors = validateFormValues(values, (key, value) => {
        let errorMessage = null;
        switch (key) {
            case 'initialState':
                hasInitialState = true;
                break;
            default:
            //TBD
        }
        return errorMessage;
    });
    if (!hasInitialState) {
        errors.initialState = <FormattedMessage {...validationErrorMessages.requiredMessage}/>
    }
    return errors;
};

export const isComponentDetailsScreenValid = (values) => {
    const errors = validateComponentDetailsScreen(values);
    if (0 == Object.keys(errors).length) {
        return true;
    }
    return false;
};

export const isStateSelectorScreenValid = (values) => {
    const errors = validateStateSelectorScreen(values);
    if (0 == Object.keys(errors).length) {
        return true;
    }
    return false;
}

class StartYourGrowStepper extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            activeStep: 0,
            stepperSteps: null
        }
    }

    componentDidMount() {
        const {stepperSteps} = this.state;
        if (!stepperSteps) {
            let stepperSteps = this.makeStepperSteps(this.props);
            this.setState({stepperSteps});
        }
    }

    submitter(){
        const {createAction,createActionForm} = this.props;
        createAction(createActionForm);
    }

    makeStepperSteps(props){

        const {dialogObject} = props;

        let stepperSteps = [];
        //

        stepperSteps.push({
            label: 'DEFINE YOUR PRODUCTION SITE',
            validator: (values) => ()=>{},
            checker: (values) => ()=>{},
            component: (props) => (
                <React.Fragment>
                  SELECT SITE or newSITE

                </React.Fragment>
            )
        });

        stepperSteps.push({
            label: 'DEFINE YOUR PRODUCTION ENVIRONMENT',
            validator: (values) => ()=>{},
            checker: (values) => ()=>{},
            component: (props) => (
                <React.Fragment>
      IMPORT or DIALOG

      * outdoor or indoor
      * mixed productions lines
      * automated or manual irrigation
      * automated or manual measurements capture
      * preset lighting programs
      * enclosure definition
      * add enclosure


                  </React.Fragment>
            )
        });
        stepperSteps.push({
            label: 'SELECT YOUR SEEDS OR CUTTINGS',
            validator: (values) => ()=>{},
            checker: (values) => ()=>{},
            component: (props) => (
                <React.Fragment>
                  SELECT SEEDS or CUTTINGS
                  THEN PACKS

                  SELECT FROM GROWCLOUD

                </React.Fragment>
            )
        });

        stepperSteps.push({
            label: 'START YOUR JOURNEY',
            validator: (values) => {},
            checker: () => { },
            component: (props) => (
                <>
                NEXT STEPS: issue PRODUCTION PERMITS
                NEXT STEPS: define product lines  (map seeds to permits)

                <StartYourGrowCommit
                    createAction={this.submitter.bind(this)}
                    createContext={props.createContext}
                    dispatch={props.dispatch}
                />
                </>
            )
        })

        return stepperSteps;
    }

    onThisPage() {
        const {dispatch} = this.props;
    }

    stepCommandHandler(command) {
        const {dispatch} = this.props;
        if ('next' == command) {
            this.onThisPage();
            this.setState({activeStep: this.state.activeStep + 1})
        } else if ('previous' == command) {
            this.setState({activeStep: this.state.activeStep - 1})
        }
    }

    stepperButtonsActive(currentStep, which) {
        const {createActionForm} = this.props;
        const {stepperSteps} = this.state;

        if (NEXT == which) {
            let thisStep = stepperSteps[currentStep];
            if (thisStep.checker) {
                let noPageErrors = thisStep.checker(createActionForm||{});
                if (noPageErrors) {
                    return {action: SHOW}
                }
            }
            /*
            return {action: DISABLE}
            */
            return {action: SHOW}
        }
        if (PREVIOUS == which) {
            return {action: SHOW}
        }
        return {action: DISABLE}
    }
    
    render() {

        const {activeStep, stepperSteps} = this.state;

        if (!stepperSteps) {
            return (<Loading/>)
        }

        let stepperContext = {activeStep: activeStep};
        let valid = true;

        let stepperLabels = [];
        stepperSteps.forEach(step => stepperLabels.push(step.label))

        return (
            <ActionStepper
                context={stepperContext}
                steps={stepperLabels}
                activeStep={activeStep}
                isValid={valid}
                stepCommandHandler={this.stepCommandHandler.bind(this)}
                stepperButtonsActive={this.stepperButtonsActive.bind(this)}
                getStepContent={
                    (activeStep, stepperContext) => {
                        const step = stepperSteps[activeStep];
                        if (step) {
                            return (step.component(this.props))
                        }
                        throw `Unknown step <${activeStep}>`;
                    }
                }
            />
        )
    }
}
export default withStyles(styleSheet)(StartYourGrowStepper);