import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Link as RouterLink, NavLink} from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import RenderButtonListItem from './RenderButtonListItem';
import RenderListItemLink from './RenderListItemLink';

import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Hidden from '@mui/material/Hidden';

import { withStyles } from '@mui/styles';
import {drawerListStyleSheet} from '../../style/BaseStyles';
import SecondaryButton from "../buttons/SecondaryButton";
import IconSVG from "../icon/IconSVG";

const makeIcon = (item) => {
    if (item.icon) {
        return (
            <IconSVG {...item.icon}/>
        )
    }
    return (null)
}

class NestedList extends React.PureComponent {
    state = {
        loading: true,
        selectedIndex: null,
        childIndex: null,
        currentRoute: null,
        hideMobileFilters: false,
    };


    handleCollapseClick = name => event => {
        const {elideCollapsing} = this.props;
        if (!elideCollapsing) {
            this.setState({[name]: !this.state[name]});
        }
    };

    handleListItemClick(event, index, childIndex, cb) {
        this.setState({
            selectedIndex: index,
            childIndex: childIndex,
        });
        cb && cb();
    }

    completeLoading = () => {
        this.setState({
            loading: false
        })
    };

    initializeListData(data, callback) {
        const {location} = this.props;

        for (let item of data) {
            const parentName = item.label.replace(/\s/g, "");
            console.log('Parent name initialized: ', parentName);
            let nextState = false;
            if (item.items) {
//                for (let childItem of item.items) {
//                    if (childItem.href && childItem.href.indexOf(location.pathname) > -1) {
//                        this.setState({
//                            [parentName]: true
//                        })
//                    }
//                }

            }

            this.setState({
                [parentName]: nextState
            })
        }

        if (typeof callback === "function") {
            callback()
        }
    }

    componentDidMount() {
        const {listData} = this.props;
        this.initializeListData(listData, this.completeLoading);
        this.setMobileFilterViewState();
    }

    setMobileFilterViewState() {
        const {isSmallWidth} = this.props;
        const {hideMobileFilters} = this.state;

        console.log('set mobile filters in NESTEDLIST: ', hideMobileFilters)
        isSmallWidth && this.setState({
            hideMobileFilters: true
        })
    }

    handleToggleMobileFilters() {
        const {hideMobileFilters} = this.state;
        this.setState({
            hideMobileFilters: !hideMobileFilters
        })
    }

    renderArrows(parentName) {
        const {classes, children, listData, location, match, elideCollapsing, ArrowUp, ArrowDown} = this.props;
        // debugger;
        return this.state[parentName] ? <ArrowUp/> : <ArrowDown/>
    }

    renderToggleButton() {
        return (
            <Hidden mdUp>
                <Grid item>
                    <SecondaryButton
                        buttonText="Toggle"
                        onClick={this.handleToggleMobileFilters.bind(this)}
                    />
                </Grid>
            </Hidden>
        )
    }

    extractNextLevel(item, index) {
        const {
            classes,
            match,
            elideCollapsing,
            noIndentList = true,
            excludeDropdownArrows,
            customClickFunction,
        } = this.props;
        const {childIndex} = this.state;
        if (item.items) {
            let itemExamples = item.items.map((nested, i) => {
                const trimmedLabel = nested.label.replace(/\s/g, "");

                let item2Examples
                let parent2Name

                if (nested.items) {
                    parent2Name = nested.label.replace(/\s/g, "");
                    console.log("Render nest lvl 3 Item", parent2Name);

                    item2Examples = nested.items.map((nested2, i) => {
                        const trimmedLabel2 = nested2.label.replace(/\s/g, "");
                        console.log("Render nested child", trimmedLabel2);
                        return (
                                <RenderListItemLink
                                    key={i + nested2.label}
                                    primary={nested2.label}
                                    bolder={item.icon ? true : false}
                                    icon={nested2.icon}
                                    isSelected={childIndex === i + trimmedLabel2/* || match.path === nested2.href*/}
                                    to={nested2.hash || nested2.href}
                                    className={classnames(classes.nestedListLink, {
                                        [classes.noIndentLink]: noIndentList,
                                        [classes.indentedNestedLink]: !noIndentList
                                    })}
                                    classes={{root: classes.nestedListLink}}
                                    onClick={event => this.handleListItemClick(event, index, i + trimmedLabel2, customClickFunction)}
                                    id={i + trimmedLabel2}
                                />
                        )
                    })
                }

                if (nested.href) {
                    return (
                        <RenderListItemLink
                            key={i + nested.label}
                            primary={nested.label}
                            isSelected={childIndex === i + trimmedLabel/*|| match.path === nested.href*/}
                            to={nested.hash || nested.href}
                            bolder={item.icon ? true : false}
                            // className={classes.nestedListLink}
                            className={classnames(classes.nestedListLink, {[classes.nestedListGutter]: !noIndentList})}
                            classes={{root: classes.nestedListLink}}
                            onClick={event => this.handleListItemClick(event, index, i + trimmedLabel, customClickFunction)}
                            component={this.renderLink}
                            id={i + trimmedLabel}
                        />
                    )
                } else if (item2Examples.length > 0) {
                    return (
                        <span key={i + trimmedLabel}>
                            <RenderButtonListItem
                                disableRipple
                                primary={nested.label}
                                primaryTypographyProps={{fontWeight: '500'}}
                                name={parent2Name}
                                onClick={this.handleCollapseClick(parent2Name)}
                                // classes={{button: classes.firstChildListItemNode}}
                                overrideClasses={classnames({[classes.firstChildListItemNode]: !noIndentList})}
                                style={{cursor: 'auto'}}
                            >
                                {elideCollapsing ? null : excludeDropdownArrows ? null : this.renderArrows(parent2Name)}
                            </RenderButtonListItem>
                            <Collapse
                                in={elideCollapsing || this.state[parent2Name]}
                                timeout="auto"
                                unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {item2Examples}
                              </List>
                            </Collapse>
                        </span>
                    )
                }
            })
            return itemExamples;
        }
    }

    render() {
        const {
            classes,
            listData,
            match,
            elideCollapsing,
            excludeDropdownArrows,
            isSmallWidth,
            withDrawerTitle,
            selectedBackgroundColor
        } = this.props;
        const {loading, selectedIndex, hideMobileFilters} = this.state;
        console.log('NestedList withDrawerTitle? ', withDrawerTitle ? 'true' : 'false');
//		todo fix list styles breaking when elideCollapsing is activated
        console.log("listData", listData)

        if (loading) {
            return (
                <div>Loading...</div>
            )
        } else {
            return (
                <List disablePadding className={classnames(classes.list, {[classes.withDrawerTitle]: withDrawerTitle})}>
                    {isSmallWidth && <ListItem disableGutters>{this.renderToggleButton()}</ListItem>}

                    <div className={classnames(classes.scrollContainer, {[classes.isHidden]: hideMobileFilters})}>
                        {listData.map((item, index) => {
                            const parentName = item.label.replace(/\s/g, "");
                            const itemLabel = item.label.toUpperCase();

                            let itemExamplesNext = this.extractNextLevel(item, index)
                            return (
                                <div key={item.label + index}>

                                    <RenderButtonListItem
                                        elideCollapsing={elideCollapsing}
                                        disableRipple
                                        primary={itemLabel}
                                        icon={makeIcon(item)}
                                        name={parentName}
                                        onClick={this.handleCollapseClick(parentName)}
                                    >
                                        {elideCollapsing ? null : excludeDropdownArrows ? null : this.renderArrows(parentName)}
                                    </RenderButtonListItem>

                                    {(itemExamplesNext.length > 0) && (
                                        <Collapse
                                            in={elideCollapsing || this.state[parentName]}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <List component="div" disablePadding>
                                                {itemExamplesNext}
                                            </List>
                                        </Collapse>
                                    )}
                                </div>
                            )
                        })
                        }
                    </div>
                </List>
            )
        }
    }
}

NestedList.propTypes = {
    classes: PropTypes.object.isRequired,
    listData: PropTypes.array.isRequired
}

export default withStyles(drawerListStyleSheet)(NestedList)