import { pipe, map } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

import {getToken,headers} from './../token-utils';

export const authenticate = (payload) => {
  return ajax({
      url: `/authentication-api/authenticate`,
      method: "POST",
      body: payload
    }).pipe(map(data => data.response));
}

export const currentUser = () => {
  return ajax({
    url: `/authentication-api/current-user`,
    method: "GET",
    headers: headers()
  }).pipe(map(data => data.response));
}

export const signOut = () => {
debugger;
  return ajax({
    url: `/authentication-api/sign-out`,
    method: "POST",
    headers: headers(),
    body: {}
  }).pipe(map(data => data.response));
}