let TOKEN = null;

export const getToken = () => {
  if(!TOKEN){
    return;
  }
  return TOKEN;
};

export const setToken = (token) => {
  if (!token) {
    TOKEN = null;
    return;
  }
  TOKEN = token;
  return;
};

export const headers = () => {
  const token = getToken();
  if (token && token.length > 8) {
    return {'Authorization': "Bearer " + getToken()}
  }
  return {};
};

