const dotProp = require('dot-prop-immutable');

import {
    GET_PAGE_CONFIGURATION,
    GET_PAGE_CONFIGURATION_FAIL,
    GET_PAGE_CONFIGURATION_SUCCESS,
    SET_CURRENT_CONTEXT,
    SET_CURRENT_TAB,
} from '../actions/application-actions'

import {
    GET_GRID_COLUMN_DATA_SUCCESS,
    SET_AXIS,
} from './../../grid-service/actions/grid-service-actions';

const newInitialState = () => {
    return {
        contextConfigurations: {}
    }
}

import {RequestStatus} from '@dc/dcx/app/util/RequestConstants';

const newContextConfiguration = () => {
    return {
        pageConfiguration: {},
        tabConfiguration: {},
        userActions: {},
        currentPage: {},
        currentStates: {},
        currentStatsPage: {},
        status: RequestStatus.NONE,
    }
}

const mergeCurrentPage = (state, status, actionData) => {
    let contextConfigurations = {...state.contextConfigurations}
    let mergeObject = {status: status, page: actionData.page, searchObject: actionData.searchObject}
    if(!mergeObject.page){
        debugger;
    }
    let updatedContextConfigurations = dotProp.merge(contextConfigurations, [actionData.contextHash, 'currentPage', actionData.channel], mergeObject);
    //let curr = dotProp.get(updatedContextConfigurations, [actionData.contextHash, 'currentPage', actionData.channel])
    return updatedContextConfigurations;
}

const mergeColumnsData = (state, status, actionData) => {
    let contextConfigurations = {...state.contextConfigurations}

//    let currentObject = dotProp.get(contextConfigurations, [actionData.contextHash, 'currentPage', actionData.channel]);

    let updatedPage = [];

    actionData.data.forEach(columnData => updatedPage.push(columnData))

//    currentObject.page.forEach(item => {
//        let key = item.origin_context ? item.origin_context.lifecycle_engine_instance : item.urn;
//        if (actionData.data[key]) {
//            let updatedItem = Object.assign(item, actionData.data[key]);
//            updatedPage.push(updatedItem);
//        } else {
//            updatedPage.push(item);
//        }
//    });
    let updatedContextConfigurations = dotProp.set(contextConfigurations, [actionData.contextHash, 'currentPage', actionData.channel, 'page'], updatedPage);
    return updatedContextConfigurations;
}

const mergeCurrentStatsPage = (state, status, actionData) => {
    let currentStatsPage = {...state.currentStatsPage}
    let mergeObject = {status: status, page: actionData.page, searchObject: actionData.searchObject}
    let updatedCurrentStatsPage = dotProp.merge(currentStatsPage, [actionData.channel], mergeObject);
    return updatedCurrentStatsPage;
}

const mergeAxis = (state, actionData) => {
    let contextConfigurations = {...state.contextConfigurations}
    let updatedContextConfigurations = dotProp.set(contextConfigurations, [actionData.contextHash, 'currentPage', actionData.channel, 'axis'], actionData.axis);
    return updatedContextConfigurations;
}

const addGridConfiguration = (state, actionData) => {
    let contextConfigurations = {...state.contextConfigurations}
    let gridConfigurations = dotProp.get(contextConfigurations, [actionData.contextHash, 'currentPage', actionData.channel, 'gridConfigurations']) || [];
    gridConfigurations.push(actionData.data);
    let updatedContextConfigurations = dotProp.set(contextConfigurations, [actionData.contextHash, 'currentPage', actionData.channel, 'gridConfigurations'], gridConfigurations);
    return updatedContextConfigurations;
}

const makeTabConfiguration = (actionData) => {

    let tabConfiguration = actionData.data.tab_configuration
    if (0 == tabConfiguration.length) {
        return tabConfiguration;
    }
    return {tabs: tabConfiguration, selectedTab: tabConfiguration[0].name, contextHash: actionData.contextHash}
}

const initialState = newInitialState();

const setContextConfigurationStatus = (state, actionData, status) => {
    let contextConfigurations = {...state.contextConfigurations}
    let updatedContextConfigurations = dotProp.set(contextConfigurations, [actionData.contextHash], newContextConfiguration());
    updatedContextConfigurations = dotProp.set(updatedContextConfigurations, ['currentContext'], actionData.contextHash);
    return updatedContextConfigurations;
}

const mergeContextConfiguration = (state, actionData, status) => {
    let contextConfigurations = {...state.contextConfigurations}
    let currentPageInitObject = {}
    Object.keys(actionData.data.page_configuration).forEach(channel => {
        currentPageInitObject[channel] = {contextHash: actionData.contextHash, axis: 'list'}
    });
    let contextConfiguration = {
        status: status,
        currentPage: currentPageInitObject,
        pageConfiguration: actionData.data.page_configuration,
        userActions: actionData.data.user_actions,
        tabConfiguration: makeTabConfiguration(actionData)
    }
    let updatedContextConfigurations = dotProp.merge(contextConfigurations, [actionData.contextHash], contextConfiguration);
    return updatedContextConfigurations;
}

const setCurrentTab = (state, actionData) => {
    let contextConfigurations = {...state.contextConfigurations}
    let updatedContextConfigurations = dotProp.merge(contextConfigurations, [actionData.contextHash, 'tabConfiguration'], {selectedTab: actionData.tabName});
    return updatedContextConfigurations;
}

const setCurrentContext = (state, actionData) => {
    let contextConfigurations = {...state.contextConfigurations}
    let updatedContextConfigurations = dotProp.set(contextConfigurations, ['currentContext'], actionData.contextHash);
    return updatedContextConfigurations;
}

const reducers = (state = initialState, actionData = null) => {

    if (-1 < actionData.type.indexOf('FAIL')) {
        console.error("++++  ERROR ++++++++++++++")
        console.error("error message:", actionData.error);
        console.error("++++++++++++++++++++++++++")
    }

    switch (actionData.type) {

//        case SET_AXIS:
//            return {
//                ...state,
//                contextConfigurations: mergeAxis(state, actionData)
//            }
        case GET_PAGE_CONFIGURATION:
            return {
                ...state,
                contextConfigurations: setContextConfigurationStatus(state, actionData, RequestStatus.SUCCESS),
            }
        case GET_PAGE_CONFIGURATION_SUCCESS:
            return {
                ...state,
                contextConfigurations: mergeContextConfiguration(state, actionData, RequestStatus.SUCCESS),
            }
        case SET_CURRENT_TAB:
            return {
                ...state,
                contextConfigurations: setCurrentTab(state, actionData),
            }
        case SET_CURRENT_CONTEXT:
            return {
                ...state,
                contextConfigurations: setCurrentContext(state, actionData),
            }
        case GET_PAGE_CONFIGURATION_FAIL:
            return {
                ...state,
                pageConfiguration: {},
                userActions: [],
                tabConfiguration: {},
                status: RequestStatus.FAIL,
            }
        case GET_GRID_COLUMN_DATA_SUCCESS:
            return {
                ...state,
                contextConfigurations: mergeColumnsData(state, RequestStatus.SUCCESS, actionData),
            }
        default:
            return state;
    }
}

export default reducers;

