import {fork, put, take} from 'redux-saga/effects'

import {
    LOAD_GC_CONFIGURATIONS, LOAD_GC_CONFIGURATIONS_FAIL, LOAD_GC_CONFIGURATIONS_SUCCESS,
    LOAD_ACTIVE_PRODUCTION_MODELS, LOAD_ACTIVE_PRODUCTION_MODELS_FAIL, LOAD_ACTIVE_PRODUCTION_MODELS_SUCCESS,
    LOAD_SITES_CONFIGURATIONS_MODELS, LOAD_SITES_CONFIGURATIONS_MODELS_FAIL, LOAD_SITES_CONFIGURATIONS_MODELS_SUCCESS
} from './../actions/growcloud-v1-service-actions';

import {httpErrorResponseHandler} from 'gc-authentication/authentication/utils/authentication-utils';

import {
    makeActiveProductionModelsRequest,
    makeSitesConfigurationsModelsRequest
} from '../../GrowcloudV1ServiceHelper';

const doSitesConfigurationsModels = (userContext, sitesConfigurationsModelsRequest) => {
    return new Promise((success, error) => {
        let sitesConfigurationsModels = []
        userContext.serviceRegistry.growcloudV1Service().sitesConfigurationsModels(sitesConfigurationsModelsRequest,
            doSitesConfigurationsModelsResponse => {
                sitesConfigurationsModels.push(doSitesConfigurationsModelsResponse.toObject())
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(sitesConfigurationsModels);
                return;
            }
            error(code, msg, trailers)
        })
    })
}

export function* sitesConfigurationsModelsConsumer() {
    while (true) {
        const request = yield take(LOAD_SITES_CONFIGURATIONS_MODELS)
        let sitesConfigurationsModelsRequest = makeSitesConfigurationsModelsRequest()
        try {
            let sitesConfigurationsModelsResponse = yield doSitesConfigurationsModels(request.userContext, sitesConfigurationsModelsRequest);
            yield put({
                type: LOAD_SITES_CONFIGURATIONS_MODELS_SUCCESS,
                sitesConfigurationsModels: sitesConfigurationsModelsResponse,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: LOAD_SITES_CONFIGURATIONS_MODELS_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}

const doActiveProductionModels = (userContext, activeProductionModelsRequest) => {
    return new Promise((success, error) => {
        let activeProductionModels = []
        userContext.serviceRegistry.growcloudV1Service().activeProductionModels(activeProductionModelsRequest,
            doActiveProductionModelsResponse => {
                activeProductionModels.push(doActiveProductionModelsResponse.toObject())
            }).then((code, msg, trailers) => {
            if (0 == code) {
                success(activeProductionModels);
                return;
            }
            error(code, msg, trailers)
        })
    })
}

export function* activeProductionModelsConsumer() {
    while (true) {
        const request = yield take(LOAD_ACTIVE_PRODUCTION_MODELS)
        let activeProductionModelsRequest = makeActiveProductionModelsRequest()
        try {
            let activeProductionModelsResponse = yield doActiveProductionModels(request.userContext, activeProductionModelsRequest);
            yield put({
                type: LOAD_ACTIVE_PRODUCTION_MODELS_SUCCESS,
                activeProductionModels: activeProductionModelsResponse,
                contextHash: request.contextHash,
            });
        } catch (error) {
            let errorObject = httpErrorResponseHandler(error, {
                type: LOAD_ACTIVE_PRODUCTION_MODELS_FAIL,
                error: error,
                channel: request.channel,
                contextHash: request.contextHash,
            });
            yield put(errorObject);
        }
    }
}

export default function* root() {
    yield fork(activeProductionModelsConsumer)
    yield fork(sitesConfigurationsModelsConsumer)
}