import React from 'react';

import { withStyles } from '@mui/styles';
import Text from '@dc/dc/content/Text';

const styles = theme => ({
    button: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.675rem',
        },
    },
    leftNode: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: '1.175rem',
    }
});

class ChangeStatusButton extends React.Component {
    render() {
        const {
            classes,
            children,
            onClick,
            buttonText,
            ...rest
        } = this.props;
        return (
            <Text
                color="textSecondary"
                variant="caption"
                onClick={(e) => {
                    if (onClick) {
                        onClick(e)
                    }
                    e.stopPropagation();
                    e.preventDefault();
                }}>
                {buttonText}
            </Text>
        )
    }
}

ChangeStatusButton.defaultProps = {
    color: 'secondary',
    fullWidth: false,
    disableRipple: true,
    variant: 'outlined'
}

export default withStyles(styles)(ChangeStatusButton);