import React from 'react'
import { withStyles } from '@mui/styles';;
//import { TextField as TextField_reduxFormMaterial } from '../../shim/redux-form-material-ui/es';
import FormControl from '@mui/material/FormControl';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
});

function TextMaskCustom(props) {
    const {input, mask} = props;
//	debugger;
    return (
        <FormControl fullWidth>
            {/*<MaskedInput
                mask={mask}
                {...input}
                {...props}
                //            render={(props) => (
                //            	<Input {...props}   />
                //            )}
            />*/}
        </FormControl>
    );
}

class TextMaskField extends React.Component {
    state = {
        textmask: 'hello',
    };

    handleChange = name => event => {
        console.log('HANDLE CHANGE masked input field: ', event);

        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        const {classes, ...rest} = this.props;
        const {textmask} = this.state;
//	console.log('Mask prop: ', this.props.mask);
        return (
            <TextMaskCustom
                label={this.props.label}
                value={textmask}
                onChange={this.handleChange('textmask')}
                {...rest}
            />
        );
    }
}

export default withStyles(styles)(TextMaskField);