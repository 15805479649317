import {
    LOAD_ACTIVE_PRODUCTION_MODELS_SUCCESS,
    LOAD_SITES_CONFIGURATIONS_MODELS_SUCCESS
} from '../actions/growcloud-v1-service-actions';

const initialState = {activeProductionModels: [], sitesConfigurationsModels: []}

const setActiveProductionModels = (state, actionData) => {
    let activeProductionModels = {...state.activeProductionModels}
    let updatedActiveProductionModels = actionData.activeProductionModels;
    return updatedActiveProductionModels;
}

const setSitesConfigurationsModels = (state, actionData) => {
    let sitesConfigurationsModels = {...state.sitesConfigurationsModels}
    let updatedSitesConfigurationsModels = actionData.sitesConfigurationsModels;
    return updatedSitesConfigurationsModels;
}

const reducers = (state = initialState, actionData = null) => {
    switch (actionData.type) {
        case LOAD_ACTIVE_PRODUCTION_MODELS_SUCCESS:
            return {
                ...state,
                activeProductionModels: setActiveProductionModels(state, actionData)
            }
        case LOAD_SITES_CONFIGURATIONS_MODELS_SUCCESS:
            return {
                ...state,
                sitesConfigurationsModels: setSitesConfigurationsModels(state, actionData)
            }
        default:
            return state;
    }
}

export default reducers;

