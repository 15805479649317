import React from 'react'
import Grid from '@mui/material/Grid';
import CTAButton from '@dc/dc/components/buttons/CTAButton';

import {connect} from 'react-redux';

import DcxFormField from '@dc/dcx/form/DcxFormField';
import DcxForm, {AUTOCOMPLETE, VARIANT} from '@dc/dcx/form/DcxForm';

import TextField from '@dc/dcx/form/Field/TextField';
import Loading from '@dc/dcx/app/common/Loading';

const contextHash = "initial-roles";

class ChooseOptionsInternal extends React.Component {

    renderRoles(currentContext){
 return [];
}
    renderRolesX(currentContext){
        let itemArray = [];
        currentContext.page.forEach(role => {
            itemArray.push((
                <>
                    <DcxFormField
                        component={TextField}
                        required={true}
                        name={`org-role-rename-${role.urn}`}
                        label={role.label}
                    />
                    {renderProcessMatches(role.process_matches)}
                </>
            ))
        })
        return itemArray;
    }

    render() {
        const { contextConfigurations } = this.props;
//        if(contextConfigurations[contextHash] && contextConfigurations[contextHash]['currentPage']) {
            const currentContext = {}; //contextConfigurations[contextHash]['currentPage'][Channel.ROLES];
            return (
                <Grid container spacing={2}>
                    <DcxForm
                        form={'importProductLine'}
                        commands={[
                            {type: VARIANT, value: 'outlined'},
                            {type: AUTOCOMPLETE, value: true},
                        ]}
                        onSubmit={() => {
                        }}
                        initialValues={{}}
                        validate={()=>true}
                    >
                    {this.renderRoles(currentContext)}
                    </DcxForm>
                </Grid>
            )
//        }
//        return (<Loading/>)
    }
}

export const ChooseOptions = connect(state => {
    return {
        contextConfigurations: state.application.contextConfigurations,
    }
})(ChooseOptionsInternal)

export const ImportProductLineCommit = props => {

    const {createAction, createContext } = props;

    return (
        <Grid container justifyContent={'flex-end'}>
            <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                    <CTAButton
                        buttonColor="primary"
                        buttonText="SUBMIT"
                        type="submit"
                        disabled={false}
                        onClick={createAction}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}