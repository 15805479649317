import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {dialogStyleSheet} from '../../style/BaseStyles';
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CloseIcon from '@mui/icons-material/Close';
import DefaultDialogTitle from "./DialogCommons";

class DefaultDialog extends React.PureComponent {

    renderDialogContentText = () => {
        const {classes, dialogContentText} = this.props;
        return (
            <DialogContentText
                className={classes.dialogContentTextSpacer}
            >
                {dialogContentText}
            </DialogContentText>
        )
    };

    render() {
        const {
            children,
            classes,
            isOpen,
            onHandleClose,
            dialogButton,
            dialogTitle,
            dialogContentText,
            dialogContext,
            underlineTitle,
            defaultPaperBackground,
            actionsBar,
            fullScreen=false,
        } = this.props;

        let hasDialogContentText = dialogContentText ? (
            <DialogContentText
                className={classes.dialogContentTextSpacer}
            >
                {dialogContentText}
            </DialogContentText>
        ) : (null);

        let paperClass = classnames(classes.paper, classes.setMinHeight, {
            [classes.defaultPaperBackground]: defaultPaperBackground
        });

        return (

            <React.Fragment>
                {dialogButton}
                <Dialog
                    open={isOpen}
                    onClose={onHandleClose}
                    aria-labelledby="form-dialog-title"
                    classes={{paper: paperClass}}
                    disableScrollLock={true}
                >
                    <DefaultDialogTitle
                        onHandleClose={onHandleClose}
                        dialogTitle={dialogTitle}
                        dialogContext={dialogContext}
                        // underlineTitle
                    />
                    <DialogContent className={underlineTitle && classes.rootContentText}>
                        {hasDialogContentText && this.renderDialogContentText()}
                        {children}
                    </DialogContent>
                    {actionsBar && (
                        <DialogActions>{actionsBar}</DialogActions>
                    )}
                </Dialog>
            </React.Fragment>
        )
    }
}

DefaultDialog.propTypes = {
    children: PropTypes.node.isRequired,
    isOpen: PropTypes.bool.isRequired,
    dialogButton: PropTypes.object,
    dialogTitle: PropTypes.string.isRequired,
}

DefaultDialog.defaultProps = {
    closeicon: <CloseIcon/>,
}

export default withStyles(dialogStyleSheet)(DefaultDialog);