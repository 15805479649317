/**
 * @fileoverview gRPC-Web generated client stub for growcloud.v1.service
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.9
// source: growcloud/v1/service/ProgramsGridService.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var growcloud_v1_grid_GridServiceMessages_pb = require('../../../growcloud/v1/grid/GridServiceMessages_pb.js')

var growcloud_v1_grid_GridCommons_pb = require('../../../growcloud/v1/grid/GridCommons_pb.js')
const proto = {};
proto.growcloud = {};
proto.growcloud.v1 = {};
proto.growcloud.v1.service = require('./ProgramsGridService_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.growcloud.v1.service.ProgramsGridServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.growcloud.v1.service.ProgramsGridServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.growcloud.v1.grid.GetConfigurationRequest,
 *   !proto.growcloud.v1.grid.GetConfigurationsResponse>}
 */
const methodDescriptor_ProgramsGridService_getProgramsGridConfigurations = new grpc.web.MethodDescriptor(
  '/growcloud.v1.service.ProgramsGridService/getProgramsGridConfigurations',
  grpc.web.MethodType.UNARY,
  growcloud_v1_grid_GridServiceMessages_pb.GetConfigurationRequest,
  growcloud_v1_grid_GridServiceMessages_pb.GetConfigurationsResponse,
  /**
   * @param {!proto.growcloud.v1.grid.GetConfigurationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  growcloud_v1_grid_GridServiceMessages_pb.GetConfigurationsResponse.deserializeBinary
);


/**
 * @param {!proto.growcloud.v1.grid.GetConfigurationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.growcloud.v1.grid.GetConfigurationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.grid.GetConfigurationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.ProgramsGridServiceClient.prototype.getProgramsGridConfigurations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/growcloud.v1.service.ProgramsGridService/getProgramsGridConfigurations',
      request,
      metadata || {},
      methodDescriptor_ProgramsGridService_getProgramsGridConfigurations,
      callback);
};


/**
 * @param {!proto.growcloud.v1.grid.GetConfigurationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.growcloud.v1.grid.GetConfigurationsResponse>}
 *     Promise that resolves to the response
 */
proto.growcloud.v1.service.ProgramsGridServicePromiseClient.prototype.getProgramsGridConfigurations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/growcloud.v1.service.ProgramsGridService/getProgramsGridConfigurations',
      request,
      metadata || {},
      methodDescriptor_ProgramsGridService_getProgramsGridConfigurations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.growcloud.v1.grid.GetColumnDataRequest,
 *   !proto.growcloud.v1.grid.ColumnDataSet>}
 */
const methodDescriptor_ProgramsGridService_getProgramsGridData = new grpc.web.MethodDescriptor(
  '/growcloud.v1.service.ProgramsGridService/getProgramsGridData',
  grpc.web.MethodType.SERVER_STREAMING,
  growcloud_v1_grid_GridServiceMessages_pb.GetColumnDataRequest,
  growcloud_v1_grid_GridCommons_pb.ColumnDataSet,
  /**
   * @param {!proto.growcloud.v1.grid.GetColumnDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  growcloud_v1_grid_GridCommons_pb.ColumnDataSet.deserializeBinary
);


/**
 * @param {!proto.growcloud.v1.grid.GetColumnDataRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.grid.ColumnDataSet>}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.ProgramsGridServiceClient.prototype.getProgramsGridData =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/growcloud.v1.service.ProgramsGridService/getProgramsGridData',
      request,
      metadata || {},
      methodDescriptor_ProgramsGridService_getProgramsGridData);
};


/**
 * @param {!proto.growcloud.v1.grid.GetColumnDataRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.growcloud.v1.grid.ColumnDataSet>}
 *     The XHR Node Readable Stream
 */
proto.growcloud.v1.service.ProgramsGridServicePromiseClient.prototype.getProgramsGridData =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/growcloud.v1.service.ProgramsGridService/getProgramsGridData',
      request,
      metadata || {},
      methodDescriptor_ProgramsGridService_getProgramsGridData);
};


module.exports = proto.growcloud.v1.service;

