const dotProp = require('dot-prop-immutable');

import {
    DYNAMIC_GRID_STATE_CHANGE_ACTION,
    GET_GRID_CONFIGURATIONS_SUCCESS,
    SET_CURRENT_GRID_CONFIGURATION,
} from '../actions/grid-service-actions';

const initialState = {gridConfigurations: {}}

const setCurrentGridConfiguration = (state, actionData) => {
    let gridConfigurations = {...state.gridConfigurations}
    let updatedGridConfigurations = dotProp.set(gridConfigurations,
        [actionData.channel, 'currentCurrentConfiguration'],
        actionData.data);
    return updatedGridConfigurations;
}

const mergeGridStateChange = (state, actionData) => {
    let gridConfigurations = {...state.gridConfigurations}
    let updatedGridConfigurations = dotProp.set(gridConfigurations,
        [actionData.channel, 'currentCurrentConfiguration', actionData.partialStateName],
        actionData.partialStateValue);
    return updatedGridConfigurations;
}

const setGridConfigurations = (state, actionData) => {
    let gridConfigurations = {...state.gridConfigurations}
    let updatedGridConfigurations = dotProp.set(gridConfigurations, [actionData.channel], {
        currentCurrentConfiguration: actionData.data[0],
        gridConfigurations: actionData.data
    });
    return updatedGridConfigurations;
}

const reducers = (state = initialState, actionData = null) => {
    switch (actionData.type) {
        case SET_CURRENT_GRID_CONFIGURATION:
            return {
                ...state,
                gridConfigurations: setCurrentGridConfiguration(state, actionData),
            }
        case DYNAMIC_GRID_STATE_CHANGE_ACTION:
            return {
                ...state,
                gridConfigurations: mergeGridStateChange(state, actionData),
            }
        case GET_GRID_CONFIGURATIONS_SUCCESS:
            return {
                ...state,
                gridConfigurations: setGridConfigurations(state, actionData)
            }
        default:
            return state;
    }
}

export default reducers;

