import React from 'react'
import ListItem from '@mui/material/ListItem';
import Text from "../../content/Text";
import {navigationLinkStyleSheet} from "../../style/BaseStyles";
import { withStyles } from '@mui/styles';
import classnames from 'classnames';

class ButtonListItem extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        const {
            item,
            classes,
            TextTypography = {
                variant: 'body1',
                color: 'textSecondary'
            },
            clickHandler,
            LinkProps = {},
            linkClassName,
        } = this.props;

        const linkClassNames = linkClassName ? classnames(classes.linkWrapper, linkClassName) : classes.linkWrapper;

        let finalToRoute;
        let finalOnClick;
        if(clickHandler){
            finalToRoute = null;
            finalOnClick = (e) => {
                clickHandler(item)
                e.preventDefault();
                e.stopPropagation();
            };
        } else {
            finalToRoute = item.href;
            finalOnClick = null
        }

        return (
            <ListItem button>
                <Text
                    toRoute={finalToRoute}
                    {...TextTypography}
                    onClick={finalOnClick}
                    {...LinkProps}
                    linkClassName={linkClassNames}
                >
                    {item.label}
                </Text>
            </ListItem>
        )
    }
}

export default withStyles(navigationLinkStyleSheet)(ButtonListItem)
