import React from 'react';
import Grid from '@mui/material/Grid';
import Text from '@dc/dc/content/Text';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import Worksheet, {WorksheetNode} from '@dc/dc/components/surface/Worksheet';

import SchedulesGrid from 'gc-webapp/gc/SchedulesGrid'


import StartYourGrowDialog from 'gc-webapp/gc/StartYourGrowDialog'

import Loading from '@dc/dcx/app/common/Loading';

import CurrentActiveProductionModels from "../component/CurrentActiveProductionModels"

class GrowcloudLanding extends React.PureComponent {
    render() {
        const { userContext } = this.props;
        return (
            <>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    <CurrentActiveProductionModels userContext={userContext}/>
                </Grid>
                <Grid item xs={2}>
                    <ContainerX AsContainer withLargeBottomMargin withLargeTopMargin>
                        {/*<ButtonGroup
                            orientation="vertical"
                            aria-label="Vertical button group"
                            variant="text"
                          >
                            <Button key="one">Add site</Button>
                            <Button key="two">Add enclosure</Button>
                            <Button key="three">Add asset</Button>
                        </ButtonGroup>*/}
                        <StartYourGrowDialog/>
                    </ContainerX>
                </Grid>
            </Grid>
            </>
        )
    }
}
export default (GrowcloudLanding)