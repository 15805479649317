const headers = () => {
    return {}
}

export const getRequest = (url) => {
    //return axios.get(`/config/${url}`, headers());
}

export const postRequest = (url, data) => {
    //return axios.post(`/config/${url}`, data, headers());
}