import React from 'react'
import PropTypes from 'prop-types';
import {contentBlockStyleSheet} from '../../style/SurfaceStyles';

import { withStyles } from '@mui/styles';
import {makeClassNames} from "../../style/StylesUtils";
import ContainerX from "../layout/ContainerX";

const ContentBlock = React.forwardRef(function ContentBlock(props, ref) {
    const {
        classes,
        className,
        component: Component = ContainerX,
        elevation = 2,
        padding = 'none',
        overrideClasses,
        square = true,
        variant = 'none',
        displayVariant = 'none',
        SurfaceProps = {},
        ...rest
    } = props;

    const classNameObj = makeClassNames(classes,
        {variant, displayVariant},
        rest,
        {
            [classes.roundedCorners]: !square,
        },
        overrideClasses);

    if (elevation === 0) {
        return (
            <Component
                className={classNameObj.classNames}
                elevation={0}
                component={Component}
                ref={ref}
                {...SurfaceProps}
                {...classNameObj.props}
            />
        )
    }

    return (
        <Component
            className={classNameObj.classNames}
            elevation={elevation}
            component={Component}
            ref={ref}
            {...SurfaceProps}
            {...classNameObj.props}
        />
    )
});

ContentBlock.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * Override or extend the styles applied to the component.
     * See [CSS API](#css) below for more details.
     */
    classes: PropTypes.object,
    /**
     * @ignore
     */
    className: PropTypes.string,
    /**
     * The size of the contentBlock padding.
     * `dense` is inline with global dense layout setting.
     */
    padding: PropTypes.oneOf(['regular', 'dense', 'none']),
    /**
     * The background color of the contentBlock
     * `dark` is equivalent to the theme background default.
     */
    backgroundColor: PropTypes.oneOf(['paper', 'default', 'none', 'primary', 'secondary']),
    component: PropTypes /* @typescript-to-proptypes-ignore */.elementType,
    /**
     * Shadow depth, corresponds to `dp` in the spec.
     * It accepts values between 0 and 24 inclusive.
     */
    /**
     * If `true`, corners are square.
     */
    square: PropTypes.bool,
    /**
     * If `true`, rounded corners are enabled.
     */
    roundedCorners: PropTypes.bool,
    /**
     * The variant to use.
     */
    // variant: PropTypes.oneOf(['elevation', 'outlined', 'fitted']),
};

export default withStyles(contentBlockStyleSheet)(ContentBlock);
