import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@mui/styles';

import {connect} from 'react-redux';

import Grid from '@mui/material/Grid';
import Text from '@dc/dc/content/Text';
import Divider from '@mui/material/Divider';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import ContentBlock from '@dc/dc/components/surface/ContentBlock';
import GlobalControlToolBar from "@dc/dcx/app/components/GlobalControlToolBar";
import Worksheet from '@dc/dc/components/surface/Worksheet';
import {
	clearSelectedItem,
	setCurrentChartConfiguration,
	setMonthRange,
} from 'gc-ui-components/ui/mw/calendar-chart-service/actions/calendar-chart-service-actions';
import LegendItem from '@dc/dc/components/dataDisplay/LegendItem';
import DynamicCalendarChart from 'gc-ui-components/ui/chart/DynamicCalendarChart';


import {
	colorHash,
	initialChartData,
	monthSlice,
	nextMonth,
	previousMonth,
	rAccessor,
	tickValues
} from "./CalendarToolUtils";

import Loading from '@dc/dcx/app/common/Loading';

const chart = { id: 'solution-calculator' };

const legendData = [
    {label: 'License', color: colorHash.licensing},
    {label: 'Cloud', color: colorHash.cloud},
    {label: 'Consulting', color: colorHash.consulting},
    {label: 'Storage', color: colorHash.storage}
]

const makeInitialCalendarChartConfiguration = () => {
	let chartConfiguration = {chartData: initialChartData, rAccessor, colorHash, tickValues, fromMonth: 'May 20', toMonth: 'Apr 21', selectedItem: null}
	return chartConfiguration;
};

const styles = theme => ({
    root: {
    	padding: 0,
    },
});

const makeChartChannel = (chart) => {
	return `$$chart-${chart.id}`
};

class CalendarTool extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
			thisSolution: null,
			chartChannel: null,
		}
    }

    componentDidMount(){
    	const{dispatch} = this.props;
		let chartChannel0 = makeChartChannel("PLANNING");
		let chartConfiguration = makeInitialCalendarChartConfiguration()
		dispatch(setCurrentChartConfiguration('a', chartChannel0, chartConfiguration));
		this.setState({ chartChannel: chartChannel0})
	}

	static getDerivedStateFromProps(props, state) {
		const {chartChannel} = state;
		const {dispatch} = props;

		if(chartChannel && chart){
			let chartChannel0 = makeChartChannel("PLANNING");
			if(chartChannel != chartChannel0){
				debugger;
				let chartConfiguration = makeInitialCalendarChartConfiguration()
				dispatch(setCurrentChartConfiguration('a', chartChannel0, chartConfiguration));
				return { chartChannel: chartChannel0}
			}
		}
		return null;
	}

	clearSelection(){
    	const{dispatch} = this.props;
		const {chartChannel} = this.state;
		dispatch(clearSelectedItem(chartChannel))
	}
	

	makeWorksheetContentNode(selectedItem){
    	const{vendingContext} = this.props;
		return (
			<>NODE</>
		)
	}

	makeDefaultWorksheetContentNode(){
		return (<span>Configure your subscription package</span>)
	}

	rightAction(){
		const {chartConfigurations, dispatch} = this.props;
		const {chartChannel} = this.state;

		const currentDynamicCalendarChart = chartConfigurations[chartChannel];

		const fromMonth = currentDynamicCalendarChart.currentCurrentConfiguration.fromMonth;
		const toMonth = currentDynamicCalendarChart.currentCurrentConfiguration.toMonth;

		const nextFromMonth = nextMonth(currentDynamicCalendarChart.currentCurrentConfiguration.chartData, fromMonth);
		const nextToMonth = nextMonth(currentDynamicCalendarChart.currentCurrentConfiguration.chartData, toMonth);

		dispatch(setMonthRange(chartChannel, nextFromMonth, nextToMonth))
	}

	leftAction(){
		const {chartConfigurations, dispatch} = this.props;
		const {chartChannel} = this.state;

		const currentDynamicCalendarChart = chartConfigurations[chartChannel];

		const fromMonth = currentDynamicCalendarChart.currentCurrentConfiguration.fromMonth;
		const toMonth = currentDynamicCalendarChart.currentCurrentConfiguration.toMonth;

		const previousFromMonth = previousMonth(currentDynamicCalendarChart.currentCurrentConfiguration.chartData, fromMonth);
		const previousToMonth = previousMonth(currentDynamicCalendarChart.currentCurrentConfiguration.chartData, toMonth);

		dispatch(setMonthRange(chartChannel, previousFromMonth, previousToMonth))
	}

	renderLegend(data) {
		let nodes = [];

		data.forEach((item, index) => {
			nodes.push(<Grid item key={index+item.label}><LegendItem label={item.label} color={item.color}/></Grid>)
		})

		return nodes;
	}

    render() {
        const {chartConfigurations, vendingContext} = this.props;
        const {chartChannel} = this.state;

		const currentDynamicCalendarChart = chartConfigurations[chartChannel];

		if(!currentDynamicCalendarChart){
			return (<Loading message={'Loading'}/>)
		}

		let selectedItem;
		if(currentDynamicCalendarChart.currentCurrentConfiguration.selectedItem){
			selectedItem = currentDynamicCalendarChart.currentCurrentConfiguration.selectedItem;
		} else {
			selectedItem = null;
		}

		const fromMonth = currentDynamicCalendarChart.currentCurrentConfiguration.fromMonth;
		const toMonth = currentDynamicCalendarChart.currentCurrentConfiguration.toMonth;
		let chartData = monthSlice(currentDynamicCalendarChart.currentCurrentConfiguration.chartData, fromMonth, toMonth);
		const leftDisabled = !previousMonth(currentDynamicCalendarChart.currentCurrentConfiguration.chartData, fromMonth);
		const rightDisabled = !nextMonth(currentDynamicCalendarChart.currentCurrentConfiguration.chartData, toMonth)

        return (
            <React.Fragment>
				<ContainerX AsContainer withLargeTopMargin>
                    <DynamicCalendarChart
                        pageContext={{page: []}}
                        channel={chartChannel}
                        currentDynamicCalendarChart={currentDynamicCalendarChart}
                        leftDisabled={leftDisabled}
                        rightDisabled={rightDisabled}
                        leftAction={this.leftAction.bind(this)}
                        rightAction={this.rightAction.bind(this)}
                        chartData={chartData}
                    />
                </ContainerX>				        										
            </React.Fragment>
        )
    }
}

CalendarTool.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(state => {
	return {
		chartConfigurations: state.calendarChart.chartConfigurations
	}
})(
	withStyles(styles)(CalendarTool)
)
