import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemText from '@mui/material/ListItemText';

import InternalLink from '@dc/dc/components/links/InternalLink';
import Badge from '@mui/material/Badge';
import {Link} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import classnames from 'classnames';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DeleteIcon from '@mui/icons-material/Delete';
//import { brandPrimaryBold, brandPrimaryBold_hover, brandPrimaryActiveText, brandSecondaryText, brandPrimary} from '@dc/dc/themes/color-palette';
import PopoverBase from '@dc/dc/components/PopoverBase';

import {popoverBaseStyleSheet} from '@dc/dc/style/BaseStyles';
import {LocalDate} from "../../../i18n/GlobalisationUtils";

//TODO this is currently blunt; it can be sharpened by adding prioirity weightings etc. to notifications
// in order to extract more relevant items to the appbar notifications
const spliceView = (notifications) => {
    if (7 < notifications.length) {
        return notifications.slice(notifications.length - 7, notifications.length);
    }
    return notifications;
}

class AppbarNotifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            anchorElement: null,
            localView: [],
        }
    }

    static getDerivedStateFromProps(props, state) {

        //TODO looks tres dodgy  - some crude way of detecting updates
        let propsViewLength = props.notifications.currentView.length;
        let stateViewLength = state.localView.length;

        if (propsViewLength != stateViewLength) {
            return {localView: spliceView(props.notifications.currentView)};
        }
        return null;
    }

    renderNotification(notification) {
        if (!notification.link) {
            return (
                <ListItemText
                    primary={notification.message}
                    secondary={(
                        <LocalDate
                            timestamp={notification.timestamp}
                            when={false}
                            divider
                        />
                    )}
                />
            )
        }
        return (
            <ListItemText
                primary={(
                    <InternalLink
                        linkLocation={notification.link}
                        linkText={notification.message}
                    />
                )}
                secondary={(
                    <LocalDate
                        timestamp={notification.timestamp}
                        when={false}
                        divider
                    />
                )}
            />
        )
    }

    renderNotificationsContent(localView) {
        const {classes, notifications} = this.props;
        let itemsArray = [];
        localView.forEach(notification => itemsArray.push(this.renderNotification(notification)));
        return itemsArray;
    }

    componentDidMount() {
        const {dispatch, notifications} = this.props;
    }

    closeNotificationsMenu() {
        this.setState({open: false})
    }

    openNotificationsMenu(element) {
        this.setState({open: true, anchorElement: element})
    }

    render() {
        const {classes} = this.props;
        const {open, anchorElement, localView} = this.state;
        const latestNotifications = this.renderNotificationsContent(localView);
        return (
            <React.Fragment>
                <IconButton
                    id="notificationsMenuButton"
                    data-id="notificationsMenuButton"
                    onClick={() => this.openNotificationsMenu(notificationsMenuButton)}
                    className={classes.buttonWrap}
                    disableRipple
                >
                    <Badge
                        color="primary"
                        badgeContent={latestNotifications.length}
                        classes={{root: classes.menuBadge, badge: classes.newActionsBadge}}
                    >
                        <NotificationsIcon/>
                    </Badge>
                </IconButton>
                <PopoverBase
                    //          customClass="classes.customAdj"
                    isOpen={open}
                    anchorEl={anchorElement}
                    onHandleMenuClose={() => this.closeNotificationsMenu(event)}
                    anchorPosition={{
                        top: '58px',
                    }}
                    disableScrollLock={true}
                >
                    <Grid container spacing={2} className={classes.notificationsMenuWidth}>
                        <Grid item xs={12}>
                            <List component="nav" disablePadding subheader={(
                                <ListSubheader
                                    className={classnames(classes.listSubheader, classes.guttersFix)}
                                >
                                    Notifications
                                </ListSubheader>
                            )}>
                                {latestNotifications.length && latestNotifications.map((notification, index) => (
                                    <ListItem
                                        key={index}
                                        classes={{gutters: classes.guttersFix}}
                                        divider
                                    >
                                        {notification}
                                        <IconButton aria-label="Delete" key={index}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItem>
                                ))}
                                <ListItem
                                    className={classnames(classes.actionsRow, classes.guttersFix)}
                                    style={{justifyContent: 'flex-end'}}
                                >
                                    <Grid item>
                                        <Button
                                            variant="text"
                                            color="inherit"
                                            size="small"
                                            component={Link}
                                            to="/notifications"
                                            onClick={this.closeNotificationsMenu.bind(this)}
                                        >
                                            See All
                                        </Button>
                                    </Grid>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </PopoverBase>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        notifications: state.notifications.notifications,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

AppbarNotifications.propTypes = {
    classes: PropTypes.object.isRequired,
    notifications: PropTypes.object,
    notificationsMenu: PropTypes.object,
}

export default withStyles(popoverBaseStyleSheet)(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AppbarNotifications))
