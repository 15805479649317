import React from 'react';
import {FormattedDate, FormattedRelativeTime, FormattedTime, injectIntl, IntlProvider,} from 'react-intl';

const renderWhen = (timestamp) => {
    const now = new Date(Date.now());
    const delta = timestamp-now;
    if(delta >= -3600000){
        const minutes = Math.floor((delta/1000)/60)
        return (
            <FormattedRelativeTime
                value={minutes}
                unit="minute"
                style="narrow"
            />
        )
    }
    if(delta >= -259200000){
        const hours = Math.floor(((delta/1000)/60)/60)
        return (
            <FormattedRelativeTime
                value={hours}
                unit="hour"
                style="narrow"
            />
        )
    }
    const days = Math.floor((((delta/1000)/60)/60)/24);
    return (
        <FormattedRelativeTime
            value={days}
            unit="day"
            style="narrow"
        />
    )
}

export const LocalDate = injectIntl(({timestamp, when}) => {
    return (
        <span>
            <FormattedDate
                value={new Date(timestamp)}/
                >
                <FormattedTime
                    value={new Date(timestamp)}
                    />
                    {when && <i>{renderWhen(timestamp)}</i>}
        </span>
    )
});

export const formatMessage = (locale, messages, message) => {
     const {intl} = new IntlProvider({
            locale: locale,
            messages: messages,
        },
        {}
    ).getChildContext()
    const value = intl.formatMessage({...message})
    return value;
}


export const normaliseLocale = (locale) => {
    return 'en'
}