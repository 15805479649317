import React from 'react'
import {red} from '@mui/material/colors';

import {alpha} from '@mui/material/styles'

export const drawerWidth = 240;
export const appbarHeight = 64;
export const worksheetWidth = 240;

export const tableStyleSheet = () => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    cell: {
        padding: 4,
        whiteSpace: 'normal',
    },
    checkbox: {
        width: 28,
        height: 28,
    },
});

export const MenuIconButtonStyleSheet = theme => ({
    button: {
        backgroundColor: 'transparent',
//    width: theme.spacing(3.5),
//    height: theme.spacing(3.5),
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    labelButton: {
        display: "flex",
        flexWrap: "wrap"
    },
    label: {
        fontWeight: "bold"
    }
});

export const dialogStyleSheet = theme => ({
    root: {
        width: '100%',
//		backgroundColor: theme.palette.background.paper,
    },
    defaultPaperBackground: {
        backgroundColor: theme.palette.background.default,
    },
    paper: {
        minWidth: '100vw',
        [theme.breakpoints.up('md')]: {
            minWidth: '600px',
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: '800px',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '86vw',
        },
    },
    paperFullScreen: {
        paddingTop: `${theme.mixins.toolbar.minHeight + theme.spacing(3)}px`,
    },
    rootContentText: {
        marginBottom: '1em',
        paddingTop: theme.spacing(3),
    },
    closeButton: {
        position: 'absolute',
        top: theme.spacing(1.5),
        right: theme.spacing(2),
    },
    underlineTitle: {
        position: 'relative',
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    slimLineDialog: {
        padding: theme.spacing(2, 3),
    },
    dialogContentTextSpacer: {
        marginBottom: theme.spacing(2),
    },
    paperScrollPaper: {
        [theme.breakpoints.up('md')]: {
            height: 'calc(100% - 96px)',
        },
    },
    setMinHeight: {
        [theme.breakpoints.up('sm')]: {
            minHeight: '50vh',
        },
    },
    rootContentBase: {
    	padding: theme.spacing(0,3,3),
    },
    rootTitle: {
		padding: theme.spacing(0,3,2),
	},
    fontWeightMedium: {
        fontWeight: theme.typography.fontWeightMedium
    },
});

export const gridStyleSheet = theme => ({
    growItem: {
        flexGrow: 1,
    }
});

export const cardStyleSheet = theme => ({
    card: {
        minWidth: '100%',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    growItem: {
        flexGrow: 1,
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    pos: {
        marginBottom: 12,
        color: theme.palette.text.secondary,
    },
    avatar: {
        backgroundColor: red[500],
    },
    titleBottomMargin: {
        marginBottom: theme.spacing(3),
    },
    fontWeightMedium: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    cardSectionWrapper: {
        padding: theme.spacing(3, 3, 0),
    },
    cardActionsWrapper: {
        padding: theme.spacing(2, 3, 3),
    },
});

export const chipStyleSheet = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    chip: {
        margin: theme.spacing(1),
    },
});


export const navigationLinkStyleSheet = theme => ({
    linkWrapper: {
        textDecoration: 'none',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontWeight: theme.typography.fontWeightMedium,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0
        }
    },
    defaultNavigationLinkText: {
        color: theme.palette.text.primary
    }
});

export const navigationMenuStyleSheet = theme => ({
    container: {
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
    },
    list: {
        'list-style-type': 'none',
        padding: '0',
        display: 'flex',
    },
    listItem: {
        display: 'inline-block',
        padding: theme.spacing(1),
    },
    link: {
        display: 'block',
        'text-decoration': 'none',
        'text-transform': 'capitalize',
        'font-size': theme.typography.fontSize,
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.text.primary
        },
        '&:focus': {
            color: theme.palette.text.primary
        },
    }
});

export const popoverBaseStyleSheet = theme => ({
    paper: {
        overflow: 'visible',
//     backgroundColor: theme.palette.background.default,
        boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.13), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',

        '&:before': {
            content: '" "',
            position: 'absolute',
            top: '-11px',
            right: '8px',
            height: 0,
            width: 0,
            borderColor: 'transparent',
            borderBottomColor: theme.palette.background.default,
            borderStyle: 'dashed dashed solid',
            borderWidth: '0 11px 11px',
        },
        '&:after': {
            content: '" "',
            zIndex: '-2',
            position: 'absolute',
            right: '6px',
            top: '-13px',
            height: 0,
            width: 0,
            borderColor: 'transparent',
            borderBottomColor: theme.palette.grey[200],
            borderStyle: 'dashed dashed solid',
            borderWidth: '0 13px 13px',
        },
    },
    listSubheader: {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    actionsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
    },
    guttersFix: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
//  defaultButton: {
//    border: `1px solid ${theme.palette.text.disabled}`,
//  },
    notificationsMenuWidth: {
        width: '273px',
    },
    buttonWrap: {
//    padding: `${theme.spacing.unit}px 0`,
//    minWidth: 'auto',
//    backgroundColor: 'transparent',
//    color: brandSecondaryText,
//    width: '32px',
//    height: '36px',

        '&:hover': {
//      backgroundColor: 'transparent',
//      color: brandPrimary
        }
    },
    notificationIndicator: {
        position: 'absolute',
        top: '-29%',
        right: '-30%',
        transform: 'translate(-50%, -50%)',
        //backgroundColor: brandPrimaryActiveText,
        width: theme.spacing(1),
        height: theme.spacing(1),
        borderRadius: theme.spacing(1),
    },
});

export const profileIconMenuBaseStyleSheet = theme => ({
    typography: {
        margin: theme.spacing(2),
    },
    userActionsBadge: {
        color: theme.palette.common.white,
        //backgroundColor: brandPrimaryActiveText,
        top: '50%',
        right: '-12px',
        width: '18px',
        height: '18px',
        fontWeight: theme.typography.fontWeightMedium,
        borderRadius: '50%',
    },
    notificationIndicator: {
        position: 'absolute',
        top: '-2px',
        right: '4px',
        //backgroundColor: brandPrimaryActiveText,
        width: theme.spacing(1),
        height: theme.spacing(1),
        borderRadius: theme.spacing(1),
        display: 'none'
    },
    actionsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
    },
    profileMenu: {
        width: '280px',
    },
    avatar: {
//    color: theme.palette.getContrastText(theme.palette.primary.main),
//    backgroundColor: 'transparent',
//    width: theme.spacing(4),
//    height: theme.spacing(4),
//    fontSize: '1rem',
//    fontWeight: theme.typography.fontWeightLight,
//    textAlign: 'center',
//    lineHeight: theme.spacing(4),
    },
    avatarProfileMenu: {
//    width: theme.spacing(8),
//    height: theme.spacing(8),
        backgroundColor: 'transparent',
//    fontSize: '2rem',
        fontWeight: theme.typography.fontWeightLight,
        textAlign: 'center',
//    lineHeight: theme.spacing(8),
    },
    avatarWrap: {
        padding: 0,
        minWidth: 0,
        borderRadius: '500px',
        transition: 'border 0.3s',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    guttersFix: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    defaultButton: {
//    border: `1px solid ${theme.palette.text.disabled}`,
    },
    popoverList: {
        margin: theme.spacing(1, 0),
    },
//    systemButtonObjLight: {
//        background: 'transparent none',
//        border: '1px solid transparent',
//        padding: theme.spacing(0.5, 1.5),
//        '&:hover': {
////            backgroundColor: systemDark,
////            backgroundImage: `linear-gradient(top,${systemDark},${systemLight})`,
////            border: `1px solid ${systemLightTransitionDark}`,
//        },
//        '&:active': {
//            color: theme.palette.text.textPrimary,
////            background: `-webkit-linear-gradient(top,${systemDark},${systemDarkFade})`,
//            '-webkit-box-shadow': 'inset 0 1px 2px 0 rgba(0,0,0,0.1)',
////            border: `1px solid ${systemLightTransitionDark}`,
//            boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.1)',
//        },
//        '&$.isSelected': {
//            color: theme.palette.text.textPrimary,
////            background: `-webkit-linear-gradient(top,${systemDark},${systemDarkFade})`,
//            '-webkit-box-shadow': 'inset 0 1px 2px 0 rgba(0,0,0,0.1)',
////            border: `1px solid ${systemLightTransitionDark}`,
//            boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.1)',
//        }
//    }
});

export const listStyleSheet = theme => ({
    list: {
        width: '100%',
    },
    rootListItemNode: {
        fontWeight: theme.typography.fontWeightMedium,
        paddingLeft: theme.spacing(3),
    },
    listWithIcon: {
        paddingLeft: 0,
    },
    selectedListItem: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    nestedListLink: {
        color: 'inherit',
        '&:hover': {
            color: theme.palette.text.primary, /// brandPrimaryText
        }
    },
    primaryLink: {
    	color: theme.palette.primary.main,
    	'&:hover': {
			color: theme.palette.primary.dark,
		}
    },
    listGroup: {
        margin: theme.spacing(0, 2),
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    wrap: {
        flexWrap: 'wrap'
    },
    inlineItem: {
        width: 'auto',
        display: 'inline-flex',
    },
    extraDensePadding: {
        paddingTop: 0,
    },
    inlineListItemText: {
        padding: theme.spacing(0, 1),
        '&:first-child': {
            padding: theme.spacing(0, 1),
        }
    },
    verticalSpaceMedium: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    noListPadding: {
        padding: `0 ${theme.spacing(1)}px 0 0`,
    }
});

export const richSubHeaderListStyleSheet = theme => ({
    headerGroup: {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            marginBottom: theme.spacing(4),
        }
    },
    listContainer: {
        marginBottom: theme.spacing(4),
        width: '100%',
        minWidth: '100%',
    },
    listGroup: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    listSubheader: {
        borderRadius: theme.shape.borderRadius,
        marginBottom: '1px',
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
        boxShadow: "0px 1px 3px 0 rgb(0,0,0,0.25)",
    },
    textBold: {
        fontWeight: theme.typography.fontWeightMedium
    },
    gutters: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        }
    },
    verticalLeading: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    backgroundPaper: {
        backgroundColor: theme.palette.background.paper,
    },
    backgroundDefault: {
        backgroundColor: systemSubZero,
    },
    zeroPadding: {
        padding: theme.spacing(0),
    },
    fullWidth: {
        width: '100%'
    },
    smallAvatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    }
});

export const drawerListStyleSheet = theme => ({
    container: {
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
    },
//    backgroundDefault: {
//        backgroundColor: systemSubZero
//    },
    innerFlexRowContainer: {
        flex: '1 1 auto',
        alignItems: 'center'
    },
    topAligned: {
        alignSelf: 'flex-start'
    },
    verticalLeading: {
        paddingBottom: theme.spacing(3)
    },
//    flatPanel: {
//        backgroundColor: theme.palette.background.default,
//        border: `1px solid ${theme.palette.grey[300]}`
//    },
    growItem: {
        flexGrow: 1,
    },
    spacer: {
        marginRight: theme.spacing(2),
    },
    buttonIconWrap: {
        marginRight: theme.spacing(1),
//		padding: theme.spacing(1.5),
//		paddingTop: 0,
//		paddingBottom: 0,
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        },
    },
    list: {
//		width: drawerWidth,
        width: '100%',
//		display: 'flex',
//		flex: 'auto'
    },
    withDrawerTitle: {
    	height: 'calc(100vh - 56px)',
        overflow: 'hidden',
    },
    scrollContainer: {
    	height: 'calc(100vh - 56px)',
    	paddingTop: theme.spacing(1),
    	paddingBottom: theme.spacing(2),
        overflowY: 'auto',
    },
    drawerPaper: {
        width: drawerWidth,
    },
    rootItem: {
        paddingLeft: theme.spacing(3),
    },
    listGroup: {
        margin: theme.spacing(0, 2),
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    listFixed: {
        borderTop: '0 none',
        minWidth: '100%',
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            right: 0,
            top: '75px',
            minWidth: 'auto',
        }
    },
    listContainer: {
        marginBottom: theme.spacing(4),
        width: '100%',
        minWidth: '100%',
        border: `1px solid ${theme.palette.grey[300]}`,
    },
    rootListItemNode: {
        fontWeight: theme.typography.fontWeightMedium,
        paddingLeft: theme.spacing(3),
    },
    rootListItemNodeLink: {
		fontSize: theme.typography.body2.fontSize,
	},
    firstChildListItemNode: {
        fontWeight: theme.typography.fontWeightMedium,
        paddingLeft: theme.spacing(2),
    },
    topLevelLink: {
//		textDecoration: 'none',
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: '1rem',

//		'&.active': {
////		  textDecoration: 'none',
//		  color: theme.palette.text.primary
//		},
        '&:hover': {
//		  textDecoration: 'none',
            color: `${theme.palette.text.primary}`,
        },
    },
    topLevelNode: {
        fontSize: '0.875rem',
//        color: brandPrimaryText,

        '&:hover': {
//            color: brandPrimaryText
        }
    },
    topLevelBlock: {
        display: 'block',
        color: theme.palette.secondary.main, // brandSecondaryText,
        '&:hover': {
            color: theme.palette.text.secondary, //brandPrimaryText
        }
    },
    selectedListItem: {
        fontWeight: theme.typography.fontWeightMedium,
    },
    nestedListCollapse: {
        cursor: 'pointer',
        textDecoration: 'none',
        fontSize: '0.75rem',
        fontWeight: theme.typography.fontWeightBold
    },
    inherit: {
        color: 'inherit',
        fontSize: 'inherit',
        lineHeight: '100%',
    },
    nested: {
        color: 'inherit', //brandSecondaryText,
        fontSize: '0.875rem',
        textDecoration: 'none',
//        paddingLeft: theme.spacing(4),
//        '&:first-child': {
//            paddingLeft: theme.spacing(4),
//        },
        '&:hover': {
            color: theme.palette.text.secondary, /// brandPrimaryText
        }
    },
    nestedListLinkText: {
//    color: theme.palette.text.secondary,
//         paddingLeft: theme.spacing(6),
        '&:hover': {
            color: theme.palette.text.primary, /// brandPrimaryText
        }
    },
    nestedListLink: {
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.text.primary, /// brandPrimaryText
        },
    },
    nestedListGutter: {
        paddingLeft: theme.spacing(4),
    },
    noIndentLink: {
        paddingLeft: theme.spacing(4),
    },
    noIndentLinkNested: {
        paddingLeft: theme.spacing(4),
    },
    indentedNestedLink: {
        paddingLeft: theme.spacing(6),
    },
    mediumText: {
      fontWeight: '500'
    },
    nestedLink: {
        color: theme.palette.text.link,
    },
    listItemIcon: {
        flexGrow: 0,
        alignSelf: 'center',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    muted: {
        color: theme.palette.text.secondary,
    },
    mutedIconOffSet: {
        color: theme.palette.text.secondary,
        right: theme.spacing(2),
        transform: 'translate(-50%, -50%)',
    },
    link: {
        color: theme.palette.secondary.main,
    },
    badge: {
        color: 'inherit',
        lineHeight: '100%',
        fontSize: '0.875rem',
        top: 'auto',
        width: '0.875rem',
        right: '-0.875rem',
        height: '0.875rem',
        fontWeight: '700'
    },
    lastListItem: {
        borderBottom: '0 none'
    },
    northEast: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    // growItem: {
    //     flexGrow: 2,
    //     display: 'inline-flex'
    // },
    spaceBetween: {
        justifyContent: 'space-between'
    },
    menuBadge: {
        display: 'flex',
    },
    staticBadge: {
        position: 'static'
    },
    denseListItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    isHidden: {
		display: 'none',
		visibility: 'hidden',
    },
    selectedBackgroundColor: {
        backgroundColor: `${alpha(theme.palette.primary.main, 0.20)}`,
        '&:hover': {
            backgroundColor: `${alpha(theme.palette.primary.main, 0.20)}`,
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        }
    }
});

export const counterStylesheet = theme => ({
    root: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    customBadge: {
        backgroundColor: 'transparent',
        color: theme.palette.text.primary,
        display: 'flex',
        top: 'auto',
        right: 'auto',
        position: 'relative',
        width: '0.875em',
        height: '0.875em'
    },
    customIcon: {
        marginRight: theme.spacing(2),
        fontSize: `${theme.spacing(2)}px`,
    },
})

export const pageScaffoldStyles = theme => ({
    pageWrap: {
        maxWidth: '600px',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1000px',
        },
        margin: '0 auto'
    },
    pageArticleWrapper: {
        margin: 0,
        maxWidth: '600px',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '1000px',
        },
    }
})

export const searchInputStyleSheet = theme => ({
    paper: {
//        marginTop: 0,
//        marginBottom: 0,
        backgroundColor: theme.palette.grey[100],
    },
    buttonWrap: {
        float: 'right',
        padding: 0,
        minWidth: 'auto',
        backgroundColor: 'transparent',
//        color: brandSecondaryText,
        width: '36px',
        height: '36px',
        verticalAlign: 'top',

        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    flexGrow: {
        flex: '1 0 auto'
    },
    inlineFlex: {
        display: 'inline-block',
    },
    snapCenter: {
        marginTop: 0,
        marginBottom: 0,
    }
});

export const appbarContentStyleSheet = theme => ({
    title: {
        marginRight: theme.spacing(2),
        textTransform: 'none',
        color: theme.palette.text.secondary,
        textDecoration: 'none',
    },
    productWrapper: {
        flex: '0 0 auto',
        [theme.breakpoints.down('xs')]: {
            minWidth: theme.spacing(20),
            flexBasis: theme.spacing(20),
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: theme.spacing(30),
            flexBasis: theme.spacing(30),
        },
    },
    flexMenu: {
        flex: '1 1 0',
    }
});

export const menuDrawerStyleSheet = theme => ({
    list: {
        width: 250,
        flex: 'initial',
    },
    item: {
        textDecoration: 'none',
        color: theme.palette.text.primary,
        '&:focus': {
            background: 'none',
        },
        '&:hover': {
            background: theme.palette.divider,
        },
    },
    icon: {
        color: theme.palette.base.common.white,
        '&:hover': {
            color: theme.palette.base.common.black
        }
    },
});

export const genericInlineItemFlexStyles = () => {
    return {
        borderRadius: 0,
        display: 'inline-flex',
        padding: '0.1em ',
        alignItems: 'center',
        flexWrap: 'wrap',
        position: 'relative',
        flexGrow: 1,
        // margin: '0 0.5em'
    }
};

export const preStyleSheet = (theme) => ({
    /**
     * https://unpkg.com/prismjs/themes/prism-okaidia.css
     *
     * okaidia theme for JavaScript, CSS and HTML
     * Loosely based on Monokai textmate theme by http://www.monokai.nl/
     * @author ocodia
     */
    /* Code blocks */
    pre: {
        backgroundColor: '#272c34',
        padding: theme.spacing(2),
        // margin: theme.spacing(1)
        overflow: 'auto',
    },
});
