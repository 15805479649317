import React from 'react'
import classnames from 'classnames';
import { withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import {alpha} from '@mui/material/styles';

//import { primaryTextButtonHoverLight, primaryTextButtonHoverExtraLight } from '../../themes/color-palette.js';

const styles = theme => ({
//    root: {
//        backgroundColor: 'transparent',
////		border: '1px solid transparent',
//        '&:hover': {
//            borderColor: `${alpha(theme.palette.text.secondary, 0.18)}`,
//            color: `${alpha(theme.palette.text.primary, 0.56)}`,
////			backgroundColor: `${alpha(primaryTextButtonHoverLight, 0.18)}`,
////			backgroundColor: 'transparent',
//        },
//    }
	/* Styles applied to the root element if `color="default"`. */
	colorDefault: {
		color: `${alpha(theme.palette.text.primary, 0.56)}`,
		 '&:hover': {
		 	color: `${alpha(theme.palette.text.primary, 0.56)}`,
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		 }
	},
});

const DefaultIconButton = React.forwardRef(function DefaultIconButton(props, ref) {
  const {
    children,
    classes,
    className,
    ariaLabel,
    color = 'default',
    Icon,
    disabled=false,
    onClick,
    ...other
  } = props;

  return (
  	<IconButton
		aria-label={ariaLabel}
		className={classnames(
			{[classes.colorDefault]: color === 'default'},
			className,
		)}
		color={color}
		disabled={disabled}
      	onClick={onClick}
      	ref={ref}
    >
    	{Icon}
     	{children}
    </IconButton>
  );
});

DefaultIconButton.propTypes = {
  /**
   * The icon element.
   */
  children: PropTypes.node,
  /**
   * The label is required to set required aria-label
   */
   ariaLabel: PropTypes.string.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  /**
   * If `true`, the button will be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If given, uses a negative margin to counteract the padding on one
   * side (this is often helpful for aligning the left or right
   * side of the icon with content above or below, without ruining the border
   * size and shape).
   */
  edge: PropTypes.oneOf(['start', 'end', false]),
  /**
   * The size of the button.
   * `small` is equivalent to the dense button styling.
   */
  size: PropTypes.oneOf(['small', 'medium']),
};

export default withStyles(styles)(DefaultIconButton);