import React from 'react'
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {Link as RouterLink} from 'react-router-dom';

const Text = (props) => {
    const {
        href,
        toRoute,
        onClick,
        linkClassName,
        LinkProps = {},
        ...other
    } = props;

    if (onClick) {
        return (
            <Link
                component="button"
                onClick={onClick}
                className={linkClassName}
                {...LinkProps}
                {...other}
            />
        )
    }
    if (href) {
        const {children, ...rest} = other;
        return (
            <Typography
                {...rest}
            >
                <Link
                    href={href}
                    {...LinkProps}
                >
                    {children}
                </Link>
            </Typography>

        )
    }
    if (toRoute) {
        const {children, ...rest} = other;
        return (
            <Typography
                {...rest}
            >
                <RouterLink
                    to={toRoute}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    className={linkClassName}
                    {...LinkProps}
                >
                    {children}
                </RouterLink>
            </Typography>
        )
    }
    return (
        <Typography {...other}/>
    )

}
export default (Text)