import { setCurrentContext } from 'gc-ui-components/ui/mw/application/actions/application-actions'

export const gridStyles = {
  width: '100%',
  '& .D1': {
    backgroundColor: 'rgba(157, 255, 118, 0.49)',
  },
  '& .D2': {
    backgroundColor: 'rgba(255, 255, 10, 0.49)',
  },
  '& .D3': {
    backgroundColor: 'rgba(150, 150, 150, 0.49)',
  },
  '& .D4': {
    backgroundColor: 'rgba(255, 150, 150, 0.49)',
  },
  '& .D5': {
    backgroundColor: 'rgba(10, 150, 255, 0.49)',
  },
  '& .D6': {
    backgroundColor: 'rgba(224, 183, 60, 0.55)',
  },
  '& .D7': {
    backgroundColor: 'rgba(200, 150, 255, 0.49)',
  },
};

export const handleInitialCurrentContext = (dispatch, props, contextHash, channels) => {
    const {contextConfigurations} = props;
    if (contextConfigurations) {
        if (!contextConfigurations[contextHash] && dispatch) {
            dispatch(setCurrentContext(contextHash))
        } else if (contextConfigurations.currentContext) {
            if (contextHash != contextConfigurations.currentContext) {
                dispatch(setCurrentContext(contextHash))
            }
            return null;
        }
    }
    return null;
}