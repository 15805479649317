import {defineMessages} from 'react-intl';

import {defaultI18nMessage} from '@dc/dc/i18n/MessageUtils';

export const dcxMessage = (name, message) => {
    return defaultI18nMessage('dcx', name, message);
}

export const dcxMessages = defineMessages({
    routeNotificationsLabel: dcxMessage('routeNotificationsLabel', 'Notifications'),
    routeHomeLabel: dcxMessage('routeHomeLabel', 'Home'),
    hierarchicDrawerHeader: dcxMessage('hierarchicDrawerHeader', 'My account'),
    defaultErrorMessage: dcxMessage('defaultErrorMessage', 'Error'),
    defaultLoadingMessage: dcxMessage('defaultLoadingMessage', 'Loading..'),
    defaultNoResultsMessage: dcxMessage('defaultNoResultsMessage', 'No results'),
    defaultRejectedMessage: dcxMessage('defaultRejectedMessage', 'Rejected'),
    defaultSubmittingMessage: dcxMessage('defaultSubmittingMessage', 'Submitting..'),
    defaultSuccessMessage: dcxMessage('defaultSuccessMessage', 'Success'),
    defaultWarningMessage: dcxMessage('defaultWarningMessage', 'Warning'),
})