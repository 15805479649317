export const BASE_TYPOGRAPHY = {
    typography: {
        h1: {
            fontSize: "2.125rem",
            lineHeight: "2.375rem",
            '@media (min-width:600px)': {
                fontSize: "3.375rem",
                lineHeight: "3.625rem",
            },
        },
        h2: {
            fontSize: "2.125rem",
            lineHeight: "2.375rem",
        },
        h3: {
            fontSize: "1.5rem",
            lineHeight: "1.625rem",

            h4: {
                fontSize: "1.125rem",
                lineHeight: "1.5rem",
            },
            h5: {
                fontSize: "1.375rem",
            },
            h6: {
                fontSize: "1.225rem",
            },
            body1: {
                fontSize: "1rem",
            },
            body2: {
                fontSize: "0.875rem",
            },
            caption: {
                fontSize: "0.75rem",
            }
        }
    }
}