export const ADD_NOTIFICATION = '$$ADD_NOTIFICATION';
export const ADD_BATCH_NOTIFICATION = '$$ADD_BATCH_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = '$$CLEAR_NOTIFICATIONS';
export const RESET_CURRENT_VIEW = '$$RESET_CURRENT_VIEW';

export const ADD_PENDING_ACTION_BATCH_NOTIFICATION = '$$ADD_PENDING_ACTION_BATCH_NOTIFICATION';
export const UPDATE_PENDING_ACTION_NOTIFICATION = '$$UPDATE_PENDING_ACTION_NOTIFICATION';

export const PUBLISH_STRUCTURED_MESSAGE = '$$PUBLISH_STRUCTURED_MESSAGE';

export const addNotification = (notificationObject) => {
    return {
        type: ADD_NOTIFICATION,
        notificationObject: notificationObject,
    }
}

export const addBatchPendingActionsNotification = (notificationObjects) => {
    return {
        type: ADD_PENDING_ACTION_BATCH_NOTIFICATION,
        notificationObjects: notificationObjects,
    }
}

export const updatePendingActionNotification = (updateObject) => {
    return {
        type: UPDATE_PENDING_ACTION_NOTIFICATION,
        updateObject: updateObject,
    }
}

export const addBatchNotification = (notificationObjects) => {
    return {
        type: ADD_BATCH_NOTIFICATION,
        notificationObjects: notificationObjects,
    }
}

export const clearNotifications = () => {
    return {
        type: CLEAR_NOTIFICATIONS,
    }
}

export const resetCurrentView = () => {
    return {
        type: RESET_CURRENT_VIEW,
    }
}

export const publishStructuredMessage = (encoder, messageBase64, timestamp) => {
    return {
        type: PUBLISH_STRUCTURED_MESSAGE,
        encoder,
        messageBase64,
        timestamp,
    }
}
