import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Text from '@dc/dc/content/Text';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ContainerX from '@dc/dc/components/layout/ContainerX';
import Worksheet, {WorksheetNode} from '@dc/dc/components/surface/Worksheet';
import SchedulesGrid from 'gc-webapp/gc/SchedulesGrid'
import ProgramsGrid from 'gc-webapp/gc/ProgramsGrid'
import Loading from '@dc/dcx/app/common/Loading';
import NavigationDrawer from 'gc-ui-components/ui/navigation/NavigationDrawer';
import { a11yProps, CustomTabPanel } from './TabsCommons'

const Configurations = ({userContext}) => {

 const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    return (
        <>
        <ContainerX AsContainer withLargeBottomMargin withLargeTopMargin>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="Configurations Tabs">
                  <Tab label="PROGRAMS" {...a11yProps(0)} />
                  <Tab label="SCHEDULES" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
              <Grid container spacing={3}>
                	<Grid item xs={10}>
                         <ProgramsGrid
                           userContext={userContext}
                         />
                    </Grid>
                    <Grid item xs={2}>
                        <ButtonGroup
                            orientation="vertical"
                            aria-label="Programs options button group"
                            variant="text"
                          >
                            <Button key="one">Add program</Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                    <SchedulesGrid
                        userContext={userContext}
                   />
              </CustomTabPanel>
            </ContainerX>
        </>
    )
}
export default connect(state => { return {} })(Configurations)